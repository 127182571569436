// BASIC IMPORTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// MUI IMPORT
import { Button } from "@mui/material";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import ProfileEditForm from "./ProfileEditForm";

// API IMPORTS
import { clientGet, clientEditPasswordPut } from "../../../actions/authAction";


function Profile() {
  const navigate = useNavigate();
  const [client, setClient] = useState({});

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.auth?.client?.data);

  const handleFormSubmit = async (data) => {
    if (data?.password && client?.client) {
      const payload = { password: data?.password };
      await clientEditPasswordPut(dispatch, client?.client, payload);
      if (store.getState()?.auth?.client_password) {
        toast.success("Password updated successfully!");
      } else {
        toast.success("Password not updated! Something went wrong");
      }
    }
  };

  useEffect(() => {
    const getClient = async () => {
      await clientGet(dispatch);
      if (store.getState()?.auth?.client) {
        setClient(store.getState()?.auth?.client?.data);
      }
    };
    
    if(activeUser === undefined){
      getClient();
    }else{
      setClient(activeUser)
    }
    // eslint-disable-next-line
  }, [dispatch, store]);


  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/profile">Profile</Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-3">
                      <div className="ListWithBadge">
                        <Button
                          className="adminbackBtn"
                          onClick={() => navigate("/trading")}
                        >
                          <i className="feather icon-chevron-right"></i>
                        </Button>
                        <h3 className="m-b-20 mr-2">User Profile</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <ProfileEditForm
                  initialValues={client}
                  onSubmit={handleFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
