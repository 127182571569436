import axios from "axios";
import { endLoader, startLoader } from "./loader_wrapper";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/api`,
});

let requestCount = 0;

axiosClient.interceptors.request.use((config) => {
  const token = JSON.parse(sessionStorage.getItem('auth'));
  if (requestCount === 0) {
    // First request, start the loader
    startLoader();
  }
  requestCount++;

  if (token) {
    config.headers.Authorization = `Bearer ${(token && token?.accessToken) ?? "" }`;
  }
  return config;
}, (error) => Promise.reject(error));


axiosClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 403) {
    window.location.href = '/';
    sessionStorage.removeItem("auth")
  }
  return Promise.reject(error);
});

// Response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    // TO stop top loader
    requestCount--;
    if (requestCount === 0) {
      // Last request, stop the loader
      endLoader();
    }

    // You can add any logic here for successful responses
    return response;
  },
  (error) => {
    // TO stop top loader
    requestCount--;

    if (requestCount === 0) {
      // Last request, stop the loader
      endLoader();
    }
    // You can add error handling logic here
    return Promise.reject(error);
  }
);

export default axiosClient;