// BASIC IMPORTS
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import TradingEditForm from "./TradingEditForm";

// API IMPORTS
import { tradingGetById, tradingUpdate } from "../../../actions/tradingAction";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";


function TradingEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialData, setInitialData] = useState();
  const [filterData, setFilterData] = useState([]);
  const [dropList, setDropList] = useState([]);
  const [product, setProduct] = useState([]);

  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);

  useEffect(() => {
    const getTradingData = async () => {
      await tradingGetById(dispatch, id);
      if (store.getState()?.trading?.tradingData) {
        setInitialData(store.getState()?.trading?.tradingData?.data);
      }
    };
    getTradingData();
  }, [id, dispatch, store]);

  const getTickSize = (shortName, companyName, accountName, marketType) => {
    const symbol = filterData?.find(
      (symbol) =>
        symbol?.shortName === shortName &&
        symbol?.companyName === companyName &&
        symbol?.accountName === accountName &&
        symbol?.marketType === marketType
    );
    return symbol ? symbol?.tickSize : "Tick size not found";
  };

  const handleFormSubmit = async (data) => {
    delete data["client"];

    if (data?.switchType === false) {
      data["switchType"] = "Buy";
    } else if (data?.switchType === true) {
      data["switchType"] = "Sell";
    } else if (data?.switchType === "Sell") {
      data["switchType"] = "Sell";
    } else if (data?.switchType === "Buy") {
      data["switchType"] = "Buy";
    }

    const foundItem = product?.find(
      (item) => Object.values(item)[0] === data["product"]
    );

    const key = foundItem ? Object.keys(foundItem)[0] : null;
    data["product"] = key;

    const selectedValue = dropList?.setting?.find((setting) => {
      const nestedObject = setting[Object.keys(setting)[0]]; // Extract the nested object
      return nestedObject.hasOwnProperty(data["accountName"]);
    });
    const selectedAccount = Object.values(selectedValue)[0];
    const account_name = selectedAccount[data["accountName"]];

    data["tickSize"] = getTickSize(
      data["symbol"],
      data["companyName"],
      account_name,
      data["exchange"]
    );
    if (key !== null) {
      await tradingUpdate(dispatch, id, data);
      if (store.getState()?.trading?.tradingData) {
        toast.success("Data updated successfully!");
        navigate("/trading");
      } else if (store.getState()?.trading?.error) {
        toast.error(
          store.getState()?.trading?.errorMessage
            ? store.getState()?.trading?.errorMessage?.message
            : "Error updating data. Please try again."
        );
      }
    }
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState()?.exchange?.exchangeProductSettingList) {
      const res = store.getState()?.exchange?.exchangeProductSettingList;
      if (res) {
        setDropList(res?.data);
        setFilterData(res?.data?.symbols);
      }
    }
  };

  useEffect(() => {
    getSettingData();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/trading">Trading</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="" style={{ fontWeight: 600 }}>
                          Edit
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <TradingEditForm
                  initialValues={initialData}
                  onSubmit={handleFormSubmit}
                  marketData={filterData}
                  dropList={dropList}
                  product={product}
                  setProduct={setProduct}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TradingEdit;
