import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { IconButton, Switch } from '@mui/material';
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";
import { updateOptionStrategyTwo, getOptionStrategyTwoById } from "../../../actions/optionStrategyTwoAction";
import { getSymbolExpirys } from "../../../actions/optionStrategyOneAction";
import { getAllSymbols } from "../../../actions/optionStrategyCommanAction";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
import AddIcon from '@mui/icons-material/Add';


function OptionStrategyTwoEdit() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      account: "",
      symbol: "",
      expiry_date: "",
      trades: [{ switchType: false, buy_quantity: '', sell_quantity: '' }]
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "trades"
  });

  const [dropdownList, setDropdownList] = useState({ setting: [] });
  const [expiryData, setExpiryData] = useState({});
  const [selectedDay, setSelectedDay] = useState([])
  const [expiries, setExpiries] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState("")
  const [selectedAccount, setSelectedAccount] = useState("")
  const [strategySymbols, setStrategySymbols] = useState([]);
  const [symbols, setAllSymbols] = useState([]);
  const [accountName, setAccountName] = useState();
  const [initialData, setInitialData] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const handleDayChange = (day) => {
    if (selectedDay.includes(day)) {
      setSelectedDay(selectedDay.filter(x => x !== day))
    }
    else {
      setSelectedDay([...selectedDay, day])
    }
  }

  const onSubmit = async (data) => {
    const selectedAccount = data.account.split("|");
    const submissionData = {
      ...data,
      account_id: selectedAccount[0],
      account_name: selectedAccount[1],
    };
    submissionData["runningDays"] = selectedDay
    delete submissionData["account"]

    const selectedSymbol = submissionData?.symbol?.split("|")
    
    submissionData["companyName"] = selectedSymbol[0]
    submissionData["symbol"] = selectedSymbol[1]
    submissionData["exchange"] = selectedSymbol[2]
    submissionData["broker"] = accountName

    await updateOptionStrategyTwo(dispatch, id, submissionData)
      .then((res) => {
        navigate("/option-strategy-two")
        toast.success("Strategy updaetd successfully");
      })
      .catch((error) => {
        console.error("Error saving data", error);
      });
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState().exchange?.exchangeProductSettingList) {
      const res = store.getState().exchange?.exchangeProductSettingList;
      if (res) {
        setDropdownList(res?.data);
      }
    }
  };

  const handleExpiry = async () => {
    if ((expiries.length === 0 || expiries.length === 1) && Object.keys(expiryData).length >= 2) {
      await getSymbolExpirys(dispatch, expiryData).then((res) => {
        setExpiries(res);
        setValue("expiry_date", selectedExpiry)
      }).catch((error) => { });
    }
  };

  const handleSymbol = (event) => {
    if (event.target.value) {
      const symbolDetail = event.target.value?.split("|");
      const symbol_company_name = symbolDetail[0]
      const symbol_name = symbolDetail[1]
      const symbol_exchange = symbolDetail[2]
      
      if (symbol_name && symbol_exchange) {
        setExpiryData({ ...expiryData, "symbol": symbol_name, "companyName": symbol_company_name, "exchange_segment": symbol_exchange });
      }
      
      setValue("expiry_date", "")
      setSelectedExpiry("")
      setExpiries([])
    }
  };

  const handleAccount = (event) => {
    const accountId = event.target.value.split("|")[0];
    setExpiryData({ ...expiryData, "account_id": accountId });
    setValue("symbol", "")
    setValue("expiry_date", "")
    setSelectedExpiry("")
    setSelectedAccount(event.target.value)
  };

  const handleFormField = () => {
    append({
      switchType: false,
      buy_quantity: '',
      sell_quantity: ''
    });
  };

  const getSymbolsData = async () => {
    await getAllSymbols(dispatch).then((res) => {
      setAllSymbols(res?.data?.symbols || [])
    }).catch((error) => {
      toast.error("Symbols list not found")
    })
  };


  const fetchStrategyData = async () => {
    try {
      const strategyData = await getOptionStrategyTwoById(dispatch, id);
      if (strategyData) {
        setValue("account", `${strategyData.account_id}|${strategyData.account_name}`);
        setValue("expiry_date", strategyData.expiry_date);
        setExpiries([strategyData.expiry_date])
        setSelectedExpiry(strategyData.expiry_date);
        setSelectedAccount(`${strategyData.account_id}|${strategyData.account_name}`)
        setExpiryData({ "symbol": strategyData.symbol, "companyName": strategyData.companyName, "account_id": strategyData.account_id, "exchange_segment": strategyData?.exchange })
        setSelectedDay(strategyData.runningDays);
        setInitialData(strategyData)
        
        reset({
          ...watch(),
          trades: strategyData.trades.map((trade) => ({
            switchType: trade.switchType,
            buy_quantity: trade.buy_quantity,
            sell_quantity: trade.sell_quantity
          }))
        });
      }
    } catch (error) {
      console.error("Error fetching strategy data", error);
    }
  };

  function getValueByKey(key) {
    for (const obj of dropdownList?.setting) {
      const values = Object.values(obj);
      for (const value of values) {
        if (key in value) {
          return value[key];
        }
      }
    }
    return null;
  }

  useEffect(() => {
    getSettingData();
    getSymbolsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchStrategyData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selectedAccount && symbols) {
      const accountName = selectedAccount?.split("|")[1];
      setAccountName(getValueByKey(accountName))
      const filteredSymbol = symbols?.filter(item => item.account_name === accountName);
      setStrategySymbols(filteredSymbol)
    }
    // eslint-disable-next-line
  }, [symbols, selectedAccount])

  useEffect(() => {
    if (initialData && strategySymbols) {
      setValue("symbol", `${initialData?.companyName}|${initialData?.symbol}|${initialData?.exchange}`);
    }
  }, [initialData, strategySymbols, setValue]);

  useEffect(() => {
    if (selectedAccount && symbols) {
      const accountName = selectedAccount?.split("|")[1];
      setAccountName(getValueByKey(accountName))
      const filteredSymbol = symbols?.filter(item => item.account_name === accountName);
      setStrategySymbols(filteredSymbol)
    }
  }, [symbols, selectedAccount])

  useEffect(() => {
    if (initialData && strategySymbols) {
      setValue("symbol", `${initialData?.companyName}|${initialData?.symbol}|${initialData?.exchange}`);
    }
  }, [initialData, strategySymbols, setValue]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/option-strategy-two" style={{ fontWeight: 600 }}>
                            ATM Strategy
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">Edit Strategy</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-body pt-3 pb-1">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-3 pr-0">
                            <label>Account*</label>
                            <select
                              className="form-control"
                              {...register("account", { required: true })}
                              defaultValue=""
                              onChange={handleAccount}
                            >
                              <option value="" disabled>Select...</option>
                              {dropdownList?.setting?.map((setting) => {
                                const id = Object.keys(setting)[0];
                                const nestedObject = setting[id];
                                const key = Object.keys(nestedObject)[0];

                                return (
                                  <option key={id} value={`${id}|${key}`}>
                                    {key}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.account && (
                              <span className="validationAlert">Account is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Symbol*</label>
                            <select
                              className="form-control"
                              {...register("symbol", { required: true })}
                              defaultValue=""
                              onChange={handleSymbol}
                            >
                              <option value="" disabled>Select...</option>
                              {strategySymbols?.map((data, index) => {
                                return accountName === "ANGEL" ? (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.companyName}</option>
                                ) : (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.shortName}</option>
                                ) 
                              })}
                            </select>
                            {errors.symbol && (
                              <span className="validationAlert">Symbol is required</span>
                            )}
                          </div>

                          <div className="form-group col-md-3 pr-0">
                            <label>Expiry date*</label>
                            <Controller
                              name="expiry_date"
                              control={control}
                              defaultValue=""
                              {...register("expiry_date", { required: true })}
                              render={({ field }) => (
                                <select
                                  className="form-control"
                                  {...field}
                                  onClick={handleExpiry}
                                >
                                  <option value="" disabled>Select...</option>
                                  {expiries?.map((data, index) => (
                                    <option key={index} value={data}>{data}</option>
                                  ))}
                                </select>
                              )}
                            />
                            {errors.expiry_date && (
                              <span className="validationAlert">Expiry date is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Running day*</label>
                            <div className="multi-select-tab-wrapper">
                              {
                                ["Mon", "Tue", "Wed", "Thu", "Fri"].map(x => {
                                  return <div className={`tab ${selectedDay.includes(x) ? "tab-active" : ""}`} onClick={() => handleDayChange(x)} >
                                    {x}
                                  </div>
                                })
                              }
                            </div>
                          </div>
                        </div>

                        {fields.map((item, index) => (
                          <div className="row col-md-12 mb-4 pr-0" key={item.id}>
                            <div className="form-group col-md-3 pr-0 text-center" style={{ marginTop: "auto" }}>
                              <label>Call</label>
                              <Switch
                                id={`switchType_${index}`}
                                name={`trades[${index}].switchType`}
                                color="default"
                                {...register(`trades[${index}].switchType`)}
                                checked={watch(`trades[${index}].switchType`)}
                                onChange={(e) => setValue(`trades[${index}].switchType`, e.target.checked)}
                                className="switchStyle"
                              />
                              <label>Put</label>
                            </div>
                            <div className="form-group col-md-2 pr-0">
                              <label>Buy quantity*</label>
                              <input
                                id={`buy_quantity_${index}`}
                                className="form-control"
                                placeholder="Buy Quantity"
                                {...register(`trades[${index}].buy_quantity`, { required: "Buy quantity is required" })}
                              />
                              {errors?.trades && errors.trades[index]?.buy_quantity && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors.trades[index].buy_quantity.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-2 pr-0">
                              <label>Sell quantity*</label>
                              <input
                                id={`sell_quantity_${index}`}
                                className="form-control"
                                placeholder="Sell Quantity"
                                {...register(`trades[${index}].sell_quantity`, { required: "Sell quantity is required" })}
                              />
                              {errors?.trades && errors.trades[index]?.sell_quantity && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors.trades[index].sell_quantity.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-1 pr-0 d-flex align-items-center mt-4">
                              <IconButton
                                aria-label="delete"
                                onClick={() => remove(index)}
                                color="error"
                              >
                                <DeleteIcon />
                              </IconButton>
                            {index ===  fields?.length - 1 ?
                                  <IconButton
                                    aria-label="add"
                                    onClick={handleFormField}
                                    color="primary"
                                  >
                                    <AddIcon />
                                  </IconButton>

                                  : null}
                            </div>
                          </div>
                        ))}


                        <div className="mt-2 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary ml-3 px-4"
                          >
                            Update Strategy
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OptionStrategyTwoEdit;
