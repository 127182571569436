// BASIC IMPORTS
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// MUI IMPORT
import { Box, Paper } from "@mui/material";

// FILE IMPORTs
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { decodeToken } from "../../../helpers/helper";
import NotFound from "../../../images/not_found.png";

// API IMPORTS
import { exchangeCreate, exchangeGet, exchangeDelete } from "../../../actions/exchangeAction";
import { productCreate, productGet, productDelete } from "../../../actions/productAction";


function Master() {
  const [exchangeForm, setExchangeForm] = useState([
    {
      id: "",
      exchange: "",
      saved: false,
    },
  ]);

  const [productForm, setProductForm] = useState([
    {
      id: "",
      product: "",
      account: "",
      subName: "",
      saved: false,
    },
  ]);

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const handleExchangeForm = () => {
    setExchangeForm((prevForms) => [
      ...prevForms,
      {
        id: "",
        exchange: "",
        saved: false,
      },
    ]);
  };

  const handleProductForm = () => {
    setProductForm((prevForms) => [
      ...prevForms,
      {
        id: "",
        product: "",
        account: "",
        subName: "",
        saved: false,
      },
    ]);
  };

  const handleExchangeDeleteForm = async (index, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete Exchange from all users?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setExchangeForm((prevForms) =>
          prevForms?.filter((_, i) => i !== index)
        );
        if (id) {
          await exchangeDelete(dispatch, id);
          if (store.getState()?.exchange?.exchangeData) {
            Swal.fire("Deleted!", "Exchange deleted successfully.", "success");
            getExchangeData();
          } else if (store.getState()?.exchange?.error) {
            toast.error(
              store.getState()?.exchange?.errorMessage
                ? store.getState()?.exchange?.errorMessage?.error
                : "Error deleting data. Please try again."
            );
          }
        }
      }
    });
  };

  const handleProductDeleteForm = async (index, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete Product from all users?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setProductForm((prevForms) => prevForms?.filter((_, i) => i !== index));
        if (id) {
          await productDelete(dispatch, id);
          if (store.getState()?.product?.productData) {
            Swal.fire("Deleted!", "Product deleted successfully.", "success");
            getProductData();
          } else if (store.getState()?.product?.error) {
            toast.error(
              store.getState()?.product?.errorMessage
                ? store.getState()?.product?.errorMessage?.error
                : "Error deleting data. Please try again."
            );
          }
        }
      }
    });
  };

  const handleExchangeChange = (index, field, value) => {
    setExchangeForm((prevForms) => {
      const newForms = [...prevForms];
      newForms[index][field] = value;
      return newForms;
    });
  };

  const handleProductChange = (index, field, value) => {
    setProductForm((prevForms) => {
      const newForms = [...prevForms];
      newForms[index][field] = value;
      return newForms;
    });
  };

  const handleExchangeSave = async (index) => {
    const user = decodeToken();
    const data = exchangeForm.filter((_, i) => i === index)[0];
    if (user && data["saved"] === false) {
      delete data["saved"];
      delete data["id"];
      await exchangeCreate(dispatch, data);
      if (store.getState()?.exchange?.exchange) {
        toast.success("Data added successfully!");
        setExchangeForm((prevForms) =>
          prevForms.map((form, i) =>
            i === index
              ? {
                  ...form,
                  saved: true,
                  id: store.getState()?.exchange?.exchange?.data["_id"],
                }
              : form
          )
        );
      } else if (store.getState()?.exchange?.error) {
        toast.error(
          store.getState()?.exchange?.errorMessage
            ? store.getState()?.exchange?.errorMessage?.error
            : "Error adding data. Please try again."
        );
      }
    }
  };

  const handleProductSave = async (index) => {
    const user = decodeToken();
    const data = productForm?.filter((_, i) => i === index)[0];
    if (user && data["saved"] === false) {
      delete data["saved"];
      delete data["id"];
      await productCreate(dispatch, data);
      if (store.getState()?.product?.product) {
        toast.success("Data added successfully!");
        setProductForm((prevForms) =>
          prevForms.map((form, i) =>
            i === index
              ? {
                  ...form,
                  saved: true,
                  id: store.getState()?.product?.product?.data["_id"],
                }
              : form
          )
        );
      } else if (store.getState()?.product?.error) {
        toast.error(
          store.getState()?.product?.errorMessage
            ? store.getState()?.product?.errorMessage?.error
            : "Error adding data. Please try again."
        );
      }
    }
  };

  const getExchangeData = async () => {
    await exchangeGet(dispatch);
    if (store.getState()?.exchange?.exchangeList) {
      const exchange_data = store.getState()?.exchange?.exchangeList;
      if (exchange_data) {
        const newDataArray = exchange_data?.data?.map((data) => ({
          exchange: data?.exchange,
          id: data?._id,
          saved: true,
        }));
        setExchangeForm(newDataArray);
      }
    }
  };

  const getProductData = async () => {
    await productGet(dispatch);
    if (store.getState()?.product?.productList) {
      const product_data = store.getState()?.product?.productList;
      if (product_data) {
        const newDataArray = product_data?.data?.map((data) => ({
          product: data?.product,
          account: data?.account,
          subName: data?.subName,
          id: data?._id,
          saved: true,
        }));
        setProductForm(newDataArray);
      }
    }
  };

  useEffect(() => {
    getExchangeData();
    getProductData();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/master" style={{ fontWeight: 600 }}>
                            Master
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-lg-3">
                        <div className="ListWithBadge">
                          <h3 className="mr-2">Master</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  <Box sx={{ width: "100%" }}>
                    <Paper
                      sx={{ width: "100%", mb: 2 }}
                      className="settingForm"
                    >
                      <div className="row dynamicForm">
                        <div className="card col-md-6 mobileCard">
                          <div className="card-body mobileCardBody">
                            <div className="col-md-12">
                              <div className="d-flex justify-content-between pb-4">
                                <h3 className="m-0" style={{ color: "teal" }}>
                                  Exchange Master
                                </h3>
                                <button
                                  type="submit"
                                  className="btn btn-primary m-0 ml-2 masterAddButton"
                                  onClick={handleExchangeForm}
                                >
                                  <i className="feather icon-plus m-0"></i>
                                  Add Exchange
                                </button>
                              </div>

                              {exchangeForm?.length !== 0 ? (
                                exchangeForm?.map((form, index) => (
                                  <div
                                    className="form-group d-flex"
                                    key={index}
                                  >
                                    <input
                                      type="name"
                                      className="form-control mr-2"
                                      placeholder="Exchange"
                                      value={form?.exchange}
                                      disabled={form?.saved}
                                      onChange={(e) =>
                                        handleExchangeChange(
                                          index,
                                          "exchange",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <button
                                      type="submit"
                                      className="btn btn-outline-primary mr-2 m-0"
                                      onClick={() => handleExchangeSave(index)}
                                    >
                                      <i className="feather icon-save m-0"></i>
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-outline-danger m-0"
                                      onClick={() =>
                                        handleExchangeDeleteForm(
                                          index,
                                          form?.id
                                        )
                                      }
                                    >
                                      <i className="feather icon-trash-2 m-0"></i>
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <div className="text-center">
                                  <img
                                    src={NotFound}
                                    alt="Not Found"
                                    className="notFoundImg"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="card col-md-6 mobileCard">
                          <div className="card-body mobileCardBody">
                            <div className="col-md-12">
                              <div className="d-flex justify-content-between pb-4">
                                <h3 className="m-0" style={{ color: "teal" }}>
                                  Product Master
                                </h3>
                                <button
                                  type="submit"
                                  className="btn btn-primary m-0 ml-2 masterAddButton"
                                  onClick={handleProductForm}
                                >
                                  <i className="feather icon-plus m-0"></i>
                                  Add Product
                                </button>
                              </div>

                              {productForm?.length !== 0 ? (
                                productForm.map((form, index) => (
                                  <div
                                    className="form-group d-flex"
                                    key={index}
                                  >
                                    <select
                                      className="form-control mr-3"
                                      name="accountName"
                                      disabled={form?.saved}
                                      value={form?.account}
                                      onChange={(e) =>
                                        handleProductChange(
                                          index,
                                          "account",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="" disabled>
                                        Select...
                                      </option>
                                      <option>ICICI</option>
                                      <option>FYERS</option>
                                      <option>ANGEL</option>
                                      <option>UPSTOX</option>
                                    </select>
                                    <input
                                      type="name"
                                      className="form-control mr-2"
                                      placeholder="Product"
                                      value={form?.product}
                                      disabled={form?.saved}
                                      onChange={(e) =>
                                        handleProductChange(
                                          index,
                                          "product",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <select
                                      className="form-control mr-3"
                                      name="subName"
                                      disabled={form?.saved}
                                      value={form?.subName}
                                      onChange={(e) =>
                                        handleProductChange(
                                          index,
                                          "subName",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="" disabled>
                                        Select...
                                      </option>
                                      <option>Equity</option>
                                      <option>Future</option>
                                      <option>Option</option>
                                    </select>
                                    <button
                                      type="submit"
                                      className="btn btn-outline-primary mr-2 m-0"
                                      onClick={() => handleProductSave(index)}
                                    >
                                      <i className="feather icon-save m-0"></i>
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-outline-danger m-0"
                                      onClick={() =>
                                        handleProductDeleteForm(index, form?.id)
                                      }
                                    >
                                      <i className="feather icon-trash-2 m-0"></i>
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <div className="text-center">
                                  <img
                                    src={NotFound}
                                    alt="Not Found"
                                    className="notFoundImg"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Master;
