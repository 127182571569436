import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "product",
  initialState: {
    product: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    createProductStart: (state) => {
      state.isFetching = false;
      state.product = null;
      state.error = false;
      state.errorMessage = "";
    },
    createProductSuccess: (state, action) => {
      state.isFetching = true;
      state.product = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    createProductFailure: (state, error) => {
      state.isFetching = false;
      state.product = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getProductStart: (state) => {
      state.isFetching = false;
      state.productList = null;
      state.error = false;
      state.errorMessage = "";
    },
    getProductSuccess: (state, action) => {
      state.isFetching = true;
      state.productList = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getProductFailure: (state, error) => {
      state.isFetching = false;
      state.productList = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteProductStart: (state) => {
      state.isFetching = false;
      state.productData = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteProductSuccess: (state, action) => {
      state.isFetching = true;
      state.productData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteProductFailure: (state, error) => {
      state.isFetching = false;
      state.productData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
  },
});

export const {
  createProductFailure,
  createProductStart,
  createProductSuccess,
  getProductFailure,
  getProductStart,
  getProductSuccess,
  deleteProductStart,
  deleteProductSuccess,
  deleteProductFailure,
} = productSlice.actions;
export default productSlice.reducer;
