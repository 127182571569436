import axiosClient from "../axios-client";
import {
    backtestPairCycleStart,
    backtestPairCycleSuccess,
    backtestPairCycleFailure,
    fyersApiCallCounterStart,
    fyersApiCallCounterSuccess,
    fyersApiCallCounterFailure,
    backtestPairHistoryStart,
    backtestPairHistorySuccess,
    backtestPairHistoryFailure,
    getAutoBacktestAllSymbolsStart,
    getAutoBacktestAllSymbolsSuccess,
    getAutoBacktestAllSymbolsFailure,
    autoBacktestCycleCountStart,
    autoBacktestCycleCountSuccess,
    autoBacktestCycleCountFailure,
    countAutoBacktestApiCallStart,
    countAutoBacktestApiCallSuccess,
    countAutoBacktestApiCallFailure,
    getFyersSymbolTypesStart,
    getFyersSymbolTypesSuccess,
    getFyersSymbolTypesFailure,
    fyersFilterSymbolStart,
    fyersFilterSymbolSuccess,
    fyersFilterSymbolFailure,
    getAutoBacktestTempSymbolsStart,
    getAutoBacktestTempSymbolsSuccess,
    getAutoBacktestTempSymbolsFailure,
    deleteAutoBacktestTempSymbolsStart,
    deleteAutoBacktestTempSymbolsSuccess,
    deleteAutoBacktestTempSymbolsFailure,
    transferAutoBacktestSymbolsStart,
    transferAutoBacktestSymbolsSuccess,
    transferAutoBacktestSymbolsFailure,
    addAutoBacktestSymbolStart,
    addAutoBacktestSymbolSuccess,
    addAutoBacktestSymbolFailure,
    getAutoBacktestSymbolByIdStart,
    getAutoBacktestSymbolByIdSuccess,
    getAutoBacktestSymbolByIdFailure,
    updateAutoBacktestSymbolByIdStart,
    updateAutoBacktestSymbolByIdSuccess,
    updateAutoBacktestSymbolByIdFailure,
    deleteAutoBacktestSymbolsStart,
    deleteAutoBacktestSymbolsSuccess,
    deleteAutoBacktestSymbolsFailure,
    getNewOrUpdatedSymbolsStart,
    getNewOrUpdatedSymbolsSuccess,
    getNewOrUpdatedSymbolsFailure,
    deleteSymbolsStart,
    deleteSymbolsSuccess,
    deleteSymbolsFailure,
} from "../reducers/backtestReducer";
import authHeader from "../services/auth-header";


export const backtestPairCycleIciciPost = async (dispatch, data) => {
    dispatch(backtestPairCycleStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/backtest-icici", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(backtestPairCycleSuccess(res.data));
    } catch (error) {
        dispatch(backtestPairCycleFailure(error.response?.data));
    }
};

export const backtestPairCycleFyersPost = async (dispatch, data) => {
    dispatch(backtestPairCycleStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/backtest-fyers", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(backtestPairCycleSuccess(res.data));
    } catch (error) {
        dispatch(backtestPairCycleFailure(error.response?.data));
    }
};

export const autoBacktestCycleCount = async (dispatch, data) => {
    dispatch(autoBacktestCycleCountStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/auto-backtest", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(autoBacktestCycleCountSuccess(res.data));
    } catch (error) {
        dispatch(autoBacktestCycleCountFailure(error.response?.data));
    }
};

export const repeatBacktestHistoricalData = async (dispatch, data) => {
    try {
        const token = authHeader();

        const res = await axiosClient.post("/repeat-backtest-historical", data, {
            headers: {
                Authorization: token,
            },
        });

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const repeatBacktestHistory = async (dispatch) => {
    try {
        const token = authHeader();

        const res = await axiosClient.get("/get_repeat_backtest_history", {
            headers: {
                Authorization: token,
            },
        });

        return res?.data;
    } catch (error) { }
};

export const repeatBacktestCycleData = async (dispatch) => {
    try {
        const token = authHeader();

        const res = await axiosClient.get("/get_repeat_backtest_cycle", {
            headers: {
                Authorization: token,
            },
        });

        return res?.data;
    } catch (error) { }
};

export const stopRepeatBacktestCron = async (dispatch) => {
    try {
        const token = authHeader();

        const res = await axiosClient.get("/stop_repeat_backtest_cron", {
            headers: {
                Authorization: token,
            },
        });

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const repeatBacktestData = async (dispatch, data) => {
    try {
        const token = authHeader();

        const res = await axiosClient.post("/repeat-backtest", data, {
            headers: {
                Authorization: token,
            },
        });

        return res?.data;
    } catch (error) { }
};

export const fyersApiCallCounter = async (dispatch) => {
    dispatch(fyersApiCallCounterStart());
    try {
        const token = authHeader();
        const res = await axiosClient.get("/fyers-api-limit", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(fyersApiCallCounterSuccess(res.data));
    } catch (error) {
        dispatch(fyersApiCallCounterFailure(error.response?.data));
    }
};

export const backtestPairHistory = async (dispatch, data) => {
    dispatch(backtestPairHistoryStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/backtest-pair-history-save", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(backtestPairHistorySuccess(res.data));
    } catch (error) {
        dispatch(backtestPairHistoryFailure(error.response?.data));
    }
};

export const backtestPairHistoryGet = async (dispatch) => {
    try {
        const token = authHeader();

        const res = await axiosClient.get("/get-backtest-pair-history", {
            headers: {
                Authorization: token,
            },
        });

        return res?.data;
    } catch (error) { }
};

export const getAutoBacktestAllSymbols = async (dispatch) => {
    dispatch(getAutoBacktestAllSymbolsStart());
    try {
        const token = authHeader();
        const res = await axiosClient.get("/get_auto_backtest_symbols", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getAutoBacktestAllSymbolsSuccess(res.data));
    } catch (error) {
        dispatch(getAutoBacktestAllSymbolsFailure(error.response?.data));
    }
};

export const backtestSymbolCycleCount = async (dispatch) => {
    try {
        const token = authHeader();

        const res = await axiosClient.get("/get_auto_backtest_data", {
            headers: {
                Authorization: token,
            },
        });

        return res?.data;
    } catch (error) { }
};

export const countAutoBacktestApiCall = async (dispatch, data) => {
    dispatch(countAutoBacktestApiCallStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/count_auto_backtest_api_call", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(countAutoBacktestApiCallSuccess(res.data));
    } catch (error) {
        dispatch(countAutoBacktestApiCallFailure(error.response?.data));
    }
};

export const fyersSymbolTypesGet = async (dispatch) => {
    dispatch(getFyersSymbolTypesStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/fyers_symbol_types", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getFyersSymbolTypesSuccess(res.data));
    } catch (error) {
        dispatch(getFyersSymbolTypesFailure(error.response?.data));
    }
};

export const fyersMultiSymbolFilter = async (dispatch, filterData) => {
    dispatch(fyersFilterSymbolStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post(
            `/fyers_multi_symbol_backtest_filter`,
            filterData,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
        dispatch(fyersFilterSymbolSuccess(res.data));
    } catch (error) {
        dispatch(fyersFilterSymbolFailure(error.response?.data));
    }
};

export const getAutoBacktestTempSymbols = async (dispatch) => {
    dispatch(getAutoBacktestTempSymbolsStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/get_auto_backtest_temp_symbols", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getAutoBacktestTempSymbolsSuccess(res.data));
    } catch (error) {
        dispatch(getAutoBacktestTempSymbolsFailure(error.response?.data));
    }
};

export const deleteAutoBacktestTempSymbols = async (dispatch, data) => {
    dispatch(deleteAutoBacktestTempSymbolsStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post(
            "/delete_auto_backtest_temp_symbols",
            data,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
        dispatch(deleteAutoBacktestTempSymbolsSuccess(res.data));
    } catch (error) {
        dispatch(deleteAutoBacktestTempSymbolsFailure(error.response?.data));
    }
};

export const transferAutoBacktestSymbols = async (dispatch, data) => {
    dispatch(transferAutoBacktestSymbolsStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post(
            "/transfer_auto_backtest_symbols",
            data,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
        dispatch(transferAutoBacktestSymbolsSuccess(res.data));
    } catch (error) {
        dispatch(transferAutoBacktestSymbolsFailure(error.response?.data));
    }
};

export const addAutoBacktestSymbol = async (dispatch, data) => {
    dispatch(addAutoBacktestSymbolStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/add_auto_backtest_symbols", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(addAutoBacktestSymbolSuccess(res.data));
    } catch (error) {
        dispatch(addAutoBacktestSymbolFailure(error.response?.data));
    }
};

export const getAutoBacktestSymbolById = async (dispatch, id) => {
    dispatch(getAutoBacktestSymbolByIdStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/get_auto_backtest_symbol/${id}`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getAutoBacktestSymbolByIdSuccess(res.data));
    } catch (error) {
        dispatch(getAutoBacktestSymbolByIdFailure(error.response?.data));
    }
};

export const updateAutoBacktestSymbolById = async (dispatch, id, data) => {
    dispatch(updateAutoBacktestSymbolByIdStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put(
            `/update_auto_backtest_symbol/${id}`,
            data,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
        dispatch(updateAutoBacktestSymbolByIdSuccess(res.data));
    } catch (error) {
        dispatch(updateAutoBacktestSymbolByIdFailure(error.response?.data));
    }
};

export const deleteAutoBacktestSymbols = async (dispatch, data) => {
    dispatch(deleteAutoBacktestSymbolsStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/delete_auto_backtest_symbols", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(deleteAutoBacktestSymbolsSuccess(res.data));
    } catch (error) {
        dispatch(deleteAutoBacktestSymbolsFailure(error.response?.data));
    }
};

export const getNewOrUpdatedSymbols = async (dispatch, id) => {
    dispatch(getNewOrUpdatedSymbolsStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/get_new_or_updated_symbols`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getNewOrUpdatedSymbolsSuccess(res.data));
    } catch (error) {
        dispatch(getNewOrUpdatedSymbolsFailure(error.response?.data));
    }
};

export const deleteSymbols = async (dispatch, data) => {
    dispatch(deleteSymbolsStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/delete_symbols", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(deleteSymbolsSuccess(res.data));
    } catch (error) {
        dispatch(deleteSymbolsFailure(error.response?.data));
    }
};

export const updateAutoBacktestSymbolType = async (dispatch, data) => {
    try {
        const token = authHeader();

        const res = await axiosClient.put(
            `/update_auto_backtest_symbol_type`,
            data,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        return res;
    } catch (error) {
        throw error;
    }
};

export const getPairBacktestAllSymbols = async () => {
    try {
        const token = authHeader();
        const res = await axiosClient.get("/get_pair_backtest_symbols", {
            headers: {
                Authorization: token,
            },
        });
        return res?.data;
    } catch (error) {
        throw error;
    }
};
