import axiosClient from "../axios-client";
import { toast } from "react-toastify";


export const saveOptionStrategyOneData = async (dispatch, data) => {
    try {
        const res = await axiosClient.post("/option_strategy_one", data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getOptionStrategyOneData = async (dispatch) => {
    try {
        const res = await axiosClient.get("/get_option_strategy_one");
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateOptionStrategyOneStatus = async (dispatch, status) => {
    try {
        const res = await axiosClient.put(
            `/update_option_strategy_one_script_status/${status}`
        );
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getSymbolExpirys = async (dispatch, data) => {
    try {
        const res = await axiosClient.put(`/get_option_symbol_expiry`, data);
        return res?.data;
    } catch (error) {
        if (error?.response?.data?.error) {
            toast.error(error?.response?.data?.error);
        }
        throw error;
    }
};
