// BASIC IMPORTS
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// MUI IMPORTS
import { Button } from "@mui/material";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";

// API IMPORTS
import { orderCreate } from "../../../actions/orderAction";
import { orderPairsGet } from "../../../actions/tradingAction";


function OrderCreateForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const [orderPair, setOrderPair] = useState([]);
  const [pairId, setPairId] = useState();

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const getOrderPairs = async () => {
    await orderPairsGet(dispatch);
    if (store.getState()?.trading?.orderPair) {
      const res = store.getState()?.trading?.orderPair;
      if (res) {
        setOrderPair(res?.data);
      }
    }
  };

  const onSubmit = async (data) => {
    await orderCreate(dispatch, data);
    if (store.getState()?.order?.order) {
      toast.success("Data added successfully!");
      navigate("/order");
    } else if (store.getState()?.order?.error) {
      toast.error(
        store.getState()?.order?.errorMessage
          ? store.getState()?.order?.errorMessage?.message
          : "Error adding data. Please try again."
      );
    }
  };

  useEffect(() => {
    getOrderPairs();
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const filteredOrderPair = orderPair.filter((dict) => dict["id"] === pairId);
    if (filteredOrderPair.length === 1) {
      setValue("accountId", filteredOrderPair[0]?.accountId);
      setValue("buyPrice", filteredOrderPair[0]?.buyPrice);
      setValue("companyName", filteredOrderPair[0]?.companyName);
      setValue("symbol", filteredOrderPair[0]?.symbol);
      setValue("sellPrice", filteredOrderPair[0]?.sellPrice);
      setValue("exchange", filteredOrderPair[0]?.exchange);
      setValue("product", filteredOrderPair[0]?.product);
      setValue("switchType", filteredOrderPair[0]?.switchType);
      setValue("expiryDate", filteredOrderPair[0]?.expiryDate);
      setValue("origQty", filteredOrderPair[0]?.quantity);
    }
    // eslint-disable-next-line
  }, [pairId]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/order">Orders</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="" style={{ fontWeight: 600 }}>
                          Create New
                        </Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-3">
                      <div className="ListWithBadge">
                        <Button
                          className="adminbackBtn"
                          onClick={() => navigate("/order")}
                        >
                          <i className="feather icon-chevron-right"></i>
                        </Button>
                        <h3 className="m-b-20 mr-2">Create New</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="card">
                        <div className="card-body mobileCardBody">
                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Pair Id*</label>
                              <Controller
                                name="trading"
                                control={control}
                                rules={{
                                  required: "Pair id is required",
                                }}
                                render={({ field }) => (
                                  <select
                                    {...field}
                                    className="form-control"
                                    defaultValue=""
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      setPairId(e.target.value);
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    {orderPair?.map((dict, index) => (
                                      <option key={index} value={dict["id"]}>
                                        {dict["id"]}{" "}
                                        &nbsp;&nbsp;&nbsp;::&nbsp;&nbsp;&nbsp;BuyPrice:{" "}
                                        {dict["buyPrice"]}{" "}
                                        &nbsp;&nbsp;::&nbsp;&nbsp; SellPrice:{" "}
                                        {dict["sellPrice"]}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              />
                              {errors?.trading && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.trading?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Account Id*</label>
                              <input
                                type="name"
                                className="form-control"
                                disabled={true}
                                placeholder="Account Id"
                                {...register("accountId", {})}
                              />
                            </div>
                          </div>
                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Symbol Full Name*</label>
                              <input
                                type="name"
                                className="form-control"
                                disabled={true}
                                placeholder="Symbol Full Name"
                                {...register("companyName", {})}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>Symbol*</label>
                              <input
                                type="name"
                                className="form-control"
                                disabled={true}
                                placeholder="Symbol"
                                {...register("symbol", {})}
                              />
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Exchange*</label>
                              <input
                                type="name"
                                className="form-control"
                                disabled={true}
                                placeholder="Exchange"
                                {...register("exchange", {})}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>Product*</label>
                              <input
                                type="name"
                                className="form-control"
                                disabled={true}
                                placeholder="Product"
                                {...register("product", {})}
                              />
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Order Id*</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Order Id"
                                {...register("orderId", {
                                  required: "Order id is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.orderId && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.orderId?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Ordered Qty*</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Ordered Qty"
                                {...register("origQty", {
                                  required: "Ordered qty is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.origQty && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.origQty?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Buy Price*</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Buy Price"
                                {...register("buyPrice", {
                                  required: "Buy price is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.buyPrice && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.buyPrice?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Sell Price*</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Sell Price"
                                {...register("sellPrice", {
                                  required: "Sell price is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.sellPrice && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.sellPrice?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Price*</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Price"
                                {...register("price", {
                                  required: "Price is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.price && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.price?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Expiry Date</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Expiry Date"
                                {...register("expiryDate", {})}
                              />
                              {errors?.expiryDate && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.expiryDate?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Order Status*</label>
                              <Controller
                                name="orderStatus"
                                control={control}
                                rules={{
                                  required: "Order status is required",
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <>
                                    <select {...field} className="form-control">
                                      <option value="" disabled>
                                        Select...
                                      </option>
                                      <option>Ordered</option>
                                      <option>Requested</option>
                                      <option>Executed</option>
                                      <option>Expired</option>
                                      <option>Queued</option>
                                    </select>
                                  </>
                                )}
                              />
                              {errors?.orderStatus && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.orderStatus?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Side*</label>
                              <Controller
                                name="side"
                                control={control}
                                rules={{ required: "Side is required" }}
                                defaultValue=""
                                render={({ field }) => (
                                  <>
                                    <select {...field} className="form-control">
                                      <option value="" disabled>
                                        Select...
                                      </option>
                                      <option value="buy">Buy</option>
                                      <option value="sell">Sell</option>
                                    </select>
                                  </>
                                )}
                              />
                              {errors?.side && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.side?.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Switch Type*</label>
                              <Controller
                                name="switchType"
                                control={control}
                                rules={{ required: "Switch Type is required" }}
                                defaultValue=""
                                render={({ field }) => (
                                  <>
                                    <select {...field} className="form-control">
                                      <option value="" disabled>
                                        Select...
                                      </option>
                                      <option>Buy</option>
                                      <option>Sell</option>
                                    </select>
                                  </>
                                )}
                              />
                              {errors?.switchType && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.switchType?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Order Side*</label>
                              <Controller
                                name="orderSide"
                                control={control}
                                rules={{ required: "Order side is required" }}
                                defaultValue=""
                                render={({ field }) => (
                                  <>
                                    <select {...field} className="form-control">
                                      <option value="" disabled>
                                        Select...
                                      </option>
                                      <option>Buy Completed</option>
                                      <option>Sell Completed</option>
                                      <option>Completed</option>
                                      <option>Pending</option>
                                    </select>
                                  </>
                                )}
                              />
                              {errors?.orderSide && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.orderSide?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12 justify-content-center pt-3">
                            <button
                              type="submit"
                              className="btn btn-primary ml-3 px-5"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderCreateForm;
