import axiosClient from "../axios-client";
import {
    createUploadExcelFailure,
    createUploadExcelStart,
    createUploadExcelSuccess,
    getUploadExcelFailure,
    getUploadExcelStart,
    getUploadExcelSuccess,
    angelSymbolStart,
    angelSymbolSuccess,
    angelSymbolFailure,
    getAngelSymbolStart,
    getAngelSymbolFailure,
    getAngelSymbolSuccess,
    upstoxJsonFileStart,
    upstoxJsonFileSuccess,
    upstoxJsonFileFailure,
    getUpstoxSymbolStart,
    getUpstoxSymbolSuccess,
    getUpstoxSymbolFailure,
    fyersSymbolStart,
    fyersSymbolSuccess,
    fyersSymbolFailure,
    getFyersSymbolStart,
    getFyersSymbolSuccess,
    getFyersSymbolFailure,
} from "../reducers/uploadExcelReducer";
import authHeader from "../services/auth-header";


export const uploadExcelGet = async (dispatch) => {
    dispatch(getUploadExcelStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/excel_data", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getUploadExcelSuccess(res.data));
    } catch (error) {
        dispatch(getUploadExcelFailure(error.response?.data));
    }
};

export const uploadExcelCreate = async (dispatch, uploadExcelData) => {
    dispatch(createUploadExcelStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/upload_excel", uploadExcelData, {
            headers: {
                Authorization: token,
                "Content-Type": "multipart/form-data",
            },
        });
        dispatch(createUploadExcelSuccess(res.data));
    } catch (error) {
        dispatch(createUploadExcelFailure(error.response?.data));
    }
};

export const uploadUpstoxSymbolFile = async (
    dispatch,
    uploadUpstoxJsonFile
) => {
    dispatch(upstoxJsonFileStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post(
            "/upload_upstox_json",
            uploadUpstoxJsonFile,
            {
                headers: {
                    Authorization: token,
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        dispatch(upstoxJsonFileSuccess(res.data));
    } catch (error) {
        dispatch(upstoxJsonFileFailure(error.response?.data));
    }
};

export const angelSymbolGet = async (dispatch) => {
    dispatch(getAngelSymbolStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/angel_symbols", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getAngelSymbolSuccess(res.data));
    } catch (error) {
        dispatch(getAngelSymbolFailure(error.response?.data));
    }
};

export const angelSymbolPost = async (dispatch, link) => {
    dispatch(angelSymbolStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/angel_symbols", link, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(angelSymbolSuccess(res.data));
    } catch (error) {
        dispatch(angelSymbolFailure(error.response?.data));
    }
};

export const upstoxSymbolGet = async (dispatch) => {
    dispatch(getUpstoxSymbolStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/upstox_symbols", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getUpstoxSymbolSuccess(res.data));
    } catch (error) {
        dispatch(getUpstoxSymbolFailure(error.response?.data));
    }
};

export const fyersSymbolPost = async (dispatch, link) => {
    dispatch(fyersSymbolStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/fyers_symbols", link, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(fyersSymbolSuccess(res.data));
    } catch (error) {
        dispatch(fyersSymbolFailure(error.response?.data));
    }
};

export const fyersSymbolGet = async (dispatch) => {
    dispatch(getFyersSymbolStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/fyers_symbols", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getFyersSymbolSuccess(res.data));
    } catch (error) {
        dispatch(getFyersSymbolFailure(error.response?.data));
    }
};

export const fyersIndexSymbolGet = async () => {
    try{
        const res = await axiosClient.get("/fyers_index_symbols")
        return res?.data
    }catch (error) {
        throw error
    }
};

export const fyersIndexFoSymbolGet = async () => {
    try{
        const res = await axiosClient.get("/fyers_index_fo_symbols")
        return res?.data
    }catch (error) {
        throw error
    }
};

export const angelIndexFoSymbolGet = async () => {
    try{
        const res = await axiosClient.get("/angel_index_fo_symbols")
        return res?.data
    }catch (error) {
        throw error
    }
};

export const iciciIndexFoSymbolGet = async () => {
    try{
        const res = await axiosClient.get("/icici_index_fo_symbols")
        return res?.data
    }catch (error) {
        throw error
    }
};
