import axiosClient from "../axios-client";
import authHeader from "../services/auth-header";
import {
    getSymbolsAccountsStart,
    getSymbolsAccountsSuccess,
    getSymbolsAccountsFailure,
    updateSymbolForAllStart,
    updateSymbolForAllSuccess,
    updateSymbolForAllFailure,
    getFavSymbolsWithAccounts,
} from "../reducers/symbolReducer";

export const symbolsGet = async () => {
    try {
        const res = await axiosClient.get("/symbol");
        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const symbolPost = async (dispatch, data) => {
    try {
        const res = await axiosClient.post("/symbol", data);

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const symbolDelete = async (data) => {
    try {
        const res = await axiosClient.put(`/symbol-delete`, data);
        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const symbolGetByID = async (dispatch, id) => {
    try {
        const res = await axiosClient.get(`/symbol/${id}`);

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const symbolUpdate = async (dispatch, id, symbolData) => {
    try {
        const res = await axiosClient.put(`/symbol/${id}`, symbolData);

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const getFavSymbolsAccounts = async (dispatch) => {
    try {
        const res = await axiosClient.get("/fav-symbol-account");

        dispatch(getFavSymbolsWithAccounts(res.data));
        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const symbolsAccountsGet = async (dispatch) => {
    dispatch(getSymbolsAccountsStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/symbol-account", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getSymbolsAccountsSuccess(res.data));
    } catch (error) {
        dispatch(getSymbolsAccountsFailure(error.response?.data));
    }
};

export const symbolUpdateForAll = async (dispatch, updated_symbol) => {
    dispatch(updateSymbolForAllStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put(`/update-symbol`, updated_symbol, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(updateSymbolForAllSuccess(res.data));
    } catch (error) {
        dispatch(updateSymbolForAllFailure(error.response?.data));
    }
};
