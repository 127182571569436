// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext } from "react";
import { ReactReduxContext, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import PropTypes from "prop-types";

// MUI IMPORTS
import { alpha } from "@mui/material/styles";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import NotFound from "../../../images/not_found.png";

// API IMPORTS
import { holidayGet, holidayDelete, holidayDeleteMany, holidayFilter } from "../../../actions/holidayAction";


function descendingComparator(a, b, holidayBy) {
  if (b[holidayBy] < a[holidayBy]) {
    return -1;
  }
  if (b[holidayBy] > a[holidayBy]) {
    return 1;
  }
  return 0;
}

function getComparator(holiday, holidayBy) {
  return holiday === "desc"
    ? (a, b) => descendingComparator(a, b, holidayBy)
    : (a, b) => -descendingComparator(a, b, holidayBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const holiday = comparator(a[0], b[0]);
    if (holiday !== 0) {
      return holiday;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "holidayDate",
    numeric: false,
    disablePadding: true,
    label: "Holiday Date",
  },
  {
    id: "holiday",
    numeric: false,
    disablePadding: false,
    label: "Holiday",
  },
  {
    id: "holidayDay",
    numeric: false,
    disablePadding: false,
    label: "Holiday Day",
  },
  {
    id: "exchange",
    numeric: false,
    disablePadding: false,
    label: "Exchange",
  },
  {
    id: "muhuratTrading",
    numeric: false,
    disablePadding: false,
    label: "Muharat Trading",
  },
  {
    id: "setting",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    holiday,
    holidayBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={holidayBy === headCell?.id ? holiday : false}
          >
            <TableSortLabel
              active={holidayBy === headCell?.id}
              direction={holidayBy === headCell?.id ? holiday : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {holidayBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {holiday === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  holiday: PropTypes.oneOf(["asc", "desc"]).isRequired,
  holidayBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function Holiday() {
  const [holiday, setHoliday] = useState("desc");
  const [holidayBy, setHolidayBy] = useState("created_at");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [holidayList, setHolidayList] = useState([]);
  const [filterParams, setFilterParams] = useState({});

  const { store } = useContext(ReactReduxContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = holidayBy === property && holiday === "asc";
    setHoliday(isAsc ? "desc" : "asc");
    setHolidayBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = holidayList?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected?.indexOf(id) !== -1;

  const visibleRows = useMemo(
    () =>
      stableSort(holidayList, getComparator(holiday, holidayBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [holiday, holidayBy, page, rowsPerPage, holidayList]
  );

  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  const getHolidayData = async () => {
    await holidayGet(dispatch);
    if (store.getState()?.holiday?.holidayList) {
      const holiday_data = store.getState()?.holiday?.holidayList;
      const rows = holiday_data?.data?.map((record) => ({
        id: record?._id,
        holidayDate: record?.holidayDate,
        holiday: record?.holiday,
        holidayDay: record?.holidayDay,
        exchange: record?.exchange,
        muhuratTrading: record?.muhuratTrading,
      }));
      setHolidayList(rows);
    }
  };

  useEffect(() => {
    getHolidayData();
    // eslint-disable-next-line
  }, [dispatch]);

  const handleDelete = async (id) => {
    await holidayDelete(dispatch, id);
    if (store.getState()?.holiday?.holidayData) {
      toast.success("Data deleted successfully!");
      getHolidayData();
      setSelected([]);
    } else if (store.getState()?.holiday?.error) {
      toast.error(
        store.getState()?.holiday?.errorMessage
          ? store.getState()?.holiday?.errorMessage.message
          : "Error deleting data. Please try again."
      );
    }
  };

  const handleFilterChange = (e) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (filterParams) {
        await holidayFilter(dispatch, filterParams);
        const holiday_data = store.getState()?.holiday?.holidayList;
        if (holiday_data) {
          const rows = holiday_data?.data?.map((record) => ({
            id: record?._id,
            holidayDate: record?.holidayDate,
            holiday: record?.holiday,
            holidayDay: record?.holidayDay,
            exchange: record?.exchange,
            muhuratTrading: record?.muhuratTrading,
          }));
          setHolidayList(rows);
          setPage(0);
        }
      }
    } catch (error) {
      console.log("Error fetching stocks:", error);
    }
  };

  const handleMultiDelete = async () => {
    if (selected.length !== 0) {
      // delte multiple records
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to delete the selected ${selected?.length} record?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { ids: selected };
          await holidayDeleteMany(dispatch, data);
          if (store.getState()?.holiday?.holidayData) {
            Swal.fire(
              "Deleted!",
              "All selected record deleted successfully.",
              "success"
            );
            setPage(0);
            setRowsPerPage(10);
            getHolidayData();
            setSelected([]);
          } else if (store.getState()?.holiday?.error) {
            toast.error(
              store.getState()?.holiday?.errorMessage
                ? store.getState()?.holiday?.errorMessage?.message
                : "Error deleting data. Please try again."
            );
          }
        }
      });
    }
  };

  const handleReset = () => {
    setFilterParams({});
    getHolidayData();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  return (
    <div className="Main">
      <div className="container-fluid">
        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
          <div className="pt-4 sd-header">
            <Button className="adminbackBtn" onClick={ToggleSidebar}>
              <i className="feather icon-chevron-right"></i>
            </Button>
            <h3 className="mt-1">Filters</h3>
          </div>
          <div className="sd-body">
            <form>
              <div className="FilterForm">
                <div className="form-group">
                  <label>Holiday Date (DD/MM/YYYY)</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Holiday Date"
                    defaultValue=""
                    name="holidayDate"
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Holiday</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Holiday"
                    defaultValue=""
                    name="holiday"
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Holiday Day</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Holiday Day"
                    defaultValue=""
                    name="holidayDay"
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Exchange</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Exchange"
                    defaultValue=""
                    name="exchange"
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Muhurat Trading</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Muhurat Trading"
                    defaultValue=""
                    name="muhuratTrading"
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <div className="d-flex pt-3 pb-4 applyChangeBtn">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleSubmit}
                >
                  Apply Changes
                </button>
                <button
                  type="button"
                  className="btn btn-square btn-danger mr-0"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/holiday" style={{ fontWeight: 600 }}>
                            Holidays
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">List</h3>
                          <h4 style={{ marginTop: "6px" }}>
                            <span className="badge badge-secondary">
                              {holidayList?.length}
                            </span>
                          </h4>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-square btn-primary holidayAddBtn"
                            onClick={() => navigate("/holiday-create")}
                          >
                            <i className="feather icon-plus"></i> Create New
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary mr-0"
                            onClick={ToggleSidebar}
                          >
                            Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  <Box sx={{ width: "100%" }}>
                    {visibleRows.length !== 0 ? (
                      <Paper
                        sx={{ width: "100%", mb: 2 }}
                        className="customTable"
                      >
                        <Toolbar
                          sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            ...(selected?.length > 0 && {
                              bgcolor: (theme) =>
                                alpha(
                                  theme.palette.primary.main,
                                  theme.palette.action.activatedOpacity
                                ),
                            }),
                          }}
                          className="p-1"
                        >
                          {selected?.length > 0 ? (
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              color="inherit"
                              variant="subtitle1"
                              component="div"
                            >
                              {selected?.length} selected
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              variant="h6"
                              id="tableTitle"
                              component="div"
                            >
                              Holidays
                            </Typography>
                          )}

                          {selected?.length > 0 ? (
                            <Tooltip title="Delete">
                              <IconButton onClick={handleMultiDelete}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <i className="feather icon-trending-up pr-3"></i>
                          )}
                        </Toolbar>
                        <TableContainer>
                          <Table
                            className="addTableFontColor"
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead
                              numSelected={selected?.length}
                              holiday={holiday}
                              holidayBy={holidayBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={holidayList?.length}
                            />
                            <TableBody>
                              {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onClick={(event) =>
                                          handleClick(event, row.id)
                                        }
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {moment(row?.holidayDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell>{row?.holiday}</TableCell>
                                    <TableCell>{row?.holidayDay}</TableCell>
                                    <TableCell>{row?.exchange}</TableCell>
                                    <TableCell>
                                      {row?.muhuratTrading === true
                                        ? "Yes"
                                        : "No"}
                                    </TableCell>
                                    <TableCell>
                                      <Link
                                        type="submit"
                                        className="btn btn-outline-primary mr-2 m-0 editDeleteBtn"
                                        to={`/holiday-edit/${row?.id}`}
                                      >
                                        <i className="feather icon-edit m-0"></i>
                                      </Link>
                                      <button
                                        type="submit"
                                        className="btn btn-outline-danger m-0 editDeleteBtn"
                                        onClick={() => handleDelete(row?.id)}
                                      >
                                        <i className="feather icon-trash-2 m-0"></i>
                                      </button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={holidayList?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <div className="text-center">
                        <img
                          src={NotFound}
                          alt="Not Found"
                          className="notFoundImg"
                        />
                      </div>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Holiday;
