// BASIC IMPORTS
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// MUI IMPORTS
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { getAllFavSymbolsWithAccounts } from "../../../reducers/symbolReducer";

// API IMPORTS
import { symbolPost } from "../../../actions/symbolAction";
import { getFavSymbolsAccounts } from "../../../actions/symbolAction";


function SymbolCreateForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {},
  });


  const dispatch = useDispatch();
  const [filterSymbolsData, setFilterSymbolsData] = useState([]);
  const [accountName, setAccountName] = useState({});
  const favSymbolsAccounts = useSelector(getAllFavSymbolsWithAccounts);


  const onSubmit = async (data) => {
    data["accountName"] = accountName?.key 
    data["account"] = accountName?.value 
    await symbolPost(dispatch, data).then((res) => {
      toast.success("Symbol added successfully!");
      reset();
    }).catch((error) => {
      toast.error("Symbol not added due to some error")
    })
  };

  
  const getSymbolsAccounts = async () => {
    if(favSymbolsAccounts?.length <= 0){
      await getFavSymbolsAccounts(dispatch).then((res) => {
        setFilterSymbolsData(res?.data);
      }).catch((error) => {
        toast.error("Favourite symbols, accounts data not found")
      })
    }else{
      setFilterSymbolsData(favSymbolsAccounts)
    }
  };


  const options = filterSymbolsData?.accounts
    ? Object.keys(filterSymbolsData.accounts).map(key => ({
      key: key,
      value: filterSymbolsData.accounts[key]
    }))
    : [];


  useEffect(() => {
    getSymbolsAccounts();
    // eslint-disable-next-line
  }, []);


  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/symbols">Add Symbols</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="" style={{ fontWeight: 600 }}>
                          Add Symbol
                        </Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-3">
                      <div className="ListWithBadge">
                        <Button
                          className="adminbackBtn"
                          onClick={() => navigate("/symbols")}
                        >
                          <i className="feather icon-chevron-right"></i>
                        </Button>
                        <h3 className="m-b-20 mr-2">Add Symbol</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="card">
                        <div className="card-body mobileCardBody">
                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Account*</label>
                              <Controller
                                name="accountName"
                                control={control}
                                rules={{ required: "Account is required" }}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    className="favAccountDropdown"
                                    options={options}
                                    getOptionLabel={(option) => option.key}
                                    isOptionEqualToValue={(option, value) => option?.key === value?.key}
                                    onChange={(_, value) => {
                                      field.onChange(value)
                                      setAccountName(value)
                                    }
                                    }
                                    value={field.value || null}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props} style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%"
                                      }}>
                                        <Typography style={{ float: "left" }}>{option.key}</Typography>
                                        <Typography style={{ float: "right" }}>{option.value}</Typography>
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        error={!!errors.account}
                                        helperText={errors.account ? errors.account.message : ''}
                                      />
                                    )}
                                  />
                                )}
                              />
                              {errors?.accountName && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.accountName?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Symbol*</label>
                              <input
                                className="form-control"
                                placeholder="Symbol"
                                {...register("shortName", {
                                  required: "Symbol is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.shortName && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.shortName?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Symbol Full Name*</label>
                              <input
                                className="form-control"
                                placeholder="Symbol Full Name"
                                {...register("companyName", {
                                  required: "Symbol full name is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.companyName && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.companyName?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Exchange*</label>
                              <input
                                className="form-control"
                                placeholder="Exchange"
                                {...register("marketType", {
                                  required: "Exchange is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.marketType && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.marketType?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Lotsize*</label>
                              <input
                                className="form-control"
                                placeholder="Lotsize"
                                {...register("LotSize", {
                                  required: "Lotsize is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.LotSize && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.LotSize?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Tick Size*</label>
                              <Controller
                                name="tickSize"
                                control={control}
                                rules={{
                                  required: "Tick Size is required",
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <>
                                    <select {...field} className="form-control">
                                      <option value="" disabled>
                                        Select...
                                      </option>
                                      <option>0.01</option>
                                      <option>0.05</option>
                                    </select>
                                  </>
                                )}
                              />
                              {errors?.tickSize && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.tickSize?.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>
                                Expiry Date{" "}
                                {accountName?.value === "ICICI"
                                  ? "(Ex: 25-Apr-2024)"
                                  : accountName?.value === "FYERS"
                                    ? "(Ex: 25-Apr-2024)"
                                    : accountName?.value === "ANGEL"
                                      ? "(Ex: 25APR2024)"
                                      : null}
                              </label>
                              <input
                                className="form-control"
                                placeholder="Expiry Date"
                                {...register("expiryDate", {})}
                              />
                              {errors?.expiryDate && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.expiryDate?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="col-md-12 justify-content-center text-center pt-3">
                              <button
                                type="submit"
                                className="btn btn-primary ml-3 px-5"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SymbolCreateForm;
