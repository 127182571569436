import axiosClient from "../axios-client";
import {
    createOrderFailure,
    createOrderStart,
    createOrderSuccess,
    getOrderFailure,
    getOrderStart,
    getOrderSuccess,
    getOrderByIdFailure,
    getOrderByIdStart,
    getOrderByIdSuccess,
    updateOrderStart,
    updateOrderSuccess,
    updateOrderFailure,
    deleteOrderStart,
    deleteOrderSuccess,
    deleteOrderFailure,
    getTrashOrderStart,
    getTrashOrderSuccess,
    getTrashOrderFailure,
    deleteTrashOrderStart,
    deleteTrashOrderFailure,
    deleteTrashOrderSuccess,
    pullBackTrashOrderStart,
    pullBackTrashOrderSuccess,
    pullBackTrashOrderFailure,
    getDistinctOrderStart,
    getDistinctOrderSuccess,
    getDistinctOrderFailure,
    getDistinctTrashOrderStart,
    getDistinctTrashOrderSuccess,
    getDistinctTrashOrderFailure,
} from "../reducers/orderReducer";
import authHeader from "../services/auth-header";


export const orderGet = async (dispatch) => {
    dispatch(getOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/order", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getOrderSuccess(res.data));
    } catch (error) {
        dispatch(getOrderFailure(error.response?.data));
    }
};

export const orderGetById = async (dispatch, id) => {
    dispatch(getOrderByIdStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/order/${id}`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getOrderByIdSuccess(res.data));
    } catch (error) {
        dispatch(getOrderByIdFailure(error.response?.data));
    }
};

export const orderCreate = async (dispatch, orderData) => {
    dispatch(createOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/order", orderData, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(createOrderSuccess(res.data));
    } catch (error) {
        dispatch(createOrderFailure(error.response?.data));
    }
};

export const orderUpdate = async (dispatch, id, orderData) => {
    dispatch(updateOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put(`/order/${id}`, orderData, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(updateOrderSuccess(res.data));
    } catch (error) {
        dispatch(updateOrderFailure(error.response?.data));
    }
};

export const reOrder = async (dispatch, id, account_id, client_id) => {
    dispatch(updateOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(
            `/reorder/${id}/${account_id}/${client_id}`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
        dispatch(updateOrderSuccess(res.data));
    } catch (error) {
        dispatch(updateOrderFailure(error.response?.data));
    }
};

export const orderDeleteMany = async (dispatch, data) => {
    dispatch(deleteOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.delete("/order", {
            headers: {
                Authorization: token,
            },
            data,
        });
        dispatch(deleteOrderSuccess(res.data));
    } catch (error) {
        dispatch(deleteOrderFailure(error.response?.data));
    }
};

export const orderFilter = async (dispatch, parameters) => {
    dispatch(getOrderStart());
    try {
        const token = authHeader();
        const res = await axiosClient.post(`/order-filter`, parameters, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getOrderSuccess(res.data));
    } catch (error) {
        dispatch(getOrderFailure(error.response?.data));
    }
};

export const trashOrderFilter = async (dispatch, parameters) => {
    dispatch(getTrashOrderStart());
    try {
        const token = authHeader();
        const res = await axiosClient.post(`/trash-order-filter`, parameters, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getTrashOrderSuccess(res.data));
    } catch (error) {
        dispatch(getTrashOrderFailure(error.response?.data));
    }
};

export const trashOrderGet = async (dispatch) => {
    dispatch(getTrashOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/trash-order", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getTrashOrderSuccess(res.data));
    } catch (error) {
        dispatch(getTrashOrderFailure(error.response?.data));
    }
};

export const trashOrderDelete = async (dispatch, data) => {
    dispatch(deleteTrashOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.delete("/trash-order", {
            headers: {
                Authorization: token,
            },
            data,
        });
        dispatch(deleteTrashOrderSuccess(res.data));
    } catch (error) {
        dispatch(deleteTrashOrderFailure(error.response?.data));
    }
};

export const pullBackOrderPost = async (dispatch, data) => {
    dispatch(pullBackTrashOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.delete("/pull-back-order", {
            headers: {
                Authorization: token,
            },
            data,
        });
        dispatch(pullBackTrashOrderSuccess(res.data));
    } catch (error) {
        dispatch(pullBackTrashOrderFailure(error.response?.data));
    }
};

export const getDistinctOrderStatus = async (dispatch) => {
    dispatch(getDistinctOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/distinct-order-statuses", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getDistinctOrderSuccess(res.data));
    } catch (error) {
        dispatch(getDistinctOrderFailure(error.response?.data));
    }
};

export const getDistinctTrashOrderStatus = async (dispatch) => {
    dispatch(getDistinctTrashOrderStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/distinct-trash-order-statuses", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getDistinctTrashOrderSuccess(res.data));
    } catch (error) {
        dispatch(getDistinctTrashOrderFailure(error.response?.data));
    }
};
