// COMMEAN IMPORTS
import 'rsuite/dist/rsuite-no-reset.min.css';
import './App.css';
import './css/filterSidebar.css';
import './css/main.css';
import { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from './routes/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './components/layout/NotFound';
import WebSocketProvider from './websocket/websocket';
import { useSelector } from 'react-redux';


// DASHBOARD PAGE IMPORT
import Dashboard from './components/main/Dashboard/Dashboaord';


// TRADING PAGE IMPORTS
import Trading from './components/main/Trading/Trading';
import TradingCreate from './components/main/Trading/TradingCreate';
import TradingEdit from './components/main/Trading/TradingEdit';
import TradingCreateBulk from './components/main/Trading/TradingCreateBulk';


// ORDER PAGE IMPORTS
import Order from './components/main/Order/Order';
import OrderCreate from './components/main/Order/OrderCreate';
import OrderEdit from './components/main/Order/OrderEdit';


// TRASH ORDER PAGE IMPORT
import TrashOrder from './components/main/TrashOrder/TrashOrder';


// ACCOUNT PAGE IMPORT
import Setting from './components/main/Account/Setting';
import GenerateToken from './components/main/Account/GenerateToken';
import BrokerSuccess from './components/main/Account/BrokerSuccess';


// ADD FAV SYMBOLS PAGE IMPORTS
import Symbols from './components/main/AddFavSymbols/Symbols';
import SymbolCreate from './components/main/AddFavSymbols/SymbolCreate';
import SymbolEdit from './components/main/AddFavSymbols/SymbolEdit';


// PAIR RANGE PAGE IMPORT
import PairRangeMessage from './components/main/PairRange/PairRangeMessage';


// CYCLE REPORT PAGE IMPORT
import CycleReport from './components/main/CycleReport/CycleReport';


// PAIR BACKTEST PAGE IMPORT
import PairBacktest from './components/main/PairBacktest/PairBacktest';


// AUTO BACKTEST PAGE IMPORTS
import AutoBacktest from './components/main/AutoBacktest/AutoBacktest';
import AddViewBacktestSymbols from './components/main/AutoBacktest/AddViewBacktestSymbols';
import AddBulkBacktestSymbols from './components/main/AutoBacktest/AddBulkBacktestSymbols';
import BacktestSymbolEdit from './components/main/AutoBacktest/BacktestSymbolEdit';


// REPEAT BACKTEST PAGE IMPORT
import RepeatBacktest from './components/main/RepeatBacktest/RepeatBacktest';


// NEW AND UPDATED SYMBOL STATUS PAGE IMPORT
import SymbolAddUpdate from './components/main/SymbolsStatus/SymbolAddUpdate';


// MASTER PAGE IMPORT
import Master from './components/main/Master/Master';


// HOLIDAY PAGE IMPORT
import Holiday from './components/main/Holiday/Holiday';
import HolidayCreate from './components/main/Holiday/HolidayCreate';
import HolidayEdit from "./components/main/Holiday/HolidayEdit";


// PROFILE PAGE IMPORT
import Profile from './components/main/Profile/Profile';


// USERS PAGE IMPORT
import UploadExcel from './components/main/Users/UploadExcel';
import Users from './components/main/Users/Users';
import DBChangeOTPVerification from './components/main/Users/DBChangeOTPVerification';


// VOLATILITY PAGE IMPORT
import Volatility from "./components/main/Volatility/Volatility";


// AUTH IMPORT
import Login from './components/main/Auth/Login';
import Register from './components/main/Auth/Register';
import ResetPassword from './components/main/Auth/ResetPassword';
import EmailVerification from './components/main/Auth/EmailVerification';

// IMPORT OPTION STRATEGY
import OptionStrategyOne from './components/main/Strategy/OptionStrategyOne';

import OptionStrategyTwo from './components/main/Strategy/OptionStrategyTwo';
import OptionStrategyTwoSymbols from './components/main/Strategy/OptionStrategyTwoSymbols';
import OptionStrategyTwoSymbolEdit from './components/main/Strategy/OptionStrategyTwoSymbolEdit';
import OptionStrategyTwoAdd from './components/main/Strategy/OptionStrategyTwoAdd';
import OptionStrategyTwoEdit from './components/main/Strategy/OptionStrategyTwoEdit';

import OptionStrategyThree from './components/main/Strategy/OptionStrategyThree';
import OptionStrategyThreeAdd from './components/main/Strategy/OptionStrategyThreeAdd';
import OptionStrategyThreeEdit from './components/main/Strategy/OptionStrategyThreeEdit';
import LoaderWrapper from './loader_wrapper';


const App = () => {

    const [auth, setAuth] = useState(false);
    const [loading, setLoading] = useState(false);

    const user = sessionStorage.getItem('auth');
    const loadingState = useSelector((state) => state.auth?.loading)

    useEffect(() => {
        if (loadingState !== undefined) setLoading(loadingState)
    }, [loadingState])

    useEffect(() => {
        if (user && user !== 'null') {
            try {
                const accessToken = JSON.parse(user).hasOwnProperty("accessToken")
                if (accessToken) {
                    setAuth(true);
                }
            }
            catch {
                sessionStorage.removeItem('auth')
                setAuth(false)
            }
        }
        else {
            sessionStorage.removeItem('auth')
        }
    }, [user]);

    return (
        <Fragment>
            <LoaderWrapper/>
            <Router>
                <WebSocketProvider>
                    <Routes>
                        <Route exact path="/" element={auth ? <Navigate to="/dashboard" /> : <Login />} />
                        <Route exact path="/register" element={auth ? <Navigate to="/dashboard" /> : <Register />} />
                        <Route exact path="/reset-password" element={auth ? <Navigate to="/dashboard" /> : <ResetPassword />} />
                        <Route exact path="/email-verify" element={<EmailVerification />} />
                        <Route element={<PrivateRoute />} >
                            <Route exact path="/dashboard" element={<Dashboard />} />
                            <Route exact path="/users" element={<Users />} />
                            <Route exact path="/profile" element={<Profile />} />
                            <Route exact path="/trading" element={<Trading />} />
                            <Route exact path="/symbols" element={<Symbols />} />
                            <Route exact path="/trading-create" element={<TradingCreate />} />
                            <Route exact path="/trading-create-bulk" element={<TradingCreateBulk />} />
                            <Route exact path="/trading-edit/:id" element={<TradingEdit />} />
                            <Route exact path="/order" element={<Order />} />
                            <Route exact path="/trash-order" element={<TrashOrder />} />
                            <Route exact path="/order-create" element={<OrderCreate />} />
                            <Route exact path="/order-edit/:id" element={<OrderEdit />} />
                            <Route exact path="/master" element={<Master />} />
                            <Route exact path="/setting" element={<Setting />} />
                            <Route exact path="/holiday" element={<Holiday />} />
                            <Route exact path="/holiday-create" element={<HolidayCreate />} />
                            <Route exact path="/holiday-edit/:id" element={<HolidayEdit />} />
                            <Route exact path="/upload-excel" element={<UploadExcel />} />
                            <Route exact path="/volatility" element={<Volatility />} />
                            <Route exact path="/cycle-report" element={<CycleReport />} />
                            <Route exact path="/otp-verify" element={<DBChangeOTPVerification />} />
                            <Route exact path="/pair-range" element={<PairRangeMessage />} />
                            <Route exact path="/pair-backtest" element={<PairBacktest />} />
                            <Route exact path="/auto-backtest" element={<AutoBacktest />} />
                            <Route exact path="/add-view-backtest-symbols" element={<AddViewBacktestSymbols />} />
                            <Route exact path="/add-bulk-backtest-symbols" element={<AddBulkBacktestSymbols />} />
                            <Route exact path="/backtest-symbol-edit/:id" element={<BacktestSymbolEdit />} />
                            <Route exact path="/symbol-update" element={<SymbolAddUpdate />} />
                            <Route exact path="/repeat-backtest" element={<RepeatBacktest />} />

                            <Route exact path="/option-strategy-one" element={<OptionStrategyOne />} />

                            <Route exact path="/option-strategy-two" element={<OptionStrategyTwo />} />
                            <Route exact path="/option-strategy-two-symbols" element={<OptionStrategyTwoSymbols />} />
                            <Route exact path="/option-strategy-two-symbols/:id" element={<OptionStrategyTwoSymbolEdit />} />
                            <Route exact path="/option-strategy-two/create" element={<OptionStrategyTwoAdd />} />
                            <Route exact path="/option-strategy-two/edit/:id" element={<OptionStrategyTwoEdit />} />

                            <Route exact path="/option-strategy-three" element={<OptionStrategyThree />} />
                            <Route exact path="/option-strategy-three/create" element={<OptionStrategyThreeAdd />} />
                            <Route exact path="/option-strategy-three/edit/:id" element={<OptionStrategyThreeEdit />} />

                            <Route path="/bauth" element={<GenerateToken />} />
                            <Route path="/success" element={<BrokerSuccess />} />
                            <Route path="/symbol-create" element={<SymbolCreate />} />
                            <Route path="/symbol-edit/:id" element={<SymbolEdit />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                    <ToastContainer position="top-right" />
                </WebSocketProvider>
            </Router>
        </Fragment>
    );
};

export default App;