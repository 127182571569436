import React, { useState } from "react";
import Loader from './components/layout/Loader';

export let startLoader = () => { };
export let endLoader = () => { };

const LoaderWrapper = () => {
  const [loader, setLoader] = useState(false);
  const startProgress = () => {
    setLoader(true);
  };

  const completeProgress = () => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  startLoader = startProgress;
  endLoader = completeProgress;

  if (loader) {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }
  return <></>;
};

export default LoaderWrapper;
