import React, { useEffect, useState, useRef, createContext } from "react";

export const WebsocketContext = createContext([false, null, () => {}]);

const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const [isReady, setIsReady] = useState(null);
  const [message, setMessage] = useState(null);

  const ws = useRef(null);
  const data = [isReady, socket, message, setMessage];

  useEffect(() => {
    const socket_conn = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/api/ws`
    );
    setSocket(socket_conn);
    socket_conn.binaryType = "arraybuffer";
    socket_conn.onopen = () => setIsReady(true);
    socket_conn.onclose = () => setIsReady(false);
    socket_conn.onerror = (error) => console.error("WebSocket error:", error);

    ws.current = socket_conn;

    return () => {
      socket_conn.close();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => setMessage(() => JSON.parse(event?.data));
    }
  }, [socket]);

  return (
    <WebsocketContext.Provider value={data}>
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebSocketProvider;
