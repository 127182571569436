// BASIC IMPORTS
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// MUI IMPORTS
import { Autocomplete, TextField, FormControlLabel, Box, Paper, Switch, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../../main/SharedStateContext";
import { decodeToken } from "../../../helpers/helper";
import NotFound from "../../../images/not_found.png";
import ScrollToTopButton from '../Comman/ScrollToTopButton';

// API IMPORTS
import { settingCreate, settingGet, settingDelete, settingDeleteConfirmation, getActiveAccountName } from "../../../actions/settingAction";
import { allClientGet, getSwitchTime } from "../../../actions/authAction";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

// Setting component
function Setting() {
  const [forms, setForms] = useState([
    {
      id: "",
      email: "",
      account: "",
      accountName: "",
      api_key: "",
      secret_key: "",
      api_session: "",
      isRunningBot: false,
      saved: false,
      client: "",
      clientId: "",
      totp: "",
      pin: "",
    },
  ]);


  const [open, setOpen] = useState(false);
  const [selectedFormIndex, setSelectedFormIndex] = useState(null);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [switchTime, setSwitchTime] = useState([]);
  const [emails, setEmails] = useState([]);


  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const targetRef = useRef(null);
  const activeUser = useSelector((state) => state.auth?.client?.data);


  const handleOpen = (index) => {
    setSelectedFormIndex(index);
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
    setSelectedFormIndex(null);
  };


  const handleCreateForm = () => {
    // targetRef.current.scrollIntoView({ behavior: 'smooth' });
    setForms((prevForms) => [
      ...prevForms,
      {
        id: "",
        email: "",
        account: "",
        accountName: "",
        api_key: "",
        secret_key: "",
        api_session: "",
        isRunningBot: false,
        saved: false,
      },
    ]);

    setTimeout(() => {
      if (forms.length > 1 && targetRef.current) {
        const additionalOffset = (forms.length % 2 === 0) ? 0 : 0;
        window.scrollTo({
          top: targetRef.current.offsetTop + additionalOffset,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  const handleDeleteForm = async (index, id) => {
    await settingDeleteConfirmation(id).then((res) => {
      Swal.fire({
        title: "Are you sure?",
        text: `${res?.message}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (id) {
            await settingDelete(id).then((res) => {
              setForms((prevForms) => prevForms?.filter((_, i) => i !== index));
              Swal.fire("Deleted!", "Account deleted successfully.", "success");
              getSettingData();
            }).catch((error) => {
              toast.error("Account not deleted. Please try again")
            })
          }
        }
      });
    }).catch((error) => {
      toast.error("Something went wrong")
    })
  };


  const handleInputChange = (index, field, value, apiStartTime, apiEndTime) => {
    const currentTime = new Date();
    const startMarketTime = new Date();
    const endMarketTime = new Date();

    // Parse API-provided start and end times
    const [startHour, startMinute] = switchTime?.start_time?.split(':').map(Number);
    const [endHour, endMinute] = switchTime?.end_time?.split(':').map(Number);

    startMarketTime.setHours(startHour, startMinute, 0);
    endMarketTime.setHours(endHour, endMinute, 0);

    const isMarketTime =
      currentTime >= startMarketTime && currentTime <= endMarketTime;

    if (!isMarketTime && field === "isRunningBot") {
      toast.info(
        `You can't start switch before market time ${switchTime?.start_time} and after market time ${switchTime?.end_time}`
      );
    } else {
      setForms((prevForms) => {
        const newForms = [...prevForms];
        newForms[index][field] = value;
        return newForms;
      });
    }
  };


  const handleSave = async (index) => {
    const user = decodeToken();
    const data = forms?.filter((_, i) => i === index)[0];
    if (data.account === "" || data.accountName === "") {
      toast.warning("All fields are required");
    } else {
      if (user) {
        delete data["saved"];
        await settingCreate(dispatch, data).then((res) => {
          if (res?.status === "update") {
            toast.success("Data updated successfully!");
          } else {
            toast.success("Data added successfully!");
          }
          setForms((prevForms) =>
            prevForms?.map((form, i) =>
              i === index
                ? {
                  ...form,
                  saved: true,
                  id: res?.data["_id"],
                }
                : form
            )
          );
          getSettingData();
        }).catch((error) => {
          if(error?.response?.data?.error){
            toast.error(error?.response?.data?.error)
          }else{
            toast.error("Account not added, due to some error")
          }
        })
      }
    }
  };

  const openLink = (url) => {
    window.open(url, "_self");
  };

  const getSettingData = async () => {
    await settingGet(dispatch).then((res) => {
      var setting_data = res?.data
      if (setting_data) {
        const newDataArray = setting_data?.map((data) => ({
          email: data?.email,
          account: data?.account,
          accountName: data?.accountName,
          api_key: data?.api_key,
          secret_key: data?.secret_key,
          api_session: data?.api_session,
          saved: true,
          id: data?._id,
          client: data?.client,
          totp: data?.totp,
          clientId: data?.clientId,
          pin: data?.pin,
          token: data?.token,
          enable_pair_gap: data?.enable_pair_gap,
          isRunningBot: data?.isRunningBot,
          fyers_url:
            data?.account === "FYERS"
              ? `https://api.fyers.in/api/v2/generate-authcode?client_id=${data?.clientId}&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/bauth&response_type=code&state=fyers_${data?._id}`
              : null,
          upstox_url:
            data?.account === "UPSTOX"
              ? `https://api.upstox.com/v2/login/authorization/dialog?response_type=code&client_id=${data?.api_key}&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/bauth&state=upstox_${data?._id}`
              : null,
        }));
        setForms(newDataArray);
      }
    }).catch((error) => {
      toast.error("Account not retrived, due to some error")
    })
  };

  const fetchActiveAccounts = async () => {
    await getActiveAccountName(dispatch);
    if (store.getState().setting?.active_accounts) {
      const res = store.getState().setting?.active_accounts;
      if (res) {
        setActiveAccounts(res?.data);
      }
    }
  };

  const getSwitchOnOffTime = async () => {
    await getSwitchTime().then((res) => {
      setSwitchTime(res?.data || {})
    }).catch((error) => { })
  }

  const getClients = async () => {
    await allClientGet(dispatch).then((res) => {
      const uniqueEmails = [...new Set(res?.data?.map(item => item?.email))];
      setEmails(uniqueEmails);
    }).catch((error) => { })
  };


  useEffect(() => {
    getSettingData();
    fetchActiveAccounts();
    getSwitchOnOffTime();
    getClients();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <ScrollToTopButton />
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/setting" style={{ fontWeight: 600 }}>
                            Setting
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">Account</h3>
                          <h4 style={{ marginTop: "6px" }}>
                            <span className="badge badge-secondary">
                              {forms?.length}
                            </span>
                          </h4>
                        </div>
                        {activeUser?.is_admin === true ?
                          <div>
                            <button
                              type="button"
                              className="btn btn-square btn-primary"
                              onClick={handleCreateForm}
                            >
                              <i className="feather icon-plus"></i> Create New
                            </button>
                          </div>
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                {forms?.length !== 0 ? (
                  <div className="page-wrapper">
                    <Box sx={{ width: "100%" }}>
                      <Paper
                        sx={{ width: "100%", mb: 2 }}
                        className="settingForm"
                      >
                        <div className="row dynamicForm">
                          {forms?.map((form, index) => (
                            <div className="card col-md-6" key={index} ref={index === forms.length - 1 ? targetRef : null}>
                              <div className="card-body mobileCardBody">
                                <div className="col-md-12">
                                  {activeUser?.is_admin === true ?
                                    <>
                                      <div
                                        className="row"
                                        style={{ marginRight: "inherit" }}
                                      >
                                        <div className="form-group col-md-6">
                                          <label className="mb-1">Client Id*</label>
                                          <input
                                            type="name"
                                            className="form-control"
                                            placeholder="Client Id"
                                            value={form?.client?.toString()}
                                            disabled={true}
                                          />
                                        </div>
                                        <div className="form-group col-md-6">
                                          <label className="mb-1">Account Id*</label>
                                          <input
                                            type="name"
                                            className="form-control"
                                            placeholder="Account Id"
                                            value={form?.id?.toString()}
                                            disabled={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Email*</label>
                                        <Autocomplete
                                          disablePortal
                                          className="accountNameAutoComplete"
                                          name="email"
                                          id="combo-box-demo"
                                          options={emails}
                                          sx={{ width: "100%" }}
                                          value={form?.email?.toString()}
                                          onChange={(e, value) =>
                                            handleInputChange(
                                              index,
                                              "email",
                                              value
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                          disabled={form?.saved}
                                        />
                                      </div>
                                    </>
                                    : null}

                                  <div className="form-group">
                                    <label className="mb-1">Broker*</label>
                                    <select
                                      className="form-control"
                                      name="account"
                                      value={form?.account}
                                      disabled={form?.saved}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "account",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="" disabled>
                                        Select...
                                      </option>
                                      {activeAccounts?.map((data, index) => {
                                        return (
                                          <option key={index}>{data}</option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label className="mb-1">Account Name*</label>
                                    <input
                                      type="name"
                                      className="form-control"
                                      placeholder="Account Name"
                                      value={form?.accountName}
                                      disabled={form?.saved}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "accountName",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  {form?.account === "ICICI" ? (
                                    <>
                                      <div className="form-group">
                                        <label className="mb-1">Api Key*</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Api Key"
                                          value={form?.api_key}
                                          disabled={form?.saved}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "api_key",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Secret Key*</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Secret Key"
                                          value={form?.secret_key}
                                          disabled={form?.saved}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "secret_key",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Api Session*</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Api Session"
                                          value={form?.api_session}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "api_session",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Pair Enable Gap</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Pair Enable Gap"
                                          value={form?.enable_pair_gap}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "enable_pair_gap",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  ) : null}

                                  {form?.account === "FYERS" ? (
                                    <>
                                      <div className="form-group">
                                        <label className="mb-1">Client ID*</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Client ID"
                                          value={form?.clientId}
                                          disabled={form?.saved}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "clientId",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Secret ID*</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Secret ID"
                                          value={form?.secret_key}
                                          disabled={form?.saved}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "secret_key",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Pair Enable Gap</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Pair Enable Gap"
                                          value={form?.enable_pair_gap}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "enable_pair_gap",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>

                                      <button
                                        onClick={() =>
                                          openLink(form?.fyers_url)
                                        }
                                        className="btn btn-outline-secondary"
                                      >
                                        Refresh Token
                                      </button>
                                      <button
                                        onClick={() => handleOpen(index)}
                                        className="btn btn-outline-secondary"
                                      >
                                        View Token
                                      </button>
                                      <label className="warningMsg">
                                        *** Click to above button for token
                                        refresh daily otherwise bot is not start
                                        ***
                                      </label>
                                      <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                      >
                                        <Box sx={style}>
                                          <Typography
                                            id="modal-modal-description"
                                            sx={{ mt: 2 }}
                                            style={{
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            {selectedFormIndex !== null &&
                                              forms[selectedFormIndex]?.token
                                              ? forms[selectedFormIndex].token
                                              : "No token available"}
                                          </Typography>
                                        </Box>
                                      </Modal>
                                    </>
                                  ) : null}

                                  {form?.account === "ANGEL" ? (
                                    <>
                                      <div className="form-group">
                                        <label className="mb-1">Api Key*</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Api Key"
                                          value={form?.api_key}
                                          disabled={form?.saved}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "api_key",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">
                                          Username (Your Client Code)*
                                        </label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Client Code"
                                          value={form?.clientId}
                                          disabled={form?.saved}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "clientId",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Password (Your pin)*</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Password"
                                          disabled={form?.saved}
                                          value={form?.pin}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "pin",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">TOTP (QR Key)*</label>{" "}
                                        <a
                                          href="https://smartapi.angelbroking.com/enable-totp"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Enable TOTP
                                        </a>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="TOTP"
                                          value={form?.totp}
                                          disabled={form?.saved}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "totp",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Pair Enable Gap</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Pair Enable Gap"
                                          value={form?.enable_pair_gap}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "enable_pair_gap",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  ) : null}

                                  {form?.account === "UPSTOX" ? (
                                    <>
                                      <div className="form-group">
                                        <label className="mb-1">API Key*</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="API Key"
                                          value={form?.api_key}
                                          disabled={form?.saved}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "api_key",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Secret Key*</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Secret key"
                                          value={form?.secret_key}
                                          disabled={form?.saved}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "secret_key",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-1">Pair Enable Gap</label>
                                        <input
                                          type="name"
                                          className="form-control"
                                          placeholder="Pair Enable Gap"
                                          value={form?.enable_pair_gap}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "enable_pair_gap",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>

                                      <button
                                        onClick={() =>
                                          openLink(form?.upstox_url)
                                        }
                                        className="btn btn-outline-secondary"
                                      >
                                        Refresh Token
                                      </button>
                                      <label className="warningMsg">
                                        *** Click to above button for token
                                        refresh daily otherwise bot is not start
                                        ***
                                      </label>
                                    </>
                                  ) : null}

                                  <div>
                                    <FormControlLabel
                                      className="botScript"
                                      control={
                                        <Android12Switch
                                          checked={form?.isRunningBot}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "isRunningBot",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Start Bot Script"
                                    />
                                  </div>
                                  <div className="row col-md-12 justify-content-center pt-3">
                                    <button
                                      type="submit"
                                      className="btn btn-outline-primary ml-3 px-5"
                                      onClick={() => handleSave(index)}
                                    >
                                      Save
                                    </button>
                                    {activeUser?.is_admin === true ?
                                      <button
                                        type="submit"
                                        className="btn btn-outline-danger mr-0"
                                        onClick={() =>
                                          handleDeleteForm(index, form?.id)
                                        }
                                      >
                                        <i className="feather icon-trash-2"></i>
                                        Delete
                                      </button>
                                      : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Paper>
                    </Box>
                  </div>
                ) : (
                  <div className="text-center">
                    <img
                      src={NotFound}
                      alt="Not Found"
                      className="notFoundImg"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Setting;