// BASIC IMPORTS
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

// MUI IMPORTS
import { Button } from "@mui/material";


function BacktestSymbolEditForm({ initialValues, onSubmit }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({ defaultValues: initialValues });

  const navigate = useNavigate();

  useEffect(() => {
    if (initialValues) {
      // Set form values when the component mounts
      Object?.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
    }
    // eslint-disable-next-line
  }, [initialValues]);

  return (
    <>
      <div className="page-header-title d-flex justify-content-between pb-3">
        <div className="ListWithBadge">
          <Button
            className="adminbackBtn"
            onClick={() => navigate("/add-view-backtest-symbols")}
          >
            <i className="feather icon-chevron-right"></i>
          </Button>
          <h3 className="m-b-20 mr-2">Edit Symbol</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body mobileCardBody">
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Symbol Full Name*</label>
                    <input
                      className="form-control"
                      placeholder="Symbol Full Name"
                      {...register("companyName", {
                        required: "Symbol full name is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.companyName && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.companyName?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Symbol*</label>
                    <input
                      className="form-control"
                      placeholder="Symbol"
                      {...register("shortName", {
                        required: "Symbol is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.shortName && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.shortName?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Exchange*</label>
                    <input
                      className="form-control"
                      placeholder="Exchange"
                      {...register("marketType", {
                        required: "Exchange is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.marketType && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.marketType?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Lotsize*</label>
                    <input
                      className="form-control"
                      placeholder="Lotsize"
                      {...register("LotSize", {
                        required: "Lotsize is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.LotSize && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.LotSize?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Tick Size*</label>
                    <Controller
                      name="tickSize"
                      control={control}
                      rules={{
                        required: "Tick Size is required",
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option value="" disabled>
                              Select...
                            </option>
                            <option>0.01</option>
                            <option>0.05</option>
                          </select>
                        </>
                      )}
                    />
                    {errors?.tickSize && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.tickSize?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Expiry Date</label>
                    <input
                      className="form-control"
                      placeholder="Expiry Date"
                      {...register("expiryDate", {})}
                    />
                    {errors?.expiryDate && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.expiryDate?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Start Price*</label>
                    <input
                      className="form-control"
                      placeholder="Start Price"
                      {...register("start_price", {
                        required: "Start price is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.start_price && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.start_price?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>End Price*</label>
                    <input
                      className="form-control"
                      placeholder="End Price"
                      {...register("end_price", {
                        required: "End price is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.end_price && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.end_price?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="col-md-12 justify-content-center text-center pt-3">
                    <button type="submit" className="btn btn-primary ml-3 px-5">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BacktestSymbolEditForm;
