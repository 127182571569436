// BASIC IMPORTS
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

// MUI IMPORTS
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Button } from "@mui/material";
import { fyersIndexSymbolGet } from "../../../actions/uploadExcel";
import { ReactReduxContext, useDispatch } from "react-redux";
import { TextField } from "@mui/material"

const tickFilterOptions = createFilterOptions({
  ignoreCase: true,
  limit: 500,
  matchFrom: "any",
  stringify: (option) => option?.companyName + option?.shortName,
});

function OptionStrategyTwoSymbolEditForm({ initialValues, onSubmit }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({ defaultValues: initialValues });


  const navigate = useNavigate();
  const [fyersSymbol, setFyersSymbol] = useState([]);

  const getFyersSymbols = async () => {
    await fyersIndexSymbolGet().then((res) => {
      setFyersSymbol(res?.FyersSymbol);
    }).catch((error) => {})
  };

  useEffect(() => {
    if (initialValues) {
      // Set form values when the component mounts
      Object?.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
      if(initialValues?.broker && initialValues?.broker !== "FYERS"){
        getFyersSymbols()
      }
    }  
    // eslint-disable-next-line
  }, [initialValues]);

  return (
    <>
      <div className="page-header-title d-flex justify-content-between pb-3">
        <div className="ListWithBadge">
          <Button className="adminbackBtn" onClick={() => navigate("/option-strategy-two-symbols")}>
            <i className="feather icon-chevron-right"></i>
          </Button>
          <h3 className="m-b-20 mr-2">Edit Symbol</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body mobileCardBody">
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Symbol*</label>
                    <input
                      className="form-control"
                      placeholder="Symbol"
                      {...register("shortName", {
                        required: "Symbol is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.shortName && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.shortName?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Symbol Full Name*</label>
                    <input
                      className="form-control"
                      placeholder="Symbol Full Name"
                      {...register("companyName", {
                        required: "Symbol full name is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.companyName && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.companyName?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Exchange*</label>
                    <input
                      className="form-control"
                      placeholder="Exchange"
                      {...register("marketType", {
                        required: "Exchange is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.marketType && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.marketType?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Lotsize*</label>
                    <input
                      className="form-control"
                      placeholder="Lotsize"
                      {...register("lotSize", {
                        required: "Lotsize is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.lotSize && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.lotSize?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Tick Size*</label>
                    <Controller
                      name="tickSize"
                      control={control}
                      rules={{
                        required: "Tick Size is required",
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option value="" disabled>
                              Select...
                            </option>
                            <option>0.01</option>
                            <option>0.05</option>
                          </select>
                        </>
                      )}
                    />
                    {errors?.tickSize && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.tickSize?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Expiry Date</label>
                    <input
                      className="form-control"
                      placeholder="Expiry Date"
                      {...register("expiryDate", {})}
                    />
                    {errors?.expiryDate && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.expiryDate?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Round of value*</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Round of value"
                      {...register("round_of_value", {
                        required: "Round of value is required",
                        valueAsNumber: true, // Converts the input to a number
                        validate: (value) => Number.isInteger(value) || "Only integers are allowed",
                      })}
                      onKeyPress={(e) => {
                        if (e.key === '-' || e.key === '.' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors?.round_of_value && (
                      <div className="validationAlert" style={{ margin: "10px 0px" }}>
                        {errors?.round_of_value?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Variation*</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Variation"
                      {...register("variation", {
                        required: "Variation is required",
                        valueAsNumber: true, // Converts the input to a number
                        validate: (value) => Number.isInteger(value) || "Only integers are allowed",
                      })}
                      onKeyPress={(e) => {
                        if (e.key === '-' || e.key === '.' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors?.variation && (
                      <div className="validationAlert" style={{ margin: "10px 0px" }}>
                        {errors?.variation?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Underlying Symbol</label>
                    <input
                      className="form-control"
                      placeholder="Underlying Symbol"
                      {...register("underlying_symbol", {
                        required: "Underlying Symbol is required"
                      })}
                    />
                    {errors?.underlying_symbol && (
                      <div className="validationAlert" style={{ margin: "10px 0px" }}>
                        {errors?.underlying_symbol?.message}
                      </div>
                    )}
                  </div>
                  {(initialValues?.broker && initialValues?.broker !== "FYERS") ?
                  <div className="form-group col-md-6">
                    <label>Tick Symbol*</label>
                    <Controller
                      name="tickSymbol"
                      control={control}
                      rules={{ required: "Symbol is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          {...field}
                          filterOptions={tickFilterOptions}
                          value={
                            fyersSymbol
                              ? fyersSymbol?.find(
                                (option) =>
                                  option.shortName === (field.value || "")
                              ) || null
                              : null
                          }
                          options={
                            fyersSymbol ? fyersSymbol : []
                          }
                          onChange={(event, value) => {
                            field.onChange(value?.shortName || "");
                            if (value) {
                              // handleFilterSymbol(event, value);
                            }
                          }}
                          getOptionLabel={(option) => option?.shortName || ""}
                          renderOption={(props, option) => (
                            <div
                              {...props}
                              key={option?._id}
                              style={{
                                width: "100%",
                                display: "inline-block",
                              }}
                            >
                              <div
                                style={{ fontSize: "13px" }}
                                className="symbolFont pt-1"
                              >
                                {option?.shortName?.toString()}
                              </div>
                              <div style={{ fontSize: "11px", color: "gray" }}>
                                <div
                                  className="mr-2 companyNameFont"
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  {option?.companyName?.toString()}
                                </div>
                              </div>
                            </div>
                          )}
                          renderInput={(params) => <TextField {...params} />}
                          onClose={(event, reason) => {
                            if (reason === "toggleInput") {
                              field.onChange(null);
                              // handleFilterSymbol(event, null);
                            }
                          }}
                        />
                      )}
                    />
                    {errors?.tickSymbol && (
                      <div className="validationAlert" style={{ margin: "10px 0px" }}>
                        {errors?.tickSymbol?.message}
                      </div>
                    )}
                  </div>
                  : null }
                </div>

                <div className="col-md-12">
                  <div className="col-md-12 justify-content-center text-center pt-3">
                    <button type="submit" className="btn btn-primary ml-3 px-5">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default OptionStrategyTwoSymbolEditForm;
