// BASIC IMPORTS
import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// MUI IMPORTS
import { Button } from "@mui/material";

// PAI IMPORTS
import { trashOrderDelete } from "../../../actions/orderAction";
import { orderPairsGet } from "../../../actions/tradingAction";


function OrderEditForm({ initialValues, onSubmit }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({ defaultValues: initialValues });

  const [pairId, setPairId] = useState();
  const [orderPair, setOrderPair] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);

  const deleteOrder = async (data) => {
    await trashOrderDelete(dispatch, data);
    if (store.getState()?.order?.trashOrderData) {
      Swal.fire("Deleted!", "Order deleted successfully.", "success");
      navigate("/order");
    } else if (store.getState()?.order?.error) {
      toast.error(
        store.getState()?.order?.errorMessage
          ? store.getState()?.order?.errorMessage?.message
          : "Error deleting data. Please try again."
      );
    }
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete order?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
      cancelButtonColor: "#6c757d",
      cancelButtonText: "Cancel",
      showDenyButton: true,
      denyButtonColor: "#d33",
      denyButtonText: "Cancel with Delete",
      reverseButtons: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { ids: [id], status: 0 };
        await deleteOrder(data)
      } else if (result.isDenied) {
        const data = { ids: [id], status: 1 };
        await deleteOrder(data)
      }
    });
  };

  const getOrderPairs = async () => {
    await orderPairsGet(dispatch);
    if (store.getState()?.trading?.orderPair) {
      const res = store.getState()?.trading?.orderPair;
      if (res) {
        setOrderPair(res?.data);
      }
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    if (initialValues) {
      Object?.keys(initialValues).forEach((key) => {
        if (key === "mo_at") {
          const formattedDate = formatDate(initialValues[key]);
          setValue(key, formattedDate);
        } else {
          setValue(key, initialValues[key]);
        }
      });
    }
  }, [initialValues, setValue]);

  useEffect(() => {
    const filteredOrderPair = orderPair.filter((dict) => dict["id"] === pairId);
    if (filteredOrderPair.length === 1) {
      setValue("accountId", filteredOrderPair[0]?.accountId);
      setValue("buyPrice", filteredOrderPair[0]?.buyPrice);
      setValue("companyName", filteredOrderPair[0]?.companyName);
      setValue("symbol", filteredOrderPair[0]?.symbol);
      setValue("sellPrice", filteredOrderPair[0]?.sellPrice);
    }
    // eslint-disable-next-line
  }, [pairId]);

  useEffect(() => {
    getOrderPairs();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <div className="page-header-title d-flex justify-content-between pb-3">
        <div className="ListWithBadge">
          <Button className="adminbackBtn" onClick={() => navigate("/order")}>
            <i className="feather icon-chevron-right"></i>
          </Button>
          <h3 className="m-b-20 mr-2">Edit</h3>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-square btn-danger mr-0"
            onClick={() => handleDelete(initialValues?._id)}
          >
            <i className="feather icon-trash-2"></i> Delete
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body mobileCardBody">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Pair Id*</label>
                    <Controller
                      name="trading"
                      control={control}
                      rules={{
                        required: "Pair id is required",
                      }}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="form-control"
                          defaultValue=""
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setPairId(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select...
                          </option>
                          {orderPair?.map((dict, index) => (
                            <option key={index} value={dict["id"]}>
                              {dict["id"]}{" "}
                              &nbsp;&nbsp;&nbsp;::&nbsp;&nbsp;&nbsp;BuyPrice:{" "}
                              {dict["buyPrice"]} &nbsp;&nbsp;::&nbsp;&nbsp;
                              SellPrice: {dict["sellPrice"]}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors?.trading && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.trading?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Account Id*</label>
                    <input
                      type="name"
                      className="form-control"
                      disabled={true}
                      placeholder="Account Id"
                      {...register("accountId", {
                        required: "Account id is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.accountId && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.accountId?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Symbol Full Name*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Symbol Full Name"
                      disabled={true}
                      {...register("companyName", {
                        required: "Symbol full name is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.companyName && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.companyName?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Symbol*</label>
                    <input
                      type="name"
                      className="form-control"
                      disabled={true}
                      placeholder="Symbol"
                      {...register("symbol", {})}
                    />
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Exchange*</label>
                    <input
                      type="name"
                      className="form-control"
                      disabled={true}
                      placeholder="Exchange"
                      {...register("exchange", {})}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Product*</label>
                    <input
                      type="name"
                      className="form-control"
                      disabled={true}
                      placeholder="Product"
                      {...register("product", {})}
                    />
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Order Id*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Order Id"
                      {...register("orderId", {
                        required: "Order id is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.orderId && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.orderId?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Ordered Qty*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Ordered Qty"
                      {...register("origQty", {
                        required: "Ordered qty is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.origQty && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.origQty?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Buy Price*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Buy Price"
                      {...register("buyPrice", {
                        required: "Buy price is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.buyPrice && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.buyPrice?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Sell Price*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Sell Price"
                      {...register("sellPrice", {
                        required: "Sell price is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.sellPrice && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.sellPrice?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Price*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Price"
                      {...register("price", {
                        required: "Price is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.price && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.price?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Order Status*</label>
                    <Controller
                      name="orderStatus"
                      control={control}
                      rules={{
                        required: "Order status is required",
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option value="" disabled>
                              Select...
                            </option>
                            <option>Ordered</option>
                            <option>Requested</option>
                            <option>Executed</option>
                            <option>Expired</option>
                            <option>Queued</option>
                            <option>Cancelled</option>
                            <option>Partially Executed</option>
                            <option>Price Beyond</option>
                            <option>Insufficient limit</option>
                            <option>Price Beyond</option>
                            <option>Error</option>
                          </select>
                        </>
                      )}
                    />
                    {errors?.orderStatus && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.orderStatus?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Side*</label>
                    <Controller
                      name="side"
                      control={control}
                      rules={{ required: "Side is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option value="" disabled>
                              Select...
                            </option>
                            <option value="buy">Buy</option>
                            <option value="sell">Sell</option>
                          </select>
                        </>
                      )}
                    />
                    {errors?.side && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.side?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Switch Type*</label>
                    <Controller
                      name="switchType"
                      control={control}
                      rules={{ required: "Switch Type is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option value="" disabled>
                              Select...
                            </option>
                            <option>Buy</option>
                            <option>Sell</option>
                          </select>
                        </>
                      )}
                    />
                    {errors?.switchType && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.switchType?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Order Side*</label>
                    <Controller
                      name="orderSide"
                      control={control}
                      rules={{ required: "Order side is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option value="" disabled>
                              Select...
                            </option>
                            <option>Buy Completed</option>
                            <option>Sell Completed</option>
                            <option>Completed</option>
                            <option>Pending</option>
                          </select>
                        </>
                      )}
                    />
                    {errors?.orderSide && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.orderSide?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Expiry Date</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Expiry Date"
                      {...register("expiryDate", {})}
                    />
                    {errors?.expiryDate && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.expiryDate?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Modified At</label>
                    <input
                      id="mo_at"
                      className="form-control"
                      placeholder="Expiry Date"
                      {...register("mo_at", {})}
                    />
                  </div>
                </div>

                <div className="row col-md-12 justify-content-center pt-3">
                  <button type="submit" className="btn btn-primary ml-3 px-5">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderEditForm;
