// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

// MUI IMPORTS
import { Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Autocomplete, Paper, Checkbox, Tooltip, IconButton } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import DeleteIcon from "@mui/icons-material/Delete";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";

// API IMPORTS
import { deleteAutoBacktestTempSymbols, fyersSymbolTypesGet, fyersMultiSymbolFilter, getAutoBacktestTempSymbols, transferAutoBacktestSymbols } from "../../../actions/backtestAction";


function descendingComparator(a, b, symbolBy) {
  if (b[symbolBy] < a[symbolBy]) {
    return -1;
  }
  if (b[symbolBy] > a[symbolBy]) {
    return 1;
  }
  return 0;
}

function getComparator(symbol, symbolBy) {
  return symbol === "desc"
    ? (a, b) => descendingComparator(a, b, symbolBy)
    : (a, b) => -descendingComparator(a, b, symbolBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const symbol = comparator(a[0], b[0]);
    if (symbol !== 0) {
      return symbol;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "shortName",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Symbol Full Name",
  },
  {
    id: "marketType",
    numeric: true,
    disablePadding: false,
    label: "Exchange",
  },
  {
    id: "LotSize",
    numeric: true,
    disablePadding: false,
    label: "Lotsize",
  },
  {
    id: "tickSize",
    numeric: true,
    disablePadding: false,
    label: "Ticksize",
  },
  {
    id: "start_price",
    numeric: true,
    disablePadding: false,
    label: "Start Price",
  },
  {
    id: "end_price",
    numeric: true,
    disablePadding: false,
    label: "End Price",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    symbol,
    symbolBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell?.id}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={symbolBy === headCell?.id ? symbol : false}
          >
            <TableSortLabel
              active={symbolBy === headCell?.id}
              direction={symbolBy === headCell?.id ? symbol : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {symbolBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {symbol === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  symbol: PropTypes.oneOf(["asc", "desc"]).isRequired,
  symbolBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function AddBulkBacktestSymbols() {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [symbolAllTypes, setSymbolAllTypes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [symbol, setSymbol] = useState("desc");
  const [symbolBy, setSymbolBy] = useState("symbol");
  const [filterSymbolsData, setFilterSymbolsData] = useState([]);
  const [bulkSymbols, setBulkSymbols] = useState([]);
  const [selectedSymbols, setSelectedSymbols] = useState("");
  const [symbols, setSymbols] = useState({});

  const watchExchange = watch("exchange", "");

  const handleRequestSort = (event, property) => {
    const isAsc = symbolBy === property && symbol === "asc";
    setSymbol(isAsc ? "desc" : "asc");
    setSymbolBy(property);
  };

  const getFyersSymbolTypes = async () => {
    if (store.getState()?.backtest?.fyers_symbol_types === undefined) {
      await fyersSymbolTypesGet(dispatch);
      if (store.getState()?.backtest?.fyers_symbol_types) {
        const res = store.getState()?.backtest?.fyers_symbol_types;
        if (res) {
          setSymbolAllTypes(res?.symbol_type);
        }
      }
    } else {
      setSymbolAllTypes(
        store.getState()?.backtest?.fyers_symbol_types?.symbol_type
      );
    }
  };

  const getAutoBacktestTempSymbolsData = async () => {
    await getAutoBacktestTempSymbols(dispatch);
    if (store.getState()?.backtest?.get_auto_backtest_temp_symbols) {
      const res =
        store.getState()?.backtest?.get_auto_backtest_temp_symbols
          ?.auto_backtest_symbols;
      if (res) {
        setFilterSymbolsData(res);
        setBulkSymbols(res)
        const uniqueSymbolsWithIds = [
          ...new Set(res?.map((item) => item?.shortName)),
        ];
        setSymbols(uniqueSymbolsWithIds);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(filterSymbolsData, getComparator(symbol, symbolBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [symbol, symbolBy, page, rowsPerPage, filterSymbolsData]
  );

  const onSubmit = async (data) => {
    data["symbolType"] = data["symbolType"]["symbolType"];
    await fyersMultiSymbolFilter(dispatch, data);
    if (store.getState()?.backtest?.get_fyers_filter_symbol) {
      if (store.getState()?.backtest?.get_fyers_filter_symbol?.filter_symbols) {
        toast.success("Symbol retrived successfully!");
        setFilterSymbolsData(
          store.getState()?.backtest?.get_fyers_filter_symbol?.filter_symbols
        );
        setBulkSymbols(
          store.getState()?.backtest?.get_fyers_filter_symbol?.filter_symbols
        )
      } else if (store.getState()?.backtest?.error) {
        toast.error(
          store.getState()?.backtest?.errorMessage
            ? store.getState()?.backtest?.errorMessage?.message
            : "Error deleting data. Please try again."
        );
      }
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filterSymbolsData?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleMultiSymbolDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete symbols?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteAutoBacktestTempSymbols(dispatch, selected);
        if (store.getState()?.backtest?.auto_backtest_delete_symbols) {
          toast.success(
            store.getState()?.backtest?.auto_backtest_delete_symbols?.message
          );
          getAutoBacktestTempSymbolsData();
          setPage(0);
          setSelected([]);
        } else if (store.getState()?.backtest?.error) {
          toast.error(
            store.getState()?.backtest?.errorMessage
              ? store.getState()?.backtest?.errorMessage?.message
              : "Error deleting data. Please try again."
          );
        }
      }
    });
  };

  const handleAddBulkSymbols = async () => {
    await transferAutoBacktestSymbols(dispatch, selected);
    if (store.getState()?.backtest?.add_auto_backtest_bulk_symbols) {
      toast.success(
        store.getState()?.backtest?.add_auto_backtest_bulk_symbols?.message
      );
      setSelected([]);
    } else if (store.getState()?.backtest?.error) {
      toast.error(
        store.getState()?.backtest?.errorMessage
          ? store.getState()?.backtest?.errorMessage?.message
          : "Error deleting data. Please try again."
      );
    }
  };


  useEffect(() => {
    const filterSymbol = bulkSymbols?.filter(
      (item) => item?.shortName === selectedSymbols
    );
    if (filterSymbol.length === 0) {
      setFilterSymbolsData(bulkSymbols);
    } else if (filterSymbol.length >= 0) {
      setFilterSymbolsData(filterSymbol);
    }
    setPage(0);
    // eslint-disable-next-line
  }, [selectedSymbols]);


  useEffect(() => {
    if (watchExchange !== "") {
      setValue("symbolType", "");
      const filteredData = symbolAllTypes?.filter(
        (item) => item.exchange === watchExchange
      );
      setFilterData(filteredData);
    }
    // eslint-disable-next-line
  }, [watchExchange]);

  useEffect(() => {
    getFyersSymbolTypes();
    getAutoBacktestTempSymbolsData();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/auto-backtest">Auto Backtest</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link
                          to="/add-view-backtest-symbols"
                          style={{ fontWeight: 600 }}
                        >
                          Add Bulk Symbols
                        </Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-3">
                      <div className="ListWithBadge">
                        <h3 className="m-b-20 mr-2">Add Backtest Symbols</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <form
                      id="autoBacktestForm"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="card">
                        <div
                          className="card-body mobileCardBody"
                          style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        >
                          <div className="row col-md-12">
                            <div className="form-group col-md-3">
                              <label>Exchange*</label>
                              <Controller
                                name="exchange"
                                control={control}
                                rules={{ required: "Exchange is required" }}
                                render={({ field }) => (
                                  <select
                                    className="form-control"
                                    {...field}
                                    defaultValue=""
                                  >
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    <option value="NSE">NSE</option>
                                    <option value="BSE">BSE</option>
                                  </select>
                                )}
                              />
                              {errors.exchange && (
                                <span className="validationAlert">
                                  {errors.exchange.message}
                                </span>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label>Symbol Type*</label>
                              <Controller
                                name="symbolType"
                                control={control}
                                rules={{ required: "Symbol type is required" }}
                                render={({ field }) => (
                                  <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-demo"
                                    options={filterData || []}
                                    value={field.value ? field.value : null} // Use field.value directly if it exists
                                    onChange={(event, value) => {
                                      field.onChange(value); // Just update with the selected value directly
                                    }}
                                    getOptionLabel={(option) =>
                                      option?.symbolType || ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option?.symbolType ===
                                      value?.symbolType &&
                                      option?.exchange === value?.exchange
                                    }
                                    renderOption={(props, option) => (
                                      <div
                                        {...props}
                                        key={option?._id}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            display: "flex",
                                          }}
                                        >
                                          <div className="symbolFont pt-1">
                                            {option?.symbolType}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                )}
                              />

                              {errors.symbolType && (
                                <span className="validationAlert">
                                  {errors.symbolType.message}
                                </span>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label>Start Price</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Start Price"
                                name="start_price"
                                {...register("start_price", {})}
                              />
                              {errors.start_price && (
                                <span className="validationAlert">
                                  {errors.start_price.message}
                                </span>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label>End Price</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="End Price"
                                name="end_price"
                                {...register("end_price", {})}
                              />
                              {errors.end_price && (
                                <span className="validationAlert">
                                  {errors.end_price.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="text-center mt-3">
                            <button
                              type="submit"
                              className="btn btn-square btn-primary mr-0"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <Box sx={{ width: "100%" }}>
                      <Paper
                        sx={{ width: "100%", mb: 2 }}
                        className="customTable"
                      >
                        <Toolbar
                          sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            ...(selected?.length > 0 && {
                              bgcolor: (theme) =>
                                alpha(
                                  theme.palette.primary.main,
                                  theme.palette.action.activatedOpacity
                                ),
                            }),
                          }}
                          className="p-1"
                        >
                          {selected?.length > 0 ? (
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              color="inherit"
                              variant="subtitle1"
                              component="div"
                            >
                              {selected?.length} selected
                            </Typography>
                          ) : (
                            <div className="ListWithBadge" style={{ width: "100%", justifyContent: "space-between" }}>
                              <div className="d-flex">
                                <h3 className="m-b-20 mr-2">Symbol</h3>
                                <h4 style={{ marginTop: "6px" }}>
                                  <span className="badge badge-secondary">
                                    {filterSymbolsData?.length}
                                  </span>
                                </h4>
                              </div>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={symbols}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={(event, value) =>
                                  setSelectedSymbols(value)
                                }
                              />
                            </div>

                          )}

                          {selected?.length > 0 ? (
                            <>
                              <button
                                className="btn btn-outline-info m-0 editDeleteBtn mr-2"
                                onClick={handleAddBulkSymbols}
                              >
                                Transfer Symbols
                              </button>
                              <Tooltip title="Delete">
                                <IconButton onClick={handleMultiSymbolDelete}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : null}
                        </Toolbar>
                        <TableContainer>
                          <Table
                            className="addTableFontColor"
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead
                              numSelected={selected?.length}
                              symbol={symbol}
                              symbolBy={symbolBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={filterSymbolsData?.length || 0}
                            />

                            <TableBody>
                              {visibleRows &&
                                visibleRows?.map((row, index) => {
                                  const isItemSelected = isSelected(row?._id);
                                  const labelId = `enhanced-table-checkbox-${index}`;

                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row?._id}
                                      selected={isItemSelected}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          onClick={(event) =>
                                            handleClick(event, row?._id)
                                          }
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                        />
                                      </TableCell>

                                      <TableCell>{row?.shortName}</TableCell>
                                      <TableCell>
                                        {row?.companyName}
                                      </TableCell>
                                      <TableCell>{row?.marketType}</TableCell>
                                      <TableCell>{row?.LotSize}</TableCell>
                                      <TableCell>{row?.tickSize}</TableCell>
                                      <TableCell>
                                        {row?.start_price}
                                      </TableCell>
                                      <TableCell>{row?.end_price}</TableCell>
                                      {/* <TableCell>
                                          <Link
                                            type="submit"
                                            className="btn btn-outline-primary mr-2 m-0 editDeleteBtn"
                                            to={`/backtest-symbol-edit/${row?._id}`}
                                          >
                                            <i className="feather icon-edit m-0"></i>
                                          </Link>
                                        </TableCell> */}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={filterSymbolsData?.length || 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBulkBacktestSymbols;
