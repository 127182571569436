import { createSlice } from "@reduxjs/toolkit";

const tradingSlice = createSlice({
  name: "trading",
  initialState: {
    trading: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    createTradingStart: (state) => {
      state.isFetching = false;
      state.trading = null;
      state.error = false;
      state.errorMessage = "";
    },
    createTradingSuccess: (state, action) => {
      state.isFetching = true;
      state.trading = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    createTradingFailure: (state, error) => {
      state.isFetching = false;
      state.trading = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getTradingStart: (state) => {
      state.isFetching = false;
      state.tradingList = null;
      state.error = false;
      state.errorMessage = "";
    },
    getTradingSuccess: (state, action) => {
      state.isFetching = true;
      state.tradingList = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getTradingFailure: (state, error) => {
      state.isFetching = false;
      state.tradingList = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getTradingByIdStart: (state) => {
      state.isFetching = false;
      state.tradingData = null;
      state.error = false;
      state.errorMessage = "";
    },
    getTradingByIdSuccess: (state, action) => {
      state.isFetching = true;
      state.tradingData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getTradingByIdFailure: (state, error) => {
      state.isFetching = false;
      state.tradingData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getOrderPairStart: (state) => {
      state.isFetching = false;
      state.orderPair = null;
      state.error = false;
      state.errorMessage = "";
    },
    getOrderPairSuccess: (state, action) => {
      state.isFetching = true;
      state.orderPair = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getOrderPairFailure: (state, error) => {
      state.isFetching = false;
      state.orderPair = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    updateTradingStart: (state) => {
      state.isFetching = false;
      state.tradingData = null;
      state.error = false;
      state.errorMessage = "";
    },
    updateTradingSuccess: (state, action) => {
      state.isFetching = true;
      state.tradingData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    updateTradingFailure: (state, error) => {
      state.isFetching = false;
      state.tradingData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteTradingStart: (state) => {
      state.isFetching = false;
      state.tradingData = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteTradingSuccess: (state, action) => {
      state.isFetching = true;
      state.tradingData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteTradingFailure: (state, error) => {
      state.isFetching = false;
      state.tradingData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteAerageSymbolPriceStart: (state) => {
      state.isFetching = false;
      state.delSymbolAvgPrice = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteAerageSymbolPriceSuccess: (state, action) => {
      state.isFetching = true;
      state.delSymbolAvgPrice = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteAerageSymbolPriceFailure: (state, error) => {
      state.isFetching = false;
      state.delSymbolAvgPrice = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getPairRangeMessagesStart: (state) => {
      state.isFetching = false;
      state.pairRangeMessage = null;
      state.error = false;
      state.errorMessage = "";
    },
    getPairRangeMessagesSuccess: (state, action) => {
      state.isFetching = true;
      state.pairRangeMessage = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getPairRangeMessagesFailure: (state, error) => {
      state.isFetching = false;
      state.pairRangeMessage = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteSymbolCycleStart: (state) => {
      state.isFetching = false;
      state.delSymbolCycle = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteSymbolCycleSuccess: (state, action) => {
      state.isFetching = true;
      state.delSymbolCycle = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteSymbolCycleFailure: (state, error) => {
      state.isFetching = false;
      state.delSymbolCycle = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
  },
});

export const {
  createTradingFailure,
  createTradingStart,
  createTradingSuccess,
  getTradingFailure,
  getTradingStart,
  getTradingSuccess,
  getTradingByIdFailure,
  getTradingByIdStart,
  getTradingByIdSuccess,
  updateTradingStart,
  updateTradingSuccess,
  updateTradingFailure,
  deleteTradingStart,
  deleteTradingSuccess,
  deleteTradingFailure,
  getOrderPairStart,
  getOrderPairSuccess,
  getOrderPairFailure,
  deleteAerageSymbolPriceStart,
  deleteAerageSymbolPriceSuccess,
  deleteAerageSymbolPriceFailure,
  getPairRangeMessagesStart,
  getPairRangeMessagesSuccess,
  getPairRangeMessagesFailure,
  deleteSymbolCycleStart,
  deleteSymbolCycleSuccess,
  deleteSymbolCycleFailure,
} = tradingSlice.actions;
export default tradingSlice.reducer;
