import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = false;
      state.auth = null;
      state.error = false;
      state.errorMessage = "";
    },
    loginSuccess: (state, action) => {
      state.isFetching = true;
      state.auth = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    loginFailure: (state, error) => {
      state.isFetching = false;
      state.auth = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    registerStart: (state) => {
      state.isFetching = false;
      state.auth = null;
      state.error = false;
      state.errorMessage = "";
    },
    registerSuccess: (state, action) => {
      state.isFetching = true;
      state.auth = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    registerFailure: (state, error) => {
      state.isFetching = false;
      state.auth = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    forgotPasswordStart: (state) => {
      state.isFetching = false;
      state.forgot_password = null;
      state.error = false;
      state.errorMessage = "";
    },
    forgotPasswordSuccess: (state, action) => {
      state.isFetching = true;
      state.forgot_password = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    forgotPasswordFailure: (state, error) => {
      state.isFetching = false;
      state.forgot_password = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    changeLoaderState: (state, action) => {
      state.loading = action.payload;
    },
    getClientStart: (state) => {
      state.isFetching = false;
      state.client = null;
      state.error = false;
      state.errorMessage = "";
    },
    getClientSuccess: (state, action) => {
      state.isFetching = true;
      state.client = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getClientFailure: (state, error) => {
      state.isFetching = false;
      state.client = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    putClientStart: (state) => {
      state.isFetching = false;
      state.client = null;
      state.error = false;
      state.errorMessage = "";
    },
    putClientSuccess: (state, action) => {
      state.isFetching = true;
      state.client = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    putClientFailure: (state, error) => {
      state.isFetching = false;
      state.client = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    putClientActivationStart: (state) => {
      state.isFetching = false;
      state.client_active = null;
      state.error = false;
      state.errorMessage = "";
    },
    putClientActivationSuccess: (state, action) => {
      state.isFetching = true;
      state.client_active = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    putClientActivationFailure: (state, error) => {
      state.isFetching = false;
      state.client_active = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    putClientPasswordStart: (state) => {
      state.isFetching = false;
      state.client_password = null;
      state.error = false;
      state.errorMessage = "";
    },
    putClientPasswordSuccess: (state, action) => {
      state.isFetching = true;
      state.client_password = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    putClientPasswordFailure: (state, error) => {
      state.isFetching = false;
      state.client_password = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getClientFilterStart: (state) => {
      state.isFetching = false;
      state.client_filter = null;
      state.error = false;
      state.errorMessage = "";
    },
    getClientFilterSuccess: (state, action) => {
      state.isFetching = true;
      state.client_filter = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getClientFilterFailure: (state, error) => {
      state.isFetching = false;
      state.client_filter = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteClientStart: (state) => {
      state.isFetching = false;
      state.clientData = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteClientSuccess: (state, action) => {
      state.isFetching = true;
      state.clientData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteClientFailure: (state, error) => {
      state.isFetching = false;
      state.clientData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    backupDbStart: (state) => {
      state.isFetching = false;
      state.backup_db = null;
      state.error = false;
      state.errorMessage = "";
    },
    backupDbSuccess: (state, action) => {
      state.isFetching = true;
      state.backup_db = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    backupDbFailure: (state, error) => {
      state.isFetching = false;
      state.backup_db = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    liveDbToLocalStart: (state) => {
      state.isFetching = false;
      state.live_to_local = null;
      state.error = false;
      state.errorMessage = "";
    },
    liveDbToLocalSuccess: (state, action) => {
      state.isFetching = true;
      state.live_to_local = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    liveDbToLocalFailure: (state, error) => {
      state.isFetching = false;
      state.live_to_local = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    sendOtpForLiveDBStart: (state) => {
      state.isFetching = false;
      state.otp_verify = null;
      state.error = false;
      state.errorMessage = "";
    },
    sendOtpForLiveDBSuccess: (state, action) => {
      state.isFetching = true;
      state.otp_verify = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    sendOtpForLiveDBFailure: (state, error) => {
      state.isFetching = false;
      state.otp_verify = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    otpVerificationForLiveDBStart: (state) => {
      state.isFetching = false;
      state.otp_verify_data = null;
      state.error = false;
      state.errorMessage = "";
    },
    otpVerificationForLiveDBSuccess: (state, action) => {
      state.isFetching = true;
      state.otp_verify_data = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    otpVerificationForLiveDBFailure: (state, error) => {
      state.isFetching = false;
      state.otp_verify_data = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  registerStart,
  registerSuccess,
  registerFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  changeLoaderState,
  getClientStart,
  getClientSuccess,
  getClientFailure,
  putClientStart,
  putClientSuccess,
  putClientFailure,
  putClientActivationStart,
  putClientActivationFailure,
  putClientActivationSuccess,
  getClientFilterStart,
  getClientFilterSuccess,
  getClientFilterFailure,
  deleteClientStart,
  deleteClientFailure,
  deleteClientSuccess,
  putClientPasswordStart,
  putClientPasswordFailure,
  putClientPasswordSuccess,
  backupDbStart,
  backupDbFailure,
  backupDbSuccess,
  liveDbToLocalStart,
  liveDbToLocalSuccess,
  liveDbToLocalFailure,
  sendOtpForLiveDBStart,
  sendOtpForLiveDBSuccess,
  sendOtpForLiveDBFailure,
  otpVerificationForLiveDBStart,
  otpVerificationForLiveDBSuccess,
  otpVerificationForLiveDBFailure,
} = authSlice.actions;
export default authSlice.reducer;
