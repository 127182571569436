import { createSlice } from "@reduxjs/toolkit";

const exchangeSlice = createSlice({
  name: "exchange",
  initialState: {
    exchange: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    createExchangeStart: (state) => {
      state.isFetching = false;
      state.exchange = null;
      state.error = false;
      state.errorMessage = "";
    },
    createExchangeSuccess: (state, action) => {
      state.isFetching = true;
      state.exchange = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    createExchangeFailure: (state, error) => {
      state.isFetching = false;
      state.exchange = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getExchangeProductSettingStart: (state) => {
      state.isFetching = false;
      state.exchangeProductSettingList = null;
      state.error = false;
      state.errorMessage = "";
    },
    getExchangeProductSettingSuccess: (state, action) => {
      state.isFetching = true;
      state.exchangeProductSettingList = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getExchangeProductSettingFailure: (state, error) => {
      state.isFetching = false;
      state.exchangeProductSettingList = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getExchangeStart: (state) => {
      state.isFetching = false;
      state.exchangeList = null;
      state.error = false;
      state.errorMessage = "";
    },
    getExchangeSuccess: (state, action) => {
      state.isFetching = true;
      state.exchangeList = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getExchangeFailure: (state, error) => {
      state.isFetching = false;
      state.exchangeList = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteExchangeStart: (state) => {
      state.isFetching = false;
      state.exchangeData = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteExchangeSuccess: (state, action) => {
      state.isFetching = true;
      state.exchangeData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteExchangeFailure: (state, error) => {
      state.isFetching = false;
      state.exchangeData = null;
      state.error = true;
      state.errorMessage = error.payload;
    }
  }
});

export const {
  createExchangeFailure,
  createExchangeStart,
  createExchangeSuccess,
  getExchangeFailure,
  getExchangeStart,
  getExchangeSuccess,
  deleteExchangeStart,
  deleteExchangeSuccess,
  deleteExchangeFailure,
  getExchangeProductSettingStart,
  getExchangeProductSettingSuccess,
  getExchangeProductSettingFailure,
} = exchangeSlice.actions;
export default exchangeSlice.reducer;
