import {jwtDecode} from 'jwt-decode'

export const getAccessTokenFromSessionStorage = () => {
    return sessionStorage.getItem('auth');
}

export const clearSessionStorage = () => {
    return sessionStorage.clear();
}

export const clearLocalStorage = () => {
    return localStorage.clear();
}

export const decodeToken = () => {
    const token = getAccessTokenFromSessionStorage();
    if(token){
        const user = jwtDecode(token)
        return user
    }else{
        return false
    }
}