// BASIC IMPORTS
import React, { useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// API IMPORTS
import { login } from "../../../actions/authAction";


const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const { store } = useContext(ReactReduxContext);

  const password = useRef({});
  const email = useRef({});
  password.current = watch("password", "");
  email.current = watch("email", "");

  const dispatch = useDispatch();

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const onSubmit = async (data) => {
    await login(dispatch, { email: email.current, password: password.current });
    if (store.getState()?.auth?.auth) {
      navigate("/trading");
      toast.success("Successfully logged in!");
    }
    if (store.getState()?.auth?.errorMessage) {
      toast.error(
        store.getState()?.auth?.errorMessage
          ? store.getState()?.auth?.errorMessage?.message
          : "Invalid Email or Password"
      );
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r"></span>
          <span className="r s"></span>
          <span className="r s"></span>
          <span className="r"></span>
        </div>
        <form id="signinForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4">
                <i className="feather icon-unlock auth-icon"></i>
              </div>
              <h3 className="mb-4">Login</h3>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control fixInputWidth"
                  id="emailAddress"
                  name="email"
                  placeholder="Email Address"
                  {...register("email", {
                    required: "Email is required",
                    maxLength: {
                      value: 100,
                    },
                  })}
                />
                {errors?.email && (
                  <div
                    id="oldPasworAlert"
                    className="validationAlert"
                    style={{ margin: "10px 0px" }}
                  >
                    {errors?.email?.message}
                  </div>
                )}
              </div>
              <div className="input-group mb-4">
                <div className="input-group">
                  <input
                    type={passwordType}
                    id="lPassword"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    {...register("password", {
                      required: "Password is required",
                      maxLength: {
                        value: 16,
                      },
                    })}
                  />
                  <div
                    className="input-group-append"
                    onClick={() => togglePassword("password")}
                  >
                    <span className="input-group-text eyeBtn">
                      {passwordType ? (
                        <i className="fa fa-fw fa-eye field-icon"></i>
                      ) : (
                        <i className="fa fa-fw fa-eye-slash field-icon"></i>
                      )}
                    </span>
                  </div>
                </div>
                {errors?.password && (
                  <div
                    id="newPaswordAlert"
                    className="validationAlert"
                    style={{ margin: "10px 0px" }}
                  >
                    {errors?.password?.message}
                  </div>
                )}
              </div>
              <button className="btn btn-primary shadow-2 mb-4">Login</button>
              <p className="mb-0 text-muted">
                Don't have an account?
                <button
                  type="submit"
                  className="customeBtn pl-1"
                  onClick={() => navigate("/register")}
                >
                  Signup
                </button>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
