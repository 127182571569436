// BASIC IMPORTS
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import OptionStrategyTwoSymbolEditForm from "./OptionStrategyTwoSymbolEditForm";

// API IMPORTS
import { getSymbolById, updateSymbol } from "../../../actions/optionStrategyCommanAction";


function OptionStrategyTwoSymbolEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialData, setInitialData] = useState();


  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();


  const handleFormSubmit = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update Symbol?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateSymbol(dispatch, id, data).then((res) => {
          toast.success("Data updated successfully!");
          navigate("/option-strategy-two-symbols");
        }).catch((error) => {
          toast.error("Symbol not updated due to some error")
        })
      }
    });
  };


  useEffect(() => {
    const getSymbolData = async () => {
      await getSymbolById(dispatch, id).then((res) => {
        setInitialData(res?.data?.symbol || {});
      }).catch((error) => {
        toast.error("Something went wrong")
      })
    };
    getSymbolData();
  }, [id, dispatch, store]);


  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                        <Link to="/option-strategy-two" style={{ fontWeight: 600 }}>
                        ATM Strategy
                        </Link>
                      </li> */}
                      <li className="breadcrumb-item">
                        <Link to="" style={{ fontWeight: 600 }}>
                          Edit Symbol
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <OptionStrategyTwoSymbolEditForm
                  initialValues={initialData}
                  onSubmit={handleFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionStrategyTwoSymbolEdit;
