// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

// MUI IMPORTS
import { Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, IconButton, Tooltip, Checkbox } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { visuallyHidden } from "@mui/utils";
import DeleteIcon from "@mui/icons-material/Delete";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";

// API IMPORTS
import { uploadExcelGet, angelSymbolGet, upstoxSymbolGet, fyersSymbolGet } from "../../../actions/uploadExcel";
import { symbolPost, symbolsGet, symbolDelete, getFavSymbolsAccounts } from "../../../actions/symbolAction";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const data = comparator(a[0], b[0]);
    if (data !== 0) {
      return data;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "accountName",
    numeric: false,
    disablePadding: false,
    label: "Account",
  },
  {
    id: "shortName",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Symbol Full Name",
  },
  {
    id: "marketType",
    numeric: true,
    disablePadding: false,
    label: "Exchange",
  },
  {
    id: "LotSize",
    numeric: true,
    disablePadding: false,
    label: "Lotsize",
  },
  {
    id: "tickSize",
    numeric: true,
    disablePadding: false,
    label: "Ticksize",
  },
  {
    id: "upperCircuit",
    numeric: true,
    disablePadding: false,
    label: "Upper Circuit",
  },
  {
    id: "lowerCircuit",
    numeric: true,
    disablePadding: false,
    label: "Lower Circuit",
  },
  {
    id: "expiryDate",
    numeric: true,
    disablePadding: false,
    label: "Expiry",
  },
  {
    id: "setting",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { 
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    onRequestSort,
    rowCount,
   } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell?.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell?.id ? order : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {orderBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function Symbols() {
  const {
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [iciciSymbols, setIciciSymbols] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [dictList, setDictList] = useState([]);
  const [filterSymbol, filterSymbolList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("accountName");
  const [isOpen, setIsopen] = useState(false);
  const [filterSymbolsData, setFilterSymbolsData] = useState([]);
  const [filterParams, setFilterParams] = useState({
    accountName: "",
    shortName: "",
    marketType: "",
    LotSize: "",
    expiryDate: "",
  });

  const watchCompanyName = watch("companyName", "");
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 500,
    matchFrom: "any",
    stringify: (option) => option?.companyName + option?.shortName,
  });

  const getIciciSymbols = async () => {
    await uploadExcelGet(dispatch);
    if (store.getState()?.uploadExcel?.uploadExcelList) {
      const res = store.getState()?.uploadExcel?.uploadExcelList;
      if (res) {
        setIciciSymbols(res);
      }
    }
  };

  const getAngelSymbols = async () => {
    await angelSymbolGet(dispatch);
    if (store.getState()?.uploadExcel?.angel_symbols) {
      const res = store.getState()?.uploadExcel?.angel_symbols;
      if (res) {
        setFilterData(res?.AngelSymbol);
      }
    }
  };

  const getUpstoxSymbols = async () => {
    await upstoxSymbolGet(dispatch);
    if (store.getState()?.uploadExcel?.upstox_symbols) {
      const res = store.getState()?.uploadExcel?.upstox_symbols;
      if (res) {
        setFilterData(res?.complete);
      }
    }
  };

  const getFyersSymbols = async () => {
    await fyersSymbolGet(dispatch);
    if (store.getState()?.uploadExcel?.fyers_symbols) {
      const res = store.getState()?.uploadExcel?.fyers_symbols;
      if (res) {
        setFilterData(res?.FyersSymbol);
      }
    }
  };

  const compareDicts = (dict1, dict2) => {
    if(dict1.hasOwnProperty("_id")){
      delete dict1["_id"]
    }
    if(dict2.hasOwnProperty("Series")){
      delete dict2["Series"]
    }
    if(dict2.hasOwnProperty("_id")){
      delete dict2["_id"]
    }
    const sortedDict1 = Object.keys(dict1)
      .sort()
      .reduce((acc, key) => {
        acc[key] = dict1[key];
        return acc;
      }, {});

    const sortedDict2 = Object.keys(dict2)
      .sort()
      .reduce((acc, key) => {
        acc[key] = dict2[key];
        return acc;
      }, {});
    
    return JSON.stringify(sortedDict1) === JSON.stringify(sortedDict2);
  };

  const isDictInArray = (dict) => {
    return dictList?.some((item) => compareDicts(item, dict));
  };

  const addToDictArray = (dict) => {
    setDictList((prevState) => [...prevState, dict]);
    filterSymbolList((prevState) => [...prevState, dict]);
  };

  const handleDictNotPresent = async (data) => {
    if (data.length !== 0) {
      let newData = { ...data, accountName: accountName?.key, account: accountName?.value };
      delete newData["id"];

      await symbolPost(dispatch, newData).then((res) => {
        addToDictArray(newData);
        getSymbolsData();
        toast.success(res?.data);
      }).catch((error) => {
        toast.error("Symbol not added due to some error")
      })
    }
  };

  const handleAddSymbol = async (data) => {
    let newData = { ...data, accountName: accountName?.key, account: accountName?.value };
    if (!isDictInArray(newData)) {
      handleDictNotPresent(newData);
    } else {
      toast.error("Symbol already added");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(filterSymbol, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filterSymbol]
  );

  useEffect(() => {
    const nseData = iciciSymbols?.NSEScripMaster?.map((item, index) => ({
      ...item,
      marketType: "NSE",
    }));
    const bseData = iciciSymbols?.BSEScripMaster?.map((item, index) => ({
      ...item,
      marketType: "BSE",
    }));
    const futureData = iciciSymbols?.FONSEScripMaster?.map((item, index) => ({
      ...item,
      marketType: "NFO",
    }));

    if (nseData && bseData && futureData) {
      const AllMarketData = [...nseData, ...bseData, ...futureData];
      setFilterData(AllMarketData);
    }
    // eslint-disable-next-line
  }, [iciciSymbols]);

  const getSymbolsData = async () => {
    await symbolsGet().then((res) => {
      setDictList(res?.data);
      filterSymbolList(res?.data)
    }).catch((error) => {
      toast.error("Symbols list not found")
    })
  };

  const handleInputChange = (value) => {
    if (value === "ICICI") {
      getIciciSymbols();
    } else if (value === "ANGEL") {
      getAngelSymbols();
    } else if (value === "UPSTOX") {
      getUpstoxSymbols();
    } else if (value === "FYERS") {
      getFyersSymbols();
    }
  };

  const handleFilterChange = (e) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      [e.target.name]: e.target.value,
    }));
  };

  const ToggleSidebar = () => {
    setFilterParams({});
    reset();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const handleSubmit = async () => {
    const filteredData = dictList.filter(item => {
        return Object.keys(filterParams).every(key => 
            filterParams[key] === '' || item[key] === filterParams[key]
        );
    });
    filterSymbolList(filteredData);
    setPage(0);
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const handleReset = (event) => {
    setFilterParams({});
    reset();
    getSymbolsData();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const getSymbolsAccounts = async () => {
    await getFavSymbolsAccounts(dispatch).then((res) => {
      setFilterSymbolsData(res?.data);
    }).catch((error) => {
      toast.error("Favourite symbols, accounts data not found")
    })
  };

  const handleFilterSymbol = (event, value) => {
    if (value !== null) {
      setFilterParams({ ...filterParams, shortName: value.shortName });
    }
  };

  const handleFilterAccount = (event, value) => {
    if (value !== null) {
      setFilterParams({ ...filterParams, accountName: value?.key });
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filterSymbol?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleMultiDelete = async () => {
    if (selected?.length !== 0) {
      // delte multiple records
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to delete the selected ${selected?.length} record?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { ids: selected };
          await symbolDelete(data).then((res) => {
            toast.success("Symbol deleted successfully!");
              getSymbolsData();
              setSelected([])
              setPage(0);
          }).catch((error) => {
            toast.error("Symbol not able delete due to some error")
          })
        }
      });
    }
  };


  const filterUniqueSymbols = (data) => {
    const seen = new Set();
    return data?.filter((item) => {
      const pair = `${item.shortName}-${item.companyName}`;
      if (seen.has(pair)) {
        return false;
      }
      seen.add(pair);
      return true;
    });
  };


  const uniqueSymbols = filterUniqueSymbols(dictList || [])
  
  const options = filterSymbolsData?.accounts
    ? Object.keys(filterSymbolsData.accounts).map(key => ({
      key: key,
      value: filterSymbolsData.accounts[key]
    }))
    : [];


  useEffect(() => {
    getSymbolsData();
    getSymbolsAccounts();
    // eslint-disable-next-line
  }, [dispatch]);


  return (
    <div className="Main">
      <div className="container-fluid">
        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
          <div className="pt-4 sd-header">
            <Button className="adminbackBtn" onClick={ToggleSidebar}>
              <i className="feather icon-chevron-right"></i>
            </Button>
            <h3 className="mt-1">Filters</h3>
          </div>
          <div className="sd-body">
            <form>
              <div className="FilterForm">
                <div className="form-group">
                  <label>Account</label>
                  <Controller
                    name="account"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Autocomplete
                        fullWidth
                        disablePortal
                        className="favAccountDropdown"
                        id="combo-box-demo"
                        {...field}
                        value={
                          options.find((option) => option.key === field.value) || null
                        }
                        options={options}
                        onChange={(event, value) => {
                          field.onChange(value?.key || "");
                          if (value) {
                            handleFilterAccount(event, value);
                          }
                        }}
                        getOptionLabel={(option) => option.key}
                        renderOption={(props, option) => (
                          <div
                            {...props}
                            key={option.key}
                            style={{
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <div
                              style={{ fontSize: "13px" }}
                              className="symbolFont pt-1"
                            >
                              {option.key.toString()}
                            </div>
                            <div style={{ fontSize: "11px", color: "gray" }}>
                              <div
                                className="mr-2 companyNameFont"
                                style={{
                                  margin: "auto",
                                }}
                              >
                                {option.value.toString()}
                              </div>
                            </div>
                          </div>
                        )}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Symbol Full Name</label>
                  <Controller
                    name="shortName"
                    control={control}
                    rules={{ required: "Symbol is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        {...field}
                        filterOptions={filterOptions}
                        value={
                          uniqueSymbols
                            ? uniqueSymbols?.find(
                              (option) =>
                                option.shortName === (field.value || "")
                            ) || null
                            : null
                        }
                        options={
                          uniqueSymbols ? uniqueSymbols : []
                        }
                        onChange={(event, value) => {
                          field.onChange(value?.shortName || "");
                          if (value) {
                            handleFilterSymbol(event, value);
                          }
                        }}
                        getOptionLabel={(option) => option?.shortName || ""}
                        renderOption={(props, option) => (
                          <div
                            {...props}
                            key={option?._id}
                            style={{
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <div
                              style={{ fontSize: "13px" }}
                              className="symbolFont pt-1"
                            >
                              {option?.shortName?.toString()}
                            </div>
                            <div style={{ fontSize: "11px", color: "gray" }}>
                              <div
                                className="mr-2 companyNameFont"
                                style={{
                                  margin: "auto",
                                }}
                              >
                                {option?.companyName?.toString()}
                              </div>
                            </div>
                          </div>
                        )}
                        renderInput={(params) => <TextField {...params} />}
                        onClose={(event, reason) => {
                          if (reason === "toggleInput") {
                            field.onChange(null);
                            handleFilterSymbol(event, null);
                          }
                        }}
                      />
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Exchange</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Exchange"
                    name="marketType"
                    value={filterParams?.marketType || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Lot Size</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Lot Size"
                    name="LotSize"
                    value={filterParams?.LotSize || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Expiry Date</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Expiry Date"
                    name="expiryDate"
                    value={filterParams?.expiryDate || ""}
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <div className="d-flex pt-3 pb-4 applyChangeBtn">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleSubmit}
                >
                  Apply Changes
                </button>
                <button
                  type="button"
                  className="btn btn-square btn-danger mr-0"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/symbols" style={{ fontWeight: 600 }}>
                          Add Symbols
                        </Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-3">
                      <div className="ListWithBadge">
                        <h3 className="m-b-20 mr-2">Add Favourite Symbols</h3>
                      </div>
                      <div className="tradingCreateButtons">
                        <button
                          type="button"
                          className="btn btn-square btn-primary"
                          onClick={() => navigate("/symbol-create")}
                        >
                          <i className="feather icon-plus"></i> Add Symbol
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary mr-0"
                          onClick={ToggleSidebar}
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <form>
                      <div className="card">
                        <div
                          className="card-body mobileCardBody"
                          style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        >
                          <div className="row col-md-12">
                            <div className="form-group col-md-12">
                              <label>Account*</label>
                              <Autocomplete
                                className="favAccountDropdown"
                                options={options}
                                getOptionLabel={(option) => option.key}
                                isOptionEqualToValue={(option, value) => option?.key === value?.key}
                                onChange={(e, value) => {
                                  setAccountName(value);
                                  handleInputChange(value?.value);
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" {...props} style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%"
                                  }}>
                                    <Typography style={{ float: "left" }}>{option.key}</Typography>
                                    <Typography style={{ float: "right" }}>{option.value}</Typography>
                                  </Box>
                                )}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-12">
                              <label>Symbol*</label>
                              <Controller
                                name="companyName"
                                control={control}
                                rules={{ required: "Symbol is required" }}
                                render={({ field }) => (
                                  <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-demo"
                                    {...field}
                                    filterOptions={filterOptions}
                                    value={
                                      filterData?.find(
                                        (option) =>
                                          option?.companyName ===
                                          watchCompanyName
                                      ) || null
                                    }
                                    options={filterData ? filterData : []}
                                    onChange={(event, value) => {
                                      field.onChange(value?.companyName || "");
                                    }}
                                    getOptionLabel={(option) =>
                                      option?.companyName || ""
                                    }
                                    renderOption={(props, option) => (
                                      <div
                                        {...props}
                                        key={option?._id}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            display: "flex",
                                          }}
                                        >
                                          <button
                                            className="btn btn-outline-primary px-2 py-0 mt-1"
                                            onClick={() =>
                                              handleAddSymbol(option)
                                            }
                                          >
                                            Add
                                          </button>
                                          <div className="symbolFont pt-1">
                                            {option?.shortName?.toString()}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            float: "right",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            className="mr-2 companyNameFont"
                                            style={{
                                              textAlign: "center",
                                              margin: "auto",
                                            }}
                                          >
                                            {option?.companyName?.toString()}
                                          </div>
                                          <span
                                            className={
                                              option?.marketType?.toString() ===
                                                "NSE"
                                                ? "badge badge-secondary nseBadge symbolFont"
                                                : "badge badge-secondary bseBadge symbolFont"
                                            }
                                          >
                                            {option?.marketType?.toString()}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                )}
                              />
                              {errors?.companyName && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.companyName?.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <Box sx={{ width: "100%" }}>
                      {visibleRows?.length !== 0 ? (
                        <Paper
                          sx={{ width: "100%", mb: 2 }}
                          className="customTable"
                        >
                          <Toolbar
                            sx={{
                              pl: { sm: 2 },
                              pr: { xs: 1, sm: 1 },
                              ...(selected?.length > 0 && {
                                bgcolor: (theme) =>
                                  alpha(
                                    theme.palette.primary.main,
                                    theme.palette.action.activatedOpacity
                                  ),
                              }),
                            }}
                            className="p-1"
                          >
                            {selected?.length > 0 ? (
                              <Typography
                                sx={{ flex: "1 1 100%" }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                              >
                                {selected?.length} selected
                              </Typography>
                            ) : (
                              <Typography
                                sx={{ flex: "1 1 100%" }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                              >
                                Symbol
                              </Typography>
                            )}

                            {selected?.length > 0 ? (
                              <Tooltip title="Delete">
                                <IconButton onClick={handleMultiDelete}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <i className="feather icon-trending-up pr-3"></i>
                            )}
                          </Toolbar>
                          <TableContainer>
                            <Table
                              className="addTableFontColor"
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                            >
                              <EnhancedTableHead
                                onSelectAllClick={handleSelectAllClick}
                                order={order}
                                orderBy={orderBy}
                                numSelected={selected?.length}
                                onRequestSort={handleRequestSort}
                                rowCount={filterSymbol?.length}
                              />

                              <TableBody>
                                {visibleRows?.map((row, index) => {
                                  const isItemSelected = isSelected(row?._id);
                                  const labelId = `enhanced-table-checkbox-${index}`;
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row?._id}
                                      selected={isItemSelected}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          onClick={(event) =>
                                            handleClick(event, row?._id)
                                          }
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell>{row?.accountName}</TableCell>
                                      <TableCell>{row?.shortName}</TableCell>
                                      <TableCell>{row?.companyName}</TableCell>
                                      <TableCell>{row?.marketType}</TableCell>
                                      <TableCell>{row?.LotSize}</TableCell>
                                      <TableCell>{row?.tickSize}</TableCell>
                                      <TableCell>{row?.upperCircuit ? row?.upperCircuit : "-"}</TableCell>
                                      <TableCell>{row?.lowerCircuit ? row?.lowerCircuit : "-"}</TableCell>
                                      <TableCell>
                                        {row?.expiryDate
                                          ? row?.expiryDate
                                          : "-"}
                                      </TableCell>
                                      <TableCell>
                                        <Link
                                          type="submit"
                                          className="btn btn-outline-primary mr-2 m-0 editDeleteBtn"
                                          to={`/symbol-edit/${row?._id}`}
                                        >
                                          <i className="feather icon-edit m-0"></i>
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filterSymbol?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                      ) : null}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Symbols;
