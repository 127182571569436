// BASIC IMPORTS
import React, { useEffect, useState, useContext } from 'react';
import { ReactReduxContext, useDispatch } from "react-redux";
import { DateRangePicker, Stack } from "rsuite";
import { startOfMonth } from "date-fns";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


// MUI IMPORTS
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Checkbox, Toolbar, Box, Typography, IconButton, Tooltip } from '@mui/material';
import { alpha } from "@mui/material/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


// API ENDPOINT IMPORTS
import { cycleDataGet, symbolCycleDelete } from '../../../actions/tradingAction';


const CycleReport = () => {
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedRowsMap, setSelectedRowsMap] = useState({});
    const [selectAll, setSelectAll] = useState(false);


    const { afterToday } = DateRangePicker;
    const getStartOfMonth = () => startOfMonth(new Date());
    const defaultStartDate = getStartOfMonth();
    const defaultEndDate = new Date();


    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();


    const processTableData = (data) => {
        const symbolExchangeAccountData = {};

        data?.forEach(entry => {
            const date = new Date(entry?.date);
            const formattedDate = `${date?.getDate()}/${date?.getMonth() + 1}/${String(date?.getFullYear()).slice(-2)}`;

            const key = `${entry?.accountName}=${entry?.symbol}=${entry?.exchange}`;
            if (!symbolExchangeAccountData[key]) {
                symbolExchangeAccountData[key] = {
                    id: key,
                    accountName: entry?.accountName,
                    symbol: entry?.symbol,
                    exchange: entry?.exchange,
                    cycles: {},
                    totalCycles: 0
                };
            }

            if (!symbolExchangeAccountData[key].cycles[formattedDate]) {
                symbolExchangeAccountData[key].cycles[formattedDate] = 0;
            }

            symbolExchangeAccountData[key].cycles[formattedDate] += entry.total_cycle_count;
            symbolExchangeAccountData[key].totalCycles += entry.total_cycle_count;
        });


        const uniqueDates = Array.from(new Set(data?.map(entry => {
            const date = new Date(entry?.date);
            return `${date?.getDate()}/${date?.getMonth() + 1}/${String(date?.getFullYear()).slice(-2)}`;
        })));


        const currentDate = new Date();
        uniqueDates?.sort((a, b) => {
            const [dayA, monthA, yearA] = a.split('/');
            const [dayB, monthB, yearB] = b.split('/');
            const dateA = new Date(`20${yearA}`, monthA - 1, dayA);
            const dateB = new Date(`20${yearB}`, monthB - 1, dayB);
            return Math.abs(dateA - currentDate) - Math.abs(dateB - currentDate);
        });


        const result = Object.values(symbolExchangeAccountData).map(symbolEntry => {
            const cyclesObj = uniqueDates?.reduce((obj, date) => {
                obj[date] = symbolEntry?.cycles[date] || 0;
                return obj;
            }, {});

            return {
                ...symbolEntry,
                ...cyclesObj
            };
        });

        setTableData(result);
    };


    const formatDateRangeDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const handleDateRangeChange = async (value) => {
        if (value) {
            const startDate = value[0];
            const endDate = value[1];

            // Format start and end dates
            const formattedStartDate = formatDateRangeDate(startDate);
            const formattedEndDate = formatDateRangeDate(endDate);

            await cycleDataGet(dispatch, formattedStartDate, formattedEndDate).then((res) => {
                setData(res?.data);
                processTableData(res?.data);
            }).catch((error) => {
                toast.error("Cycle count data not found");
            })
        }
    };


    const handleAccountChange = (event, value) => {
        const accountValue = value;
        if (data !== undefined) {
            const filtered = data?.filter(entry =>
                accountValue ? entry.accountName === accountValue : true
            );

            if (filtered !== undefined) {
                processTableData(filtered);
                setPage(0); // Reset page to 0 when filters change
            }
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const getUniqueAccounts = () => {
        const accounts = new Set(data?.map(entry => entry?.accountName));
        return Array.from(accounts);
    };


    const uniqueAccounts = getUniqueAccounts();


    const paginatedData = tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    const uniqueDates = Array.from(new Set(data?.map(entry => {
        const date = new Date(entry?.date);
        return `${date?.getDate()}/${date?.getMonth() + 1}/${String(date?.getFullYear()).slice(-2)}`;
    })));


    const currentDate = new Date();
    uniqueDates.sort((a, b) => {
        const [dayA, monthA, yearA] = a.split('/');
        const [dayB, monthB, yearB] = b.split('/');
        const dateA = new Date(`20${yearA}`, monthA - 1, dayA);
        const dateB = new Date(`20${yearB}`, monthB - 1, dayB);
        return Math.abs(dateA - currentDate) - Math.abs(dateB - currentDate);
    });


    const handleRowClick = (event, id) => {
        const updatedMap = { ...selectedRowsMap };
        if (updatedMap[id]) {
            delete updatedMap[id];
        } else {
            updatedMap[id] = true;
        }
        setSelectedRowsMap(updatedMap);
        setSelectAll(false); // Deselect "Select All" when individual row is clicked
    };


    const handleSelectAllClick = (event) => {
        const newSelectedMap = { ...selectedRowsMap };
        if (event.target.checked) {
            tableData.forEach((row) => {
                newSelectedMap[row.id] = true;
            });
            setSelectAll(true);
        } else {
            for (let key in newSelectedMap) {
                delete newSelectedMap[key];
            }
            setSelectAll(false);
        }
        setSelectedRowsMap(newSelectedMap);
    };


    const isSelected = (id) => !!selectedRowsMap[id];


    const handleDeleteSelected = async () => {
        const selectedIds = Object.keys(selectedRowsMap);
        if (selectedIds?.length !== 0) {
            // delte multiple records
            Swal.fire({
                title: "Are you sure?",
                text: `Are you sure you want to delete the selected ${selectedIds?.length} record?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const convertedData = selectedIds?.map((item) => ({
                        accountName: item.split("=")[0],
                        symbol: item.split("=")[1],
                        exchange: item.split("=")[2],
                    }));

                    const filteredData = { data: convertedData };
                    await symbolCycleDelete(dispatch, filteredData);
                    if (store.getState()?.trading?.delSymbolCycle) {
                        Swal.fire(
                            "Deleted!",
                            "All selected record deleted successfully.",
                            "success"
                        );
                        setSelectedRowsMap({})
                        handleDateRangeChange([defaultStartDate, defaultEndDate]);
                    } else if (store.getState()?.trading?.error) {
                        toast.error(
                            store.getState()?.trading?.errorMessage
                                ? store.getState()?.trading?.errorMessage?.message
                                : "Error deleting data. Please try again."
                        );
                    }
                }
            });
        }
    };


    useEffect(() => {
        handleDateRangeChange([defaultStartDate, defaultEndDate]);
        // eslint-disable-next-line
    }, []);


    return (
        <div className="pcoded-inner-content">
            <div className="page-block">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="page-header-title d-flex justify-content-between pb-3">
                            <div className="ListWithBadge">
                                <h4 className="pt-2 mr-3">Date :-</h4>

                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    spacing={6}
                                >
                                    <DateRangePicker
                                        size="lg"
                                        defaultValue={[defaultStartDate, defaultEndDate]}
                                        shouldDisableDate={afterToday()}
                                        ranges={[]}
                                        placeholder="Select..."
                                        onChange={handleDateRangeChange}
                                    />
                                </Stack>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="Main">
                <div className="main-body">
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }} className="customTable">
                            <Toolbar
                                sx={{
                                    pl: { sm: 2 },
                                    pr: { xs: 1, sm: 1 },
                                    ...(Object.keys(selectedRowsMap).length > 0 && {
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                theme.palette.action.activatedOpacity
                                            ),
                                    }),
                                }}
                                className="p-1"
                            >
                                {Object.keys(selectedRowsMap).length > 0 ? (
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        color="inherit"
                                        variant="subtitle1"
                                        component="div"
                                    >
                                        {Object.keys(selectedRowsMap).length} selected
                                    </Typography>
                                ) : (
                                    <div className='d-flex justify-between' style={{ width: "100%" }}>
                                        <Typography
                                            sx={{ flex: "1 1 100%" }}
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                        >
                                            Cycle Count
                                        </Typography>
                                        <Autocomplete
                                            disablePortal
                                            className='accountNameAutoComplete'
                                            name="account"
                                            id="combo-box-demo"
                                            options={uniqueAccounts}  // Use your list of account names here
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Account Name" />}
                                            onChange={handleAccountChange}
                                        />
                                    </div>
                                )}

                                {Object.keys(selectedRowsMap).length > 0 && (
                                    <Tooltip title="Delete">
                                        <IconButton onClick={handleDeleteSelected}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Toolbar>

                            <TableContainer component={Paper}>
                                <Table
                                    className="addTableFontColor"
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size="medium"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={Object.keys(selectedRowsMap).length > 0 && Object.keys(selectedRowsMap).length < tableData.length}
                                                    checked={selectAll && Object.keys(selectedRowsMap).length === tableData.length}
                                                    onChange={handleSelectAllClick}
                                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                                />
                                            </TableCell>
                                            <TableCell>Account</TableCell>
                                            <TableCell>Symbol</TableCell>
                                            <TableCell>Ex</TableCell>
                                            <TableCell>Total</TableCell> {/* New column header */}
                                            {uniqueDates?.map(date => (
                                                <TableCell key={date}>{date}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {tableData?.length > 0 ? (
                                        <TableBody>
                                            {paginatedData?.map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        key={row.id}
                                                        hover
                                                        onClick={(event) => handleRowClick(event, row.id)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        selected={isItemSelected}
                                                        sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f596' } }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{row.accountName}</TableCell>
                                                        <TableCell>{row.symbol}</TableCell>
                                                        <TableCell>{row.exchange}</TableCell>
                                                        <TableCell>{row.totalCycles}</TableCell> {/* New column cell value */}
                                                        {uniqueDates?.map(date => (
                                                            <TableCell key={date}>{row[date] || 0}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={uniqueDates?.length + 5} align="center"> {/* Adjusted colspan */}
                                                    <label>Data not found</label>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={tableData?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                style={{ marginTop: '10px' }}
                            />
                        </Paper>
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default CycleReport;
