import { createSlice } from "@reduxjs/toolkit";

const settingSlice = createSlice({
  name: "setting",
  initialState: {
    setting: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    getActiveAccountNameStart: (state) => {
      state.isFetching = false;
      state.active_accounts = null;
      state.error = false;
      state.errorMessage = "";
    },
    getActiveAccountNameSuccess: (state, action) => {
      state.isFetching = true;
      state.active_accounts = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getActiveAccountNameFailure: (state, error) => {
      state.isFetching = false;
      state.active_accounts = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    uploadNewSymbolsStart: (state) => {
      state.isFetching = false;
      state.upload_new_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    uploadNewSymbolsSuccess: (state, action) => {
      state.isFetching = true;
      state.upload_new_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    uploadNewSymbolsFailure: (state, error) => {
      state.isFetching = false;
      state.upload_new_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getCronLastRunTimeStart: (state) => {
      state.isFetching = false;
      state.cron_last_run_times = null;
      state.error = false;
      state.errorMessage = "";
    },
    getCronLastRunTimeSuccess: (state, action) => {
      state.isFetching = true;
      state.cron_last_run_times = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getCronLastRunTimeFailure: (state, error) => {
      state.isFetching = false;
      state.cron_last_run_times = null;
      state.error = true;
      state.errorMessage = error.payload;
    }
  },
});

export const {
  getActiveAccountNameStart,
  getActiveAccountNameSuccess,
  getActiveAccountNameFailure,
  uploadNewSymbolsStart,
  uploadNewSymbolsSuccess,
  uploadNewSymbolsFailure,
  getCronLastRunTimeStart, getCronLastRunTimeSuccess, getCronLastRunTimeFailure
} = settingSlice.actions;
export default settingSlice.reducer;
