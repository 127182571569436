import axiosClient from "../axios-client";
import {
    createExchangeFailure,
    createExchangeStart,
    createExchangeSuccess,
    getExchangeFailure,
    getExchangeStart,
    getExchangeSuccess,
    deleteExchangeStart,
    deleteExchangeSuccess,
    deleteExchangeFailure,
    getExchangeProductSettingStart,
    getExchangeProductSettingSuccess,
    getExchangeProductSettingFailure,
} from "../reducers/exchangeReducer";
import authHeader from "../services/auth-header";


export const exchangeGet = async (dispatch) => {
    dispatch(getExchangeStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/exchange", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getExchangeSuccess(res.data));
    } catch (error) {
        dispatch(getExchangeFailure(error.response?.data));
    }
};

export const exchangeCreate = async (dispatch, exchangeData) => {
    dispatch(createExchangeStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/exchange", exchangeData, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(createExchangeSuccess(res.data));
    } catch (error) {
        dispatch(createExchangeFailure(error.response?.data));
    }
};

export const exchangeDelete = async (dispatch, id) => {
    dispatch(deleteExchangeStart());
    try {
        const token = authHeader();
        const res = await axiosClient.delete(`/exchange/${id}`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(deleteExchangeSuccess(res.data));
    } catch (error) {
        dispatch(deleteExchangeFailure(error.response?.data));
    }
};

export const getExchangeProductAccounts = async (dispatch) => {
    try {
        const res = await axiosClient.get("/exchange_product_account_list");

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const getUseAccessById = async (dispatch, id) => {
    try {
        const res = await axiosClient.get(`/user_access/${id}`);

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const updateUserAccess = async (dispatch, data) => {
    try {
        const res = await axiosClient.put("/user_access", data);

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const exchangeProductSettingGet = async (dispatch) => {
    dispatch(getExchangeProductSettingStart());
    try {
        const token = authHeader();
        const res = await axiosClient.get("/exchange_product_setting", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getExchangeProductSettingSuccess(res.data));
    } catch (error) {
        dispatch(getExchangeProductSettingFailure(error.response?.data));
    }
};
