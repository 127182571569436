// BASIC IMPORTS
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import OrderEditForm from "./OrderEditForm";

// API IMPORTS
import { orderGetById, orderUpdate } from "../../../actions/orderAction";


function OrderEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialData, setInitialData] = useState();

  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);

  function convertToFormattedDateTime(originalDateTimeString) {
    // Split the original date string
    const [datePart, timePart] = originalDateTimeString.split(" ");
    const [day, month, year] = datePart.split("/");

    // Reconstruct the date in the desired format
    const formattedDateTimeString = `${year}-${month.padStart(
      2,
      "0"
    )}-${day.padStart(2, "0")} ${timePart}.000`;

    return formattedDateTimeString;
  }

  const handleFormSubmit = async (data) => {
    delete data["client"];
    delete data["_id"];
    const formattedDateTimeString = convertToFormattedDateTime(data["mo_at"]);
    data["mo_at"] = formattedDateTimeString;
    await orderUpdate(dispatch, id, data);
    if (store.getState()?.order?.orderData) {
      toast.success("Data updated successfully!");
      navigate("/order");
    } else if (store.getState()?.order?.error) {
      toast.error(
        store.getState()?.order?.errorMessage
          ? store.getState()?.order?.errorMessage?.message
          : "Error updating data. Please try again."
      );
    }
  };

  useEffect(() => {
    const getOrderData = async () => {
      await orderGetById(dispatch, id);
      if (store.getState()?.order?.orderData) {
        setInitialData(store.getState()?.order?.orderData?.data);
      }
    };
    getOrderData();
  }, [id, dispatch, store]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/order">Orders</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="" style={{ fontWeight: 600 }}>
                          Edit
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <OrderEditForm
                  initialValues={initialData}
                  onSubmit={handleFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderEdit;
