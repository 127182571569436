import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Typography, TableBody, Paper, Table, TableHead, TableRow, TableCell, TableContainer, FormControl, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { SharedStateProvider } from "../SharedStateContext";
import { deleteOptionStrategyThree, getOptionStrategyThreeData, startOptionStrategyThree, updateOptionStrategyThreeExpiryDate, updateOptionStrategyThreeStatus } from "../../../actions/optionStrategyThreeAction";
import { getSymbolExpirys } from "../../../actions/optionStrategyOneAction";
import { toast } from "react-toastify";
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import Swal from "sweetalert2";


function OptionStrategyThree() {
  const { control, handleSubmit, watch, register, reset, formState: { errors } } = useForm();

  const [strategies, setStrategies] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [activeId, setActiveId] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const expiries = useRef();

  const getOptionBacktestData = async () => {
    await getOptionStrategyThreeData(dispatch).then((res) => {
      const { strategies } = res?.data || {};
      setStrategies(strategies || []);
    }).catch(() => { });
  };

  const handleEditStrategy = (id) => {
    navigate(`/option-strategy-three/edit/${id}`)
  }

  const handleDeleteStrategy = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete this strategy?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteOptionStrategyThree(dispatch, id).then((res) => {
          toast.success("Strategy deleted successfully");
          getOptionBacktestData();
        }).catch((error) => {
          toast.error("Strategy not deleted, due to some error");
        });
      }
    });
  }

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day); // Month is zero-based in JavaScript
  };

  const handleStartStrategy = (data) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const expiry_date = parseDate(data?.expiry_date);
    if (currentDate > expiry_date) {
      setOpenModal(true); // Open the modal
      reset();
      handleExpiry(data)
      setActiveId(data?.id)
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to Start this strategy?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, start it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          updateOptionStrategyThreeStatus(dispatch, data?.id, "running").then(async (res) => {
            getOptionBacktestData();
            await startOptionStrategyThree(dispatch, data?.id).then((res) => {
              toast.success("Strategy successfully running");
            }).catch((error) => { });
          }).catch((error) => { 
            if(error?.response?.data?.error){
              toast.error(error?.response?.data?.error)
            }
          });
        }
      });
    }
  }

  const handleStopStrategy = async (id) => {
    await updateOptionStrategyThreeStatus(dispatch, id, "stop").then((res) => {
      toast.success("Strategy execution finished in under a minute.");
      getOptionBacktestData();
    }).catch((error) => { });
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  }

  const handleConfirm = () => {
    const selectedExpiry = watch('expiry_date');
    if (selectedExpiry) {
      updateOptionStrategyThreeExpiryDate(dispatch, activeId, { "expiry_date": selectedExpiry }).then((res) => {
        toast.success("Expiry updated successfully")
        getOptionBacktestData();
      }).catch((error) => {
        toast.error("Expiry not updated, due to some error")
      })
    } else {
      toast.error('Expiry date is required.');
    }
    handleCloseModal();
  }

  const handleExpiry = async (data) => {
    const expiryData = { "symbol": data?.symbol, "account_id": data?.account_id }
    if (!expiries.current && expiryData) {
      await getSymbolExpirys(dispatch, expiryData).then((res) => {
        expiries.current = res || []
      }).catch((error) => { });
    }
  };

  useEffect(() => {
    getOptionBacktestData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/option-strategy-three" style={{ fontWeight: 600 }}>
                          OTM Strategy
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h4 className="m-b-20 mr-2">Strategies ({strategies?.length}) & Trades</h4>
                        </div>
                        <div className="orderCreateButton">
                          <button
                            type="button"
                            className="btn btn-square btn-secondary"
                            onClick={() => navigate("/option-strategy-three/create")}
                          >
                            <i className="feather icon-plus"></i> Add Strategy
                          </button>
                          <button
                            type="button"
                            className="btn btn-square btn-primary"
                            onClick={() => navigate("/option-strategy-two-symbols")}
                          >
                            <i className="feather icon-plus"></i> Add Symbols
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  {strategies
                    ?.sort((a, b) => (a.status === "running" ? -1 : 1))
                    .map((data, index) => (
                      <div key={index} className="card" style={{ marginBottom: '20px' }}>
                        <div className={data?.status === "running" ? "card-header d-flex justify-content-between active" : "card-header d-flex justify-content-between"}>
                          <div>
                            <h5 className="card-title mb-3">Account: {data?.account_name}</h5>
                            <h6 className="card-subtitle mb-2">Status: {data?.status}</h6>
                          </div>
                          <div>
                            <button className="btn btn-outline-primary" onClick={() => handleStartStrategy(data)} disabled={data?.status === "running"}>Start Strategy</button>
                            <button className="btn btn-outline-danger" onClick={() => handleStopStrategy(data?.id)}>Stop Strategy</button>
                            <button className="btn btn-primary" onClick={() => handleEditStrategy(data?.id)} disabled={data?.status === "running"}>Edit</button>
                            <button className="btn btn-danger" onClick={() => handleDeleteStrategy(data?.id)} disabled={data?.status === "running"}>Delete</button>
                          </div>
                        </div>
                        <div className="card-body col-md-12 d-flex">
                          <div className="col-md-4">
                            <p className="card-text"><strong>Symbol:</strong> {data?.symbol}</p>
                            <p className="card-text"><strong>Expiry Date:</strong> {data?.expiry_date}</p>
                            <p className="card-text"><strong>Running Days:</strong> {data?.runningDays.join(', ')}</p>
                          </div>
                          <div className="col-md-8">
                            <div className="table-responsive">
                              <table className="table table-bordered strategyTradeTable">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Switch Type</th>
                                    <th>Type</th>
                                    <th>Start Value</th>
                                    <th>Condition</th>
                                    <th>Value</th>
                                    <th>Quantity</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.trades.map((trade, tradeIndex) => (
                                    <tr key={tradeIndex}>
                                      <td>{tradeIndex + 1}</td>
                                      <td>{trade?.switchType ? 'PE' : 'CE'}</td>
                                      <td>{trade?.buySellSwitchType ? 'Sell' : 'Buy'}</td>
                                      <td>{trade?.start_value}</td>
                                      <td>{trade?.condition}</td>
                                      <td>{trade?.value}</td>
                                      <td>{trade?.quantity}</td>
                                      
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {data?.orders?.length > 0 ?
                          <div className="card-body px-4 pt-0">
                            <Typography variant="h6" gutterBottom>
                              Trade Triggers
                            </Typography>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Symbol</TableCell>
                                    <TableCell>Trade</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Error</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {
                                    data?.orders?.map((trigger, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{new Date(trigger?.datetime).toLocaleString()}</TableCell>
                                        <TableCell>{trigger?.symbol}</TableCell>
                                        <TableCell>{trigger?.trade} ({trigger.trade_price})</TableCell>
                                        <TableCell>{trigger?.type}</TableCell>
                                        <TableCell>{trigger?.quantity}</TableCell>
                                        <TableCell>{trigger?.message ? trigger?.message : "-"}</TableCell>
                                      </TableRow>
                                    ))
                                  }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                          : null}
                      </div>
                    ))}

                </div>
              </div>


              <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Strategy Expiry expired, Select latest expiry</DialogTitle>
                <DialogContent>
                  <form onSubmit={handleSubmit(handleConfirm)}>
                    <FormControl fullWidth error={!!errors.expiry_date}>
                      <Controller
                        name="expiry_date"
                        control={control}
                        defaultValue=""
                        {...register("expiry_date", { required: true })}
                        render={({ field }) => (
                          <select
                            className="form-control"
                            {...field}
                            style={{ outline: 'none', boxShadow: 'none' }}
                          >
                            <option value="" disabled>Select...</option>
                            {expiries.current?.map((data, index) => (
                              <option key={index} value={data}>{data}</option>
                            ))}
                          </select>
                        )}
                      />
                      {errors.expiry_date && (
                        <span className="validationAlert">Expiry date is required</span>
                      )}
                      {/* </div> */}
                    </FormControl>
                  </form>
                </DialogContent>
                <div className="text-center pt-0 pb-3">
                  <button onClick={handleSubmit(handleConfirm)} className="btn btn-primary">Update Expiry</button>
                  <button onClick={handleCloseModal} className="btn btn-danger">Cancle</button>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
}

export default OptionStrategyThree;