import axiosClient from "../axios-client";
import {
    createProductFailure,
    createProductStart,
    createProductSuccess,
    getProductFailure,
    getProductStart,
    getProductSuccess,
    deleteProductStart,
    deleteProductSuccess,
    deleteProductFailure,
} from "../reducers/productReducer";
import authHeader from "../services/auth-header";


export const productGet = async (dispatch) => {
    dispatch(getProductStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/product", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getProductSuccess(res.data));
    } catch (error) {
        dispatch(getProductFailure(error.response?.data));
    }
};

export const productCreate = async (dispatch, productData) => {
    dispatch(createProductStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/product", productData, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(createProductSuccess(res.data));
    } catch (error) {
        dispatch(createProductFailure(error.response?.data));
    }
};

export const productDelete = async (dispatch, id) => {
    dispatch(deleteProductStart());
    try {
        const token = authHeader();
        const res = await axiosClient.delete(`/product/${id}`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(deleteProductSuccess(res.data));
    } catch (error) {
        dispatch(deleteProductFailure(error.response?.data));
    }
};
