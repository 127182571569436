// BASIC IMPORTS
import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// API IMPORT
import { holidayDelete } from "../../../actions/holidayAction";

function HolidayEditForm({ initialValues, onSubmit }) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);

  useEffect(() => {
    if (initialValues) {
      // Set form values when the component mounts
      Object?.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
    }
  }, [initialValues, setValue]);

  const handleDelete = async (id) => {
    await holidayDelete(dispatch, id);
    if (store.getState()?.holiday?.holidayData) {
      toast.success("Data deleted successfully!");
      navigate("/holiday");
    } else if (store.getState()?.holiday?.error) {
      toast.error(
        store.getState()?.holiday?.errorMessage
          ? store.getState()?.holiday?.errorMessage?.message
          : "Error deleting data. Please try again."
      );
    }
  };

  return (
    <>
      <div className="page-header-title d-flex justify-content-between pb-3">
        <div className="ListWithBadge">
          <Button className="adminbackBtn" onClick={() => navigate("/holiday")}>
            <i className="feather icon-chevron-right"></i>
          </Button>
          <h3 className="m-b-20 mr-2">Edit</h3>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-square btn-danger mr-0"
            onClick={() => handleDelete(initialValues?._id)}
          >
            <i className="feather icon-trash-2"></i> Delete
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body mobileCardBody">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Holiday Date* (DD/MM/YYYY)</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Holiday Date"
                      {...register("holidayDate", {
                        required: "Holiday date is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.holidayDate && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.holidayDate?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Holiday*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Holiday"
                      {...register("holiday", {
                        required: "Holiday is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.holiday && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.holiday?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Holiday Day*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Holiday Day"
                      {...register("holidayDay", {
                        required: "Holiday day is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.holidayDay && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.holidayDay?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Exchange*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Exchange"
                      {...register("exchange", {
                        required: "Exchange is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.exchange && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.exchange?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Muhurat Trading*</label>
                    <Controller
                      name="muhuratTrading"
                      control={control}
                      rules={{
                        required: "Muhurat trading is required",
                      }}
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                          {field.touched && field.value === "Enabled" && (
                            <div
                              className="validationAlert"
                              style={{ margin: "10px 0px" }}
                            >
                              {errors?.muhuratTrading?.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="row col-md-12 justify-content-center pt-3">
                  <button type="submit" className="btn btn-primary ml-3 px-5">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HolidayEditForm;
