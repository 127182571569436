import { createSlice } from "@reduxjs/toolkit";

const holidaySlice = createSlice({
  name: "holiday",
  initialState: {
    holiday: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    createHolidayStart: (state) => {
      state.isFetching = false;
      state.holiday = null;
      state.error = false;
      state.errorMessage = "";
    },
    createHolidaySuccess: (state, action) => {
      state.isFetching = true;
      state.holiday = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    createHolidayFailure: (state, error) => {
      state.isFetching = false;
      state.holiday = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getHolidayStart: (state) => {
      state.isFetching = false;
      state.holidayList = null;
      state.error = false;
      state.errorMessage = "";
    },
    getHolidaySuccess: (state, action) => {
      state.isFetching = true;
      state.holidayList = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getHolidayFailure: (state, error) => {
      state.isFetching = false;
      state.holidayList = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getHolidayByIdStart: (state) => {
      state.isFetching = false;
      state.holidayData = null;
      state.error = false;
      state.errorMessage = "";
    },
    getHolidayByIdSuccess: (state, action) => {
      state.isFetching = true;
      state.holidayData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getHolidayByIdFailure: (state, error) => {
      state.isFetching = false;
      state.holidayData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    updateHolidayStart: (state) => {
      state.isFetching = false;
      state.holidayData = null;
      state.error = false;
      state.errorMessage = "";
    },
    updateHolidaySuccess: (state, action) => {
      state.isFetching = true;
      state.holidayData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    updateHolidayFailure: (state, error) => {
      state.isFetching = false;
      state.holidayData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteHolidayStart: (state) => {
      state.isFetching = false;
      state.holidayData = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteHolidaySuccess: (state, action) => {
      state.isFetching = true;
      state.holidayData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteHolidayFailure: (state, error) => {
      state.isFetching = false;
      state.holidayData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    checkTomorrowHolidayStart: (state) => {
      state.isFetching = false;
      state.tomorrowHoliday = null;
      state.error = false;
      state.errorMessage = "";
    },
    checkTomorrowHolidaySuccess: (state, action) => {
      state.isFetching = true;
      state.tomorrowHoliday = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    checkTomorrowHolidayFailure: (state, error) => {
      state.isFetching = false;
      state.tomorrowHoliday = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
  },
});

export const {
  createHolidayFailure,
  createHolidayStart,
  createHolidaySuccess,
  getHolidayFailure,
  getHolidayStart,
  getHolidaySuccess,
  getHolidayByIdFailure,
  getHolidayByIdStart,
  getHolidayByIdSuccess,
  updateHolidayStart,
  updateHolidaySuccess,
  updateHolidayFailure,
  deleteHolidayStart,
  deleteHolidaySuccess,
  deleteHolidayFailure,
  checkTomorrowHolidayStart,
  checkTomorrowHolidaySuccess,
  checkTomorrowHolidayFailure,
} = holidaySlice.actions;
export default holidaySlice.reducer;
