// BASIC IMPORTS
import React, { useContext, useEffect, useState, useMemo } from "react";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PropTypes from "prop-types";


// MUI IMPORTS
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Box, Tooltip, IconButton, Checkbox, alpha,
} from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";


// API ENDPOINT IMPORTS
import { averageSymbolPriceGet, averageSymbolPriceDelete } from "../../../actions/tradingAction";

const headCells = [
    {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account",
    },
    {
        id: "symbol",
        numeric: false,
        disablePadding: false,
        label: "Symbol",
    },
    {
        id: "exchange",
        numeric: false,
        disablePadding: false,
        label: "Exchange",
    },
    {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "Side",
    },
    {
        id: "lot",
        numeric: false,
        disablePadding: false,
        label: "Total Lot",
    },
    {
        id: "avg",
        numeric: true,
        disablePadding: false,
        label: "Avg Price",
    },
];

function EnhancedTableHeadCycle(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHeadCycle.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function SymbolAveragePrice() {
    const [symbolAveragePrice, setSymbolAveragePrice] = useState([]);
    const [pagePrice, setPagePrice] = useState(0);
    const [rowsPerPagePrice, setRowsPerPagePrice] = useState(5);
    const [selectedPrice, setSelectedPrice] = useState([]);
    const [accountName, setAccountName] = useState([]);
    const [selectedAccountForPrice, setSelectedAccountForPrice] = useState("All");
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("avg");

    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();

    const handleChangePagePrice = (event, newPage) => {
        setPagePrice(newPage);
    };

    const handleChangeRowsPerPagePrice = (event) => {
        setRowsPerPagePrice(parseInt(event.target.value, 10));
        setPagePrice(0);
    };


    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }


    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }


    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const getAveragePrice = async () => {
        await averageSymbolPriceGet(dispatch).then((res) => {
            setSymbolAveragePrice(res?.data);
            const uniqueAccountNames = [...new Set(res?.data?.map(item => item?.account))];
            setAccountName(uniqueAccountNames)
        }).catch((error) => {
            toast.error("Symbol average price data not found")
        })
    };

    const handleMultiDeletePrice = async () => {
        if (selectedPrice.length !== 0) {
            // delete multiple records
            Swal.fire({
                title: "Are you sure?",
                text: `Are you sure you want to delete the selected ${selectedPrice.length} record${selectedPrice.length > 1 ? 's' : ''}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const data = { ids: selectedPrice };
                    let filteredData = symbolAveragePrice.filter((item) =>
                        data?.ids?.includes(item.id)
                    );
                    filteredData = { data: filteredData };
                    await averageSymbolPriceDelete(dispatch, filteredData);
                    if (store.getState()?.trading?.delSymbolAvgPrice) {
                        Swal.fire(
                            "Deleted!",
                            "All selected records deleted successfully.",
                            "success"
                        );
                        setRowsPerPagePrice(5);
                        setPagePrice(0);
                        getAveragePrice();
                        setSelectedAccountForPrice("All");
                        setSelectedPrice([]);
                    } else if (store.getState()?.trading?.error) {
                        toast.error(
                            store.getState()?.trading?.errorMessage
                                ? store.getState()?.trading?.errorMessage?.message
                                : "Error deleting data. Please try again."
                        );
                    }
                }
            });
        }
    };

    const handleSelectAllClickPrice = (event) => {
        if (event.target.checked) {
            const newSelected = symbolAveragePrice.map((n) => n.id);
            setSelectedPrice(newSelected);
            return;
        }
        setSelectedPrice([]);
    };

    const handleClickPrice = (event, id) => {
        const selectedIndex = selectedPrice.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedPrice, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedPrice.slice(1));
        } else if (selectedIndex === selectedPrice.length - 1) {
            newSelected = newSelected.concat(selectedPrice.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedPrice.slice(0, selectedIndex),
                selectedPrice.slice(selectedIndex + 1)
            );
        }
        setSelectedPrice(newSelected);
    };

    const isSelectedPrice = (id) => selectedPrice.indexOf(id) !== -1;

    const visibleRowsPrice = useMemo(() => {
        let data = null;
        if (selectedAccountForPrice === "All") {
            data = symbolAveragePrice;
        } else {
            data = symbolAveragePrice.filter(
                (row) => row.account === selectedAccountForPrice
            );
        }

        const sortedRows = stableSort(data, getComparator(order, orderBy));
        return sortedRows;
        // eslint-disable-next-line
    }, [order, orderBy, symbolAveragePrice, selectedAccountForPrice]);

    const handleRequestSortPrice = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleAccountChange = (event, value) => {
        setSelectedAccountForPrice(value ? value : "All");
        setPagePrice(0); // Reset page when account filter changes
    };

    useEffect(() => {
        getAveragePrice();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="main-body">
            <div className="page-wrapper">
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }} className="customTable">
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                ...(selectedPrice.length > 0 && {
                                    bgcolor: (theme) =>
                                        alpha(
                                            theme.palette.primary.main,
                                            theme.palette.action.activatedOpacity
                                        ),
                                }),
                            }}
                            className="p-1"
                        >
                            {selectedPrice.length > 0 ? (
                                <Typography
                                    sx={{ flex: "1 1 100%" }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {selectedPrice.length} selected
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{ flex: "1 1 100%" }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div"
                                >
                                    Symbol Average Price
                                </Typography>
                            )}

                            {selectedPrice.length > 0 ? (
                                <Tooltip title="Delete">
                                    <IconButton onClick={handleMultiDeletePrice}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Autocomplete
                                    disablePortal
                                    className="accountNameAutoComplete"
                                    name="account"
                                    id="combo-box-demo"
                                    options={accountName}
                                    sx={{ width: 300 }}
                                    onChange={handleAccountChange}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Account Name" />
                                    )}
                                />
                            )}
                        </Toolbar>
                        <TableContainer component={Paper}>
                            <Table
                                className="addTableFontColor"
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                            >
                                <EnhancedTableHeadCycle
                                    numSelected={selectedPrice.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClickPrice}
                                    onRequestSort={handleRequestSortPrice}
                                    rowCount={visibleRowsPrice.length}
                                />
                                <TableBody>
                                    {visibleRowsPrice
                                        .slice(
                                            pagePrice * rowsPerPagePrice,
                                            pagePrice * rowsPerPagePrice + rowsPerPagePrice
                                        )
                                        .map((row, index) => {
                                            const isItemSelected = isSelectedPrice(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                    sx={{ cursor: "pointer" }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            onClick={(event) => handleClickPrice(event, row.id)}
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                "aria-labelledby": labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{row.account}</TableCell>
                                                    <TableCell>{row.symbol}</TableCell>
                                                    <TableCell>{row.exchange}</TableCell>
                                                    <TableCell style={{ textTransform: "capitalize" }}>{row.type}</TableCell>
                                                    <TableCell>{row.lot}</TableCell>
                                                    <TableCell>{row.avg}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {visibleRowsPrice.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                <Typography variant="subtitle1">Data not found</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={visibleRowsPrice.length}
                            rowsPerPage={rowsPerPagePrice}
                            page={pagePrice}
                            onPageChange={handleChangePagePrice}
                            onRowsPerPageChange={handleChangeRowsPerPagePrice}
                        />
                    </Paper>
                </Box>
            </div>
        </div>
    );
}

export default SymbolAveragePrice;
