// BASIC IMPORTS
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";


function ProfileEditForm({ initialValues, onSubmit }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { initialValues },
  });
  console.log("initialValues :: ", initialValues);
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  useEffect(() => {
    if (initialValues) {
      // Set form values when the component mounts
      Object?.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
    }
  }, [initialValues, setValue]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <form id="userDetailForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-body mobileCardBody">
              <div className="row col-md-12">
                <div className="form-group col-md-12">
                  <label htmlFor="exampleInputEmail1">Email address*</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    defaultValue={initialValues?.email}
                    disabled={true}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label>Username*</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Name"
                    defaultValue={initialValues?.username}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row col-md-12">
                <div className="form-group col-md-12">
                  <label htmlFor="exampleInputPassword1">Password*</label>
                  <div className="input-group">
                    <input
                      type={passwordType}
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      autoComplete="current-password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters!",
                        },
                        maxLength: {
                          value: 16,
                          message:
                            "Password must not be longer than 16 characters!",
                        },
                      })}
                    />
                    <div
                      className="input-group-append eyeBtn"
                      onClick={() => togglePassword("password")}
                    >
                      <span className="input-group-text">
                        {passwordType ? (
                          <i className="fa fa-fw fa-eye field-icon"></i>
                        ) : (
                          <i className="fa fa-fw fa-eye-slash field-icon"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  {errors?.password && (
                    <div
                      id="newPaswordAlert"
                      className="validationAlert"
                      style={{ margin: "10px 0px" }}
                    >
                      {errors?.password?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="row col-md-12 justify-content-center mt-3">
                <button type="submit" className="btn btn-primary ml-3 px-5">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfileEditForm;
