import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    order: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    createOrderStart: (state) => {
      state.isFetching = false;
      state.order = null;
      state.error = false;
      state.errorMessage = "";
    },
    createOrderSuccess: (state, action) => {
      state.isFetching = true;
      state.order = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    createOrderFailure: (state, error) => {
      state.isFetching = false;
      state.order = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getOrderStart: (state) => {
      state.isFetching = false;
      state.orderList = null;
      state.error = false;
      state.errorMessage = "";
    },
    getOrderSuccess: (state, action) => {
      state.isFetching = true;
      state.orderList = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getOrderFailure: (state, error) => {
      state.isFetching = false;
      state.orderList = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getOrderByIdStart: (state) => {
      state.isFetching = false;
      state.orderData = null;
      state.error = false;
      state.errorMessage = "";
    },
    getOrderByIdSuccess: (state, action) => {
      state.isFetching = true;
      state.orderData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getOrderByIdFailure: (state, error) => {
      state.isFetching = false;
      state.orderData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    updateOrderStart: (state) => {
      state.isFetching = false;
      state.orderData = null;
      state.error = false;
      state.errorMessage = "";
    },
    updateOrderSuccess: (state, action) => {
      state.isFetching = true;
      state.orderData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    updateOrderFailure: (state, error) => {
      state.isFetching = false;
      state.orderData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteOrderStart: (state) => {
      state.isFetching = false;
      state.orderData = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteOrderSuccess: (state, action) => {
      state.isFetching = true;
      state.orderData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteOrderFailure: (state, error) => {
      state.isFetching = false;
      state.orderData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getTrashOrderStart: (state) => {
      state.isFetching = false;
      state.trashOrderList = null;
      state.error = false;
      state.errorMessage = "";
    },
    getTrashOrderSuccess: (state, action) => {
      state.isFetching = true;
      state.trashOrderList = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getTrashOrderFailure: (state, error) => {
      state.isFetching = false;
      state.trashOrderList = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteTrashOrderStart: (state) => {
      state.isFetching = false;
      state.trashOrderData = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteTrashOrderSuccess: (state, action) => {
      state.isFetching = true;
      state.trashOrderData = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteTrashOrderFailure: (state, error) => {
      state.isFetching = false;
      state.trashOrderData = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    pullBackTrashOrderStart: (state) => {
      state.isFetching = false;
      state.orderList = null;
      state.error = false;
      state.errorMessage = "";
    },
    pullBackTrashOrderSuccess: (state, action) => {
      state.isFetching = true;
      state.orderList = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    pullBackTrashOrderFailure: (state, error) => {
      state.isFetching = false;
      state.orderList = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getDistinctOrderStart: (state) => {
      state.isFetching = false;
      state.distinct_order_status = null;
      state.error = false;
      state.errorMessage = "";
    },
    getDistinctOrderSuccess: (state, action) => {
      state.isFetching = true;
      state.distinct_order_status = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getDistinctOrderFailure: (state, error) => {
      state.isFetching = false;
      state.distinct_order_status = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getDistinctTrashOrderStart: (state) => {
      state.isFetching = false;
      state.distinct_trash_order_status = null;
      state.error = false;
      state.errorMessage = "";
    },
    getDistinctTrashOrderSuccess: (state, action) => {
      state.isFetching = true;
      state.distinct_trash_order_status = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getDistinctTrashOrderFailure: (state, error) => {
      state.isFetching = false;
      state.distinct_trash_order_status = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
  },
});

export const {
  createOrderFailure,
  createOrderStart,
  createOrderSuccess,
  getOrderFailure,
  getOrderStart,
  getOrderSuccess,
  getOrderByIdFailure,
  getOrderByIdStart,
  getOrderByIdSuccess,
  updateOrderStart,
  updateOrderSuccess,
  updateOrderFailure,
  deleteOrderStart,
  deleteOrderSuccess,
  deleteOrderFailure,
  getTrashOrderStart,
  getTrashOrderSuccess,
  getTrashOrderFailure,
  deleteTrashOrderStart,
  deleteTrashOrderFailure,
  deleteTrashOrderSuccess,
  pullBackTrashOrderStart,
  pullBackTrashOrderSuccess,
  pullBackTrashOrderFailure,
  getDistinctOrderStart,
  getDistinctOrderSuccess,
  getDistinctOrderFailure,
  getDistinctTrashOrderStart, getDistinctTrashOrderSuccess, getDistinctTrashOrderFailure
} = orderSlice.actions;
export default orderSlice.reducer;
