import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/authReducer";
import tradingReducer from "../reducers/tradingReducer";
import orderReducer from "../reducers/orderReducer";
import settingReducer from "../reducers/settingReducer";
import exchangeReducer from "../reducers/exchangeReducer";
import productReducer from "../reducers/productReducer";
import uploadExcelReducer from "../reducers/uploadExcelReducer";
import holidayReducer from "../reducers/holidayReducer";
import symbolReducer from "../reducers/symbolReducer";
import backtestReducer from "../reducers/backtestReducer";

export default configureStore({
    reducer: {
        auth: authReducer,
        trading: tradingReducer,
        order: orderReducer,
        setting: settingReducer,
        exchange: exchangeReducer,
        product: productReducer,
        uploadExcel: uploadExcelReducer,
        holiday: holidayReducer,
        symbol: symbolReducer,
        backtest: backtestReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    })
});