import React from "react";

export default function BrokerSuccess() {
  return (
    <div className="container my-20">
      <div className="card">
        <div className="card-body">
          <div>
            <h1 className="text text-success">Success</h1>
          </div>
          <div>We are now authorised to trade in your brokerage account.</div>
        </div>
      </div>
    </div>
  );
}
