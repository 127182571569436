import axiosClient from "../axios-client";
import {
    createTradingFailure,
    createTradingStart,
    createTradingSuccess,
    getTradingFailure,
    getTradingStart,
    getTradingSuccess,
    getTradingByIdFailure,
    getTradingByIdStart,
    getTradingByIdSuccess,
    updateTradingStart,
    updateTradingSuccess,
    updateTradingFailure,
    deleteTradingStart,
    deleteTradingSuccess,
    deleteTradingFailure,
    getOrderPairStart,
    getOrderPairSuccess,
    getOrderPairFailure,
    deleteAerageSymbolPriceStart,
    deleteAerageSymbolPriceSuccess,
    deleteAerageSymbolPriceFailure,
    getPairRangeMessagesStart,
    getPairRangeMessagesSuccess,
    getPairRangeMessagesFailure,
    deleteSymbolCycleStart,
    deleteSymbolCycleSuccess,
    deleteSymbolCycleFailure,
} from "../reducers/tradingReducer";
import authHeader from "../services/auth-header";


export const tradingGet = async (dispatch) => {
    dispatch(getTradingStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/trading", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getTradingSuccess(res.data));
    } catch (error) {
        dispatch(getTradingFailure(error.response?.data));
    }
};

export const tradingGetById = async (dispatch, id) => {
    dispatch(getTradingByIdStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/trading/${id}`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getTradingByIdSuccess(res.data));
    } catch (error) {
        dispatch(getTradingByIdFailure(error.response?.data));
    }
};

export const orderPairsGet = async (dispatch) => {
    dispatch(getOrderPairStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/order_pair", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getOrderPairSuccess(res.data));
    } catch (error) {
        dispatch(getOrderPairFailure(error.response?.data));
    }
};

export const tradingCreate = async (dispatch, tradingData) => {
    dispatch(createTradingStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/trading", tradingData, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(createTradingSuccess(res.data));
    } catch (error) {
        dispatch(createTradingFailure(error.response?.data));
    }
};

export const tradingBulkCreate = async (dispatch, tradingData) => {
    dispatch(createTradingStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/trading-create-bulk", tradingData, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(createTradingSuccess(res.data));
    } catch (error) {
        dispatch(createTradingFailure(error.response?.data));
    }
};

export const tradingUpdate = async (dispatch, id, tradingData) => {
    dispatch(updateTradingStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put(`/trading/${id}`, tradingData, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(updateTradingSuccess(res.data));
    } catch (error) {
        dispatch(updateTradingFailure(error.response?.data));
    }
};

export const tradingUpdateErrorStatus = async (dispatch, client_id) => {
    dispatch(updateTradingStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/update-trading/${client_id}`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(updateTradingSuccess(res.data));
    } catch (error) {
        dispatch(updateTradingFailure(error.response?.data));
    }
};

export const tradingDeleteMany = async (dispatch, data) => {
    dispatch(deleteTradingStart());
    try {
        const token = authHeader();

        const res = await axiosClient.delete("/trading", {
            headers: {
                Authorization: token,
            },
            data,
        });
        dispatch(deleteTradingSuccess(res.data));
    } catch (error) {
        dispatch(deleteTradingFailure(error.response?.data));
    }
};

export const tradingFilter = async (dispatch, parameters) => {
    dispatch(getTradingStart());
    try {
        const token = authHeader();
        const res = await axiosClient.post(`/trading-filter`, parameters, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getTradingSuccess(res.data));
    } catch (error) {
        dispatch(getTradingFailure(error.response?.data));
    }
};

export const cycleDataGet = async (dispatch, start_date, end_date) => {
    try {
        const res = await axiosClient.get("/get_cycle_count", {
            params: {
                start_date: start_date,
                end_date: end_date,
            },
        });

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const averageSymbolPriceGet = async (dispatch) => {
    try {
        const res = await axiosClient.get("/get_symbol_average_price");

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const averageSymbolPriceDelete = async (dispatch, data) => {
    dispatch(deleteAerageSymbolPriceStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put("/delete_avg_symbol_price", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(deleteAerageSymbolPriceSuccess(res.data));
    } catch (error) {
        dispatch(deleteAerageSymbolPriceFailure(error.response?.data));
    }
};

export const symbolCycleDelete = async (dispatch, data) => {
    dispatch(deleteSymbolCycleStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put("/delete_cycle_data", data, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(deleteSymbolCycleSuccess(res.data));
    } catch (error) {
        dispatch(deleteSymbolCycleFailure(error.response?.data));
    }
};

export const pairRangeMessagegsGet = async (dispatch) => {
    dispatch(getPairRangeMessagesStart());
    try {
        const token = authHeader();
        const res = await axiosClient.get("/get_pair_range_messages", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getPairRangeMessagesSuccess(res.data));
    } catch (error) {
        dispatch(getPairRangeMessagesFailure(error.response?.data));
    }
};

export const deletePairRangeMsg = async (data) => {
    try {
        const token = authHeader();
        const res = await axiosClient.put(`/pair_range_messages`, data, {
            headers: {
                Authorization: token,
            },
        });
        return res?.data;
    } catch (error) {
        throw error;
    }
};
