// BASIC IMPORTS
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DateRangePicker, Stack } from "rsuite";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import PropTypes from "prop-types";
import { parseISO } from 'date-fns';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// MUI IMPORTS
import { Box, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TableSortLabel, Checkbox, TextField, Switch } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../../main/SharedStateContext";

// API IMPORTS
import { backtestPairCycleFyersPost, fyersApiCallCounter, backtestPairHistory, backtestPairHistoryGet, getPairBacktestAllSymbols } from "../../../actions/backtestAction";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";



ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const headCells = [
  {
    id: "accountName",
    numeric: false,
    disablePadding: false,
    label: "Account",
  },
  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "switchType",
    numeric: false,
    disablePadding: false,
    label: "Side",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "buyPrice",
    numeric: true,
    disablePadding: false,
    label: "Buy Price",
  },
  {
    id: "sellPrice",
    numeric: true,
    disablePadding: false,
    label: "Sell Price",
  },
  {
    id: "cycle_done",
    numeric: true,
    disablePadding: false,
    label: "Cycle",
  },
  {
    id: "buyStatus",
    numeric: false,
    disablePadding: false,
    label: "Buy Status",
  },
  {
    id: "sellStatus",
    numeric: false,
    disablePadding: false,
    label: "Sell Status",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "errorMessage",
    numeric: false,
    disablePadding: false,
    label: "Error",
  },
  {
    id: "setting",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell?.id}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell?.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell?.id ? order : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {orderBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const filterOptions = createFilterOptions({
  ignoreCase: true,
  limit: 100,
  matchFrom: "any",
  stringify: (option) => option?.companyName + option?.shortName,
});

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function PairBacktest() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20); // Maximum 20 candles per page
  const [totalPages, setTotalPages] = useState(1);

  const [backtestPairCycle, setBacktestPairCycle] = useState([]);
  const [volume, setVolume] = useState([]);
  const [symbolHistory, setSymbolHistory] = useState({});
  const [fyersApiCall, setFyersApiCall] = useState();
  const [dropdownList, setDropdownList] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pairSymbols, setPairSymbols] = useState([]);
  const switchLabel = { inputProps: { "aria-label": "Switch demo" } };

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const { afterToday } = DateRangePicker;
  
  const [errorDateRange, setErrorDateRange] = useState("");
  const [dateRangePicker, setDateRangePicker] = useState([]);
  const [selectedSymbol, setSetectedSymbol] = useState("");
  
  const watchSymbol = watch("companyName", "");
  const watchSellPrice = watch("start_sellPrice", "");
  const watchSwitchType = watch("switchType", "");
  const watchGapSwitch = watch("gapSwitch", "");

  const labels = volume?.map((item) => item.date);
  const volumes = volume?.map((item) => item.volume);

  const calculateDaysBetweenDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays;
  };

  const calculateStartSellPrice = (startBuyPrice, gapSwitch, gap) => {
    startBuyPrice = parseFloat(startBuyPrice);
    gap = parseFloat(gap);
    if (gapSwitch) {
      return ((gap * startBuyPrice) / 100 + startBuyPrice).toFixed(2);
    } else {
      return (startBuyPrice + gap).toFixed(2);
    }
  };

  const onSubmit = async (data) => {
    if (dateRangePicker?.length === 0) {
      setErrorDateRange("Date Range is required");
      return;
    }

    const fromDateTime = new Date(dateRangePicker[0]);
    const toDateTime = new Date(dateRangePicker[1]);

    // Formatting from_date and to_date
    const fromDate = `${fromDateTime.getFullYear()}-${(
      fromDateTime.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fromDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}T09:15:00.000Z`;
    const toDate = `${toDateTime.getFullYear()}-${(toDateTime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${toDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}T15:30:00.000Z`;

    const formattedData = {
      ...data,
      from_date: fromDate,
      to_date: toDate,
    };
    delete formattedData["dateRange"];
    formattedData["symbol"] = selectedSymbol?.shortName;
    formattedData["companyName"] = selectedSymbol?.companyName;
    formattedData["switchType"] = data?.switchType ? "Sell" : "Buy";

    const expiry_date = selectedSymbol?.expiryDate
      ? selectedSymbol?.expiryDate
      : "";

    formattedData["tickSize"] = selectedSymbol?.tickSize;
    formattedData["expiryDate"] = expiry_date;

    const accountName = data?.account.split("-")[0].trim();
    var sellPrice = null 

    if (watchSellPrice.trim() === "") {
      sellPrice = calculateStartSellPrice(
        formattedData["start_buyPrice"],
        formattedData["gapSwitch"],
        formattedData["gap"]
      );
      setValue("start_sellPrice", sellPrice);
      formattedData["start_sellPrice"] = sellPrice;
    }

    if (accountName === "FYERS") {
      await backtestPairCycleFyersPost(dispatch, formattedData);
      if (store.getState()?.backtest?.backtest_pair_cycle) {
        setBacktestPairCycle(
          store.getState()?.backtest?.backtest_pair_cycle?.data
        );
        setVolume(store.getState()?.backtest?.backtest_pair_cycle?.volume);
        const totalDays = calculateDaysBetweenDates(
          dateRangePicker[0],
          dateRangePicker[1]
        );
        const api_payload = {
          pair_symbol: { symbol: data?.companyName, totalDays: totalDays + 1, account: data?.account, interval: data?.interval, exchange: data?.exchange, gap: data?.gap, gapSwitch: data?.gapSwitch, product: data?.product, start_buyPrice: data?.start_buyPrice, start_sellPrice: data?.start_sellPrice ? data?.start_sellPrice : sellPrice, no_of_pair: data?.no_of_pair, switchType: data?.switchType, from_date: fromDate, to_date: toDate },
          pair_history: store.getState()?.backtest?.backtest_pair_cycle?.data,
          volume_history:
            store.getState()?.backtest?.backtest_pair_cycle?.volume,
        };
        await backtestPairHistory(dispatch, api_payload);
        toast.success("Data retrieved successfully");
      } else if (store.getState()?.backtest?.error) {
        toast.error(
          store.getState()?.backtest?.errorMessage
            ? store.getState()?.backtest?.errorMessage?.error
            : "Something went wrong"
        );
      }
    }

    setPage(0);
    setCurrentPage(0);
    GetFyersApiCall();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUniqueDates = (data) => {
    const uniqueDates = {};
    data?.forEach((item) => {
      uniqueDates[item.date] = true;
    });
    return Object.keys(uniqueDates);
  };

  const getUniquePriceRanges = (data) => {
    const uniquePriceRanges = {};
    data?.forEach((item) => {
      const priceRange = `${item.buy_price} - ${item.sell_price}`;
      uniquePriceRanges[priceRange] = true;
    });
    return Object.keys(uniquePriceRanges);
  };

  const columns = [
    { id: "date", label: "Date" },
    { id: "cycle", label: "Total" },
    ...getUniquePriceRanges(backtestPairCycle).map((priceRange) => ({
      id: priceRange,
      label: priceRange,
    })),
  ];

  function createData(date, priceRanges, cycles) {
    const rowData = {};
    priceRanges.forEach((priceRange, index) => {
      rowData[priceRange] = cycles[index];
    });
    return { date, ...rowData, cycle: cycles.reduce((a, b) => a + b, 0) };
  }

  const rows = getUniqueDates(backtestPairCycle).map((date) => {
    const cycles = [];
    const priceRanges = [];
    backtestPairCycle?.forEach((item) => {
      if (item.date === date) {
        const priceRange = `${item.buy_price} - ${item.sell_price}`;
        const index = priceRanges.indexOf(priceRange);
        if (index === -1) {
          priceRanges.push(priceRange);
          cycles.push(item.cycle);
        } else {
          cycles[index] += item.cycle;
        }
      }
    });
    return createData(date, priceRanges, cycles);
  });

  const totalCycles = rows?.reduce((total, row) => total + row.cycle, 0);

  const GetFyersApiCall = async () => {
    await fyersApiCallCounter(dispatch);
    if (store.getState()?.backtest?.fyers_api_call_counter) {
      setFyersApiCall(store.getState()?.backtest?.fyers_api_call_counter?.data);
    }
    await backtestPairHistoryGet(dispatch).then((res) => {
      setBacktestPairCycle(
        res?.pair_history
      );
      setVolume(
        res?.volume_history
      );
      setSymbolHistory(
        res?.pair_symbol || {}
      );
    })
  };

  // Function to get the data for the current page
  const getPagedData = () => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;

    return {
      labels: labels?.slice(start, end),
      datasets: [
        {
          label: "Volume",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: volumes?.slice(start, end),
        },
      ],
    };
  };

  const barData = getPagedData();

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Volume",
        },
      },
    },
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState().exchange?.exchangeProductSettingList) {
      const res = store.getState().exchange?.exchangeProductSettingList;
      if (res) {
        setDropdownList(res?.data);
      }
    }
  };

  const getPairBacktestSymbols = async () => {
    await getPairBacktestAllSymbols().then((res) => {
      setPairSymbols(res?.symbols)
    }).catch((error) => {})
  }

  const handleResetForm = () => {
    reset();
    setDateRangePicker([]);
    setSetectedSymbol("");
  }


  useEffect(() => {
    const calculateItemsPerPage = () => {
      const screenWidth = window.innerWidth;
      const maxCandlesPerPage = 40; // Desired number of candles per page

      const calculatedItemsPerPage = Math.floor(
        screenWidth / maxCandlesPerPage
      );

      return calculatedItemsPerPage;
    };

    const handleResize = () => {
      setItemsPerPage(calculateItemsPerPage());
    };

    setItemsPerPage(calculateItemsPerPage());

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    if (Object.keys(symbolHistory).length !== 0) {
      if (symbolHistory?.from_date !== undefined && symbolHistory?.to_date !== undefined) {
        var from_date = new Date(symbolHistory?.from_date);
        var to_date = new Date(symbolHistory?.to_date);
        from_date = parseISO(from_date.toISOString());
        to_date = parseISO(to_date.toISOString());
        setDateRangePicker([from_date, to_date])
        setValue("account", symbolHistory?.account)
        setValue("interval", symbolHistory?.interval)
        setValue("exchange", symbolHistory?.exchange)
        setValue("product", symbolHistory?.product)
        setValue("start_buyPrice", symbolHistory?.start_buyPrice)
        setValue("start_sellPrice", symbolHistory?.start_sellPrice)
        setValue("no_of_pair", symbolHistory?.no_of_pair)
        setValue("gap", symbolHistory?.gap)
        setValue("switchType", symbolHistory?.switchType)
        setValue("gapSwitch", symbolHistory?.gapSwitch)
      }
    }
    // eslint-disable-next-line
  }, [symbolHistory])

  useEffect(() => {
    // Update total pages when labels or itemsPerPage changes
    setTotalPages(Math.ceil(labels?.length / itemsPerPage));
  }, [labels, itemsPerPage]);

  useEffect(() => {
    GetFyersApiCall();
    getSettingData();
    getPairBacktestSymbols();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/pair-backtest" style={{ fontWeight: 600 }}>
                            Pair Backtest
                          </Link>
                        </li>
                      </ul>
                      <div
                        style={{ marginTop: "6px", display: "flex" }}
                        className="pb-3"
                      >
                        {fyersApiCall
                          ? fyersApiCall?.length !== 0
                            ? "API CALL DETAIL :-"
                            : null
                          : null}
                        {fyersApiCall?.map((data, index) => (
                          <div key={index}>
                            <span className="ml-2">{data?.account_name}</span> -{" "}
                            <span className="mr-2">{data?.api_count}</span>
                            {index !== fyersApiCall?.length - 1 && (
                              <span>|</span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-3 pr-0">
                            <label>Account*</label>
                            <select
                              className="form-control"
                              {...register("account", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              {dropdownList?.setting?.map((setting) => {
                                // Extracting the nested key from each setting object
                                const nestedKey = Object.keys(setting)[0];
                                // Extracting the nested keys from the nested object
                                const keys = Object.keys(setting[nestedKey]);
                                const keys_val = Object.values(
                                  setting[nestedKey]
                                );
                                return keys?.map((key, index) =>
                                  // keys_val[index] === "ICICI" ||
                                  keys_val[index] === "FYERS" ? (
                                    <option
                                      key={index}
                                      value={`${keys_val[index]} - ${key}`}
                                    >
                                      {key}
                                    </option>
                                  ) : null
                                );
                              })}
                            </select>
                            {errors.account && (
                              <span className="validationAlert">
                                Account is required
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Interval*</label>
                            <select
                              className="form-control"
                              {...register("interval", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              <option value="5second">5second</option>
                              <option value="15second">15second</option>
                              <option value="30second">30second</option>
                              <option value="1minute">1minute</option>
                              <option value="5minute">5minute</option>
                              <option value="30minute">30minute</option>
                              <option value="1day">1day</option>
                            </select>
                            {errors.interval && (
                              <span className="validationAlert">
                                Interval is required
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Exchange*</label>
                            <select
                              className="form-control"
                              {...register("exchange", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              {dropdownList?.exchange?.map((data, index) => {
                                return (
                                  <option value={data} key={index}>
                                    {data}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.exchange && (
                              <span className="validationAlert">
                                Exchange is required
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Product*</label>
                            <select
                              className="form-control"
                              {...register("product", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              <option>Equity</option>
                              <option>Future</option>
                              <option>Option</option>
                            </select>
                            {errors.product && (
                              <span className="validationAlert">
                                Product is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-3 pr-0 customDateRangeStyle">
                            <label>Date Range*</label>
                            <Stack>
                              <DateRangePicker
                                size="lg"
                                value={dateRangePicker}
                                onChange={(value) => {
                                  setDateRangePicker(value);
                                  setErrorDateRange("");
                                }}
                                disabledDate={afterToday()}
                                placeholder="Select..."
                              />
                            </Stack>
                            {errorDateRange && (
                              <div className="validationAlert">
                                Date Range is required
                              </div>
                            )}
                          </div>

                          <div className="form-group col-md-9 pr-0">
                            <label>Symbol*</label>
                            <Controller
                              name="companyName"
                              control={control}
                              rules={{
                                required: "Symbol full name is required",
                              }}
                              render={({ field }) => (
                                <Autocomplete
                                  fullWidth
                                  disablePortal
                                  id="combo-box-demo"
                                  {...field}
                                  filterOptions={filterOptions}
                                  value={
                                    pairSymbols?.find(
                                      (option) =>
                                        option?.shortName === watchSymbol
                                    ) || null
                                  }
                                  options={pairSymbols ? pairSymbols : []}
                                  onChange={(event, value) => {
                                    field.onChange(value?.shortName || "");
                                    setSetectedSymbol(value || "");
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.shortName || ""
                                  }
                                  renderOption={(props, option) => (
                                    <div
                                      {...props}
                                      key={option?.shortName}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          float: "left",
                                        }}
                                      >
                                        <div className="symbolFont">
                                          {option?.shortName?.toString()}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          float: "right",
                                        }}
                                      >
                                        <div className="mr-2 companyNameFont">
                                          {option?.companyName?.toString()}
                                        </div>
                                        <div className="pt-1">
                                          <span
                                            className={
                                              option?.marketType?.toString() ===
                                                "NSE"
                                                ? "badge badge-secondary nseBadge symbolFont"
                                                : "badge badge-secondary bseBadge symbolFont"
                                            }
                                          >
                                            {option?.marketType?.toString()}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              )}
                            />
                            {errors.companyName && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors.companyName?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-4 pr-0">
                            <label>Start Buy Price*</label>
                            <input
                              // type="number"
                              className="form-control"
                              placeholder="Start Buy Price"
                              {...register("start_buyPrice", {
                                required: "Start Buy price is required",
                                maxLength: {
                                  value: 100,
                                },
                              })}
                            />
                            {errors?.start_buyPrice && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.start_buyPrice?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-4 pr-0">
                            <label>Start Sell Price (Auto (Start Buy Price + Gap))</label>
                            <input
                              // type="number"
                              name="start_sellPrice"
                              className="form-control"
                              placeholder="Start Sell Price"
                              {...register("start_sellPrice", {})}
                            />
                            {errors?.start_sellPrice && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.start_sellPrice?.message}
                              </div>
                            )}
                          </div>

                          <div className="form-group col-md-4 pr-0">
                            <label>No Of Pair*</label>
                            <input
                              // type="number"
                              id="no_of_pair"
                              className="form-control"
                              placeholder="No Of Pair"
                              {...register("no_of_pair", {
                                required: "No Of Pair is required",
                                maxLength: {
                                  value: 100,
                                },
                              })}
                            />
                            {errors?.no_of_pair && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.no_of_pair?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-6 switchMargin pr-0">
                            <label>Gap*</label>&nbsp;&nbsp;&nbsp;
                            <label>( Numeric (Ex: 100)</label>
                            <Switch
                              {...switchLabel}
                              {...register("gapSwitch", { required: false })}
                                checked={watchGapSwitch ? true : false}
                                onChange={(event) => {
                                  setValue(
                                    "gapSwitch",
                                    event.target.checked ? true : false
                                  );
                                }}
                              className="switchGapStyle"
                            />
                            <label>Percentage (Ex: 0.25)(100*0.25) )</label>
                            <input
                              // type="number"
                              id="gap"
                              className="form-control"
                              placeholder="Gap"
                              {...register("gap", {
                                required: "Gap is required",
                                maxLength: {
                                  value: 100,
                                },
                              })}
                            />
                            {errors?.gap && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.gap?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-6 pr-0">
                            <label>Switch Type*</label>
                            <div
                              style={{
                                display: "flax",
                                color: "cadetblue",
                                fontWeight: 500,
                              }}
                            >
                              <label>Buy</label>
                              <Switch
                                {...switchLabel}
                                color="default"
                                {...register("switchType", { required: false })}
                                checked={watchSwitchType ? true : false}
                                onChange={(event) => {
                                  setValue(
                                    "switchType",
                                    event.target.checked ? true : false
                                  );
                                }}
                                className="switchStyle"
                              />
                              <label>Sell</label>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 text-center">
                          <button
                            type="submit"
                            className="btn btn-outline-primary ml-3 px-5"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger ml-3 px-4"
                            onClick={handleResetForm}
                          >
                            Clear
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  {symbolHistory ? (
                    <div className="main-body">
                      <div className="page-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <span className="mr-3">
                              Symbol: {symbolHistory?.symbol}{" "}
                            </span>
                            ||
                            <span className="mx-3">
                              Total Days: {symbolHistory?.totalDays ? symbolHistory?.totalDays?.toFixed(2) : ""}
                            </span>
                            ||
                            <span className="mx-3">
                              Total Cycle: {totalCycles}
                            </span>
                            {symbolHistory?.totalDays > 30 ? (
                              <>
                                ||
                                <span className="mx-3">
                                  Total Months:{" "}
                                  {symbolHistory?.totalDays > 30
                                    ? (symbolHistory?.totalDays / 30).toFixed(2)
                                    : null}
                                </span>
                                ||
                                <span className="mx-3">
                                  Monthly Cycle:{" "}
                                  {(
                                    totalCycles /
                                    (symbolHistory?.totalDays / 30).toFixed(2)
                                  ).toFixed(2)}
                                </span>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {backtestPairCycle ? (
                    <div className="card">
                      <div className="card-body">
                        <div>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns?.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((row, index) => (
                                    <TableRow
                                      key={row.date}
                                      style={{ whiteSpace: "nowrap" }}
                                      className={
                                        index % 2 !== 0 ? "table-row-odd" : ""
                                      }
                                    >
                                      {columns?.map((column) => (
                                        <TableCell key={column.id}>
                                          {column.id === "date"
                                            ? moment(
                                              (row?.date).toString(),
                                              "YYYY/M/D"
                                            ).format("DD/MM/YY")
                                            : row[column.id]}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={rows?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {volume ? (
                <section>
                  <div
                    style={{
                      overflowX: "auto",
                      width: "100%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <Bar data={barData} options={chartOptions} height={500} />
                    </div>
                  </div>
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <button
                      className="btn btn-outline-primary px-3 py-1"
                      onClick={() =>
                        setCurrentPage(Math.max(currentPage - 1, 0))
                      }
                      disabled={currentPage === 0}
                    >
                      Previous
                    </button>
                    <span style={{ margin: "0 10px" }}>
                      Page {currentPage + 1} of {totalPages}
                    </span>
                    <button
                      className="btn btn-outline-primary px-3 py-1"
                      onClick={() =>
                        setCurrentPage(
                          Math.min(currentPage + 1, totalPages - 1)
                        )
                      }
                      disabled={currentPage === totalPages - 1}
                    >
                      Next
                    </button>
                  </div>
                </section>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PairBacktest;
