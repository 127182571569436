import axiosClient from "../axios-client";
import { loginFailure, loginStart, loginSuccess, registerStart, registerSuccess, registerFailure, forgotPasswordStart, forgotPasswordSuccess, forgotPasswordFailure, getClientStart, getClientSuccess, getClientFailure, putClientStart, putClientSuccess, putClientFailure, putClientActivationStart, putClientActivationFailure, putClientActivationSuccess, getClientFilterStart, getClientFilterSuccess, getClientFilterFailure, deleteClientStart, deleteClientFailure, deleteClientSuccess, putClientPasswordStart, putClientPasswordFailure, putClientPasswordSuccess, backupDbStart, backupDbFailure, backupDbSuccess, liveDbToLocalStart, liveDbToLocalSuccess, liveDbToLocalFailure, otpVerificationForLiveDBStart, otpVerificationForLiveDBSuccess, otpVerificationForLiveDBFailure, sendOtpForLiveDBStart, sendOtpForLiveDBSuccess, sendOtpForLiveDBFailure } from "../reducers/authReducer";
import authHeader from "../services/auth-header";


export const login = async (dispatch, user) => {
    dispatch(loginStart());
    try {

        const res = await axiosClient.post("/auth/login", user);
        sessionStorage.setItem("auth", JSON.stringify(res?.data));
        dispatch(loginSuccess(res?.data));

    } catch (error) {
        dispatch(loginFailure(error.response?.data));

    }
}

export const register = async (dispatch, user) => {
    dispatch(registerStart());
    try {

        const res = await axiosClient.post("/auth/verify_otp", user);
        sessionStorage.setItem("auth", JSON.stringify(res?.data));
        dispatch(registerSuccess(res?.data));

    } catch (error) {
        dispatch(registerFailure(error.response?.data));

    }
}

export const clientPut = async (dispatch, data) => {
    dispatch(putClientStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put('/auth/client', data, {
            headers: {
                Authorization: token
            }
        })
        dispatch(putClientSuccess(res.data));

    } catch (error) {
        dispatch(putClientFailure(error.response?.data));

    }
}

export const clientGet = async (dispatch) => {
    dispatch(getClientStart());
    try {
        const token = authHeader();
        const res = await axiosClient.get('/auth/client', {
            headers: {
                Authorization: token
            }
        })
        dispatch(getClientSuccess(res.data));
    } catch (error) {
        dispatch(getClientFailure(error.response?.data));
    }
}

export const allClientGet = async (dispatch) => {
    try {
        const token = authHeader();

        const res = await axiosClient.get('/auth/clients', {
            headers: {
                Authorization: token
            }
        })

        return res?.data
    } catch (error) {

        throw error;
    }
}

export const emailVerification = async (dispatch, user) => {
    dispatch(registerStart());
    try {

        const res = await axiosClient.post("/auth/register", user);
        sessionStorage.setItem("auth", JSON.stringify(res.data));
        dispatch(registerSuccess(res.data));

    } catch (error) {
        dispatch(registerFailure(error.response?.data));

    }
}

export const forgotPassword = async (dispatch, email) => {
    dispatch(forgotPasswordStart());
    try {

        const res = await axiosClient.post("/auth/forgot-password", email);
        dispatch(forgotPasswordSuccess(res.data));

    } catch (error) {
        dispatch(forgotPasswordFailure(error.response?.data));

    }
}

export const activateUserPut = async (dispatch, id, data) => {
    dispatch(putClientActivationStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put(`/auth/activate_user/${id}`, data, {
            headers: {
                Authorization: token
            }
        })
        dispatch(putClientActivationSuccess(res.data));

    } catch (error) {
        dispatch(putClientActivationFailure(error.response?.data));

    }
}

export const clientEditPasswordPut = async (dispatch, id, data) => {
    dispatch(putClientPasswordStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put(`/auth/edit-password/${id}`, data, {
            headers: {
                Authorization: token
            }
        })
        dispatch(putClientPasswordSuccess(res.data));

    } catch (error) {
        dispatch(putClientPasswordFailure(error.response?.data));

    }
}

export const clientFilter = async (dispatch, parameters) => {
    dispatch(getClientFilterStart());
    try {
        const token = authHeader();
        const res = await axiosClient.post(`/auth/client-filter`, parameters, {
            headers: {
                Authorization: token
            }
        })
        dispatch(getClientFilterSuccess(res.data));
    } catch (error) {
        dispatch(getClientFilterFailure(error.response?.data));
    }
}

export const clientDelete = async (dispatch, id, data) => {
    dispatch(deleteClientStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put(`/auth/client/${id}`, data, {
            headers: {
                Authorization: token
            }
        })
        dispatch(deleteClientSuccess(res.data));

    } catch (error) {
        dispatch(deleteClientFailure(error.response?.data));

    }
}

export const backupDb = async (dispatch) => {
    dispatch(backupDbStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/auth/backup_db`, {
            headers: {
                Authorization: token
            }
        })
        dispatch(backupDbSuccess(res.data));

    } catch (error) {
        dispatch(backupDbFailure(error.response?.data));

    }
}

export const liveDbToLocal = async (dispatch) => {
    dispatch(liveDbToLocalStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/auth/live_db_to_local`, {
            headers: {
                Authorization: token
            }
        })
        dispatch(liveDbToLocalSuccess(res.data));

    } catch (error) {
        dispatch(liveDbToLocalFailure(error.response?.data));

    }
}

export const SendOtpForLiveDB = async (dispatch) => {
    dispatch(sendOtpForLiveDBStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/auth/send_otp", {
            headers: {
                Authorization: token
            }
        });
        dispatch(sendOtpForLiveDBSuccess(res?.data));

    } catch (error) {
        dispatch(sendOtpForLiveDBFailure(error.response?.data));

    }
}

export const OtpVerificationForLiveDB = async (dispatch, data) => {
    dispatch(otpVerificationForLiveDBStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/auth/verify_live_otp", data, {
            headers: {
                Authorization: token
            }
        });
        dispatch(otpVerificationForLiveDBSuccess(res?.data));

    } catch (error) {
        dispatch(otpVerificationForLiveDBFailure(error.response?.data));

    }
}

export const updateSwitchTime = async (data) => {
    try {
        const token = authHeader();
        const res = await axiosClient.put(`/auth/save_switch_time`, data, {
            headers: {
                Authorization: token
            }
        })
        return res?.data
    } catch (error) {
        throw error
    }
}

export const getSwitchTime = async () => {
    try {
        const token = authHeader();
        const res = await axiosClient.get(`/auth/get_switch_time`, {
            headers: {
                Authorization: token
            }
        })
        return res?.data
    } catch (error) {
        throw error
    }
}