// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

// MUI IMPORTS
import { Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, IconButton, Tooltip, Checkbox } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { visuallyHidden } from "@mui/utils";
import DeleteIcon from "@mui/icons-material/Delete";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";

// API IMPORTS
import { angelIndexFoSymbolGet, fyersIndexFoSymbolGet, fyersIndexSymbolGet, iciciIndexFoSymbolGet, uploadExcelGet } from "../../../actions/uploadExcel";
import { getAllSymbols, createSymbol, deleteSymbol } from "../../../actions/optionStrategyCommanAction";
import { getFavSymbolsAccounts } from "../../../actions/symbolAction";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const data = comparator(a[0], b[0]);
    if (data !== 0) {
      return data;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "account_name",
    numeric: false,
    disablePadding: false,
    label: "Account",
  },
  {
    id: "shortName",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "underlying_symbol",
    numeric: false,
    disablePadding: false,
    label: "Underlying Symbol",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Symbol Full Name",
  },
  {
    id: "marketType",
    numeric: true,
    disablePadding: false,
    label: "Exchange",
  },
  {
    id: "round_of_value",
    numeric: true,
    disablePadding: false,
    label: "Round Value",
  },
  {
    id: "variation",
    numeric: true,
    disablePadding: false,
    label: "Variation",
  },
  {
    id: "lotSize",
    numeric: true,
    disablePadding: false,
    label: "Lotsize",
  },
  {
    id: "expiryDate",
    numeric: true,
    disablePadding: false,
    label: "Expiry",
  },
  {
    id: "setting",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    onRequestSort,
    rowCount,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell?.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell?.id ? order : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {orderBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function OptionStrategyTwoSymbols() {
  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: {},
  });

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterSymbol, filterSymbolList] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("accountName");
  const [accountName, setAccountName] = useState("");
  const [filterSymbolsData, setFilterSymbolsData] = useState([]);
  const [isOpen, setIsopen] = useState(false);
  const [iciciSymbols, setIciciSymbols] = useState([]);
  const [fyersIndexSymbol, setFyersIndexSymbol] = useState([]);
  
  const watchCompanyName = watch("shortName", "");
  const isSelected = (id) => selected.indexOf(id) !== -1;
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 500,
    matchFrom: "any",
    stringify: (option) => option?.companyName + option?.shortName,
  });

  const tickFilterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 500,
    matchFrom: "any",
    stringify: (option) => option?.companyName + option?.shortName,
  });

  const getSymbolsAccounts = async () => {
    await getFavSymbolsAccounts(dispatch).then((res) => {
      setFilterSymbolsData(res?.data);
    }).catch((error) => {
      toast.error("Favourite symbols, accounts data not found")
    })
  };

  const options = filterSymbolsData?.accounts
    ? Object.keys(filterSymbolsData.accounts).map(key => ({
      key: key,
      value: filterSymbolsData.accounts[key]
    }))
    : [];

    const getFyersIndexSymbols = async () => {
      await fyersIndexSymbolGet().then((res) => {
        setFyersIndexSymbol(res?.FyersSymbol);
      }).catch((error) => {})
    };
  

  const getFyersSymbols = async () => {
    await fyersIndexFoSymbolGet(dispatch).then((res) => {
      setFilterData(res?.FyersSymbol);
    }).catch((error) => {})
  };

  const getIciciSymbols = async () => {
    await iciciIndexFoSymbolGet(dispatch).then((res) => {
      setIciciSymbols(res);
    }).catch(() => {})
  };

  const getAngelSymbols = async () => {
    await angelIndexFoSymbolGet(dispatch).then((res) => {
      setFilterData(res?.AngelSymbol);
    }).catch((error) => {})
  };

  const handleAddSymbol = async (data) => {
    const roundOfValue = parseInt(data?.round_of_value);
    const variation = parseInt(data?.variation);

    const newData = { ...selectedSymbol };
    delete newData["id"];
    delete newData["LotSize"]
    newData["round_of_value"] = roundOfValue
    newData["variation"] = variation
    newData["underlying_symbol"] = data?.underlying_symbol
    newData["lotSize"] = data?.lotSize
    newData["tickSymbol"] = data?.tickSymbol
    newData["account_name"] = accountName?.key
    newData["broker"] = accountName?.value

    await createSymbol(dispatch, newData).then((res) => {
      getSymbolsData();
      toast.success("Symbol added successfully");
      reset()
    }).catch((error) => {
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error)
      }
    })
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(filterSymbol, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filterSymbol]
  );


  const getSymbolsData = async () => {
    await getAllSymbols(dispatch).then((res) => {
      filterSymbolList(res?.data?.symbols || [])
    }).catch((error) => {
      toast.error("Symbols list not found")
    })
  };

  const ToggleSidebar = () => {
    // setFilterParams({});
    reset();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filterSymbol?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleMultiDelete = async () => {
    if (selected?.length !== 0) {
      // delte multiple records
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to delete the selected ${selected?.length} record?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { ids: selected };
          await deleteSymbol(dispatch, data).then((res) => {
            toast.success("Symbol deleted successfully!");
            getSymbolsData();
            setSelected([])
            setPage(0);
          }).catch((error) => {
            toast.error("Symbol not able delete due to some error")
          })
        }
      });
    }
  };

  const handleInputChange = (value) => {
    if (value === "ICICI") {
      getIciciSymbols();
    } else if (value === "ANGEL") {
      getAngelSymbols();
    } else if (value === "FYERS") {
      getFyersSymbols();
    }
  };

  useEffect(() => {
    const nseData = iciciSymbols?.NSEScripMaster?.map((item, index) => ({
      ...item,
      marketType: "NSE",
    }));
    const bseData = iciciSymbols?.BSEScripMaster?.map((item, index) => ({
      ...item,
      marketType: "BSE",
    }));
    const futureNseData = iciciSymbols?.FONSEScripMaster?.map((item, index) => ({
      ...item,
      marketType: "NFO",
    }));
    const futureBseData = iciciSymbols?.FOBSEScripMaster?.map((item, index) => ({
      ...item,
      marketType: "BFO",
    }));

    if (nseData && bseData && futureNseData && futureBseData) {
      const AllMarketData = [...nseData, ...bseData, ...futureNseData, ...futureBseData];
      setFilterData(AllMarketData);
    }
    // eslint-disable-next-line
  }, [iciciSymbols]);

  useEffect(() => {
    getSymbolsData();
    getSymbolsAccounts();
    // eslint-disable-next-line
  }, [dispatch]);


  useEffect(() => {
    if(accountName?.value && accountName?.value !== "FYERS"){
      getFyersIndexSymbols();
    }
    // eslint-disable-next-line
  }, [accountName]);


  return (
    <div className="Main">
      <div className="container-fluid">
        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
          <div className="pt-4 sd-header">
            <Button className="adminbackBtn" onClick={ToggleSidebar}>
              <i className="feather icon-chevron-right"></i>
            </Button>
            <h3 className="mt-1">Filters</h3>
          </div>
        </div>
      </div>

      <div
        className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/option-strategy-two-symbols" style={{ fontWeight: 600 }}>
                          Symbols
                        </Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-3">
                      <div className="ListWithBadge">
                        <h3 className="m-b-20 mr-2">Add Strategy Symbols</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <form onSubmit={handleSubmit(handleAddSymbol)}>
                      <div className="card">
                        <div
                          className="card-body mobileCardBody"
                          style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        >
                          <div className="row col-md-12">
                          <div className="form-group col-md-12 d-flex">
                            <h6 className="mr-2">NOTE: </h6><label>Underlying Symbol get from fyers symbol after NSE: is our Underlying Symbol</label>
                          </div>
                            <div className="form-group col-md-12">
                              <label>Account*</label>
                              <Autocomplete
                                className="favAccountDropdown"
                                options={options}
                                getOptionLabel={(option) => option.key}
                                isOptionEqualToValue={(option, value) => option?.key === value?.key}
                                onChange={(e, value) => {
                                  setAccountName(value);
                                  handleInputChange(value?.value);
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" {...props} style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%"
                                  }}>
                                    <Typography style={{ float: "left" }}>{option.key}</Typography>
                                    <Typography style={{ float: "right" }}>{option.value}</Typography>
                                  </Box>
                                )}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </div>
                            <div className="form-group col-md-12">
                              <label>Symbol*</label>
                              <Controller
                                name="shortName"
                                control={control}
                                rules={{ required: "Symbol is required" }}
                                render={({ field }) => (
                                  <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-demo"
                                    {...field}
                                    filterOptions={filterOptions}
                                    value={
                                      filterData?.find(
                                        (option) =>
                                          option?.shortName ===
                                          watchCompanyName
                                      ) || null
                                    }
                                    options={filterData ? filterData : []}
                                    onChange={(event, value) => {
                                      setSelectedSymbol(value)
                                      field.onChange(value?.shortName || "");
                                    }}
                                    getOptionLabel={(option) => option?.shortName || ""}
                                    renderOption={(props, option) => (
                                      <div
                                        {...props}
                                        key={option?._id}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <div style={{ float: "left", display: "flex" }}>
                                          <div className="symbolFont pt-1">
                                            {option?.shortName?.toString()}
                                          </div>
                                        </div>
                                        <div style={{ float: "right", display: "flex" }}>
                                          <div
                                            className="mr-2 companyNameFont"
                                            style={{
                                              textAlign: "center",
                                              margin: "auto",
                                            }}
                                          >
                                            {option?.companyName?.toString()}
                                          </div>
                                          <span
                                            className={
                                              option?.marketType?.toString() === "NSE"
                                                ? "badge badge-secondary nseBadge symbolFont"
                                                : "badge badge-secondary bseBadge symbolFont"
                                            }
                                          >
                                            {option?.marketType?.toString()}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                )}
                              />
                              {errors?.companyName && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors?.companyName?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-2">
                              <label>Round of value*</label>
                              <input
                                type="number"
                                id={`round_of_value`}
                                className="form-control"
                                placeholder="Value"
                                {...register(`round_of_value`, {
                                  required: "Round of value is required",
                                  valueAsNumber: true, // Converts the input to a number
                                  validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                                })}
                                onKeyPress={(e) => {
                                  if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors?.round_of_value && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors?.round_of_value?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-1">
                              <label>Variation*</label>
                              <input
                                type="number"
                                id={`variation`}
                                className="form-control"
                                placeholder="Variation"
                                {...register(`variation`, {
                                  required: "Variation is required",
                                  valueAsNumber: true, // Converts the input to a number
                                  validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                                })}
                                onKeyPress={(e) => {
                                  if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors?.variation && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors?.variation?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label>Underlying Symbol (Ex. NIFTY, BANKNIFTY)*</label>
                              <input
                                id={`underlying_symbol`}
                                className="form-control"
                                placeholder="Underlying Symbol"
                                {...register(`underlying_symbol`, {
                                  required: "Underlying symbol is required"
                                })}
                              />
                              {errors?.underlying_symbol && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors?.underlying_symbol?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-2">
                              <label>Lotsize*</label>
                              <input
                                type="number"
                                id={`lotSize`}
                                className="form-control"
                                placeholder="Lotsize"
                                {...register(`lotSize`, {
                                  required: "Lotsize is required",
                                  valueAsNumber: true, // Converts the input to a number
                                  validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                                })}
                                onKeyPress={(e) => {
                                  if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors?.lotSize && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors?.lotSize?.message}
                                </div>
                              )}
                            </div>
                            {(accountName?.value && accountName?.value !== "FYERS") ?
                            <div className="form-group col-md-3">
                              <label>Tick Symbol*</label>
                              <Controller
                                name="tickSymbol"
                                control={control}
                                rules={{ required: "Symbol is required" }}
                                render={({ field }) => (
                                  <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-demo"
                                    {...field}
                                    filterOptions={tickFilterOptions}
                                    value={
                                      fyersIndexSymbol
                                        ? fyersIndexSymbol?.find(
                                          (option) =>
                                            option.shortName === (field.value || "")
                                        ) || null
                                        : null
                                    }
                                    options={
                                      fyersIndexSymbol ? fyersIndexSymbol : []
                                    }
                                    onChange={(event, value) => {
                                      field.onChange(value?.shortName || "");
                                      if (value) {
                                        // handleFilterSymbol(event, value);
                                      }
                                    }}
                                    getOptionLabel={(option) => option?.shortName || ""}
                                    renderOption={(props, option) => (
                                      <div
                                        {...props}
                                        key={option?._id}
                                        style={{
                                          width: "100%",
                                          display: "inline-block",
                                        }}
                                      >
                                        <div
                                          style={{ fontSize: "13px" }}
                                          className="symbolFont pt-1"
                                        >
                                          {option?.shortName?.toString()}
                                        </div>
                                        <div style={{ fontSize: "11px", color: "gray" }}>
                                          <div
                                            className="mr-2 companyNameFont"
                                            style={{
                                              margin: "auto",
                                            }}
                                          >
                                            {option?.companyName?.toString()}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    renderInput={(params) => <TextField {...params} />}
                                    onClose={(event, reason) => {
                                      if (reason === "toggleInput") {
                                        field.onChange(null);
                                        // handleFilterSymbol(event, null);
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors?.tickSymbol && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors?.tickSymbol?.message}
                                </div>
                              )}
                            </div>
                            : null }
                            <div className="form-group col-md-12 mb-0">
                              <button type="submit" className="btn btn-outline-primary">
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>


                    <Box sx={{ width: "100%" }}>
                      {visibleRows?.length !== 0 ? (
                        <Paper
                          sx={{ width: "100%", mb: 2 }}
                          className="customTable"
                        >
                          <Toolbar
                            sx={{
                              pl: { sm: 2 },
                              pr: { xs: 1, sm: 1 },
                              ...(selected?.length > 0 && {
                                bgcolor: (theme) =>
                                  alpha(
                                    theme.palette.primary.main,
                                    theme.palette.action.activatedOpacity
                                  ),
                              }),
                            }}
                            className="p-1"
                          >
                            {selected?.length > 0 ? (
                              <Typography
                                sx={{ flex: "1 1 100%" }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                              >
                                {selected?.length} selected
                              </Typography>
                            ) : (
                              <Typography
                                sx={{ flex: "1 1 100%" }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                              >
                                Symbol
                              </Typography>
                            )}

                            {selected?.length > 0 ? (
                              <Tooltip title="Delete">
                                <IconButton onClick={handleMultiDelete}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <i className="feather icon-trending-up pr-3"></i>
                            )}
                          </Toolbar>
                          <TableContainer>
                            <Table
                              className="addTableFontColor"
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                            >
                              <EnhancedTableHead
                                onSelectAllClick={handleSelectAllClick}
                                order={order}
                                orderBy={orderBy}
                                numSelected={selected?.length}
                                onRequestSort={handleRequestSort}
                                rowCount={filterSymbol?.length}
                              />

                              <TableBody>
                                {visibleRows?.map((row, index) => {
                                  const isItemSelected = isSelected(row?._id);
                                  const labelId = `enhanced-table-checkbox-${index}`;
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row?._id}
                                      selected={isItemSelected}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          onClick={(event) =>
                                            handleClick(event, row?._id)
                                          }
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell>{row?.account_name}</TableCell>
                                      <TableCell>{row?.shortName}</TableCell>
                                      <TableCell>{row?.underlying_symbol}</TableCell>
                                      <TableCell>{row?.companyName}</TableCell>
                                      <TableCell>{row?.marketType}</TableCell>
                                      <TableCell>{row?.round_of_value}</TableCell>
                                      <TableCell>{row?.variation}</TableCell>
                                      <TableCell>{row?.lotSize}</TableCell>
                                      <TableCell>
                                        {row?.expiryDate
                                          ? row?.expiryDate
                                          : "-"}
                                      </TableCell>
                                      <TableCell>
                                        <Link
                                          type="submit"
                                          className="btn btn-outline-primary mr-2 m-0 editDeleteBtn"
                                          to={`/option-strategy-two-symbols/${row?._id}`}
                                        >
                                          <i className="feather icon-edit m-0"></i>
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filterSymbol?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                      ) : null}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionStrategyTwoSymbols;
