import { createSlice } from "@reduxjs/toolkit";

const symbolSlice = createSlice({
  name: "symbol",
  initialState: {
    auth: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    getSymbolsAccountsStart: (state) => {
      state.isFetching = false;
      state.symbols_accounts = null;
      state.error = false;
      state.errorMessage = "";
    },
    getSymbolsAccountsSuccess: (state, action) => {
      state.isFetching = true;
      state.symbols_accounts = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getSymbolsAccountsFailure: (state, error) => {
      state.isFetching = false;
      state.symbols_accounts = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    updateSymbolForAllStart: (state) => {
      state.isFetching = false;
      state.updated_symbol = null;
      state.error = false;
      state.errorMessage = "";
    },
    updateSymbolForAllSuccess: (state, action) => {
      state.isFetching = true;
      state.updated_symbol = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    updateSymbolForAllFailure: (state, error) => {
      state.isFetching = false;
      state.updated_symbol = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getFavSymbolsWithAccounts: (state, action) => {
      state.fav_symbols_with_accounts = action.payload;
    }
  },
  selectors: {
    getAllFavSymbolsWithAccounts: (state) => state.fav_symbols_with_accounts?.data ?? [],
  },
});

export const {
  getSymbolsAccountsStart,
  getSymbolsAccountsSuccess,
  getSymbolsAccountsFailure,
  updateSymbolForAllStart,
  updateSymbolForAllSuccess,
  updateSymbolForAllFailure,
  getFavSymbolsWithAccounts
} = symbolSlice.actions;

export const { getAllFavSymbolsWithAccounts } = symbolSlice.selectors;

export default symbolSlice.reducer;
