// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import moment from "moment";
import Swal from "sweetalert2";

// MUI IMPORTS
import { Button, Box, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip } from "@mui/material";
import { alpha } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../../main/SharedStateContext";
import NotFound from "../../../images/not_found.png";

// API IMPORTS
import { orderGet, trashOrderDelete, orderFilter, reOrder } from "../../../actions/orderAction";
import { symbolsAccountsGet } from "../../../actions/symbolAction";
import { getDistinctOrderStatus } from "../../../actions/orderAction";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "accountName",
    numeric: false,
    disablePadding: false,
    label: "Account",
  },
  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "orderId",
    numeric: true,
    disablePadding: false,
    label: "Order Id",
  },
  {
    id: "orderStatus",
    numeric: true,
    disablePadding: false,
    label: "Order Status",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "origQty",
    numeric: false,
    disablePadding: false,
    label: "Orig Qty",
  },
  {
    id: "side",
    numeric: false,
    disablePadding: false,
    label: "Side",
  },
  {
    id: "switchType",
    numeric: false,
    disablePadding: false,
    label: "Switch Type",
  },
  {
    id: "orderSide",
    numeric: false,
    disablePadding: false,
    label: "Order Side",
  },
  {
    id: "error",
    numeric: false,
    disablePadding: false,
    label: "Error",
  },
  {
    id: "mo_at",
    numeric: false,
    disablePadding: true,
    label: "Modified At",
  },
  {
    id: "setting",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell?.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell?.id ? order : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {orderBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const filterOptions = createFilterOptions({
  ignoreCase: true,
  limit: 100,
  matchFrom: "any",
  stringify: (option) => option?.companyName + option?.shortName,
});

function Order() {
  const { control, reset } = useForm({
    defaultValues: {},
  });

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderList, setOrderList] = useState([]);
  const [openModals, setOpenModals] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [filterSymbols, setFilterSymbols] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [filterParams, setFilterParams] = useState({
    companyName: "",
    accountName: "",
    symbol: "",
    orderId: "",
    origQty: "",
    price: "",
    orderStatus: "",
    side: "",
    switchType: "",
    orderSide: "",
  });

  const { store } = useContext(ReactReduxContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = orderList?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected?.indexOf(id) !== -1;

  const visibleRows = useMemo(
    () =>
      stableSort(orderList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, orderList]
  );

  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    setFilterParams();
    reset();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  const getOrderData = async () => {
    await orderGet(dispatch);
    if (store.getState()?.order?.orderList) {
      const order_data = store.getState()?.order?.orderList;

      const rows = order_data?.data?.map((transaction) => ({
        id: transaction?._id,
        mo_at: new Date(transaction?.mo_at).toLocaleString(),
        symbol: transaction?.symbol,
        clientId: transaction?.client,
        orderId: transaction?.orderId,
        accountId: transaction?.accountId,
        accountName: transaction?.accountName,
        orderStatus: transaction?.orderStatus,
        price: transaction?.price,
        origQty: transaction?.origQty,
        side: transaction?.side,
        switchType: transaction?.switchType,
        orderSide: transaction?.orderSide,
        errorMessage: transaction?.errorMessage,
      }));
      setOrderList(rows);
    }
  };

  const deleteSingleOrder = async (data) => {
    await trashOrderDelete(dispatch, data);
    if (store.getState()?.order?.trashOrderData) {
      Swal.fire("Deleted!", "Order deleted successfully.", "success");
      setPage(0);
      setRowsPerPage(10);
      getOrderData();
      setSelected([]);
    } else if (store.getState()?.order?.error) {
      toast.error(
        store.getState()?.order?.errorMessage
          ? store.getState()?.order?.errorMessage?.message
          : "Error deleting data. Please try again."
      );
    }
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete order?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
      cancelButtonColor: "#6c757d",
      cancelButtonText: "Cancel",
      showDenyButton: true,
      denyButtonColor: "#d33",
      denyButtonText: "Cancel with Delete",
      reverseButtons: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { ids: [id], status: 0 };
        await deleteSingleOrder(data)
      } else if (result.isDenied) {
        const data = { ids: [id], status: 1 };
        await deleteSingleOrder(data)
      }
    });
  };

  const handleFilterSymbol = (event, value) => {
    if (value !== null) {
      setFilterParams({ ...filterParams, symbol: value.shortName });
    }
  };

  const handleFilterChange = (e) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await orderFilter(dispatch, filterParams);
      const order_data = store.getState()?.order?.orderList;
      if (order_data) {
        const rows = order_data?.data?.map((transaction) => ({
          id: transaction?._id,
          mo_at: new Date(transaction?.mo_at).toLocaleString(),
          symbol: transaction?.symbol,
          clientId: transaction?.client,
          accountId: transaction?.accountId,
          accountName: transaction?.accountName,
          orderId: transaction?.orderId,
          orderStatus: transaction?.orderStatus,
          price: transaction?.price,
          origQty: transaction?.origQty,
          side: transaction?.side,
          switchType: transaction?.switchType,
          orderSide: transaction?.orderSide,
          status: transaction?.status,
          errorMessage: transaction?.errorMessage,
        }));
        setOrderList(rows);
        setPage(0);
      }
    } catch (error) {
      console.log("Error fetching stocks:", error);
    }
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const deleteMultipleOrder = async (data) => {
    await trashOrderDelete(dispatch, data);
    if (store.getState()?.order?.trashOrderData) {
      Swal.fire(
        "Deleted!",
        "All selected record deleted successfully.",
        "success"
      );
      getOrderData();
      distinctTrashOrderStutuses();
      setSelected([]);
    } else if (store.getState()?.order?.error) {
      toast.error(
        store.getState()?.order?.errorMessage
          ? store.getState()?.order?.errorMessage?.message
          : "Error deleting data. Please try again."
      );
    }
  }

  const handleMultiDelete = async () => {
    if (selected.length !== 0) {
      // delte multiple records
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to delete the selected ${selected?.length} record?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonColor: "#6c757d",
        cancelButtonText: "Cancel",
        showDenyButton: true,
        denyButtonColor: "#d33",
        denyButtonText: "Cancel with Delete",
        reverseButtons: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { ids: selected, status: 0 };
          await deleteMultipleOrder(data)
        } else if (result.isDenied) {
          const data = { ids: selected, status: 1 };
          await deleteMultipleOrder(data)
        }
      });
    }
  };

  const handleReorder = async (id, account_id, client_id) => {
    await reOrder(dispatch, id, account_id, client_id);
    if (store.getState()?.order?.orderData) {
      toast.success("Order placed successfully!");
      setOpenModals({});
      getOrderData();
    } else if (store.getState()?.order?.error) {
      setOpenModals({});
      toast.error(
        store.getState()?.order?.errorMessage
          ? store.getState()?.order?.errorMessage?.error
          : "Error will Re-order. Please try again."
      );
    }
  };

  const handleOpenModal = (rowId) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [rowId]: true,
    }));
  };

  const handleCloseModal = (rowId) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [rowId]: false,
    }));
  };

  const handleReset = () => {
    setFilterParams({});
    getOrderData();
    reset();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const getUniqueData = (data) => {
    const seen = new Set();
    return data.filter(item => {
      const identifier = `${item.shortName}-${item.exchange}`;
      if (seen.has(identifier)) {
        return false;
      }
      seen.add(identifier);
      return true;
    });
  };


  const getSymbolsAccounts = async () => {
    await symbolsAccountsGet(dispatch);
    const response = store.getState()?.symbol?.symbols_accounts?.data
    if (response) {
      setFilterData(response);
      const getUniqueSymbols = getUniqueData(response?.symbols || [])
      setFilterSymbols(getUniqueSymbols)
    }
  };

  const distinctTrashOrderStutuses = async () => {
    await getDistinctOrderStatus(dispatch);
    if (store.getState()?.order?.distinct_order_status) {
      setOrderStatus(store.getState()?.order?.distinct_order_status?.order_statuses);
    }
  }

  useEffect(() => {
    getOrderData();
    getSymbolsAccounts();
    distinctTrashOrderStutuses();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="Main">
      <div className="container-fluid">
        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
          <div className="pt-4 sd-header">
            <Button className="adminbackBtn" onClick={ToggleSidebar}>
              <i className="feather icon-chevron-right"></i>
            </Button>
            <h3 className="mt-1">Filters</h3>
          </div>
          <div className="sd-body">
            <form>
              <div className="FilterForm">
                <div className="form-group">
                  <label>Account Name</label>
                  <select
                    className="form-control"
                    name="accountName"
                    value={filterParams?.accountName || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {filterData?.accounts?.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Symbol Full Name</label>
                  <Controller
                    name="shortName"
                    control={control}
                    rules={{ required: "Symbol is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        {...field}
                        filterOptions={filterOptions}
                        value={
                          filterSymbols
                            ? filterSymbols?.find(
                              (option) =>
                                option.shortName === (field.value || "")
                            ) || null
                            : null
                        }
                        options={filterSymbols ? filterSymbols : []}
                        onChange={(event, value) => {
                          field.onChange(value?.shortName || "");
                          if (value) {
                            handleFilterSymbol(event, value);
                          }
                        }}
                        getOptionLabel={(option) => option?.shortName || ""}
                        renderOption={(props, option) => (
                          <div
                            {...props}
                            key={option?.id}
                            style={{
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <div
                              style={{ fontSize: "13px" }}
                              className="symbolFont pt-1"
                            >
                              {option?.shortName?.toString()}
                            </div>
                            <div style={{ fontSize: "11px", color: "gray" }}>
                              <div
                                className="mr-2 companyNameFont"
                                style={{
                                  margin: "auto",
                                }}
                              >
                                {option?.companyName?.toString()}
                              </div>
                            </div>
                          </div>
                        )}
                        renderInput={(params) => <TextField {...params} />}
                        onClose={(event, reason) => {
                          if (reason === "toggleInput") {
                            field.onChange(null);
                            handleFilterSymbol(event, null);
                          }
                        }}
                      />
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Order Id</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Order Id"
                    name="orderId"
                    value={filterParams?.orderId || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Orig Qty</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Orig Qty"
                    name="origQty"
                    value={filterParams?.origQty || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Price</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Price"
                    name="price"
                    value={filterParams?.price || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Order Status</label>
                  <select
                    className="form-control"
                    name="orderStatus"
                    value={filterParams?.orderStatus || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {orderStatus?.map((data, index) => {
                      return (
                        <option key={index}>{data}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Side</label>
                  <select
                    className="form-control"
                    name="side"
                    value={filterParams?.side || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Switch Type</label>
                  <select
                    className="form-control"
                    name="switchType"
                    value={filterParams?.switchType || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option>Buy</option>
                    <option>Sell</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Order Side</label>
                  <select
                    className="form-control"
                    name="orderSide"
                    value={filterParams?.orderSide || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option>Buy Completed</option>
                    <option>Sell Completed</option>
                    <option>Completed</option>
                    <option>Pending</option>
                  </select>
                </div>
              </div>
              <div className="d-flex pt-3 pb-4 applyChangeBtn">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleSubmit}
                >
                  Apply Changes
                </button>
                <button
                  type="button"
                  className="btn btn-square btn-danger mr-0"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/order" style={{ fontWeight: 600 }}>
                            Orders
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">List</h3>
                          <h4 style={{ marginTop: "6px" }}>
                            <span className="badge badge-secondary">
                              {orderList?.length}
                            </span>
                          </h4>
                        </div>
                        <div className="orderCreateButton">
                          <button
                            type="button"
                            className="btn btn-square btn-primary"
                            onClick={() => navigate("/order-create")}
                          >
                            <i className="feather icon-plus"></i> Create New
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary mr-0"
                            onClick={ToggleSidebar}
                          >
                            Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  <Box sx={{ width: "100%" }}>
                    {visibleRows.length !== 0 ? (
                      <Paper
                        sx={{ width: "100%", mb: 2 }}
                        className="customTable"
                      >
                        <Toolbar
                          sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            ...(selected?.length > 0 && {
                              bgcolor: (theme) =>
                                alpha(
                                  theme.palette.primary.main,
                                  theme.palette.action.activatedOpacity
                                ),
                            }),
                          }}
                          className="p-1"
                        >
                          {selected?.length > 0 ? (
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              color="inherit"
                              variant="subtitle1"
                              component="div"
                            >
                              {selected?.length} selected
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              variant="h6"
                              id="tableTitle"
                              component="div"
                            >
                              Orders
                            </Typography>
                          )}

                          {selected?.length > 0 ? (
                            <Tooltip title="Delete">
                              <IconButton onClick={handleMultiDelete}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <i className="feather icon-trending-up pr-3"></i>
                          )}
                        </Toolbar>
                        <TableContainer>
                          <Table
                            className="addTableFontColor"
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead
                              numSelected={selected?.length}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={orderList?.length}
                            />
                            <TableBody>
                              {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onClick={(event) =>
                                          handleClick(event, row.id)
                                        }
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell>{row?.accountName}</TableCell>
                                    <TableCell>{row?.symbol}</TableCell>
                                    <TableCell>{row?.orderId}</TableCell>
                                    <TableCell>{row?.orderStatus}</TableCell>
                                    <TableCell>{row?.price}</TableCell>
                                    <TableCell>{row?.origQty}</TableCell>
                                    <TableCell
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {row?.side}{" "}
                                    </TableCell>
                                    <TableCell>{row?.switchType}</TableCell>
                                    <TableCell>
                                      <span className="badge badge-secondary stockStatus">
                                        {row?.orderSide}
                                      </span>
                                    </TableCell>
                                    {row?.errorMessage ? (
                                      <TableCell
                                        style={{ paddingLeft: "30px" }}
                                        onClick={() => handleOpenModal(row.id)}
                                      >
                                        <i
                                          className="feather icon-alert-circle"
                                          style={{ color: "ef4c25" }}
                                        />
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        style={{ paddingLeft: "30px" }}
                                      >
                                        -
                                      </TableCell>
                                    )}
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                    >
                                      {moment(row?.mo_at?.toString()).format("DD/MM/YY HH:mm:ss")}
                                    </TableCell>
                                    <TableCell>
                                      <Link
                                        type="submit"
                                        className="btn btn-outline-primary mr-2 m-0 editDeleteBtn"
                                        to={`/order-edit/${row?.id}`}
                                      >
                                        <i className="feather icon-edit m-0"></i>
                                      </Link>
                                      <button
                                        type="submit"
                                        className="btn btn-outline-danger m-0 editDeleteBtn"
                                        onClick={() => handleDelete(row?.id)}
                                      >
                                        <i className="feather icon-trash-2 m-0"></i>
                                      </button>
                                    </TableCell>
                                    <Modal
                                      open={!!openModals[row.id]}
                                      onClose={() => handleCloseModal(row.id)}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                      className="errorModal"
                                    >
                                      <Box sx={style}>
                                        <Typography
                                          id={`modal-modal-title-${row.id}`}
                                          variant="h6"
                                          component="h2"
                                        >
                                          Error Message From Order
                                        </Typography>
                                        <Typography
                                          id={`modal-modal-description-${row.id}`}
                                          sx={{ mt: 2 }}
                                        >
                                          {row?.errorMessage}
                                        </Typography>
                                        <Typography className="pt-4">
                                          <Button
                                            variant="outlined"
                                            onClick={() =>
                                              handleReorder(
                                                row?.orderId,
                                                row?.accountId,
                                                row?.clientId
                                              )
                                            }
                                          >
                                            Re Order
                                          </Button>
                                        </Typography>
                                      </Box>
                                    </Modal>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={orderList?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <div className="text-center">
                        <img
                          src={NotFound}
                          alt="Not Found"
                          className="notFoundImg"
                        />
                      </div>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Order;
