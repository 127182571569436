// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import moment from "moment";
import Swal from "sweetalert2";


// COMPONENT IMPORT
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import NotFound from "../../../images/not_found.png";
import { SharedStateProvider } from "../../main/SharedStateContext";


// MUI IMPORTS
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography, Paper, Checkbox, Modal, Switch, useMediaQuery, Button } from "@mui/material";
import { visuallyHidden } from "@mui/utils";


// API IMPORTS
import { allClientGet, activateUserPut, clientFilter, clientDelete, backupDb, SendOtpForLiveDB, updateSwitchTime, getSwitchTime } from "../../../actions/authAction";
import { getExchangeProductAccounts, updateUserAccess, getUseAccessById } from "../../../actions/exchangeAction";
import { uploadNewSymbols, getCronLastRunTime } from "../../../actions/settingAction"


function descendingComparator(a, b, userBY) {
  if (b[userBY] < a[userBY]) {
    return -1;
  }
  if (b[userBY] > a[userBY]) {
    return 1;
  }
  return 0;
}


function getComparator(user, userBY) {
  return user === "desc"
    ? (a, b) => descendingComparator(a, b, userBY)
    : (a, b) => -descendingComparator(a, b, userBY);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const user = comparator(a[0], b[0]);
    if (user !== 0) {
      return user;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}


const headCells = [
  {
    id: "cr_at",
    numeric: false,
    disablePadding: true,
    label: "Created At",
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "password",
    numeric: true,
    disablePadding: false,
    label: "Password",
  },
  {
    id: "is_verified",
    numeric: true,
    disablePadding: false,
    label: "Verified",
  },
  {
    id: "is_active",
    numeric: true,
    disablePadding: false,
    label: "Active",
  },
  {
    id: "setting",
    numeric: false,
    disablePadding: false,
    label: "Roles",
  },
];


function EnhancedTableHead(props) {
  const { user, userBY, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            style={{ paddingLeft: "10px" }}
            key={index}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={userBY === headCell?.id ? user : false}
          >
            <TableSortLabel
              active={userBY === headCell?.id}
              direction={userBY === headCell?.id ? user : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {userBY === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {user === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  user: PropTypes.oneOf(["asc", "desc"]).isRequired,
  userBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function Users() {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [user, setUser] = useState("desc");
  const [userBy, setUserBy] = useState("cr_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userList, setUserList] = useState([]);
  const [openModals, setOpenModals] = useState({});
  const [exchangeProduct, setExchangeProduct] = useState({});
  const [exchanges, setExchanges] = useState([]);
  const [products, setProducts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [cronTimes, setCronTimes] = useState({});


  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: isMobile ? 350 : 1200,
    maxWidth: 1200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    display: "block",
    height: "90%"
  };


  const handleRequestSort = (event, property) => {
    const isAsc = userBy === property && user === "asc";
    setUser(isAsc ? "desc" : "asc");
    setUserBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const visibleRows = useMemo(
    () =>
      stableSort(userList, getComparator(user, userBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [user, userBy, page, rowsPerPage, userList]
  );


  const [isOpen, setIsopen] = useState(false);


  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };


  const getClients = async () => {
    await allClientGet(dispatch).then((res) => {
      const client_data = res?.data;
      const rows = client_data?.map((record) => ({
        id: record?.client,
        cr_at: new Date(record?.cr_at).toLocaleString(),
        username: record?.username,
        email: record?.email,
        password: record?.password,
        is_verified: record?.is_verified,
        is_active: record?.is_active,
      }));
      setUserList(rows);
    }).catch((error) => {
      toast.error("Clients not found")
    })
  };


  const getExchangeProduct = async () => {
    await getExchangeProductAccounts(dispatch).then((res) => {
      setExchangeProduct(res?.data);
    }).catch((error) => { })
  };


  const getExchangeProductById = async (id) => {
    await getUseAccessById(dispatch, id).then((res) => {
      setExchanges(res?.data?.exchange || []);
      setProducts(res?.data?.product || []);
      setAccounts(res?.data?.account || []);
      setActiveAccounts(res?.data?.active_account || []);
    }).catch((error) => {
      toast.error("User access data not found")
    })
  };


  const handleOpenModal = (rowId) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [rowId]: true,
    }));
    getExchangeProductById(rowId);
  };


  const handleCloseModal = (rowId) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [rowId]: false,
    }));
  };


  const handleExchangeCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setExchanges([...exchanges, value]);
    } else {
      setExchanges(exchanges?.filter((exchange) => exchange !== value));
    }
  };


  const handleProductCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setProducts([...products, value]);
    } else {
      setProducts(products?.filter((product) => product !== value));
    }
  };


  const handleAccountCheckboxChange = (event, account_key) => {
    setAccounts(accounts => ({
      ...accounts,
      [account_key]: event.target.checked
    }));
  };


  const handleSave = async (id) => {
    const response = { client: id, exchange: exchanges, product: products, account: accounts };
    await updateUserAccess(dispatch, response).then((res) => {
      toast.success("Data saved successfully!");
      handleCloseModal(id);
    }).catch((error) => {
      toast.error("Data not saved, Something want wrong");
    })
  };


  const handleFilterChange = (e) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFilter = async () => {
    try {
      if (filterParams) {
        await clientFilter(dispatch, filterParams);
        const client_data = store.getState()?.auth?.client_filter;
        if (client_data) {
          const rows = client_data?.data?.map((record) => ({
            id: record?.client,
            cr_at: new Date(record?.cr_at).toLocaleString(),
            username: record?.username,
            email: record?.email,
            password: record?.password,
            is_verified: record?.is_verified,
            is_active: record?.is_active,
          }));
          setUserList(rows);
        }
      }
    } catch (error) {
      console.log("Error fetching stocks:", error);
    }
  };

  const handleReset = () => {
    setFilterParams({});
    getClients();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const handleActiveUser = async (event, id) => {
    await activateUserPut(dispatch, id, { is_active: event.target.checked });
    if (store.getState()?.auth?.client_active) {
      if (event.target.checked === false) {
        toast.success("User Activate!");
      } else if (event.target.checked === true) {
        toast.success("User Deavtivate!");
      }
      getClients();
    } else {
      toast.error("Data not saved, Something want wrong");
    }
  };

  const handleUserDelete = async (id, email) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await clientDelete(dispatch, id, { email: email });
        if (store.getState()?.auth?.clientData) {
          Swal.fire("Deleted!", "User deleted successfully.", "success");
          getClients();
        } else {
          toast.warning("User not deleted!");
        }
      }
    });
  };

  const handleBackupDb = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to Backup Database?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Backup Database",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await backupDb(dispatch);
        if (store.getState()?.auth?.backup_db) {
          Swal.fire("Done!", "Databse Backup Successfull", "success");
        } else {
          toast.warning("Databse Backup Not Done");
        }
      }
    });
  };

  const handleLiveToLocal = async () => {
    navigate("/otp-verify");
    await SendOtpForLiveDB(dispatch);
    if (store.getState()?.auth?.otp_verify) {
      toast.success("Please Verify OTP, OTP Successfully Sended To Main Admin");
    } else {
      toast.error("OTP not sended to mail, Please try again");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleCron = async (broker) => {
    await uploadNewSymbols(dispatch, broker)
    if (store.getState()?.setting?.upload_new_symbols?.message) {
      toast.success("Symbol updated successfully");
    } else {
      toast.error("Something went wrong");
    }
    getAllCronLastRunTIme();
  }

  const getAllCronLastRunTIme = async () => {
    await getCronLastRunTime(dispatch)
    if (store.getState()?.setting?.cron_last_run_times) {
      setCronTimes(store.getState()?.setting?.cron_last_run_times)
    } else {
      toast.error("Something went wrong");
    }
  }

  const getSwitchOnOffTime = async () => {
    await getSwitchTime().then((res) => {
      setValue("start_time", res?.data?.start_time)
      setValue("end_time", res?.data?.end_time)
    }).catch((error) => { })
  }

  const onSubmit = (data) => {
    updateSwitchTime(data).then((res) => {
      toast.success("Swtch time updated successfully")
    }).catch((error) => {
      toast.error("Switch time not updated due to some error")
    })
  }


  const isChecked = (account_key) => {
    return accounts[account_key] || false;
  };


  useEffect(() => {
    getClients();
    getExchangeProduct();
    getAllCronLastRunTIme();
    getSwitchOnOffTime();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="Main">
      <div className="container-fluid">
        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
          <div className="pt-4 sd-header">
            <Button className="adminbackBtn" onClick={ToggleSidebar}>
              <i className="feather icon-chevron-right"></i>
            </Button>
            <h3 className="mt-1">Filters</h3>
          </div>
          <div className="sd-body">
            <form>
              <div className="FilterForm">
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Username"
                    defaultValue=""
                    name="username"
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Email"
                    defaultValue=""
                    name="email"
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Verified</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Verified"
                    defaultValue=""
                    name="is_verified"
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Active</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Active"
                    defaultValue=""
                    name="is_active"
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <div className="d-flex pt-3 pb-4 applyChangeBtn">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleFilter}
                >
                  Apply Changes
                </button>
                <button
                  type="button"
                  className="btn btn-square btn-danger mr-0"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>

      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>

      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="page-header-title d-flex justify-content-between pb-3 dashboardHeader">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">Total User</h3>
                          <h4 style={{ marginTop: "6px" }}>
                            <span className="badge badge-secondary">
                              {userList?.length}
                            </span>
                          </h4>
                        </div>
                        <div className="orderCreateButton">
                          <button
                            type="button"
                            className="btn mr-3"
                            style={{
                              backgroundColor: "rgb(17 187 145)",
                              color: "white",
                            }}
                            onClick={handleLiveToLocal}
                          >
                            Live to Local
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary mr-3 "
                            onClick={handleBackupDb}
                          >
                            Backup Database
                          </button>

                          <button
                            type="button"
                            className="btn btn-outline-primary mr-0"
                            onClick={ToggleSidebar}
                          >
                            Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="main-body">
                <div className="page-wrapper">
                  <Box sx={{ width: "100%" }}>
                    {visibleRows.length !== 0 ? (
                      <Paper
                        sx={{ width: "100%", mb: 2 }}
                        className="customTable"
                      >
                        <TableContainer>
                          <Table
                            className="addTableFontColor userTable"
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead
                              user={user}
                              userBy={userBy}
                              onRequestSort={handleRequestSort}
                              rowCount={userList?.length}
                            />
                            <TableBody>
                              {visibleRows?.map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    key={index}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell
                                      style={{ paddingLeft: "10px" }}
                                      component="th"
                                      scope="row"
                                      padding="none"
                                    >
                                      {moment(row?.cr_at).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </TableCell>
                                    <TableCell>{row?.username}</TableCell>
                                    <TableCell>{row?.email}</TableCell>
                                    <TableCell>{row?.password}</TableCell>
                                    <TableCell>
                                      {row?.is_verified === true ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell>
                                      <Switch
                                        {...label}
                                        onChange={(event) =>
                                          handleActiveUser(event, row?.id)
                                        }
                                        checked={
                                          row?.is_active === true ? true : false
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <button
                                        className="btn btn-outline-primary"
                                        style={{ padding: "7px 15px" }}
                                        onClick={() => handleOpenModal(row?.id)}
                                      >
                                        <i className="feather icon-align-justify m-0"></i>
                                      </button>
                                      <button
                                        className="btn btn-outline-danger"
                                        style={{ padding: "7px 15px" }}
                                        onClick={() =>
                                          handleUserDelete(row?.id, row?.email)
                                        }
                                      >
                                        <i className="feather icon-trash m-0"></i>
                                      </button>
                                    </TableCell>
                                    <Modal
                                      open={!!openModals[row?.id]}
                                      onClose={() => handleCloseModal(row?.id)}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                      className="activeUserModal"
                                    >
                                      <Box sx={style}>
                                        <Typography
                                          id={`modal-modal-title-${row?.id}`}
                                          variant="h6"
                                          component="h3"
                                          className="roleHeader"
                                        >
                                          Change roles for user :-{" "}
                                          {row?.username} <span className="mx-3">||</span> Email :- {row?.email}
                                        </Typography>

                                        <div className="row dynamicForm">

                                          <div className="card col-md-3 mobileCard">
                                            <div className="card-body mobileCardBody">
                                              <div className="col-md-12">
                                                <div className="d-flex justify-content-between pb-4">
                                                  <h3
                                                    className="m-0"
                                                    style={{ color: "teal" }}
                                                  >
                                                    Exchange Master
                                                  </h3>
                                                </div>

                                                <div
                                                  className="form-group d-flex"
                                                  key={index}
                                                >
                                                  <ul className="exchangeProduct">
                                                    {exchangeProduct?.exchange
                                                      ?.length !== 0
                                                      ? exchangeProduct?.exchange?.map(
                                                        (data, index) => {
                                                          return (
                                                            <div
                                                              key={index}
                                                              style={{
                                                                display:
                                                                  "flex",
                                                                paddingBottom:
                                                                  "10px",
                                                              }}
                                                            >
                                                              <Checkbox
                                                                value={data}
                                                                onChange={
                                                                  handleExchangeCheckboxChange
                                                                }
                                                                color="primary"
                                                                checked={exchanges?.includes(
                                                                  data
                                                                )}
                                                              />
                                                              <li
                                                                style={{
                                                                  display:
                                                                    "block",
                                                                }}
                                                              >
                                                                {data}
                                                              </li>
                                                              <br />
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                      : null}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="card col-md-3 mobileCard">
                                            <div className="card-body mobileCardBody">
                                              <div className="col-md-12">
                                                <div className="d-flex justify-content-between pb-4">
                                                  <h3
                                                    className="m-0"
                                                    style={{ color: "teal" }}
                                                  >
                                                    Product Master
                                                  </h3>
                                                </div>

                                                <div
                                                  className="form-group d-flex scrollableAccount"
                                                  key={index}
                                                >
                                                  <ul className="exchangeProduct">
                                                    {exchangeProduct?.product
                                                      ?.length !== 0
                                                      ? exchangeProduct?.product?.map(
                                                        (data, index) => {
                                                          return (
                                                            <div
                                                              key={index}
                                                              style={{
                                                                display:
                                                                  "flex",
                                                                paddingBottom:
                                                                  "10px",
                                                              }}
                                                            >
                                                              <Checkbox
                                                                value={data}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleProductCheckboxChange(
                                                                    e
                                                                  );
                                                                }}
                                                                color="primary"
                                                                checked={products?.includes(
                                                                  data
                                                                )}
                                                              />
                                                              <li
                                                                style={{
                                                                  display:
                                                                    "block",
                                                                }}
                                                              >
                                                                {data}
                                                              </li>
                                                              <br />
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                      : null}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="card col-md-5 mobileCard">
                                            <div className="card-body mobileCardBody">
                                              <div className="col-md-12">
                                                <div className="d-flex justify-content-between pb-4">
                                                  <h3
                                                    className="m-0"
                                                    style={{ color: "teal" }}
                                                  >
                                                    Account Master
                                                  </h3>
                                                </div>

                                                <div
                                                  className="form-group d-flex scrollableAccount"
                                                  key={index}
                                                >
                                                  <ul className="exchangeProduct">
                                                    {exchangeProduct?.accounts?.length !== 0
                                                      ? exchangeProduct?.accounts?.filter(data => activeAccounts?.includes(Object.keys(data)[0]))
                                                        .map((data, index) => {
                                                          const account_key = Object.keys(data)[0];
                                                          const value = Object.values(data)[0];
                                                          return (
                                                            <div
                                                              key={index}
                                                              style={{
                                                                display: "flex",
                                                                paddingBottom: "10px",
                                                              }}
                                                            >
                                                              <Checkbox
                                                                value={account_key}
                                                                onChange={(e) => handleAccountCheckboxChange(e, account_key)}
                                                                color="primary"
                                                                checked={isChecked(account_key)}
                                                              />
                                                              <li
                                                                style={{
                                                                  display: "block",
                                                                }}
                                                              >
                                                                {value}
                                                              </li>
                                                              <br />
                                                            </div>
                                                          );
                                                        })
                                                      : null}
                                                  </ul>

                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                        <div className="text-center adminPopUpButtons">
                                          <button
                                            type="submit"
                                            className="btn btn-outline-primary ml-3 px-5"
                                            onClick={() => handleSave(row?.id)}
                                          >
                                            Save
                                          </button>
                                          <button
                                            type="submit"
                                            className="btn btn-outline-danger px-5"
                                            onClick={() =>
                                              handleCloseModal(row?.id)
                                            }
                                          >
                                            Close
                                          </button>
                                        </div>
                                      </Box>
                                    </Modal>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={userList?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <div className="text-center">
                        <img
                          src={NotFound}
                          alt="Not Found"
                          className="notFoundImg"
                        />
                      </div>
                    )}
                  </Box>
                </div>
              </div>

              <div className="col-md-12 p-0 d-flex">
                <div className="main-body col-md-6 p-0">
                  <div className="page-wrapper">
                    <div className="card my-2 mr-2 p-4">
                      <h5>Add Account Switch Start/End time (Add in 24 Hr Format)</h5>
                      <hr></hr>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-12 p-0 d-flex">
                          <label className="mr-3" style={{ margin: "auto", textAlign: "center", marginLeft: 0 }}>Start Time (Ex: 07:00) :-</label>
                          <input
                            type="text"
                            name="start_time"
                            className={`form-control col-md-5 ${errors.start_time ? 'is-invalid' : ''}`}
                            placeholder="Start Time"
                            {...register('start_time', {
                              required: 'Start time is required',
                              pattern: {
                                value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                                message: 'Invalid time format (HH:mm)'
                              }
                            })}
                          />
                        </div>
                        {errors.start_time && <span className="invalid-feedback">{errors.start_time.message}</span>}
                        <div className="col-md-12 p-0 d-flex mt-3">
                          <label className="mr-4" style={{ margin: "auto", textAlign: "center", marginLeft: 0 }}>End Time (Ex: 15:30) :- </label>
                          <input
                            type="text"
                            name="end_time"
                            className={`form-control col-md-5 ${errors.end_time ? 'is-invalid' : ''}`}
                            placeholder="End Time"
                            {...register('end_time', {
                              required: 'End time is required',
                              pattern: {
                                value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                                message: 'Invalid time format (HH:mm)'
                              }
                            })}
                          />
                        </div>
                        {errors.end_time && <span className="invalid-feedback">{errors.end_time.message}</span>}
                        <div className="text-center">
                          <button type="submit" className="btn btn-outline-primary mt-3" style={{ width: "100px" }}>Save</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="main-body col-md-6 p-0">
                  <div className="page-wrapper">
                    <div className="card my-2 ml-2 pt-4 pb-4">
                      <div className="col-md-12">
                        <label className="col-md-8">Start ICICI Cron ({cronTimes?.ICICI ? (moment(cronTimes?.ICICI).format('DD MMMM YYYY HH:mm:ss')) : "-"}) </label>
                        <button className="col-md-2 btn btn-outline-primary ml-3" onClick={() => handleCron("ICICI")}>Start</button>
                      </div>
                      <div className="col-md-12">
                        <label className="col-md-8">Start FYERS Cron ({cronTimes?.FYERS ? (moment(cronTimes?.FYERS).format('DD MMMM YYYY HH:mm:ss')) : "-"}) </label>
                        <button className="col-md-2 btn btn-outline-primary ml-3" onClick={() => handleCron("FYERS")}>Start</button>
                      </div>
                      <div className="col-md-12">
                        <label className="col-md-8">Start ANGEL Cron ({cronTimes?.ANGEL ? (moment(cronTimes?.ANGEL).format('DD MMMM YYYY HH:mm:ss')) : "-"}) </label>
                        <button className="col-md-2 btn btn-outline-primary ml-3" onClick={() => handleCron("ANGEL")}>Start</button>
                      </div>
                      <div className="col-md-12">
                        <label className="col-md-8">Start UPSTOX Cron ({cronTimes?.UPSTOX ? (moment(cronTimes?.UPSTOX).format('DD MMMM YYYY HH:mm:ss')) : "-"}) </label>
                        <button className="col-md-2 btn btn-outline-primary ml-3" onClick={() => handleCron("UPSTOX")}>Start</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Users;
