import React, { createContext, useState } from "react";

// Create a context
const SharedStateContext = createContext();

// Provider component to wrap around the components needing shared state
const SharedStateProvider = ({ children }) => {
  const [mobileSliderStatus, setMobileSliderStatus] = useState(false);

  return (
    <SharedStateContext.Provider
      value={{ mobileSliderStatus, setMobileSliderStatus }}
    >
      {children}
    </SharedStateContext.Provider>
  );
};

export { SharedStateContext, SharedStateProvider };
