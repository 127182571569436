// // BASIC IMPORTS
// import React, { useEffect, useState } from 'react';
// import { useDispatch } from "react-redux";

// // MUI IMPORTS
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Toolbar, Box, Typography } from '@mui/material';

// // FILE IMPORTS
// import { SharedStateProvider } from '../SharedStateContext';
// import SideBar from '../../layout/Sidebar';
// import Header from '../../layout/Header';

// // API IMPORTS
// import { cycleDataGet } from '../../../actions/tradingAction';


// const CycleReport = () => {
//     const [data, setData] = useState([]);
//     const [tableData, setTableData] = useState([]);
//     const [accountFilter, setAccountFilter] = useState('');
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(10);

//     const dispatch = useDispatch();

//     useEffect(() => {
//         const fetchData = async () => {
//             const start_date = "1000-01-01"
//             const end_date = "9999-01-01"
//             await cycleDataGet(dispatch, start_date, end_date).then((res) => {
//                 setData(res?.data);
//                 processTableData(res?.data);
//             }).catch((error) => {})
//         };
//         fetchData();
//         // eslint-disable-next-line
//     }, []);

//     const getUniqueMonths = () => {
//         const months = new Set();
//         data?.forEach(entry => {
//             const date = new Date(entry?.date);
//             const monthYear = `${date?.getMonth() + 1}/${date?.getFullYear().toString().slice(-2)}`;
//             months.add(monthYear);
//         });
//         return Array.from(months);
//     };

//     const uniqueMonths = getUniqueMonths();

//     const processTableData = async (data) => {
//         const months = new Set();
//         data?.forEach(entry => {
//             const date = new Date(entry?.date);
//             const monthYear = `${date?.getMonth() + 1}/${date?.getFullYear().toString().slice(-2)}`;
//             months.add(monthYear);
//         });
//         var uniqueMonths = Array.from(months);

//         const symbolExchangeAccountData = {};

//         data?.forEach(entry => {
//             const date = new Date(entry?.date);
//             const monthYear = `${date?.getMonth() + 1}/${date?.getFullYear().toString().slice(-2)}`;

//             const key = `${entry?.symbol}-${entry?.exchange}-${entry?.accountName}`;
//             if (!symbolExchangeAccountData[key]) {
//                 symbolExchangeAccountData[key] = {
//                     id: key,
//                     symbol: entry?.symbol,
//                     exchange: entry?.exchange,
//                     accountName: entry?.accountName,
//                     cycles: {}
//                 };
//             }

//             if (!symbolExchangeAccountData[key].cycles[monthYear]) {
//                 symbolExchangeAccountData[key].cycles[monthYear] = 0;
//             }

//             symbolExchangeAccountData[key].cycles[monthYear] += entry?.total_cycle_count;
//         });

//         const result = Object.values(symbolExchangeAccountData).map(symbolEntry => {
//             const totalCycles = uniqueMonths?.map(month => symbolEntry?.cycles[month] || 0);
//             const totalCycleSum = totalCycles?.reduce((acc, cycle) => acc + cycle, 0);

//             const cyclesObj = uniqueMonths.reduce((obj, month, index) => {
//                 obj[month] = totalCycles[index];
//                 return obj;
//             }, {});

//             return {
//                 ...symbolEntry,
//                 ...cyclesObj,
//                 totalCycleSum
//             };
//         });
//         setTableData(result);
//     };

    
//     const handleAccountChange = (event) => {
//         const accountValue = event.target.value;
//         setAccountFilter(accountValue);

//         const filtered = data.filter(entry =>
//             accountValue ? entry?.accountName === accountValue : true
//         );
//         console.log("filtered :: ", filtered);
//         processTableData(filtered);
//         setPage(0); // Reset page to 0 when filters change
//     };

//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//     };

//     const getUniqueAccounts = () => {
//         const accounts = new Set(data?.map(entry => entry?.accountName));
//         return Array.from(accounts);
//     };

//     const uniqueAccounts = getUniqueAccounts();

//     const paginatedData = tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//     return (
//         <div className="Main">
//             <SharedStateProvider>
//                 <SideBar />
//                 <Header />
//             </SharedStateProvider>

//             <section className="pcoded-main-container">
//                 <div className="pcoded-wrapper">
//                     <div className="pcoded-content">
//                         <div className="pcoded-inner-content">
//                             <div className="Main">
//                                 <div className="main-body">
//                                     <Box sx={{ width: "100%" }}>
//                                         <Paper
//                                             sx={{ width: "100%", mb: 2 }}
//                                             className="customTable"
//                                         >
//                                             <Toolbar
//                                                 sx={{
//                                                     pl: { sm: 2 },
//                                                     pr: { xs: 1, sm: 1 }
//                                                 }}
//                                                 className="p-1"
//                                             >
//                                                 <Typography
//                                                     sx={{ flex: "1 1 100%" }}
//                                                     variant="h6"
//                                                     id="tableTitle"
//                                                     component="div"
//                                                 >
//                                                     Monthly Cycle Report
//                                                 </Typography>
//                                                 <select
//                                                     className="form-control"
//                                                     name="account"
//                                                     style={{ width: "20%" }}
//                                                     value={accountFilter}
//                                                     onChange={handleAccountChange}
//                                                 >
//                                                     <option value="" disabled>
//                                                         Select Account
//                                                     </option>
//                                                     <option value="">All</option>
//                                                     {uniqueAccounts?.map(account => (
//                                                         <option key={account} value={account}>{account}</option>
//                                                     ))}
//                                                 </select>

//                                             </Toolbar>

//                                             <TableContainer component={Paper}>
//                                                 <Table>
//                                                     <TableHead>
//                                                         <TableRow>
//                                                             <TableCell>Symbol</TableCell>
//                                                             <TableCell>Exchange</TableCell>
//                                                             <TableCell>Account Name</TableCell>
//                                                             {uniqueMonths?.map(month => (
//                                                                 <TableCell key={month}>{month}</TableCell>
//                                                             ))}
//                                                         </TableRow>
//                                                     </TableHead>
//                                                     <TableBody>
//                                                         {paginatedData?.map((row, index) => (
//                                                             <TableRow
//                                                                 key={index}
//                                                                 sx={{ backgroundColor: index % 2 === 1 ? 'rgba(0, 0, 0, 0.04)' : 'inherit' }}
//                                                             >
//                                                                 <TableCell>{row?.symbol}</TableCell>
//                                                                 <TableCell>{row?.exchange}</TableCell>
//                                                                 <TableCell>{row?.accountName}</TableCell>
//                                                                 {uniqueMonths?.map(month => (
//                                                                     <TableCell key={month}>{row[month] || 0}</TableCell>
//                                                                 ))}
//                                                             </TableRow>
//                                                         ))}
//                                                     </TableBody>
//                                                 </Table>
//                                             </TableContainer>
//                                             <TablePagination
//                                                 rowsPerPageOptions={[5, 10, 25]}
//                                                 component="div"
//                                                 count={tableData?.length}
//                                                 rowsPerPage={rowsPerPage}
//                                                 page={page}
//                                                 onPageChange={handleChangePage}
//                                                 onRowsPerPageChange={handleChangeRowsPerPage}
//                                                 style={{ marginTop: '16px' }}
//                                             />

//                                         </Paper>
//                                     </Box>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     );
// };

// export default CycleReport;
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Toolbar, Box, Typography } from '@mui/material';
import { SharedStateProvider } from '../SharedStateContext';
import SideBar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { cycleDataGet } from '../../../actions/tradingAction';

const CycleReport = () => {
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [accountFilter, setAccountFilter] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const start_date = "1000-01-01";
            const end_date = "9999-01-01";
            await cycleDataGet(dispatch, start_date, end_date).then((res) => {
                setData(res?.data);
                processTableData(res?.data);
            }).catch((error) => {});
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    const getUniqueMonths = () => {
        const months = new Set();
        data?.forEach(entry => {
            const date = new Date(entry?.date);
            const monthYear = `${date?.getMonth() + 1}/${date?.getFullYear().toString().slice(-2)}`;
            months.add(monthYear);
        });
        return Array.from(months);
    };

    const uniqueMonths = getUniqueMonths();

    const processTableData = (data) => {
        const months = new Set();
        data?.forEach(entry => {
            const date = new Date(entry?.date);
            const monthYear = `${date?.getMonth() + 1}/${date?.getFullYear().toString().slice(-2)}`;
            months.add(monthYear);
        });
        const uniqueMonths = Array.from(months);

        const symbolExchangeAccountData = {};

        data?.forEach(entry => {
            const date = new Date(entry?.date);
            const monthYear = `${date?.getMonth() + 1}/${date?.getFullYear().toString().slice(-2)}`;

            const key = `${entry?.symbol}-${entry?.exchange}-${entry?.accountName}`;
            if (!symbolExchangeAccountData[key]) {
                symbolExchangeAccountData[key] = {
                    id: key,
                    symbol: entry?.symbol,
                    exchange: entry?.exchange,
                    accountName: entry?.accountName,
                    cycles: {}
                };
            }

            if (!symbolExchangeAccountData[key].cycles[monthYear]) {
                symbolExchangeAccountData[key].cycles[monthYear] = 0;
            }

            symbolExchangeAccountData[key].cycles[monthYear] += entry?.total_cycle_count;
        });

        const result = Object.values(symbolExchangeAccountData).map(symbolEntry => {
            const totalCycles = uniqueMonths?.map(month => symbolEntry?.cycles[month] || 0);
            const totalCycleSum = totalCycles?.reduce((acc, cycle) => acc + cycle, 0);

            const cyclesObj = uniqueMonths.reduce((obj, month, index) => {
                obj[month] = totalCycles[index];
                return obj;
            }, {});

            return {
                ...symbolEntry,
                ...cyclesObj,
                totalCycleSum // Add total for the row
            };
        });

        setTableData(result);
    };

    const handleAccountChange = (event) => {
        const accountValue = event.target.value;
        setAccountFilter(accountValue);

        const filtered = data.filter(entry =>
            accountValue ? entry?.accountName === accountValue : true
        );
        processTableData(filtered);
        setPage(0); // Reset page to 0 when filters change
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getUniqueAccounts = () => {
        const accounts = new Set(data?.map(entry => entry?.accountName));
        return Array.from(accounts);
    };

    const uniqueAccounts = getUniqueAccounts();

    const paginatedData = tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    // Calculate total for each column (month)
    const totalPerMonth = uniqueMonths.map(month => {
        return tableData.reduce((sum, row) => sum + (row[month] || 0), 0);
    });

    return (
        <div className="Main">
            <SharedStateProvider>
                <SideBar />
                <Header />
            </SharedStateProvider>

            <section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="Main">
                                <div className="main-body">
                                    <Box sx={{ width: "100%" }}>
                                        <Paper sx={{ width: "100%", mb: 2 }} className="customTable">
                                            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} className="p-1">
                                                <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                                                    Monthly Cycle Report
                                                </Typography>
                                                <select
                                                    className="form-control"
                                                    name="account"
                                                    style={{ width: "20%" }}
                                                    value={accountFilter}
                                                    onChange={handleAccountChange}
                                                >
                                                    <option value="" disabled>Select Account</option>
                                                    <option value="">All</option>
                                                    {uniqueAccounts?.map(account => (
                                                        <option key={account} value={account}>{account}</option>
                                                    ))}
                                                </select>
                                            </Toolbar>

                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Symbol</TableCell>
                                                            <TableCell>Exchange</TableCell>
                                                            <TableCell>Account Name</TableCell>
                                                            <TableCell>Total</TableCell> {/* Move Total column here */}
                                                            {uniqueMonths?.map(month => (
                                                                <TableCell key={month}>{month}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {paginatedData?.map((row, index) => (
                                                            <TableRow key={index} sx={{ backgroundColor: index % 2 === 1 ? 'rgba(0, 0, 0, 0.04)' : 'inherit' }}>
                                                                <TableCell>{row?.symbol}</TableCell>
                                                                <TableCell>{row?.exchange}</TableCell>
                                                                <TableCell>{row?.accountName}</TableCell>
                                                                <TableCell>{row.totalCycleSum}</TableCell> {/* Total column for each row */}
                                                                {uniqueMonths?.map(month => (
                                                                    <TableCell key={month}>{row[month] || 0}</TableCell>
                                                                ))}
                                                            </TableRow>
                                                        ))}

                                                        {/* Total Row */}
                                                        <TableRow>
                                                            <TableCell colSpan={3}><b>Total</b></TableCell>
                                                            <TableCell><b>{totalPerMonth?.reduce((sum, total) => sum + total, 0)}</b></TableCell> {/* Grand total */}
                                                            {totalPerMonth?.map((total, index) => (
                                                                <TableCell key={index}><b>{total}</b></TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={tableData?.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                style={{ marginTop: '16px' }}
                                            />
                                        </Paper>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CycleReport;
