// BASIC IMPORTS
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import BacktestSymbolEditForm from "./BacktestSymbolEditForm";

// API IMPORTS
import { getAutoBacktestSymbolById, updateAutoBacktestSymbolById } from "../../../actions/backtestAction";


function BacktestSymbolEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialData, setInitialData] = useState();

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const handleFormSubmit = async (data) => {
    await updateAutoBacktestSymbolById(dispatch, id, data);
    if (store.getState()?.backtest?.auto_backtest_symbol_update) {
      toast.success("Data updated successfully!");
      navigate("/add-view-backtest-symbols");
    } else if (store.getState()?.backtest?.error) {
      toast.error(
        store.getState()?.backtest?.errorMessage
          ? store.getState()?.backtest?.errorMessage?.message
          : "Error updating data. Please try again."
      );
    }
  };

  useEffect(() => {
    const getSymbolData = async () => {
      await getAutoBacktestSymbolById(dispatch, id);
      if (store.getState()?.backtest?.auto_backtest_symbol_by_id) {
        setInitialData(
          store.getState()?.backtest?.auto_backtest_symbol_by_id?.data
        );
      }
    };
    getSymbolData();
  }, [id, dispatch, store]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/auto-backtest">Auto Backtest</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/add-view-backtest-symbols">Add Symbols</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="" style={{ fontWeight: 600 }}>
                          Edit Symbol
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <BacktestSymbolEditForm
                  initialValues={initialData}
                  onSubmit={handleFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BacktestSymbolEdit;
