// BASIC IMPORTS
import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// MUI IMPORT
import { Button } from "@mui/material";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { holidayCreate } from "../../../actions/holidayAction";



function HolidayCreateForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      muhuratTrading: "No",
    },
  });

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    await holidayCreate(dispatch, data);
    if (store.getState()?.holiday?.holiday) {
      toast.success("Data added successfully!");
      navigate("/holiday");
    } else if (store.getState()?.holiday?.error) {
      toast.error(
        store.getState()?.holiday?.errorMessage
          ? store.getState()?.holiday?.errorMessage?.message
          : "Error adding data. Please try again."
      );
    }
  };

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/holiday">Holidays</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="" style={{ fontWeight: 600 }}>
                          Create New
                        </Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-3">
                      <div className="ListWithBadge">
                        <Button
                          className="adminbackBtn"
                          onClick={() => navigate("/holiday")}
                        >
                          <i className="feather icon-chevron-right"></i>
                        </Button>
                        <h3 className="m-b-20 mr-2">Create New</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="card">
                        <div className="card-body mobileCardBody">
                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Holiday Date* (DD/MM/YYYY)</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Holiday Date"
                                {...register("holidayDate", {
                                  required: "Holiday date is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.holidayDate && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.holidayDate?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Holiday*</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Holiday"
                                {...register("holiday", {
                                  required: "Holiday is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.holiday && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.holiday?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Holiday Day*</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Holiday Day"
                                {...register("holidayDay", {
                                  required: "Holiday day is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.holidayDay && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.holidayDay?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Exchange*</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Exchange"
                                {...register("exchange", {
                                  required: "Exchange is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.exchange && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.exchange?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Muhurat Trading*</label>
                              <Controller
                                name="muhuratTrading"
                                control={control}
                                rules={{
                                  required: "Muhurat trading field is required",
                                }}
                                render={({ field }) => (
                                  <>
                                    <select {...field} className="form-control">
                                      <option>Yes</option>
                                      <option>No</option>
                                    </select>
                                    {field.touched &&
                                      field.value === "Enabled" && (
                                        <div
                                          className="validationAlert"
                                          style={{ margin: "10px 0px" }}
                                        >
                                          {errors?.muhuratTrading?.message}
                                        </div>
                                      )}
                                  </>
                                )}
                              />
                            </div>
                          </div>

                          <div className="row col-md-12 justify-content-center pt-3">
                            <button
                              type="submit"
                              className="btn btn-primary ml-3 px-5"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HolidayCreateForm;
