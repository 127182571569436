// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

// MUI IMPORTS
import { alpha } from "@mui/material/styles";
import { Box, Table, Modal, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";

// API IMPORTS
import { getNewOrUpdatedSymbols, deleteSymbols } from "../../../actions/backtestAction";
import { symbolUpdateForAll } from "../../../actions/symbolAction";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCellsNewSymbol = [
  {
    id: "shortName",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Symbol Full Name",
  },
  {
    id: "Broker",
    numeric: false,
    disablePadding: false,
    label: "Broker",
  },
  {
    id: "LotSize",
    numeric: true,
    disablePadding: false,
    label: "Lotsize",
  },
  {
    id: "tickSize",
    numeric: true,
    disablePadding: false,
    label: "Ticksize",
  },
  {
    id: "expiryDate",
    numeric: true,
    disablePadding: false,
    label: "Expiry",
  },
];

const headCellsUpdatedSymbol = [
  {
    id: "shortName",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Symbol Full Name",
  },
  {
    id: "Broker",
    numeric: false,
    disablePadding: false,
    label: "Broker",
  },
  {
    id: "LotSize",
    numeric: true,
    disablePadding: false,
    label: "Lotsize",
  },
  {
    id: "tickSize",
    numeric: true,
    disablePadding: false,
    label: "Ticksize",
  },
  {
    id: "expiryDate",
    numeric: true,
    disablePadding: false,
    label: "Expiry",
  },
  {
    id: "setting",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHeadNewSymbol(props) {
  const {
    onSelectAllClickNewSymbol,
    orderNewSymbol,
    orderByNewSymbol,
    numSelectedNewSymbol,
    rowCountNewSymbol,
    onRequestSortNewSymbol,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSortNewSymbol(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={
              numSelectedNewSymbol > 0 &&
              numSelectedNewSymbol < rowCountNewSymbol
            }
            checked={
              rowCountNewSymbol > 0 &&
              numSelectedNewSymbol === rowCountNewSymbol
            }
            onChange={onSelectAllClickNewSymbol}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCellsNewSymbol.map((headCell, index) => (
          <TableCell
            key={index}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={
              orderByNewSymbol === headCell?.id ? orderNewSymbol : false
            }
          >
            <TableSortLabel
              active={orderByNewSymbol === headCell?.id}
              direction={
                orderByNewSymbol === headCell?.id ? orderNewSymbol : "asc"
              }
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {orderByNewSymbol === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {orderNewSymbol === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHeadUpdatedSymbol(props) {
  const {
    onSelectAllClickUpdatedSymbol,
    orderUpdatedSymbol,
    orderByUpdatedSymbol,
    numSelectedUpdatedSymbol,
    rowCountUpdatedSymbol,
    onRequestSortUpdatedSymbol,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSortUpdatedSymbol(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={
              numSelectedUpdatedSymbol > 0 &&
              numSelectedUpdatedSymbol < rowCountUpdatedSymbol
            }
            checked={
              rowCountUpdatedSymbol > 0 &&
              numSelectedUpdatedSymbol === rowCountUpdatedSymbol
            }
            onChange={onSelectAllClickUpdatedSymbol}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCellsUpdatedSymbol.map((headCell, index) => (
          <TableCell
            key={index}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={
              orderByUpdatedSymbol === headCell?.id ? orderUpdatedSymbol : false
            }
          >
            <TableSortLabel
              active={orderByUpdatedSymbol === headCell?.id}
              direction={
                orderByUpdatedSymbol === headCell?.id
                  ? orderUpdatedSymbol
                  : "asc"
              }
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {orderByUpdatedSymbol === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {orderUpdatedSymbol === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadNewSymbol.propTypes = {
  onSelectAllClickNewSymbol: PropTypes.func.isRequired,
  orderNewSymbol: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderByNewSymbol: PropTypes.string.isRequired,
  numSelectedNewSymbol: PropTypes.number.isRequired,
  rowCountNewSymbol: PropTypes.number.isRequired,
  onRequestSortNewSymbol: PropTypes.func.isRequired,
};

EnhancedTableHeadUpdatedSymbol.propTypes = {
  onSelectAllClickUpdatedSymbol: PropTypes.func.isRequired,
  orderUpdatedSymbol: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderByUpdatedSymbol: PropTypes.string.isRequired,
  numSelectedUpdatedSymbol: PropTypes.number.isRequired,
  rowCountUpdatedSymbol: PropTypes.number.isRequired,
  onRequestSortUpdatedSymbol: PropTypes.func.isRequired,
};

function SymbolAddUpdate() {
  const [orderNewSymbol, setOrderNewSymbol] = useState("asc");
  const [orderUpdatedSymbol, setOrderUpdatedSymbol] = useState("asc");
  const [orderByNewSymbol, setOrderByNewSymbol] = useState("shortName");
  const [orderByUpdatedSymbol, setOrderByUpdatedSymbol] = useState("shortName");
  const [selectedNewSymbol, setSelectedNewSymbol] = useState([]);
  const [selectedUpdatedSymbol, setSelectedUpdatedSymbol] = useState([]);
  const [pageNewSymbol, setPageNewSymbol] = useState(0);
  const [pageUpdatedSymbol, setPageUpdatedSymbol] = useState(0);
  const [rowsPerPageNewSymbol, setRowsPerPageNewSymbol] = useState(5);
  const [rowsPerPageUpdatedSymbol, setRowsPerPageUpdatedSymbol] = useState(5);
  const [symbolData, setSymbolData] = useState([]);
  const [newSymbolFilterData, setNewSymbolFilterData] = useState([]);
  const [updatedSymbolFilterData, setUpdatedSymbolFilterData] = useState([]);
  const [filterNewSymbol, setFilterNewSymbol] = useState("");
  const [filterNewSymbolExchange, setFilterNewSymbolExchange] = useState("");
  const [filterUpdatedSymbol, setFilterUpdatedSymbol] = useState("");
  const [filterUpdatedSymbolExchange, setFilterUpdatedSymbolExchange] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const handleRequestSortNewSymbol = (event, property) => {
    const isAsc = orderByNewSymbol === property && orderNewSymbol === "asc";
    setOrderNewSymbol(isAsc ? "desc" : "asc");
    setOrderByNewSymbol(property);
  };

  const handleRequestSortUpdatedSymbol = (event, property) => {
    const isAsc =
      orderByUpdatedSymbol === property && orderUpdatedSymbol === "asc";
    setOrderUpdatedSymbol(isAsc ? "desc" : "asc");
    setOrderByUpdatedSymbol(property);
  };

  const handleSelectAllClickNewSymbol = (event) => {
    if (event.target.checked) {
      const newSelected = newSymbolFilterData?.map((n) => n._id);
      setSelectedNewSymbol(newSelected);
      return;
    }
    setSelectedNewSymbol([]);
  };

  const handleSelectAllClickUpdatedSymbol = (event) => {
    if (event.target.checked) {
      const newSelected = updatedSymbolFilterData?.map((n) => n._id);
      setSelectedUpdatedSymbol(newSelected);
      return;
    }
    setSelectedUpdatedSymbol([]);
  };

  const handleClickNewSymbol = (event, id) => {
    const selectedIndex = selectedNewSymbol?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selectedNewSymbol, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selectedNewSymbol?.slice(1));
    } else if (selectedIndex === selectedNewSymbol?.length - 1) {
      newSelected = newSelected?.concat(selectedNewSymbol?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selectedNewSymbol?.slice(0, selectedIndex),
        selectedNewSymbol?.slice(selectedIndex + 1)
      );
    }
    setSelectedNewSymbol(newSelected);
  };

  const handleClickUpdatedSymbol = (event, id) => {
    const selectedIndex = selectedUpdatedSymbol?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selectedUpdatedSymbol, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selectedUpdatedSymbol?.slice(1));
    } else if (selectedIndex === selectedUpdatedSymbol?.length - 1) {
      newSelected = newSelected?.concat(selectedUpdatedSymbol?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selectedUpdatedSymbol?.slice(0, selectedIndex),
        selectedUpdatedSymbol?.slice(selectedIndex + 1)
      );
    }
    setSelectedUpdatedSymbol(newSelected);
  };

  const handleChangePageNewSymbol = (event, newPage) => {
    setPageNewSymbol(newPage);
  };

  const handleChangePageUpdatedSymbol = (event, newPage) => {
    setPageUpdatedSymbol(newPage);
  };

  const handleChangeNewSymbolRowsPerPage = (event) => {
    setRowsPerPageNewSymbol(parseInt(event.target.value, 10));
    setPageNewSymbol(0);
  };

  const handleChangeUpdatedSymbolRowsPerPage = (event) => {
    setRowsPerPageUpdatedSymbol(parseInt(event.target.value, 10));
    setPageUpdatedSymbol(0);
  };

  const isSelectedNewSymbol = (id) => selectedNewSymbol?.indexOf(id) !== -1;
  const isSelectedUpdatedSymbol = (id) =>
    selectedUpdatedSymbol?.indexOf(id) !== -1;

  const visibleRowsNewSymbol = useMemo(
    () =>
      stableSort(
        newSymbolFilterData,
        getComparator(orderNewSymbol, orderByNewSymbol)
      )?.slice(
        pageNewSymbol * rowsPerPageNewSymbol,
        pageNewSymbol * rowsPerPageNewSymbol + rowsPerPageNewSymbol
      ),
    [
      orderNewSymbol,
      orderByNewSymbol,
      pageNewSymbol,
      rowsPerPageNewSymbol,
      newSymbolFilterData,
    ]
  );

  const visibleRowsUpdatedSymbol = useMemo(
    () =>
      stableSort(
        updatedSymbolFilterData,
        getComparator(orderUpdatedSymbol, orderByUpdatedSymbol)
      )?.slice(
        pageUpdatedSymbol * rowsPerPageUpdatedSymbol,
        pageUpdatedSymbol * rowsPerPageUpdatedSymbol + rowsPerPageUpdatedSymbol
      ),
    [
      orderUpdatedSymbol,
      orderByUpdatedSymbol,
      pageUpdatedSymbol,
      rowsPerPageUpdatedSymbol,
      updatedSymbolFilterData,
    ]
  );

  const handleMultiDeleteNewSymbol = async () => {
    if (selectedNewSymbol?.length !== 0) {
      // delte multiple records
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to delete the selected ${selectedNewSymbol?.length} record?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { new_symbols: selectedNewSymbol };
          await deleteSymbols(dispatch, data);
          if (store.getState()?.backtest?.delete_symbols) {
            Swal.fire(
              "Deleted!",
              "All selected record deleted successfully.",
              "success"
            );
            setSelectedNewSymbol([]);
            getSymbolsData();
            setPageNewSymbol(0);
          } else if (store.getState()?.backtest?.error) {
            toast.error(
              store.getState()?.backtest?.errorMessage
                ? store.getState()?.backtest?.errorMessage?.message
                : "Error deleting data. Please try again."
            );
          }
        }
      });
    }
  };

  const handleMultiDeleteUpdatedSymbol = async () => {
    if (selectedUpdatedSymbol?.length !== 0) {
      // delte multiple records
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to delete the selected ${selectedUpdatedSymbol?.length} record?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { updated_symbols: selectedUpdatedSymbol };
          await deleteSymbols(dispatch, data);
          if (store.getState()?.backtest?.delete_symbols) {
            Swal.fire(
              "Deleted!",
              "All selected record deleted successfully.",
              "success"
            );
            setSelectedUpdatedSymbol([]);
            getSymbolsData();
            setPageUpdatedSymbol(0);
          } else if (store.getState()?.backtest?.error) {
            toast.error(
              store.getState()?.backtest?.errorMessage
                ? store.getState()?.backtest?.errorMessage?.message
                : "Error deleting data. Please try again."
            );
          }
        }
      });
    }
  };

  const getSymbolsData = async () => {
    await getNewOrUpdatedSymbols(dispatch);
    if (store.getState()?.backtest?.get_symbol_status) {
      setSymbolData(store.getState()?.backtest?.get_symbol_status?.data);
      setNewSymbolFilterData(
        store.getState()?.backtest?.get_symbol_status?.data?.new_symbols
      );
      setUpdatedSymbolFilterData(
        store.getState()?.backtest?.get_symbol_status?.data?.updated_symbols
      );
    }
  };
  

  const handleInputChange = (e, field) => {
    setSelectedRow({
      ...selectedRow,
      [field]: e.target.value,
    });
  };

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to update symbol in all pair, order, and symbol tabel for all user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await symbolUpdateForAll(dispatch, [selectedRow]);
        if (store.getState()?.symbol?.updated_symbol?.message) {
          toast.success(store.getState()?.symbol?.updated_symbol?.message);
          const data = { updated_symbols: [selectedRow?._id] };
          await deleteSymbols(dispatch, data);
          setOpenModal(false);
          getSymbolsData();
        } else {
          toast.error("Something went wrong");
        }
      }
    });
  };

  const handleMultiUpdate = async () => {
    const combinedDictList = selectedUpdatedSymbol
      ?.map((id) => updatedSymbolFilterData?.find((item) => item._id === id))
      .filter(Boolean);
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to update all symbols in all pair, order, and symbol tabel for all user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await symbolUpdateForAll(dispatch, combinedDictList);
        if (store.getState()?.symbol?.updated_symbol?.message) {
          toast.success(store.getState()?.symbol?.updated_symbol?.message);
          const idList = combinedDictList.map(obj => obj._id);
          const data = { updated_symbols: idList };
          await deleteSymbols(dispatch, data);
          setOpenModal(false);
          getSymbolsData();
          setSelectedUpdatedSymbol([]);
        } else {
          toast.error("Something went wrong");
        }
      }
    });
  };

  useEffect(() => {
    if (filterNewSymbol !== undefined) {
      const filteredNewSymbolsData = symbolData?.new_symbols?.filter(
        (item) => item?.Broker?.toUpperCase() === filterNewSymbol?.toUpperCase()
      );
      setNewSymbolFilterData(filteredNewSymbolsData);
    }
    setPageNewSymbol(0);
    // eslint-disable-next-line
  }, [filterNewSymbol]);

  useEffect(() => {
    if (filterNewSymbolExchange !== undefined) {
      const filteredNewSymbolsData = symbolData?.new_symbols?.filter(
        (item) => item?.marketType?.toUpperCase() === filterNewSymbolExchange?.toUpperCase()
      );
      setNewSymbolFilterData(filteredNewSymbolsData);
    }
    setPageNewSymbol(0);
    // eslint-disable-next-line
  }, [filterNewSymbolExchange]);


  



  useEffect(() => {
    if (filterUpdatedSymbol !== undefined) {
      const filteredUpdatedSymbolsData = symbolData?.updated_symbols?.filter(
        (item) =>
          item?.Broker?.toUpperCase() === filterUpdatedSymbol?.toUpperCase()
      );
      setUpdatedSymbolFilterData(filteredUpdatedSymbolsData);
    }
    setPageUpdatedSymbol(0);
    // eslint-disable-next-line
  }, [filterUpdatedSymbol]);


  useEffect(() => {
    if (filterUpdatedSymbolExchange !== undefined) {
      const filteredUpdatedSymbolsData = symbolData?.updated_symbols?.filter(
        (item) =>
          item?.marketType?.toUpperCase() === filterUpdatedSymbolExchange?.toUpperCase()
      );
      setUpdatedSymbolFilterData(filteredUpdatedSymbolsData);
    }
    setPageUpdatedSymbol(0);
    
    // eslint-disable-next-line
  }, [filterUpdatedSymbolExchange]);


  useEffect(() => {
    getSymbolsData();
    // eslint-disable-next-line
  }, [dispatch]);


  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/order" style={{ fontWeight: 600 }}>
                            Symbols Status
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <Box sx={{ width: "100%" }}>
                    <Paper
                      sx={{ width: "100%", mb: 2 }}
                      className="customTable"
                    >
                      <Toolbar
                        sx={{
                          pl: { sm: 2 },
                          pr: { xs: 1, sm: 1 },
                          ...(selectedUpdatedSymbol?.length > 0 && {
                            bgcolor: (theme) =>
                              alpha(
                                theme.palette.primary.main,
                                theme.palette.action.activatedOpacity
                              ),
                          }),
                        }}
                        className="p-1"
                      >
                        {selectedUpdatedSymbol?.length > 0 ? (
                          <Typography
                            sx={{ flex: "1 1 100%" }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                          >
                            {selectedUpdatedSymbol?.length} selected
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ flex: "1 1 100%" }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                          >
                            Updated Symbols ({updatedSymbolFilterData?.length})
                          </Typography>
                        )}

                        {selectedUpdatedSymbol?.length > 0 ? (
                          <>
                            <button
                              className="btn btn-primary m-0 mr-3"
                              onClick={handleMultiUpdate}
                              sx={{ mt: 2 }}
                            >
                              Update All Symbol
                            </button>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={handleMultiDeleteUpdatedSymbol}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : (
                          <Fragment>
                          <select
                            className="form-control mr-3"
                            name="accountNewSymbol"
                            defaultValue=""
                            style={{ width: "20%" }}
                            onChange={(event) =>
                              setFilterUpdatedSymbolExchange(event.target.value)
                            }
                          >
                            <option value="" disabled>
                              Select Exchange
                            </option>
                            <option>NSE</option>
                            <option>BSE</option>
                            <option>NFO</option>
                            <option>CDN</option>
                          </select>
                          <select
                            className="form-control"
                            name="accountUpdatedSymbol"
                            value={filterUpdatedSymbol}
                            style={{ width: "20%" }}
                            onChange={(event) =>
                              setFilterUpdatedSymbol(event.target.value)
                            }
                          >
                            <option value="" disabled>
                              Select Account
                            </option>
                            <option>ICICI</option>
                            <option>FYERS</option>
                            <option>ANGEL</option>
                            <option>UPSTOX</option>
                          </select>
                          </Fragment>
                        )}
                      </Toolbar>
                      <TableContainer>
                        <Table
                          className="addTableFontColor"
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                        >
                          <EnhancedTableHeadUpdatedSymbol
                            onSelectAllClickUpdatedSymbol={
                              handleSelectAllClickUpdatedSymbol
                            }
                            orderUpdatedSymbol={orderUpdatedSymbol}
                            orderByUpdatedSymbol={orderByUpdatedSymbol}
                            numSelectedUpdatedSymbol={
                              selectedUpdatedSymbol?.length || 0
                            }
                            rowCountUpdatedSymbol={
                              updatedSymbolFilterData?.length || 0
                            }
                            onRequestSortUpdatedSymbol={
                              handleRequestSortUpdatedSymbol
                            }
                          />
                          <TableBody>
                            {visibleRowsUpdatedSymbol?.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={7} align="center">
                                  No data found
                                </TableCell>
                              </TableRow>
                            ) : (
                              visibleRowsUpdatedSymbol?.map((row, index) => {
                                const isItemSelected = isSelectedUpdatedSymbol(
                                  row._id
                                );
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row._id}
                                    selected={isItemSelected}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onClick={(event) =>
                                          handleClickUpdatedSymbol(
                                            event,
                                            row._id
                                          )
                                        }
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell>{row?.shortName}</TableCell>
                                    <TableCell>{row?.companyName}</TableCell>
                                    <TableCell>
                                      {row?.Broker?.toUpperCase()}
                                    </TableCell>
                                    <TableCell>{row?.LotSize}</TableCell>
                                    <TableCell>{row?.tickSize}</TableCell>
                                    <TableCell>
                                      {row?.expiryDate ? row?.expiryDate : "-"}
                                    </TableCell>
                                    <TableCell>
                                      <button
                                        className="btn btn-outline-primary m-0"
                                        onClick={() => handleOpenModal(row)}
                                      >
                                        <i className="feather icon-menu m-0"></i>
                                      </button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={updatedSymbolFilterData?.length || 0}
                        rowsPerPage={rowsPerPageUpdatedSymbol}
                        page={pageUpdatedSymbol}
                        onPageChange={handleChangePageUpdatedSymbol}
                        onRowsPerPageChange={
                          handleChangeUpdatedSymbolRowsPerPage
                        }
                      />
                    </Paper>
                  </Box>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <Box sx={{ width: "100%" }}>
                    <Paper
                      sx={{ width: "100%", mb: 2, zIndex: 10 }}
                      className="customTable"
                    >
                      <Toolbar
                        sx={{
                          pl: { sm: 2 },
                          pr: { xs: 1, sm: 1 },
                          ...(selectedNewSymbol?.length > 0 && {
                            bgcolor: (theme) =>
                              alpha(
                                theme.palette.primary.main,
                                theme.palette.action.activatedOpacity
                              ),
                          }),
                        }}
                        className="p-1"
                      >
                        {selectedNewSymbol?.length > 0 ? (
                          <Typography
                            sx={{ flex: "1 1 100%" }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                          >
                            {selectedNewSymbol?.length} selected
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ flex: "1 1 100%" }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                          >
                            New Symbols ({newSymbolFilterData?.length})
                          </Typography>
                        )}

                        {selectedNewSymbol?.length > 0 ? (
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() =>
                                handleMultiDeleteNewSymbol("new_symbols")
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Fragment>
                          <select
                            className="form-control mr-3"
                            name="accountNewSymbol"
                            defaultValue=""
                            style={{ width: "20%" }}
                            onChange={(event) =>
                              setFilterNewSymbolExchange(event.target.value)
                            }
                          >
                            <option value="" disabled>
                              Select Exchange
                            </option>
                            <option>NSE</option>
                            <option>BSE</option>
                            <option>NFO</option>
                            <option>CDN</option>
                          </select>
                          <select
                            className="form-control"
                            name="accountNewSymbol"
                            value={filterNewSymbol}
                            style={{ width: "20%" }}
                            onChange={(event) =>
                              setFilterNewSymbol(event.target.value)
                            }
                          >
                            <option value="" disabled>
                              Select Account
                            </option>
                            <option>ICICI</option>
                            <option>FYERS</option>
                            <option>ANGEL</option>
                            <option>UPSTOX</option>
                          </select>
                          </Fragment>
                        )}
                      </Toolbar>
                      <TableContainer>
                        <Table
                          className="addTableFontColor"
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                        >
                          <EnhancedTableHeadNewSymbol
                            onSelectAllClickNewSymbol={
                              handleSelectAllClickNewSymbol
                            }
                            orderNewSymbol={orderNewSymbol}
                            orderByNewSymbol={orderByNewSymbol}
                            numSelectedNewSymbol={
                              selectedNewSymbol?.length || 0
                            }
                            rowCountNewSymbol={newSymbolFilterData?.length || 0}
                            onRequestSortNewSymbol={handleRequestSortNewSymbol}
                          />
                          <TableBody>
                            {visibleRowsNewSymbol?.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={7} align="center">
                                  No data found
                                </TableCell>
                              </TableRow>
                            ) : (
                              visibleRowsNewSymbol?.map((row, index) => {
                                const isItemSelected = isSelectedNewSymbol(
                                  row._id
                                );
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row._id}
                                    selected={isItemSelected}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onClick={(event) =>
                                          handleClickNewSymbol(event, row._id)
                                        }
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell>{row?.shortName}</TableCell>
                                    <TableCell>{row?.companyName}</TableCell>
                                    <TableCell>
                                      {row?.Broker?.toUpperCase()}
                                    </TableCell>
                                    <TableCell>{row?.LotSize}</TableCell>
                                    <TableCell>{row?.tickSize}</TableCell>
                                    <TableCell>
                                      {row?.expiryDate ? row?.expiryDate : "-"}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={newSymbolFilterData?.length || 0}
                        rowsPerPage={rowsPerPageNewSymbol}
                        page={pageNewSymbol}
                        onPageChange={handleChangePageNewSymbol}
                        onRowsPerPageChange={handleChangeNewSymbolRowsPerPage}
                      />
                    </Paper>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Symbol Details
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}></Typography>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>New Data</TableCell>
                  <TableCell>Old Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Symbol</TableCell>
                  <TableCell className="p-2">
                    <input
                      className="form-control"
                      type="text"
                      value={selectedRow?.shortName || ""}
                      onChange={(e) => handleInputChange(e, "shortName")}
                    />
                  </TableCell>
                  <TableCell>
                    {selectedRow?.old_shortName
                      ? selectedRow?.old_shortName
                      : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell className="p-2">
                    <input
                      className="form-control"
                      type="text"
                      value={selectedRow?.companyName || ""}
                      onChange={(e) => handleInputChange(e, "companyName")}
                    />
                  </TableCell>
                  <TableCell>
                    {selectedRow?.old_companyName
                      ? selectedRow?.old_companyName
                      : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Lot Size</TableCell>
                  <TableCell className="p-2">
                    <input
                      className="form-control"
                      type="text"
                      value={selectedRow?.LotSize || ""}
                      onChange={(e) => handleInputChange(e, "LotSize")}
                    />
                  </TableCell>
                  <TableCell>
                    {selectedRow?.old_LotSize ? selectedRow?.old_LotSize : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tick Size</TableCell>
                  <TableCell className="p-2">
                    <input
                      className="form-control"
                      type="text"
                      value={selectedRow?.tickSize || ""}
                      onChange={(e) => handleInputChange(e, "tickSize")}
                    />
                  </TableCell>
                  <TableCell>
                    {selectedRow?.old_tickSize
                      ? selectedRow?.old_tickSize
                      : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Expiry</TableCell>
                  <TableCell className="p-2">
                    <input
                      className="form-control"
                      type="text"
                      value={selectedRow?.expiryDate || ""}
                      onChange={(e) => handleInputChange(e, "expiryDate")}
                    />
                  </TableCell>
                  <TableCell>
                    {selectedRow?.old_expiryDate
                      ? selectedRow?.old_expiryDate
                      : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Series</TableCell>
                  <TableCell className="p-2">
                    <input
                      className="form-control"
                      type="text"
                      value={selectedRow?.Series || ""}
                      onChange={(e) => handleInputChange(e, "Series")}
                    />
                  </TableCell>
                  <TableCell>
                    {selectedRow?.old_Series ? selectedRow?.old_Series : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Symbol Token (Angel)</TableCell>
                  <TableCell className="p-2">
                    <input
                      className="form-control"
                      type="text"
                      value={selectedRow?.symbolToken || ""}
                      onChange={(e) => handleInputChange(e, "symbolToken")}
                    />
                  </TableCell>
                  <TableCell>
                    {selectedRow?.old_symbolToken
                      ? selectedRow?.old_symbolToken
                      : "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="w-100 text-center">
            <button
              className="btn btn-primary mt-4"
              onClick={handleUpdate}
              sx={{ mt: 2 }}
            >
              Update
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default SymbolAddUpdate;
