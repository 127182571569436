import axiosClient from "../axios-client";
import {
    createHolidayFailure,
    createHolidayStart,
    createHolidaySuccess,
    getHolidayFailure,
    getHolidayStart,
    getHolidaySuccess,
    getHolidayByIdFailure,
    getHolidayByIdStart,
    getHolidayByIdSuccess,
    updateHolidayStart,
    updateHolidaySuccess,
    updateHolidayFailure,
    deleteHolidayStart,
    deleteHolidaySuccess,
    deleteHolidayFailure,
    checkTomorrowHolidayStart,
    checkTomorrowHolidaySuccess,
    checkTomorrowHolidayFailure,
} from "../reducers/holidayReducer";
import authHeader from "../services/auth-header";


export const holidayGet = async (dispatch) => {
    dispatch(getHolidayStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/holiday", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getHolidaySuccess(res.data));
    } catch (error) {
        dispatch(getHolidayFailure(error.response?.data));
    }
};

export const holidayGetById = async (dispatch, id) => {
    dispatch(getHolidayByIdStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/holiday/${id}`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getHolidayByIdSuccess(res.data));
    } catch (error) {
        dispatch(getHolidayByIdFailure(error.response?.data));
    }
};

export const holidayCreate = async (dispatch, holidayData) => {
    dispatch(createHolidayStart());
    try {
        const token = authHeader();

        const res = await axiosClient.post("/holiday", holidayData, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(createHolidaySuccess(res.data));
    } catch (error) {
        dispatch(createHolidayFailure(error.response?.data));
    }
};

export const holidayUpdate = async (dispatch, id, holidayData) => {
    dispatch(updateHolidayStart());
    try {
        const token = authHeader();

        const res = await axiosClient.put(`/holiday/${id}`, holidayData, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(updateHolidaySuccess(res.data));
    } catch (error) {
        dispatch(updateHolidayFailure(error.response?.data));
    }
};

export const holidayDelete = async (dispatch, id) => {
    dispatch(deleteHolidayStart());
    try {
        const token = authHeader();

        const res = await axiosClient.delete(`/holiday/${id}`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(deleteHolidaySuccess(res.data));
    } catch (error) {
        dispatch(deleteHolidayFailure(error.response?.data));
    }
};

export const holidayDeleteMany = async (dispatch, data) => {
    dispatch(deleteHolidayStart());
    try {
        const token = authHeader();

        const res = await axiosClient.delete("/holiday", {
            headers: {
                Authorization: token,
            },
            data,
        });
        dispatch(deleteHolidaySuccess(res.data));
    } catch (error) {
        dispatch(deleteHolidayFailure(error.response?.data));
    }
};

export const holidayFilter = async (dispatch, parameters) => {
    dispatch(getHolidayStart());
    try {
        const token = authHeader();
        const res = await axiosClient.post(`/holiday-filter`, parameters, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getHolidaySuccess(res.data));
    } catch (error) {
        dispatch(getHolidayFailure(error.response?.data));
    }
};

export const checkTomorrowHoliday = async (dispatch) => {
    dispatch(checkTomorrowHolidayStart());
    try {
        const token = authHeader();
        const res = await axiosClient.get("/check_tomorrow_holiday", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(checkTomorrowHolidaySuccess(res.data));
    } catch (error) {
        dispatch(checkTomorrowHolidayFailure(error.response?.data));
    }
};
