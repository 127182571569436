// BASIC IMPORTS
import React from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

// MUI IMPORTS
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";


function Volatility() {
  const {
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/volatility" style={{ fontWeight: 600 }}>
                          Volatility
                        </Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-2">
                      <div className="ListWithBadge">
                        <h3 className="m-b-20 mr-2">Volatility</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <form>
                      <div className="card">
                        <div className="card-body mobileCardBody">
                          <div className="form-group col-md-12">
                            <label>Symbol*</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Symbol"
                              {...register("symbol", {
                                required: "Symbol is required",
                                maxLength: {
                                  value: 100,
                                },
                              })}
                            />
                            {errors?.symbol && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.symbol?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label>Exchange*</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Exchange"
                              {...register("exchange", {
                                required: "Exchange is required",
                                maxLength: {
                                  value: 100,
                                },
                              })}
                            />
                            {errors?.exchange && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.exchange?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label>Product*</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Product"
                              {...register("product", {
                                required: "Product is required",
                                maxLength: {
                                  value: 100,
                                },
                              })}
                            />
                            {errors?.product && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.product?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label>Duration*</label>
                            <Controller
                              name="status"
                              control={control}
                              defaultValue=""
                              rules={{ required: "Status is required" }}
                              render={({ field }) => (
                                <select {...field} className="form-control">
                                  <option value="" disabled>
                                    Select...
                                  </option>
                                  <option>minute</option>
                                  <option>day</option>
                                  <option>month</option>
                                  <option>year</option>
                                </select>
                              )}
                            />
                            <div
                              className="validationAlert"
                              style={{ margin: "10px 0px" }}
                            >
                              {errors?.status?.message}
                            </div>
                          </div>
                          <div className="form-group col-md-12">
                            <label>Minute/Day/Month/Year*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Days"
                              {...register("days", {
                                required: "Days is required",
                                maxLength: {
                                  value: 100,
                                },
                              })}
                            />
                            {errors?.days && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.days?.message}
                              </div>
                            )}
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-12 datePicker">
                              <label>From Date*</label>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                className="row"
                              >
                                <DatePicker />
                              </LocalizationProvider>
                            </div>
                            <div className="form-group col-md-12 datePicker">
                              <label>End Date*</label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker />
                              </LocalizationProvider>
                            </div>
                          </div>

                          <div className="row col-md-12 justify-content-center mt-3">
                            <button
                              type="submit"
                              className="btn btn-primary ml-3 px-5"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Volatility;
