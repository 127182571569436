import { createSlice } from "@reduxjs/toolkit";

const backtestSlice = createSlice({
  name: "backtest",
  initialState: {
    backtest: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    backtestPairCycleStart: (state) => {
      state.isFetching = false;
      state.backtest_pair_cycle = null;
      state.error = false;
      state.errorMessage = "";
    },
    backtestPairCycleSuccess: (state, action) => {
      state.isFetching = true;
      state.backtest_pair_cycle = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    backtestPairCycleFailure: (state, error) => {
      state.isFetching = false;
      state.backtest_pair_cycle = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    backtestPairDeleteStart: (state) => {
      state.isFetching = false;
      state.backtest_pair_delete = null;
      state.error = false;
      state.errorMessage = "";
    },
    backtestPairDeleteSuccess: (state, action) => {
      state.isFetching = true;
      state.backtest_pair_delete = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    backtestPairDeleteFailure: (state, error) => {
      state.isFetching = false;
      state.backtest_pair_delete = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    fyersApiCallCounterStart: (state) => {
      state.isFetching = false;
      state.fyers_api_call_counter = null;
      state.error = false;
      state.errorMessage = "";
    },
    fyersApiCallCounterSuccess: (state, action) => {
      state.isFetching = true;
      state.fyers_api_call_counter = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    fyersApiCallCounterFailure: (state, error) => {
      state.isFetching = false;
      state.fyers_api_call_counter = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    backtestPairHistoryStart: (state) => {
      state.isFetching = false;
      state.backtest_pair_history_save = null;
      state.error = false;
      state.errorMessage = "";
    },
    backtestPairHistorySuccess: (state, action) => {
      state.isFetching = true;
      state.backtest_pair_history_save = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    backtestPairHistoryFailure: (state, error) => {
      state.isFetching = false;
      state.backtest_pair_history_save = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getAutoBacktestAllSymbolsStart: (state) => {
      state.isFetching = false;
      state.get_all_auto_backtest_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    getAutoBacktestAllSymbolsSuccess: (state, action) => {
      state.isFetching = true;
      state.get_all_auto_backtest_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getAutoBacktestAllSymbolsFailure: (state, error) => {
      state.isFetching = false;
      state.get_all_auto_backtest_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    autoBacktestCycleCountStart: (state) => {
      state.isFetching = false;
      state.auto_backtest_cycle_count = null;
      state.error = false;
      state.errorMessage = "";
    },
    autoBacktestCycleCountSuccess: (state, action) => {
      state.isFetching = true;
      state.auto_backtest_cycle_count = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    autoBacktestCycleCountFailure: (state, error) => {
      state.isFetching = false;
      state.auto_backtest_cycle_count = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    countAutoBacktestApiCallStart: (state) => {
      state.isFetching = false;
      state.auto_backtest_api_call_count = null;
      state.error = false;
      state.errorMessage = "";
    },
    countAutoBacktestApiCallSuccess: (state, action) => {
      state.isFetching = true;
      state.auto_backtest_api_call_count = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    countAutoBacktestApiCallFailure: (state, error) => {
      state.isFetching = false;
      state.auto_backtest_api_call_count = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getFyersSymbolTypesStart: (state) => {
      state.isFetching = false;
      state.fyers_symbol_types = null;
      state.error = false;
      state.errorMessage = "";
    },
    getFyersSymbolTypesSuccess: (state, action) => {
      state.isFetching = true;
      state.fyers_symbol_types = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getFyersSymbolTypesFailure: (state, error) => {
      state.isFetching = false;
      state.fyers_symbol_types = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    fyersFilterSymbolStart: (state) => {
      state.isFetching = false;
      state.get_fyers_filter_symbol = null;
      state.error = false;
      state.errorMessage = "";
    },
    fyersFilterSymbolSuccess: (state, action) => {
      state.isFetching = true;
      state.get_fyers_filter_symbol = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    fyersFilterSymbolFailure: (state, error) => {
      state.isFetching = false;
      state.get_fyers_filter_symbol = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getAutoBacktestTempSymbolsStart: (state) => {
      state.isFetching = false;
      state.get_auto_backtest_temp_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    getAutoBacktestTempSymbolsSuccess: (state, action) => {
      state.isFetching = true;
      state.get_auto_backtest_temp_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getAutoBacktestTempSymbolsFailure: (state, error) => {
      state.isFetching = false;
      state.get_auto_backtest_temp_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteAutoBacktestTempSymbolsStart: (state) => {
      state.isFetching = false;
      state.auto_backtest_delete_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteAutoBacktestTempSymbolsSuccess: (state, action) => {
      state.isFetching = true;
      state.auto_backtest_delete_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteAutoBacktestTempSymbolsFailure: (state, error) => {
      state.isFetching = false;
      state.auto_backtest_delete_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    transferAutoBacktestSymbolsStart: (state) => {
      state.isFetching = false;
      state.add_auto_backtest_bulk_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    transferAutoBacktestSymbolsSuccess: (state, action) => {
      state.isFetching = true;
      state.add_auto_backtest_bulk_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    transferAutoBacktestSymbolsFailure: (state, error) => {
      state.isFetching = false;
      state.add_auto_backtest_bulk_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    addAutoBacktestSymbolStart: (state) => {
      state.isFetching = false;
      state.auto_backtest_add_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    addAutoBacktestSymbolSuccess: (state, action) => {
      state.isFetching = true;
      state.auto_backtest_add_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    addAutoBacktestSymbolFailure: (state, error) => {
      state.isFetching = false;
      state.auto_backtest_add_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getAutoBacktestSymbolByIdStart: (state) => {
      state.isFetching = false;
      state.auto_backtest_symbol_by_id = null;
      state.error = false;
      state.errorMessage = "";
    },
    getAutoBacktestSymbolByIdSuccess: (state, action) => {
      state.isFetching = true;
      state.auto_backtest_symbol_by_id = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getAutoBacktestSymbolByIdFailure: (state, error) => {
      state.isFetching = false;
      state.auto_backtest_symbol_by_id = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    updateAutoBacktestSymbolByIdStart: (state) => {
      state.isFetching = false;
      state.auto_backtest_symbol_update = null;
      state.error = false;
      state.errorMessage = "";
    },
    updateAutoBacktestSymbolByIdSuccess: (state, action) => {
      state.isFetching = true;
      state.auto_backtest_symbol_update = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    updateAutoBacktestSymbolByIdFailure: (state, error) => {
      state.isFetching = false;
      state.auto_backtest_symbol_update = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteAutoBacktestSymbolsStart: (state) => {
      state.isFetching = false;
      state.auto_backtest_symbol_delete = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteAutoBacktestSymbolsSuccess: (state, action) => {
      state.isFetching = true;
      state.auto_backtest_symbol_delete = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteAutoBacktestSymbolsFailure: (state, error) => {
      state.isFetching = false;
      state.auto_backtest_symbol_delete = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getNewOrUpdatedSymbolsStart: (state) => {
      state.isFetching = false;
      state.get_symbol_status = null;
      state.error = false;
      state.errorMessage = "";
    },
    getNewOrUpdatedSymbolsSuccess: (state, action) => {
      state.isFetching = true;
      state.get_symbol_status = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getNewOrUpdatedSymbolsFailure: (state, error) => {
      state.isFetching = false;
      state.get_symbol_status = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    deleteSymbolsStart: (state) => {
      state.isFetching = false;
      state.delete_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    deleteSymbolsSuccess: (state, action) => {
      state.isFetching = true;
      state.delete_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    deleteSymbolsFailure: (state, error) => {
      state.isFetching = false;
      state.delete_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
  },
});

export const {
  backtestPairCycleStart,
  backtestPairCycleSuccess,
  backtestPairCycleFailure,
  backtestPairDeleteStart,
  backtestPairDeleteSuccess,
  backtestPairDeleteFailure,
  fyersApiCallCounterStart,
  fyersApiCallCounterSuccess,
  fyersApiCallCounterFailure,
  backtestPairHistoryStart,
  backtestPairHistorySuccess,
  backtestPairHistoryFailure,
  getAutoBacktestAllSymbolsStart,
  getAutoBacktestAllSymbolsSuccess,
  getAutoBacktestAllSymbolsFailure,
  autoBacktestCycleCountStart,
  autoBacktestCycleCountSuccess,
  autoBacktestCycleCountFailure,
  countAutoBacktestApiCallStart,
  countAutoBacktestApiCallSuccess,
  countAutoBacktestApiCallFailure,
  getFyersSymbolTypesStart,
  getFyersSymbolTypesSuccess,
  getFyersSymbolTypesFailure,
  fyersFilterSymbolStart,
  fyersFilterSymbolSuccess,
  fyersFilterSymbolFailure,
  getAutoBacktestTempSymbolsStart,
  getAutoBacktestTempSymbolsSuccess,
  getAutoBacktestTempSymbolsFailure,
  deleteAutoBacktestTempSymbolsStart,
  deleteAutoBacktestTempSymbolsSuccess,
  deleteAutoBacktestTempSymbolsFailure,
  transferAutoBacktestSymbolsStart,
  transferAutoBacktestSymbolsSuccess,
  transferAutoBacktestSymbolsFailure,
  addAutoBacktestSymbolStart,
  addAutoBacktestSymbolSuccess,
  addAutoBacktestSymbolFailure,
  getAutoBacktestSymbolByIdStart,
  getAutoBacktestSymbolByIdSuccess,
  getAutoBacktestSymbolByIdFailure,
  updateAutoBacktestSymbolByIdStart,
  updateAutoBacktestSymbolByIdSuccess,
  updateAutoBacktestSymbolByIdFailure,
  deleteAutoBacktestSymbolsStart,
  deleteAutoBacktestSymbolsSuccess,
  deleteAutoBacktestSymbolsFailure,
  getNewOrUpdatedSymbolsStart,
  getNewOrUpdatedSymbolsSuccess,
  getNewOrUpdatedSymbolsFailure,
  deleteSymbolsStart,
  deleteSymbolsSuccess,
  deleteSymbolsFailure,
} = backtestSlice.actions;
export default backtestSlice.reducer;
