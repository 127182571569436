import React, { useContext } from "react";
import { SharedStateContext } from "../main/SharedStateContext";
import { useNavigate, Link } from "react-router-dom";
import { clearSessionStorage, clearLocalStorage } from "../../helpers/helper";
import { toast } from "react-toastify";
import { decodeToken } from "../../helpers/helper";

function Header() {
  const { mobileSliderStatus, setMobileSliderStatus } =
    useContext(SharedStateContext);
  const navigate = useNavigate();

  const token = decodeToken();
  const handleSlider = () => {
    if (mobileSliderStatus) {
      setMobileSliderStatus(false);
    } else {
      setMobileSliderStatus(true);
    }
  };

  const handleSignOut = () => {
    clearSessionStorage();
    clearLocalStorage();
    navigate("/");
    navigate(0);
    toast.success("Successfully logged out!");
  };

  return (
    <header className="navbar pcoded-header navbar-expand-lg navbar-light">
      <div className="m-header">
        <button
          className={mobileSliderStatus ? "mobile-menu on" : "mobile-menu"}
          id="mobile-collapse1"
          onClick={handleSlider}
        >
          <span></span>
        </button>
        <Link to="/trading" className="b-brand">
          <div className="b-bg">
            <i className="feather icon-trending-up"></i>
          </div>
          <span className="b-title">Stock Market</span>
        </Link>
      </div>
      <button className="mobile-menu" id="mobile-header" onClick={handleSlider}>
        <i className="feather icon-more-horizontal"></i>
      </button>

      <div className="collapse navbar-collapse">
        <ul className="navbar-nav ml-auto settingBtn">
          <li>
            <div className="dropdown drp-user">
              <Link className="dropdown-toggle" data-toggle="dropdown">
                <i className="icon feather icon-settings"></i>
              </Link>
              <div className="dropdown-menu dropdown-menu-right profile-notification">
                <div className="pro-head">
                  <img
                    src="assets/images/user/avatar-2.jpg"
                    className="img-radius"
                    alt="User-Profile-Image1"
                  />
                  <span>
                    {token
                      ? token?.username?.substring(0, 24) +
                        (token?.username?.length > 24 ? "..." : "")
                      : "client@gmail.com"}
                  </span>
                </div>

                <ul className="pro-body">
                  <li>
                    <Link to="/profile" className="dropdown-item">
                      <i className="feather icon-user"></i> Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item logoutBtn"
                      onClick={handleSignOut}
                    >
                      <i className="feather icon-lock"></i> Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
