// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

// MUI IMPORTS
import { Box, Tooltip, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Checkbox, Typography, IconButton, Paper, Button } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { fyersSymbolGet } from "../../../actions/uploadExcel";

// API IMPORTS
import { getAutoBacktestAllSymbols, addAutoBacktestSymbol, deleteAutoBacktestSymbols, updateAutoBacktestSymbolType } from "../../../actions/backtestAction";


function descendingComparator(a, b, symbolBy) {
  if (b[symbolBy] < a[symbolBy]) {
    return -1;
  }
  if (b[symbolBy] > a[symbolBy]) {
    return 1;
  }
  return 0;
}

function getComparator(symbol, symbolBy) {
  return symbol === "desc"
    ? (a, b) => descendingComparator(a, b, symbolBy)
    : (a, b) => -descendingComparator(a, b, symbolBy);
}

function stableSort(array, comparator) {
  if (Array.isArray(array) && array?.length !== 0) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const symbol = comparator(a[0], b[0]);
      if (symbol !== 0) {
        return symbol;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
}

const headCells = [
  {
    id: "shortName",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Symbol Full Name",
  },
  {
    id: "marketType",
    numeric: true,
    disablePadding: false,
    label: "Exchange",
  },
  {
    id: "LotSize",
    numeric: true,
    disablePadding: false,
    label: "Lotsize",
  },
  {
    id: "tickSize",
    numeric: true,
    disablePadding: false,
    label: "Ticksize",
  },
  {
    id: "start_price",
    numeric: true,
    disablePadding: false,
    label: "Start Price",
  },
  {
    id: "end_price",
    numeric: true,
    disablePadding: false,
    label: "End Price",
  },
  {
    id: "setting",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    symbol,
    symbolBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell?.id}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={symbolBy === headCell?.id ? symbol : false}
          >
            <TableSortLabel
              active={symbolBy === headCell?.id}
              direction={symbolBy === headCell?.id ? symbol : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {symbolBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {symbol === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  symbol: PropTypes.oneOf(["asc", "desc"]).isRequired,
  symbolBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function AddViewBacktestSymbols() {
  const {
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {},
  });

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const watchCompanyName = watch("companyName", "");

  const [fyersSymbols, setFyersSymbols] = useState([]);
  const [dictList, setDictList] = useState([]);
  const [filterSymbolsData, setFilterSymbolsData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [symbol, setSymbol] = useState("desc");
  const [symbolBy, setSymbolBy] = useState("symbol");
  const [isOpen, setIsopen] = useState(false);
  const [filterParams, setFilterParams] = useState({
    shortName: "",
    marketType: "",
    LotSize: "",
    expiryDate: "",
  });

  const handleRequestSort = (event, property) => {
    const isAsc = symbolBy === property && symbol === "asc";
    setSymbol(isAsc ? "desc" : "asc");
    setSymbolBy(property);
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 500,
    matchFrom: "any",
    stringify: (option) => option?.companyName + option?.shortName,
  });

  const getFyersSymbols = async () => {
    if (store.getState()?.uploadExcel?.fyers_symbols === undefined) {
      await fyersSymbolGet(dispatch);
      if (store.getState()?.uploadExcel?.fyers_symbols) {
        const res = store.getState()?.uploadExcel?.fyers_symbols;
        if (res) {
          setFyersSymbols(res?.FyersSymbol);
        }
      }
    } else {
      setFyersSymbols(
        store.getState()?.uploadExcel?.fyers_symbols?.FyersSymbol
      );
    }
  };

  const compareDicts = (dict1, dict2) => {
    const sortedDict1 = Object.keys(dict1)
      .sort()
      .reduce((acc, key) => {
        acc[key] = dict1[key];
        return acc;
      }, {});

    const sortedDict2 = Object.keys(dict2)
      .sort()
      .reduce((acc, key) => {
        acc[key] = dict2[key];
        return acc;
      }, {});

    return JSON.stringify(sortedDict1) === JSON.stringify(sortedDict2);
  };

  const isDictInArray = (dict) => {
    if (filterSymbolsData) {
      return filterSymbolsData?.some((item) => compareDicts(item, dict));
    }
  };

  const addToDictArray = (dict) => {
    if (filterSymbolsData) {
      setFilterSymbolsData((prevState) => [...prevState, dict]);
    } else {
      setFilterSymbolsData(dict);
    }
  };

  const handleDictNotPresent = async (data) => {
    if (data.length !== 0) {
      await addAutoBacktestSymbol(dispatch, data);
      if (store.getState()?.backtest?.auto_backtest_add_symbols?.message) {
        addToDictArray(data);
        getBacktestSymbols();
        toast.success("Symbol added successfully!");
      } else if (store.getState()?.backtest?.auto_backtest_add_symbols?.error) {
        toast.error(
          store.getState()?.backtest?.auto_backtest_add_symbols?.error
            ? store.getState()?.backtest?.auto_backtest_add_symbols?.error
            : "Error while adding symbol. Please try again."
        );
      }
    }
  };

  const handleAddSymbol = async (data) => {
    if (!isDictInArray(data)) {
      handleDictNotPresent(data);
    } else {
      toast.info("Symbol already exist");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(filterSymbolsData, getComparator(symbol, symbolBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [symbol, symbolBy, page, rowsPerPage, filterSymbolsData]
  );

  const getBacktestSymbols = async () => {
    await getAutoBacktestAllSymbols(dispatch);
    if (store.getState()?.backtest?.get_all_auto_backtest_symbols) {
      setDictList(
        store.getState()?.backtest?.get_all_auto_backtest_symbols?.symbols
      );
      setFilterSymbolsData(
        store.getState()?.backtest?.get_all_auto_backtest_symbols?.symbols
      );
    }
  };

  const handleFilterChange = (e) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      [e.target.name]: e.target.value,
    }));
  };

  const ToggleSidebar = () => {
    setFilterParams({});
    reset();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const handleSubmit = async () => {
    const result = dictList.filter((item) => {
      return Object.keys(filterParams).every((key) => {
        return item[key] && item[key].includes(filterParams[key]);
      });
    });
    setFilterSymbolsData(result);
    isOpen === true ? setIsopen(false) : setIsopen(true);
    setPage(0);
  };

  const handleReset = (event) => {
    setFilterParams({});
    reset();
    getBacktestSymbols();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const handleFilterSymbol = (event, value) => {
    if (value !== null) {
      setFilterParams({ ...filterParams, shortName: value.shortName });
    }
  };

  const handleDropdownClick = () => {
    getFyersSymbols();
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filterSymbolsData?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleMultiSymbolDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete symbols?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteAutoBacktestSymbols(dispatch, selected);
        if (store.getState()?.backtest?.auto_backtest_symbol_delete) {
          toast.success(
            store.getState()?.backtest?.auto_backtest_symbol_delete?.message
          );
          getBacktestSymbols();
          setPage(0);
          setSelected([]);
        } else if (store.getState()?.backtest?.error) {
          toast.error(
            store.getState()?.backtest?.errorMessage
              ? store.getState()?.backtest?.errorMessage?.message
              : "Error deleting data. Please try again."
          );
        }
      }
    });
  };

  const handleAddFavourite = () => {
    if (selected.length !== 0) {
      // delte multiple records
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to add in favourite?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, add it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await updateAutoBacktestSymbolType(dispatch, selected).then((res) => {
            if (res?.data?.message) {
              Swal.fire(
                "Symbol Transfer Done!",
                "All selected record moved successfully.",
                "success"
              );
              const updatedData = dictList?.map(item =>
                selected.includes(item?._id) ? { ...item, type: '1' } : item
              );
              setDictList(updatedData);
              setFilterSymbolsData(updatedData);
              setSelected([]);
              setPage(0);
            }
          }).catch((error) => { })
        }
      });
    }
  }

  const handleFilterSymbolType = (event) => {
    if(event.target.value === "All"){
      setFilterSymbolsData(dictList)
    }else if(event.target.value === "Favourite"){
      const filteredData = dictList?.filter(item => item?.type === '1');
      setFilterSymbolsData(filteredData)
    }else if(event.target.value === "Unfavourite"){
      const filteredData = dictList?.filter(item => item?.type === '0');
      setFilterSymbolsData(filteredData)
    }
  };


  useEffect(() => {
    getBacktestSymbols();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="Main">
      <div className="container-fluid">
        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
          <div className="pt-4 sd-header">
            <Button className="adminbackBtn" onClick={ToggleSidebar}>
              <i className="feather icon-chevron-right"></i>
            </Button>
            <h3 className="mt-1">Filters</h3>
          </div>
          <div className="sd-body">
            <form>
              <div className="FilterForm">
                <div className="form-group">
                  <label>Symbol Full Name</label>
                  <Controller
                    name="shortName"
                    control={control}
                    rules={{ required: "Symbol is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        {...field}
                        filterOptions={filterOptions}
                        value={
                          dictList
                            ? dictList?.find(
                              (option) =>
                                option.shortName === (field.value || "")
                            ) || null
                            : null
                        }
                        options={dictList ? dictList : []}
                        onChange={(event, value) => {
                          field.onChange(value?.shortName || "");
                          if (value) {
                            handleFilterSymbol(event, value);
                          }
                        }}
                        getOptionLabel={(option) => option?.shortName || ""}
                        renderOption={(props, option) => (
                          <div
                            {...props}
                            key={option?.id}
                            style={{
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <div
                              style={{ fontSize: "13px" }}
                              className="symbolFont pt-1"
                            >
                              {option?.shortName?.toString()}
                            </div>
                            <div style={{ fontSize: "11px", color: "gray" }}>
                              <div
                                className="mr-2 companyNameFont"
                                style={{
                                  margin: "auto",
                                }}
                              >
                                {option?.companyName?.toString()}
                              </div>
                            </div>
                          </div>
                        )}
                        renderInput={(params) => <TextField {...params} />}
                        onClose={(event, reason) => {
                          if (reason === "toggleInput") {
                            field.onChange(null);
                            handleFilterSymbol(event, null);
                          }
                        }}
                      />
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Exchange</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Exchange"
                    name="marketType"
                    value={filterParams?.marketType || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Lot Size</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Lot Size"
                    name="LotSize"
                    value={filterParams?.LotSize || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Expiry Date</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Expiry Date"
                    name="expiryDate"
                    value={filterParams?.expiryDate || ""}
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <div className="d-flex pt-3 pb-4 applyChangeBtn">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleSubmit}
                >
                  Apply Changes
                </button>
                <button
                  type="button"
                  className="btn btn-square btn-danger mr-0"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/auto-backtest">Auto Backtest</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link
                          to="/add-view-backtest-symbols"
                          style={{ fontWeight: 600 }}
                        >
                          Add Symbols
                        </Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-3">
                      <div className="ListWithBadge">
                        <h3 className="m-b-20 mr-2">Add Backtest Symbols</h3>
                      </div>
                      <div className="tradingCreateButtons">
                        <button
                          type="button"
                          className="btn btn-outline-primary mr-0"
                          onClick={ToggleSidebar}
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <form>
                      <div className="card">
                        <div
                          className="card-body mobileCardBody"
                          style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        >
                          <div className="row col-md-12">
                            <div className="form-group col-md-12">
                              <label>Add Symbol*</label>
                              <Controller
                                name="companyName"
                                control={control}
                                rules={{ required: "Symbol is required" }}
                                render={({ field }) => (
                                  <div onClick={handleDropdownClick}>
                                    <Autocomplete
                                      fullWidth
                                      disablePortal
                                      id="combo-box-demo"
                                      {...field}
                                      filterOptions={filterOptions}
                                      value={
                                        fyersSymbols?.find(
                                          (option) =>
                                            option?.companyName ===
                                            watchCompanyName
                                        ) || null
                                      }
                                      options={fyersSymbols ? fyersSymbols : []}
                                      onChange={(event, value) => {
                                        field.onChange(
                                          value?.companyName || ""
                                        );
                                      }}
                                      getOptionLabel={(option) =>
                                        option?.companyName || ""
                                      }
                                      renderOption={(props, option) => (
                                        <div
                                          {...props}
                                          key={option?._id}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              float: "left",
                                              display: "flex",
                                            }}
                                          >
                                            <button
                                              className="btn btn-outline-primary px-2 py-0 mt-1"
                                              onClick={() =>
                                                handleAddSymbol(option)
                                              }
                                            >
                                              Add
                                            </button>
                                            <div className="symbolFont pt-1">
                                              {option?.shortName?.toString()}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              float: "right",
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                              className="mr-2 companyNameFont"
                                              style={{
                                                textAlign: "center",
                                                margin: "auto",
                                              }}
                                            >
                                              {option?.companyName?.toString()}
                                            </div>
                                            <span
                                              className={
                                                option?.marketType?.toString() ===
                                                  "NSE"
                                                  ? "badge badge-secondary nseBadge symbolFont"
                                                  : "badge badge-secondary bseBadge symbolFont"
                                              }
                                            >
                                              {option?.marketType?.toString()}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </div>
                                )}
                              />
                              {errors?.companyName && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.companyName?.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <Box sx={{ width: "100%" }}>
                        <Paper
                          sx={{ width: "100%", mb: 2 }}
                          className="customTable"
                        >
                          <Toolbar
                            sx={{
                              pl: { sm: 2 },
                              pr: { xs: 1, sm: 1 },
                              ...(selected?.length > 0 && {
                                bgcolor: (theme) =>
                                  alpha(
                                    theme.palette.primary.main,
                                    theme.palette.action.activatedOpacity
                                  ),
                              }),
                            }}
                            className="p-1"
                          >
                            {selected?.length > 0 ? (
                              <Typography
                                sx={{ flex: "1 1 100%" }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                              >
                                {selected?.length} selected
                              </Typography>
                            ) : (
                              <div style={{justifyContent: "space-between", display: "flex", width: "100%"}}>
                                <div className="ListWithBadge">
                                  <h3 className="m-b-20 mr-2">Symbol</h3>
                                  <h4 style={{ marginTop: "6px" }}>
                                    <span className="badge badge-secondary">
                                      {filterSymbolsData?.length}
                                    </span>
                                  </h4>
                                </div>
                                <div>
                                  <select
                                    className="form-control"
                                    defaultValue=""
                                    placeholder="Filter Symbol"
                                    onChange={handleFilterSymbolType}
                                  >
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    <option value="All">All</option>
                                    <option value="Favourite">Favourite</option>
                                    <option value="Unfavourite">Unfavourite</option>
                                    </select>
                                </div>
                              </div>
                            )}

                            {selected?.length > 0 ? (
                              <>
                                <button
                                  type="submit"
                                  className="btn btn-outline-primary mr-3 mt-1"
                                  onClick={handleAddFavourite}
                                >
                                  Add To Favourite
                                </button>
                                <Tooltip title="Delete">
                                  <IconButton onClick={handleMultiSymbolDelete}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : null}
                          </Toolbar>
                          <TableContainer>
                            <Table
                              className="addTableFontColor"
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                            >
                              <EnhancedTableHead
                                numSelected={selected?.length}
                                symbol={symbol}
                                symbolBy={symbolBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={filterSymbolsData?.length}
                              />

                              <TableBody>
                                {visibleRows &&
                                  visibleRows?.map((row, index) => {
                                    const isItemSelected = isSelected(row?._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row?._id}
                                        selected={isItemSelected}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            onClick={(event) =>
                                              handleClick(event, row?._id)
                                            }
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                              "aria-labelledby": labelId,
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell>{row?.shortName}</TableCell>
                                        <TableCell>
                                          {row?.companyName}
                                        </TableCell>
                                        <TableCell>{row?.marketType}</TableCell>
                                        <TableCell>{row?.LotSize}</TableCell>
                                        <TableCell>{row?.tickSize}</TableCell>
                                        <TableCell>
                                          {row?.start_price}
                                        </TableCell>
                                        <TableCell>{row?.end_price}</TableCell>
                                        <TableCell>
                                          <Link
                                            type="submit"
                                            className="btn btn-outline-primary mr-2 m-0 editDeleteBtn"
                                            to={`/backtest-symbol-edit/${row?._id}`}
                                          >
                                            <i className="feather icon-edit m-0"></i>
                                          </Link>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filterSymbolsData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddViewBacktestSymbols;
