import axiosClient from "../axios-client";
import {
    getActiveAccountNameStart,
    getActiveAccountNameSuccess,
    getActiveAccountNameFailure,
    uploadNewSymbolsStart,
    uploadNewSymbolsSuccess,
    uploadNewSymbolsFailure,
    getCronLastRunTimeStart,
    getCronLastRunTimeSuccess,
    getCronLastRunTimeFailure,
} from "../reducers/settingReducer";
import authHeader from "../services/auth-header";


export const settingGet = async (dispatch) => {
    try {
        const res = await axiosClient.get("/setting");

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const settingCreate = async (dispatch, settingData) => {
    try {
        const res = await axiosClient.post("/setting", settingData);

        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const settingDelete = async (id) => {
    try {
        const res = await axiosClient.delete(`/setting/${id}`);
        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const settingDeleteConfirmation = async (id) => {
    try {
        const res = await axiosClient.delete(`/confirmation/${id}`);
        return res?.data;
    } catch (error) {
        throw error;
    }
};

export const getActiveAccountName = async (dispatch) => {
    dispatch(getActiveAccountNameStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get("/active-account", {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getActiveAccountNameSuccess(res.data));
    } catch (error) {
        dispatch(getActiveAccountNameFailure(error.response?.data));
    }
};

export const uploadNewSymbols = async (dispatch, broker) => {
    dispatch(uploadNewSymbolsStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/upload-new-symbols/${broker}`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(uploadNewSymbolsSuccess(res.data));
    } catch (error) {
        dispatch(uploadNewSymbolsFailure(error.response?.data));
    }
};

export const getCronLastRunTime = async (dispatch) => {
    dispatch(getCronLastRunTimeStart());
    try {
        const token = authHeader();

        const res = await axiosClient.get(`/get_cron_times`, {
            headers: {
                Authorization: token,
            },
        });
        dispatch(getCronLastRunTimeSuccess(res.data));
    } catch (error) {
        dispatch(getCronLastRunTimeFailure(error.response?.data));
    }
};
