// BASIC IMPORTS
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

// API IMPORT
import { register as registerPage } from "../../../actions/authAction";


const EmailVerification = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);

  const onSubmit = async (data) => {
    const email = localStorage.getItem("register");
    const user = {
      email: email,
      otp: data["verify_code"],
    };
    await registerPage(dispatch, user);
    if (store.getState()?.auth?.auth) {
      if (typeof store.getState()?.auth?.auth?.hasOwnProperty("accessToken")) {
        navigate("/");
      }
      toast.success(store.getState()?.auth?.auth["message"]);
    } else if (store.getState()?.auth?.error) {
      toast.error(
        store.getState()?.auth?.errorMessage
          ? store.getState()?.auth?.errorMessage?.message
          : "Invalid OTP"
      );
    }
  };
  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r"></span>
          <span className="r s"></span>
          <span className="r s"></span>
          <span className="r"></span>
        </div>
        <form id="emailVerificationForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4">
                <i className="feather icon-mail auth-icon"></i>
              </div>
              <h3 className="mb-4">Email Verification</h3>
              <div className="input-group mb-3">
                <div className="input-group">
                  <input
                    type="number"
                    id="verify_code"
                    className="form-control"
                    name="verify_code"
                    placeholder="OTP"
                    {...register("verify_code", {
                      required: "code is required",
                      minLength: {
                        value: 4,
                        message: "Invalid code!",
                      },
                      maxLength: {
                        value: 4,
                        message: "Invalid code!",
                      },
                    })}
                  />
                </div>
                {errors?.verify_code && (
                  <div
                    id="newPaswordAlert"
                    className="validationAlert"
                    style={{ margin: "10px 0px" }}
                  >
                    {errors?.verify_code.message}
                  </div>
                )}
              </div>
              <button className="btn btn-primary mb-4 shadow-2">Verify</button>
              <p className="mb-0 text-muted">
                Allready have an account?
                <button className="customeBtn" onClick={() => navigate("/")}>
                  Login
                </button>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailVerification;
