// BASIC IMPORT
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// API IMPORT
import { emailVerification } from "../../../actions/authAction";


const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    localStorage.setItem("register", data?.email);
    if (data) {
      await emailVerification(dispatch, data);
      if (store.getState()?.auth?.auth) {
        navigate("/email-verify");
        toast.success("Verification code sened to your email");
      } else if (store.getState()?.auth?.error) {
        toast.error(
          store.getState()?.auth?.errorMessage
            ? store.getState()?.auth?.errorMessage?.message
            : "User already registered"
        );
      }
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r"></span>
          <span className="r s"></span>
          <span className="r s"></span>
          <span className="r"></span>
        </div>
        <form id="registerForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4">
                <i className="feather icon-user-plus auth-icon"></i>
              </div>
              <h3 className="mb-4">Sign up</h3>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control fixInputWidth"
                  placeholder="Username"
                  id="username"
                  {...register("username", {
                    required: "Username is required",
                    maxLength: {
                      value: 100,
                    },
                  })}
                />
                {errors?.username && (
                  <div
                    id="firstNameAlert"
                    className="validationAlert"
                    style={{ margin: "10px 0px" }}
                  >
                    {errors?.username?.message}
                  </div>
                )}
              </div>
              <div className="input-group mb-4">
                <input
                  type="email"
                  className="form-control fixInputWidth"
                  id="email"
                  placeholder="Email"
                  {...register("email", {
                    required: "Email is required",
                    maxLength: {
                      value: 100,
                    },
                  })}
                />
                {errors?.email && (
                  <div
                    className="validationAlert"
                    style={{ margin: "10px 0px" }}
                  >
                    {errors?.email?.message}
                  </div>
                )}
              </div>
              <div className="input-group mb-4">
                <div className="input-group">
                  <input
                    type={passwordType}
                    id="registerPassword"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters!",
                      },
                      maxLength: {
                        value: 16,
                        message:
                          "Password must not be longer than 16 characters!",
                      },
                    })}
                  />
                  <div
                    className="input-group-append"
                    onClick={() => togglePassword("password")}
                  >
                    <span className="input-group-text">
                      {passwordType ? (
                        <i className="fa fa-fw fa-eye field-icon"></i>
                      ) : (
                        <i className="fa fa-fw fa-eye-slash field-icon"></i>
                      )}
                    </span>
                  </div>
                </div>
                {errors?.password && (
                  <div
                    id="pasworAlert"
                    className="validationAlert"
                    style={{ margin: "10px 0px" }}
                  >
                    {errors?.password?.message}
                  </div>
                )}
              </div>
              <button className="btn btn-primary shadow-2 mb-4" type="submit">
                Sign up
              </button>
              <p className="mb-0 text-muted">
                Allready have an account?{" "}
                <button className="customeBtn" onClick={() => navigate("/")}>
                  {" "}
                  Log in
                </button>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
