// BASIC IMPORTS
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";

// MUI IMPORTS
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography } from '@mui/material';

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";

// API IMPORTS
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";
import { saveOptionStrategyOneData, getOptionStrategyOneData, updateOptionStrategyOneStatus, getSymbolExpirys } from "../../../actions/optionStrategyOneAction";
import { toast } from "react-toastify";

function OptionStrategy() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      account: "",
      symbol: "",
      formData: [{ id: 1, level: '' }]
    },
  });

  const [dropdownList, setDropdownList] = useState({ setting: [] });
  const [triggers, setTriggers] = useState([]);
  const [formData, setFormData] = useState([{ id: 1, level: '' }]);
  const [expiryData, setExpiryData] = useState({})
  const expiries = useRef()

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const selectedAccount = data.account.split("|");
    const submissionData = {
      ...data,
      account_id: selectedAccount[0],
      account_name: selectedAccount[1],
    };
    await saveOptionStrategyOneData(dispatch, submissionData)
      .then((res) => {
        toast.success("Levles successfully saved in json file");
        handleResetForm(); // Call handleResetForm to reset the form after saving
      })
      .catch((error) => {
        console.error("Error saving data", error);
      });
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState().exchange?.exchangeProductSettingList) {
      const res = store.getState().exchange?.exchangeProductSettingList;
      if (res) {
        setDropdownList(res?.data);
      }
    }
  };

  const handleFormField = () => {
    // Get current form values
    const currentFormData = watch("formData") || [];

    // Create a new form field
    const newForm = {
      id: currentFormData.length + 1,
      level: ''
    };

    // Add the new form field to the existing ones
    setFormData([...currentFormData, newForm]);

    // Update react-hook-form state
    setValue("formData", [...currentFormData, newForm]);
  };


  const handleResetForm = () => {
    // Reset local state
    const initialFormData = [{ id: 1, level: '' }];
    setFormData(initialFormData);

    // Reset react-hook-form state
    reset({
      account: watch("account"),
      symbol: watch("symbol"),
      formData: initialFormData
    });
  };

  const getOptionBacktestData = async () => {
    await getOptionStrategyOneData(dispatch).then((res) => {
      const { status, account_id, account_name, symbol, levels, triggers, expiry_date } = res?.data || {};
      if (account_id && account_name && symbol) {
        setValue("account", `${account_id}|${account_name}`);
        setValue("symbol", symbol);
        setValue("expiry_date", expiry_date);
        setExpiryData({ "symbol": symbol, "account_id": account_id })

        setTriggers(triggers || [])

        // Map levels data to formData
        const levelsData = levels.map((levelData, index) => ({
          id: index + 1,
          ...levelData,
          level: levelData.level || ''
        }));
        setFormData(levelsData);
        setValue("formData", levelsData); // Update react-hook-form state
      }
    }).catch(() => { });
  }

  const handleStart = async () => {
    await updateOptionStrategyOneStatus(dispatch, "running").then((res) =>{
      toast.success("Strategy successfully running");
    }).catch((error) => {})
  }

  const handleExpiry = async () => {
    if (!expiries.current && Object.keys(expiryData).length === 2) {
      await getSymbolExpirys(dispatch, expiryData).then((res) => {
        expiries.current = res
      }).catch((error) => { })
    }
  }

  const handleSymbol = (event) => {
    if(event.target.value){
      setExpiryData({...expiryData, "symbol": event.target.value})
    }
  } 
  
  const handleAccount = (event) => {
    const accountId = event.target.value.split("|")[0]
    setExpiryData({...expiryData, "account_id": accountId}) 
  }

  useEffect(() => {
    getSettingData();
    getOptionBacktestData();
  }, []);

  useEffect(() => {
    handleExpiry()
  }, [expiryData])

  const totalDifference = triggers.reduce((acc, trigger) => acc + (trigger.difference || 0), 0);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/option-strategy-one" style={{ fontWeight: 600 }}>
                            Option Strategy
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-body pt-3 pb-1">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-3 pr-0">
                            <label>Account*</label>
                            <select
                              className="form-control"
                              {...register("account", { required: true })}
                              defaultValue=""
                              onChange={handleAccount}
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              {dropdownList?.setting?.map((setting) => {
                                const id = Object.keys(setting)[0];
                                const nestedObject = setting[id];
                                const key = Object.keys(nestedObject)[0];
                                const value = nestedObject[key];

                                return value === "FYERS" ? (
                                  <option key={id} value={`${id}|${key}`}>
                                    {key}
                                  </option>
                                ) : null;
                              })}
                            </select>
                            {errors.account && (
                              <span className="validationAlert">
                                Account is required
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Symbol*</label>
                            <select
                              className="form-control"
                              {...register("symbol", { required: true })}
                              defaultValue=""
                              onChange={handleSymbol}
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              <option value="NSE:NIFTY50-INDEX">NSE:NIFTY50-INDEX</option>
                            </select>
                            {errors.symbol && (
                              <span className="validationAlert">
                                Symbol is required
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Expiry Date*</label>
                            <Controller
                              name="expiry_date"
                              control={control}
                              defaultValue="" // Default value if not set
                              render={({ field }) => (
                                <select
                                  className="form-control"
                                  {...field} // Spread the field attributes
                                  onClick={handleExpiry} // Make sure this is invoked correctly
                                >
                                  <option value="" disabled>
                                    Select...
                                  </option>
                                  {expiries.current?.map((data, index) => (
                                    <option key={index} value={data}>
                                      {data}
                                    </option>
                                  ))}
                                </select>
                              )}
                            />
                            {errors.expiry_date && (
                              <span className="validationAlert">
                                Expiry date is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row col-md-12">
                          {formData?.map((form, index) => (
                            <div className="col-md-2 pr-0" key={index}>
                              <div className="form-group pr-0">
                                <label>{index + 1} Level*</label>
                                <input
                                  id={`level_${index}`}
                                  className="form-control"
                                  placeholder="Level"
                                  {...register(`formData[${index}].level`, {
                                    required: "Level is required",
                                    maxLength: { value: 100 }
                                  })}
                                  defaultValue={form.level} // Set default value
                                />
                                {errors?.formData && errors.formData[index]?.level && (
                                  <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                    {errors.formData[index].level.message}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2 text-center">
                          <button
                            type="submit"
                            className="btn btn-outline-primary ml-3 px-4"
                          >
                            Save in json
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary ml-3 px-4"
                            onClick={handleStart}
                          >
                            Start
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary ml-3 px-3"
                            onClick={handleFormField}
                          >
                            Add Form
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger ml-3 px-4"
                            onClick={handleResetForm}
                          >
                            Clear
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>


              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-body p-4">
                      <Typography variant="h6" gutterBottom>
                        Trade Triggers
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Time</TableCell>
                              <TableCell>SL</TableCell>
                              <TableCell>High</TableCell>
                              <TableCell>Low</TableCell>
                              <TableCell>Level</TableCell>
                              <TableCell>Trade</TableCell>
                              <TableCell>Dif</TableCell>
                              <TableCell>Type</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {triggers?.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                  Not found
                                </TableCell>
                              </TableRow>
                            ) : (
                              triggers?.map((trigger, index) => (
                                <TableRow key={index}>
                                  <TableCell>{new Date(trigger?.time).toLocaleString()}</TableCell>
                                  <TableCell>{trigger?.active_high_price ? "SL HIT" : ""}</TableCell>
                                  <TableCell>{trigger?.active_high_price}</TableCell>
                                  <TableCell>{trigger?.active_low_price}</TableCell>
                                  <TableCell>{trigger?.level}</TableCell>
                                  <TableCell>{trigger?.trade} ({trigger?.trade_latest_price})</TableCell>
                                  <TableCell>{trigger?.difference}</TableCell>
                                  <TableCell>{trigger?.type}</TableCell>
                                </TableRow>
                              ))
                            )}
                            <TableRow>
                              <TableCell colSpan={6} style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</TableCell>
                              <TableCell>{totalDifference.toFixed(2)}</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OptionStrategy;
