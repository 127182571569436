// BASIC IMPORTS
import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


// MUI IMPORTS
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Button, TextField, Switch } from "@mui/material";

// API IMPORTS
import { tradingDeleteMany } from "../../../actions/tradingAction";


function TradingEditForm({
  initialValues,
  onSubmit,
  marketData,
  dropList,
  product,
  setProduct,
}) {
  const [lotSize, setLotSize] = useState(0);
  const [selectedSymbol, setSetectedSymbol] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [accountSymbols, setAccountSymbols] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({ defaultValues: initialValues });

  const getUniqueData = (data) => {
    const seen = new Set();
    return data.filter(item => {
        const identifier = `${item.shortName}-${item.marketType}`;
        if (seen.has(identifier)) {
            return false;
        }
        seen.add(identifier);
        return true;
    });
};


  useEffect(() => {
    const uniqueData = getUniqueData(marketData);
    setFilterData(uniqueData);
  }, [marketData]);

  const watchCompanyName = watch("companyName", "");
  const watchSymbol = watch("symbol", "");
  const watchExpiryDate = watch("expiryDate", "");
  const watchExchange = watch("exchange", "");
  const watchProduct = watch("product", "");
  const switchTypeValue = watch("switchType", "");
  const watchAccoutName = watch("accountName", "");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 100,
    matchFrom: "any",
    stringify: (option) => option?.companyName + option?.shortName,
  });

  const deltePair = async (data) => {
    await tradingDeleteMany(dispatch, data);
    if (store.getState()?.trading?.tradingData) {
      Swal.fire(
        "Deleted!",
        "Pair deleted successfully.",
        "success"
      );
      navigate("/trading")
    } else if (store.getState()?.trading?.error) {
      toast.error(
        store.getState()?.trading?.errorMessage
          ? store.getState()?.trading?.errorMessage?.message
          : "Error deleting data. Please try again."
      );
    }
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete pair?`,
      icon: "warning",
      showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonColor: "#6c757d",
        cancelButtonText: "Cancel",
        showDenyButton: true,
        denyButtonColor: "#d33",
        denyButtonText: "Cancel with Delete",
        reverseButtons: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { ids: [id], status: 0 };
        await deltePair(data)
      } else if (result.isDenied) {
        const data = { ids: [id], status: 1 };
        await deltePair(data)
      }
    });
  };

  const filterSymbols = (account) => {
    const selectedValue = dropList?.setting?.find((setting) => {
      const nestedObject = setting[Object.keys(setting)[0]]; // Extract the nested object
      return nestedObject.hasOwnProperty(account);
    });
    const selectedAccount = Object.values(selectedValue)[0]; // Extract the account name from selectedValue

    const filteredData = dropList?.symbols?.filter((item) => {
      return item?.accountName === selectedAccount[account];
    });
    setAccountSymbols(filteredData);
    setValue("companyName", "");
    setValue("symbol", "");
    setValue("product", "");
    setLotSize(0);
    setSetectedSymbol(false);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const getValueByKey = (keyToFind, symbols) => {
    if (!Array.isArray(symbols)) return null; // Make sure symbols is an array

    for (const symbol of symbols) {
      const symbolId = Object.keys(symbol)[0]; // Assuming there's always one key
      const symbolData = symbol[symbolId];
      if (symbolData.hasOwnProperty(keyToFind)) {
        return symbolData[keyToFind];
      }
    }

    return null; // Return null if key is not found
  };

  const getAllAccountProducts = () => {
    const value = getValueByKey(watchAccoutName, dropList?.setting);
    if (value) {
      const filteredDatas = dropList?.product?.filter((data) => {
        return data?.includes(value);
      });
      const result = filteredDatas?.map((str) => {
        const parts = str.split(" : ");
        return { [parts[1]]: parts[2] };
      });
      setProduct(result);
    }
  };

  useEffect(() => {
    if (dropList && dropList?.setting) {
      getAllAccountProducts();
    }
    if (
      initialValues !== null &&
      initialValues !== undefined &&
      dropList?.setting !== null &&
      dropList?.setting !== undefined
    ) {
      const selectedValue = dropList?.setting?.find((setting) => {
        const nestedObject = setting[Object.keys(setting)[0]]; // Extract the nested object
        return nestedObject.hasOwnProperty(initialValues?.accountName);
      });
      if(selectedValue){
        const selectedAccount = Object.values(selectedValue)[0]; // Extract the account name from selectedValue
  
        const filteredData = dropList?.symbols?.filter((item) => {
          return (
            item?.accountName === selectedAccount[initialValues?.accountName]
          );
        });
        setAccountSymbols(filteredData);
      }
    }

    // eslint-disable-next-line
  }, [dropList, initialValues, watchAccoutName]);

  useEffect(() => {
    if (initialValues) {
      // Set form values when the component mounts
      Object?.keys(initialValues).forEach((key) => {
        if (key === "cr_at") {
          const formattedDate = formatDate(initialValues[key]);
          setValue(key, formattedDate);
        } else if (key === "product") {
          const foundItem = product?.find(
            (item) => Object.keys(item)[0] === initialValues[key]
          );
          const productVal = foundItem ? Object.values(foundItem)[0] : null;
          setValue(key, productVal);
        } else {
          setValue(key, initialValues[key]);
        }
      });
    }
    // eslint-disable-next-line
  }, [initialValues]);

  useEffect(() => {
    if (initialValues) {
      // Set form values when the component mounts
      Object?.keys(initialValues).forEach((key) => {
        if (key === "product") {
          const foundItem = product?.find(
            (item) => Object.keys(item)[0] === initialValues[key]
          );
          const productVal = foundItem ? Object.values(foundItem)[0] : null;
          setValue(key, productVal);
        }
      });
    }
    // eslint-disable-next-line
  }, [initialValues, product]);

  useEffect(() => {
    if (
      watchExchange?.toUpperCase() === "NSE" &&
      accountSymbols?.length !== 0
    ) {
      setFilterData(
        accountSymbols?.filter((item) => item?.marketType === "NSE")
      );
    } else if (
      watchExchange?.toUpperCase() === "BSE" &&
      accountSymbols?.length !== 0
    ) {
      setFilterData(
        accountSymbols?.filter((item) => item?.marketType === "BSE")
      );
    } else if (
      watchExchange?.toUpperCase() === "NFO" &&
      accountSymbols?.length !== 0
    ) {
      setFilterData(
        accountSymbols?.filter((item) => item?.marketType === "NFO")
      );
    }
    // eslint-disable-next-line
  }, [watchExchange, accountSymbols]);

  useEffect(() => {
    if (filterData?.length !== 0 && watchExchange) {
      setValue("companyName", "");
      setValue("symbol", "");
      setLotSize(0);
      setSetectedSymbol(false);
    }
    // eslint-disable-next-line
  }, [watchExchange]);

  useEffect(() => {
    if (selectedSymbol === false) {
      setValue("companyName", "");
      setValue("symbol", "");
    } else {
      setValue(
        "companyName",
        selectedSymbol?.companyName
          ? selectedSymbol?.companyName
          : initialValues?.companyName
      );
      setValue(
        "symbol",
        selectedSymbol?.shortName
          ? selectedSymbol?.shortName
          : initialValues?.symbol
      );
    }
    // eslint-disable-next-line
  }, [initialValues, watchSymbol]);

  return (
    <>
      <div className="page-header-title d-flex justify-content-between pb-3">
        <div className="ListWithBadge">
          <Button className="adminbackBtn" onClick={() => navigate("/trading")}>
            <i className="feather icon-chevron-right"></i>
          </Button>
          <h3 className="m-b-20 mr-2">Edit</h3>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-square btn-danger mr-0"
            onClick={() => handleDelete(initialValues?._id)}
          >
            <i className="feather icon-trash-2"></i> Delete
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body mobileCardBody">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Pair Id*</label>
                    <input
                      type="name"
                      name="_id"
                      className="form-control"
                      disabled={true}
                      value={initialValues?._id || ""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Order Id*</label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Order Id"
                      {...register("orderId", {})}
                    />
                    {errors?.orderId && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.orderId?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-12">
                    <label>Account*</label>
                    <Controller
                      name="accountName"
                      control={control}
                      rules={{
                        required: "Account is required",
                      }}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="form-control"
                          value={watchAccoutName || ""}
                          onChange={(e) => {
                            filterSymbols(e.target.value);
                            field.onChange(e);
                          }}
                        >
                          <option value="" disabled>
                            Select...
                          </option>
                          {dropList?.setting?.map((setting) => {
                            // Extracting the nested key from each setting object
                            const nestedKey = Object.keys(setting)[0];
                            // Extracting the nested keys from the nested object
                            const keys = Object.keys(setting[nestedKey]);
                            return keys.map((key, index) => (
                              <option key={index} value={key}>
                                {key}
                              </option>
                            ));
                          })}
                        </select>
                      )}
                    />
                    <div
                      className="validationAlert"
                      style={{ margin: "10px 0px" }}
                    >
                      {errors?.accountName?.message}
                    </div>
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-12">
                    <label>Exchange*</label>
                    <Controller
                      name="exchange"
                      control={control}
                      rules={{
                        required: "Exchange is required",
                      }}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="form-control"
                          value={watchExchange || ""}
                        >
                          <option value="" disabled>
                            Select...
                          </option>
                          {dropList?.exchange?.map((data, index) => (
                            <option key={index}>{data}</option>
                          ))}
                        </select>
                      )}
                    />
                    <div
                      className="validationAlert"
                      style={{ margin: "10px 0px" }}
                    >
                      {errors?.exchange?.message}
                    </div>
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-12">
                    <label>Product*</label>
                    <Controller
                      name="product"
                      control={control}
                      rules={{ required: "Product is required" }}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="form-control"
                          value={watchProduct || ""}
                        >
                          <option value="" disabled>
                            Select...
                          </option>
                          {product?.map((data, index) => (
                            <option key={index}>
                              {Object.values(data).join(", ")}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    <div
                      className="validationAlert"
                      style={{ margin: "10px 0px" }}
                    >
                      {errors?.product?.message}
                    </div>
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-12">
                    <label>Symbol*</label>
                    <Controller
                      name="symbol"
                      control={control}
                      rules={{ required: "Symbol full name is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          {...field}
                          filterOptions={filterOptions}
                          value={watchSymbol || null}
                          isOptionEqualToValue={(option, value) =>
                            option?.shortName === value
                          }
                          options={filterData ? filterData : []} // Assuming filterData is your array of options
                          getOptionLabel={(option) => {
                            return typeof option === "string"
                              ? option
                              : typeof option === "object"
                              ? option?.shortName
                              : "";
                          }}
                          onChange={(event, newValue) => {
                            setSetectedSymbol(newValue);
                            setLotSize(newValue?.LotSize);
                            field.onChange(newValue?.shortName || ""); // Update the form data object with the selected value
                          }}
                          renderOption={(props, option) => (
                            <div
                              {...props}
                              key={option?.id}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  float: "left",
                                }}
                              >
                                <div className="symbolFont">
                                  {option?.shortName?.toString()}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  float: "right",
                                }}
                              >
                                <div className="mr-2 companyNameFont">
                                  {option?.companyName?.toString()}
                                </div>
                                <div className="pt-1">
                                  <span
                                    className={
                                      option?.marketType?.toString() === "NSE"
                                        ? "badge badge-secondary nseBadge symbolFont"
                                        : "badge badge-secondary bseBadge symbolFont"
                                    }
                                  >
                                    {option?.marketType?.toString()}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    />
                    {errors?.shortName && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.shortName?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Symbol Full Name*</label>
                    <input
                      type="name"
                      name="companyName"
                      className="form-control"
                      placeholder="Symbol Full Name"
                      disabled={true}
                      value={watchCompanyName || ""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Expiry Date*</label>
                    <input
                      type="name"
                      name="expiryDate"
                      className="form-control"
                      placeholder="Expiry Date"
                      disabled={true}
                      value={watchExpiryDate || ""}
                    />
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Buy Price*</label>
                    <input
                      // type="number"
                      className="form-control"
                      placeholder="Buy Price"
                      {...register("buyPrice", {
                        required: "Buy price is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.buyPrice && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.buyPrice?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Buy Status*</label>
                    <Controller
                      name="buyStatus"
                      control={control}
                      rules={{
                        required: "Buy status is required",
                      }}
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option>Done</option>
                            <option>Pending</option>
                          </select>
                          {field.touched && field.value === "Enabled" && (
                            <div
                              className="validationAlert"
                              style={{ margin: "10px 0px" }}
                            >
                              {errors?.buyStatus?.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Sell Price*</label>
                    <input
                      // type="number"
                      className="form-control"
                      placeholder="Sell Price"
                      {...register("sellPrice", {
                        required: "Sell price is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.sellPrice && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.sellPrice?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Sell Status*</label>
                    <Controller
                      name="sellStatus"
                      control={control}
                      rules={{
                        required: "Sell status is required",
                      }}
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option>Done</option>
                            <option>Pending</option>
                          </select>
                          {field.touched && field.value === "Enabled" && (
                            <div
                              className="validationAlert"
                              style={{ margin: "10px 0px" }}
                            >
                              {errors?.sellStatus?.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Quantity*</label>
                    {lotSize ? (
                      <label className="pl-2">(Lotsize={lotSize})</label>
                    ) : null}
                    <input
                      // type="number"
                      id="quantity"
                      className="form-control"
                      placeholder="Quantity"
                      {...register("quantity", {
                        required: "Quantity is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.quantity && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.quantity?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Status*</label>
                    <Controller
                      name="status"
                      control={control}
                      rules={{ required: "Status is required" }}
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option>Enabled</option>
                            <option>Disabled</option>
                            <option>In Progress</option>
                          </select>
                          {field.touched && field.value === "Enabled" && (
                            <div
                              className="validationAlert"
                              style={{ margin: "10px 0px" }}
                            >
                              {errors?.status?.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Created At*</label>
                    <input
                      id="cr_at"
                      className="form-control"
                      disabled={true}
                      placeholder="Expiry Date"
                      {...register("cr_at", {})}
                    />
                  </div>

                  <div className="form-group col-md-12">
                    <label>Switch Type*</label>
                    <div
                      style={{
                        display: "flax",
                        color: "cadetblue",
                        fontWeight: 500,
                      }}
                    >
                      <label>Buy</label>
                      <Switch
                        name="switchType"
                        color="default"
                        {...register("switchType", { required: false })}
                        checked={switchTypeValue === "Sell" ? true : false}
                        onChange={(event) => {
                          setValue(
                            "switchType",
                            event.target.checked ? "Sell" : "Buy"
                          );
                        }}
                        className="switchStyle"
                      />
                      <label>Sell</label>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="row col-md-12 justify-content-center pt-3">
                    <button type="submit" className="btn btn-primary ml-3 px-5">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TradingEditForm;
