// BASIC IMPORTS
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosClient from "../../../axios-client";
import authHeader from "../../../services/auth-header";
import { toast } from "react-toastify";


export default function GenerateToken() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const stateName = searchParams.get("state");
  const stateNameArray = stateName.split("_");
  const token = authHeader();

  useEffect(() => {
    if (stateNameArray[0] === "fyers") {
      const code = searchParams.get("auth_code");
      if (code) {
        const getToken = async () => {
          const data = {
            code: code,
            account_id: stateNameArray[1],
          };
          await axiosClient
            .post("/broker_access_token", data, {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              toast.success("Fyers Token Refreshed Successfully");
              navigate("/setting");
            })
            .catch((error) => {
              toast.error("Token not refreshed due to some error");
            });
        };
        getToken();
      }
    } else if (stateNameArray[0] === "upstox") {
      const code = searchParams.get("code");
      if (code) {
        const getToken = async () => {
          const data = {
            code: code,
            account_id: stateNameArray[1],
          };
          await axiosClient
            .post("/broker_access_token", data, {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              toast.success("Upstox Token Refreshed Successfully");
              navigate("/setting");
            })
            .catch((error) => {
              toast.error("Token not refreshed due to some error");
            });
        };
        getToken();
      }
    }
    // eslint-disable-next-line
  }, []);

  return <></>;
}
