// BASIC IMPORTS
import React from "react";
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../../main/SharedStateContext";
import CycleCount from "./CycleCount"
import SymbolAveragePrice from "./SymbolAveragePrice"

function Dashboard() {
    return (
        <div className="Main">
            <SharedStateProvider>
                <SideBar />
                <Header />
            </SharedStateProvider>

            <section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <CycleCount />
                        <SymbolAveragePrice />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Dashboard;
