// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

// MUI IMPORTS
import { alpha } from "@mui/material/styles";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Button, TextField, Modal } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { WebsocketContext } from "../../../websocket/websocket";
import NotFound from "../../../images/not_found.png";
import Sound from "../../../sound_file/sound.mp3";

// API IMPORTS
import { tradingUpdateErrorStatus } from "../../../actions/tradingAction";
import { symbolsAccountsGet } from "../../../actions/symbolAction";
import { tradingGet, tradingDeleteMany, tradingFilter } from "../../../actions/tradingAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "accountName",
    numeric: false,
    disablePadding: false,
    label: "Account",
  },
  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "switchType",
    numeric: false,
    disablePadding: false,
    label: "Side",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "buyPrice",
    numeric: true,
    disablePadding: false,
    label: "Buy Price",
  },
  {
    id: "sellPrice",
    numeric: true,
    disablePadding: false,
    label: "Sell Price",
  },
  {
    id: "cycle_done",
    numeric: true,
    disablePadding: false,
    label: "Cycle",
  },
  {
    id: "buyStatus",
    numeric: false,
    disablePadding: false,
    label: "Buy Status",
  },
  {
    id: "sellStatus",
    numeric: false,
    disablePadding: false,
    label: "Sell Status",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "errorMessage",
    numeric: false,
    disablePadding: false,
    label: "Error",
  },
  {
    id: "setting",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell?.id}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell?.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell?.id ? order : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {orderBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const filterOptions = createFilterOptions({
  ignoreCase: true,
  limit: 100,
  matchFrom: "any",
  stringify: (option) => option?.companyName + option?.shortName,
});

function Trading() {
  const { control, reset } = useForm({
    defaultValues: {},
  });

  const [isReady, socket, message, setMessage] = useContext(WebsocketContext);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tradingList, setTradingList] = useState([]);
  const [isOpen, setIsopen] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [openModals, setOpenModals] = useState({});
  const [prevMessage, setPrevMessage] = useState(null);
  const [filterParams, setFilterParams] = useState({
    accountName: "",
    companyName: "",
    symbol: "",
    quantity: "",
    cycle_done: "",
    buyPrice: "",
    sellPrice: "",
    buyStatus: "",
    sellStatus: "",
    status: "",
  });

  const { store } = useContext(ReactReduxContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const client_state = useSelector((state) => state?.auth);
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tradingList?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(tradingList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, tradingList]
  );

  const ToggleSidebar = () => {
    setFilterParams();
    reset();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const getTradingData = async () => {
    await tradingGet(dispatch);
    if (store.getState()?.trading.tradingList) {
      const trading_data = store.getState()?.trading?.tradingList;

      const rows = trading_data.data.map((transaction) => ({
        id: transaction?._id,
        created_at: new Date(transaction?.createdAt).toLocaleString(),
        accountName: transaction?.accountName,
        accountId: transaction?.accountId,
        symbol: transaction?.symbol,
        quantity: transaction?.quantity,
        cycle_done: transaction?.cycle_done,
        buyPrice: transaction?.buyPrice,
        sellPrice: transaction?.sellPrice,
        buyStatus: transaction?.buyStatus,
        sellStatus: transaction?.sellStatus,
        status: transaction?.status,
        cr_at: transaction?.cr_at,
        errorMessage: transaction?.errorMessage,
        switchType: transaction?.switchType,
      }));
      setMessage();
      setTradingList(rows);
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete pair?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
      cancelButtonColor: "#6c757d",
      cancelButtonText: "Cancel",
      showDenyButton: true,
      denyButtonColor: "#d33",
      denyButtonText: "Cancel with Delete",
      reverseButtons: false, // Keep buttons in their defined order
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { ids: [id], status: 0 };
        await deltePair(data)
      } else if (result.isDenied) {
        const data = { ids: [id], status: 1 };
        await deltePair(data)
      }
    });
  };

  const handleFilterChange = (e) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await tradingFilter(dispatch, filterParams);
      const trading_data = store.getState()?.trading?.tradingList;
      if (trading_data) {
        const rows = trading_data?.data?.map((transaction) => ({
          id: transaction?._id,
          created_at: new Date(transaction?.createdAt).toLocaleString(),
          accountName: transaction?.accountName,
          accountId: transaction?.accountId,
          symbol: transaction?.symbol,
          quantity: transaction?.quantity,
          cycle_done: transaction?.cycle_done,
          buyPrice: transaction?.buyPrice,
          sellPrice: transaction?.sellPrice,
          buyStatus: transaction?.buyStatus,
          sellStatus: transaction?.sellStatus,
          status: transaction?.status,
          errorMessage: transaction?.errorMessage,
          switchType: transaction?.switchType,
        }));
        setTradingList(rows);
        setPage(0);
      }
    } catch (error) {
      console.log("Error fetching stocks:", error);
    }
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const deltePair = async (data) => {
    await tradingDeleteMany(dispatch, data);
    if (store.getState()?.trading?.tradingData) {
      Swal.fire(
        "Deleted!",
        "Pair deleted successfully.",
        "success"
      );
      setPage(0);
      setRowsPerPage(10);
      getTradingData();
      setSelected([]);
    } else if (store.getState()?.trading?.error) {
      toast.error(
        store.getState()?.trading?.errorMessage
          ? store.getState()?.trading?.errorMessage?.message
          : "Error deleting data. Please try again."
      );
    }
  }

  const handleMultiDelete = async () => {
    if (selected?.length !== 0) {
      // delte multiple records
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to delete the selected ${selected?.length} record?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonColor: "#6c757d",
        cancelButtonText: "Cancel",
        showDenyButton: true,
        denyButtonColor: "#d33",
        denyButtonText: "Cancel with Delete",
        reverseButtons: false,  
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { ids: selected, status: 0 };
          await deltePair(data)
        } else if (result.isDenied) {
          const data = { ids: selected, status: 1 };
          await deltePair(data)
        }
      });
    }
  };

  const handleReset = (event) => {
    setFilterParams({});
    getTradingData();
    reset();
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const handleReorder = async (client_id) => {
    await tradingUpdateErrorStatus(dispatch, client_id);
    if (store.getState()?.trading?.tradingData) {
      toast.success("Order placed successfully!");
      setOpenModals({});
      getTradingData();
    } else if (store.getState()?.trading?.error) {
      toast.error(
        store.getState()?.trading?.errorMessage
          ? store.getState()?.trading?.errorMessage?.error
          : "Error will Re-order. Please try again."
      );
      setOpenModals({});
    }
  };

  const getSymbolsAccounts = async () => {
    await symbolsAccountsGet(dispatch);
    if (store.getState()?.symbol?.symbols_accounts) {
      setFilterData(store.getState()?.symbol?.symbols_accounts?.data);
    }
  };

  function findBuyStatus(message, id) {
    if (message) {
      for (let i = 0; i < message.length; i++) {
        const pair = message[i];
        if (pair.trading_id === id && pair.buyStatus) {
          return pair.buyStatus;
        }
      }
    }
    return null;
  }

  function findSellStatus(message, id) {
    if (message) {
      for (let i = 0; i < message.length; i++) {
        const pair = message[i];
        if (pair.trading_id === id && pair.sellStatus) {
          return pair.sellStatus;
        }
      }
    }
    return null;
  }

  function getBadgeBuyClass(message, id, switchType) {
    const buyStatus = findBuyStatus(message, id);
    const sellStatus = findSellStatus(message, id);
    if (switchType === "Buy" && buyStatus === "Done" && sellStatus !== "Done") {
      return "badgeGreenColor";
    } else if (
      switchType === "Sell" &&
      buyStatus === "Done" &&
      sellStatus === "Done"
    ) {
      return "badgeRedColor";
    } else {
      return "badgeGrayColor";
    }
  }

  function getBadgeBuyDbClass(buyStatus, sellStatus, switchType) {
    if (switchType === "Buy" && buyStatus === "Done" && sellStatus !== "Done") {
      return "badgeGreenColor";
    } else if (
      switchType === "Sell" &&
      buyStatus === "Done" &&
      sellStatus === "Done"
    ) {
      return "badgeRedColor";
    } else {
      return "badgeGrayColor";
    }
  }

  function getBadgeSellClass(message, id, switchType) {
    const buyStatus = findBuyStatus(message, id);
    const sellStatus = findSellStatus(message, id);
    if (
      switchType === "Sell" &&
      buyStatus !== "Done" &&
      sellStatus === "Done"
    ) {
      return "badgeGreenColor";
    } else if (
      switchType === "Buy" &&
      buyStatus === "Done" &&
      sellStatus === "Done"
    ) {
      return "badgeRedColor";
    } else {
      return "badgeGrayColor";
    }
  }

  function getBadgeSellDbClass(buyStatus, sellStatus, switchType) {
    if (
      switchType === "Sell" &&
      buyStatus !== "Done" &&
      sellStatus === "Done"
    ) {
      return "badgeGreenColor";
    } else if (
      switchType === "Buy" &&
      buyStatus === "Done" &&
      sellStatus === "Done"
    ) {
      return "badgeRedColor";
    } else {
      return "badgeGrayColor";
    }
  }

  const handleOpenModal = (rowId) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [rowId]: true,
    }));
  };

  const handleCloseModal = (rowId) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [rowId]: false,
    }));
  };

  const handleFilterSymbol = (event, value) => {
    if (value !== null) {
      setFilterParams({ ...filterParams, symbol: value.shortName });
    }
  };

  useEffect(() => {
    try{
      if (client_state?.client?.data?.client) {
        socket.send(String(client_state?.client?.data?.client));
      }
    }catch (error) {
      console.log("Error in socket:", error);
    }
    // eslint-disable-next-line
  }, [isReady, client_state]);

  useEffect(() => {
    if (isReady === false) {
      toast.warning("Socket Disconnected! Please refresh page for live update");
    }
    // eslint-disable-next-line
  }, [isReady]);

  useEffect(() => {
    if (message && Array.isArray(message)) {
      if (prevMessage) {
        // Iterate over each item in the current message
        message.forEach((currentItem) => {
          // Find the corresponding item in the previous message
          const prevItem = prevMessage.find(
            (prev) => prev.trading_id === currentItem.trading_id
          );
          // Compare cycle_done values if both current and previous items exist
          if (prevItem && prevItem.cycle_done !== currentItem.cycle_done) {
            // Log twice
            const audio = new Audio(Sound);
            audio.play().catch((error) => {
              console.log("Failed to play sound:", error);
            });
          }
        });
      }
      setPrevMessage(message);
    }
  }, [message, prevMessage]);

  useEffect(() => {
    getTradingData();
    getSymbolsAccounts();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="Main">
      <div className="container-fluid">
        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
          <div className="pt-4 sd-header">
            <Button className="adminbackBtn" onClick={ToggleSidebar}>
              <i className="feather icon-chevron-right"></i>
            </Button>
            <h3 className="mt-1">Filters</h3>
          </div>
          <div className="sd-body">
            <form>
              <div className="FilterForm">
                <div className="form-group">
                  <label>Account Name</label>
                  <select
                    className="form-control"
                    name="accountName"
                    value={filterParams?.accountName || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {filterData?.accounts?.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Symbol Full Name</label>
                  <Controller
                    name="symbol"
                    control={control}
                    rules={{ required: "Symbol is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        {...field}
                        filterOptions={filterOptions}
                        value={
                          filterData && field.value
                            ? filterData.symbols.find(
                              (option) =>
                                option.shortName ===
                                (field.value.shortName || "")
                            ) || null
                            : null
                        }
                        options={filterData ? filterData?.symbols : []}
                        onChange={(event, value) => {
                          field.onChange(value?.shortName || "");
                          if (value) {
                            handleFilterSymbol(event, value);
                          }
                        }}
                        getOptionLabel={(option) => option?.shortName || ""}
                        renderOption={(props, option) => (
                          <div
                            {...props}
                            key={option?.id}
                            style={{
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <div
                              style={{ fontSize: "13px" }}
                              className="symbolFont pt-1"
                            >
                              {option?.shortName?.toString()}
                            </div>
                            <div style={{ fontSize: "11px", color: "gray" }}>
                              <div
                                className="mr-2 companyNameFont"
                                style={{
                                  margin: "auto",
                                }}
                              >
                                {option?.companyName?.toString()}
                              </div>
                            </div>
                          </div>
                        )}
                        renderInput={(params) => <TextField {...params} />}
                        onClose={(event, reason) => {
                          if (reason === "toggleInput") {
                            field.onChange(null);
                            handleFilterSymbol(event, null);
                          }
                        }}
                      />
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Quantity</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Quantity"
                    name="quantity"
                    value={filterParams?.quantity || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Cycle Done</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Cycle Done"
                    name="cycle_done"
                    value={filterParams?.cycle_done || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Buy Price</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Buy Price"
                    name="buyPrice"
                    value={filterParams?.buyPrice || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Sell Price</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Sell Price"
                    name="sellPrice"
                    value={filterParams?.sellPrice || ""}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label>Switch Type</label>
                  <select
                    className="form-control"
                    name="switchType"
                    value={filterParams?.switchType || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option>Buy</option>
                    <option>Sell</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Buy Status</label>
                  <select
                    className="form-control"
                    name="buyStatus"
                    value={filterParams?.buyStatus || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option>Pending</option>
                    <option>Done</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Sell Status</label>
                  <select
                    className="form-control"
                    name="sellStatus"
                    value={filterParams?.sellStatus || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option>Pending</option>
                    <option>Done</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Status</label>
                  <select
                    className="form-control"
                    name="status"
                    value={filterParams?.status || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option>Enabled</option>
                    <option>Disabled</option>
                    <option>In Progress</option>
                  </select>
                </div>
              </div>
              <div className="d-flex pt-3 pb-4 applyChangeBtn">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleSubmit}
                >
                  Apply Changes
                </button>
                <button
                  type="button"
                  className="btn btn-square btn-danger mr-0"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/trading" style={{ fontWeight: 600 }}>
                            Trading
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3 col-md-12">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">List</h3>
                          <h4 style={{ marginTop: "6px" }}>
                            <span className="badge badge-secondary">
                              {tradingList?.length}
                            </span>
                          </h4>
                        </div>
                        <div className="tradingCreateButtons">
                          <button
                            type="button"
                            className="btn btn-square btn-primary"
                            onClick={() => navigate("/trading-create-bulk")}
                          >
                            <i className="feather icon-plus"></i> Create Bulk
                          </button>
                          <button
                            type="button"
                            className="btn btn-square btn-primary"
                            onClick={() => navigate("/trading-create")}
                          >
                            <i className="feather icon-plus"></i> Create New
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary mr-0"
                            onClick={ToggleSidebar}
                          >
                            Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  <Box sx={{ width: "100%" }}>
                    {tradingList?.length !== 0 ? (
                      <Paper
                        sx={{ width: "100%", mb: 2 }}
                        className="customTable"
                      >
                        <Toolbar
                          sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            ...(selected?.length > 0 && {
                              bgcolor: (theme) =>
                                alpha(
                                  theme.palette.primary.main,
                                  theme.palette.action.activatedOpacity
                                ),
                            }),
                          }}
                          className="p-1"
                        >
                          {selected?.length > 0 ? (
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              color="inherit"
                              variant="subtitle1"
                              component="div"
                            >
                              {selected?.length} selected
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              variant="h6"
                              id="tableTitle"
                              component="div"
                            >
                              Trading
                            </Typography>
                          )}

                          {selected?.length > 0 ? (
                            <Tooltip title="Delete">
                              <IconButton onClick={handleMultiDelete}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <i className="feather icon-trending-up pr-3"></i>
                          )}
                        </Toolbar>
                        <TableContainer>
                          <Table
                            className="addTableFontColor"
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead
                              numSelected={selected?.length}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={tradingList?.length}
                            />

                            <TableBody>
                              {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(row?.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row?.id}
                                    selected={isItemSelected}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onClick={(event) =>
                                          handleClick(event, row?.id)
                                        }
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell>{row?.accountName}</TableCell>
                                    <TableCell>{row?.symbol}</TableCell>
                                    <TableCell>
                                      <span
                                        className={
                                          row?.switchType === "Buy"
                                            ? "badge badge-secondary stockStatus badgeGreenColor"
                                            : "badge badge-secondary stockStatus badgeRedColor"
                                        }
                                      >
                                        {" "}
                                        {row?.switchType}
                                      </span>
                                    </TableCell>
                                    <TableCell>{row?.quantity}</TableCell>
                                    <TableCell>{row?.buyPrice}</TableCell>
                                    <TableCell>{row?.sellPrice}</TableCell>
                                    <TableCell>
                                      {message && Array.isArray(message) > 0
                                        ? message.map((pair, key) =>
                                          pair?.trading_id === row?.id &&
                                            pair?.cycle_done >= 0
                                            ? (pair?.cycle_done).toString()
                                            : null
                                        )
                                        : (row?.cycle_done).toString()}
                                    </TableCell>
                                    <TableCell>
                                      {message && Array.isArray(message) > 0 ? (
                                        message.map((pair, index) =>
                                          pair?.trading_id === row?.id &&
                                            pair?.buyStatus ? (
                                            <span
                                              key={index}
                                              className={`badge badge-secondary stockStatus ${getBadgeBuyClass(
                                                message,
                                                row?.id,
                                                row?.switchType
                                              )}`}
                                            >
                                              {" "}
                                              {pair?.buyStatus}
                                            </span>
                                          ) : null
                                        )
                                      ) : (
                                        <span
                                          key={index}
                                          className={`badge badge-secondary stockStatus ${getBadgeBuyDbClass(
                                            row?.buyStatus,
                                            row?.sellStatus,
                                            row?.switchType
                                          )}`}
                                        >
                                          {" "}
                                          {row?.buyStatus}
                                        </span>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {message && Array.isArray(message) > 0 ? (
                                        message.map((pair, index) =>
                                          pair?.trading_id === row?.id &&
                                            pair?.sellStatus ? (
                                            <span
                                              key={index}
                                              className={`badge badge-secondary stockStatus ${getBadgeSellClass(
                                                message,
                                                row?.id,
                                                row?.switchType
                                              )}`}
                                            >
                                              {" "}
                                              {pair?.sellStatus}
                                            </span>
                                          ) : null
                                        )
                                      ) : (
                                        <span
                                          key={index}
                                          className={`badge badge-secondary stockStatus ${getBadgeSellDbClass(
                                            row?.buyStatus,
                                            row?.sellStatus,
                                            row?.switchType
                                          )}`}
                                        >
                                          {" "}
                                          {row?.sellStatus}
                                        </span>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {message && Array.isArray(message) > 0 ? (
                                        message.map((pair, index) =>
                                          pair?.trading_id === row?.id &&
                                            pair?.status ? (
                                            <span
                                              key={index}
                                              className={
                                                pair?.status === "Disabled"
                                                  ? "badge badge-secondary stockStatus badgeRedColor"
                                                  : "badge badge-secondary stockStatus"
                                              }
                                            >
                                              {pair?.status}
                                            </span>
                                          ) : null
                                        )
                                      ) : (
                                        <span
                                          key={index}
                                          className={
                                            row?.status === "Disabled"
                                              ? "badge badge-secondary stockStatus badgeRedColor"
                                              : "badge badge-secondary stockStatus"
                                          }
                                        >
                                          {row?.status}
                                        </span>
                                      )}
                                    </TableCell>
                                    {message && Array.isArray(message) > 0 ? (
                                      message.map((pair, index) =>
                                        pair?.trading_id === row?.id ? (
                                          <>
                                            {pair?.errorMessage ? (
                                              <TableCell
                                                key={index}
                                                style={{ paddingLeft: "30px" }}
                                                onClick={() =>
                                                  handleOpenModal(row?.id)
                                                }
                                              >
                                                <i
                                                  className="feather icon-alert-circle"
                                                  style={{ color: "ef4c25" }}
                                                />
                                              </TableCell>
                                            ) : (
                                              <TableCell
                                                key={index}
                                                style={{ paddingLeft: "30px" }}
                                              >
                                                -
                                              </TableCell>
                                            )}
                                          </>
                                        ) : null
                                      )
                                    ) : (
                                      <>
                                        {row?.errorMessage ? (
                                          <TableCell
                                            key={index}
                                            style={{ paddingLeft: "30px" }}
                                            onClick={() =>
                                              handleOpenModal(row?.id)
                                            }
                                          >
                                            <i
                                              className="feather icon-alert-circle"
                                              style={{ color: "ef4c25" }}
                                            />
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            key={index}
                                            style={{ paddingLeft: "30px" }}
                                          >
                                            -
                                          </TableCell>
                                        )}
                                      </>
                                    )}

                                    <TableCell>
                                      <Link
                                        type="submit"
                                        className="btn btn-outline-primary mr-2 m-0 editDeleteBtn"
                                        to={`/trading-edit/${row?.id}`}
                                      >
                                        <i className="feather icon-edit m-0"></i>
                                      </Link>
                                      <button
                                        type="submit"
                                        className="btn btn-outline-danger m-0 editDeleteBtn"
                                        onClick={() => handleDelete(row?.id)}
                                      >
                                        <i className="feather icon-trash-2 m-0"></i>
                                      </button>
                                    </TableCell>
                                    <Modal
                                      open={!!openModals[row?.id]}
                                      onClose={() => handleCloseModal(row?.id)}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                      className="errorModal"
                                    >
                                      <Box sx={style}>
                                        <Typography
                                          id={`modal-modal-title-${row?.id}`}
                                          variant="h6"
                                          component="h2"
                                        >
                                          Error Message From Order
                                        </Typography>
                                        <Typography
                                          id={`modal-modal-description-${row?.id}`}
                                          sx={{ mt: 2 }}
                                        >
                                          {message && Array.isArray(message) > 0
                                            ? message.map((pair, key) =>
                                              pair?.trading_id === row?.id
                                                ? pair?.errorMessage
                                                : null
                                            )
                                            : row?.errorMessage}
                                        </Typography>
                                        <Typography className="pt-4">
                                          <Button
                                            variant="outlined"
                                            onClick={() =>
                                              handleReorder(row?.id)
                                            }
                                          >
                                            Re Order
                                          </Button>
                                        </Typography>
                                      </Box>
                                    </Modal>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={tradingList?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <div className="text-center">
                        <img
                          src={NotFound}
                          alt="Not Found"
                          className="notFoundImg"
                        />
                      </div>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Trading;