import { createSlice } from "@reduxjs/toolkit";

const uploadExcelSlice = createSlice({
  name: "uploadExcel",
  initialState: {
    uploadExcel: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    createUploadExcelStart: (state) => {
      state.isFetching = false;
      state.response = null;
      state.error = false;
      state.errorMessage = "";
    },
    createUploadExcelSuccess: (state, action) => {
      state.isFetching = true;
      state.response = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    createUploadExcelFailure: (state, error) => {
      state.isFetching = false;
      state.response = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    upstoxJsonFileStart: (state) => {
      state.isFetching = false;
      state.upstox_symbol = null;
      state.error = false;
      state.errorMessage = "";
    },
    upstoxJsonFileSuccess: (state, action) => {
      state.isFetching = true;
      state.upstox_symbol = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    upstoxJsonFileFailure: (state, error) => {
      state.isFetching = false;
      state.upstox_symbol = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    angelSymbolStart: (state) => {
      state.isFetching = false;
      state.angel_symbol = null;
      state.error = false;
      state.errorMessage = "";
    },
    angelSymbolSuccess: (state, action) => {
      state.isFetching = true;
      state.angel_symbol = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    angelSymbolFailure: (state, error) => {
      state.isFetching = false;
      state.angel_symbol = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getUploadExcelStart: (state) => {
      state.isFetching = false;
      state.uploadExcelList = null;
      state.error = false;
      state.errorMessage = "";
    },
    getUploadExcelSuccess: (state, action) => {
      state.isFetching = true;
      state.uploadExcelList = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getUploadExcelFailure: (state, error) => {
      state.isFetching = false;
      state.uploadExcelList = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getAngelSymbolStart: (state) => {
      state.isFetching = false;
      state.angel_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    getAngelSymbolSuccess: (state, action) => {
      state.isFetching = true;
      state.angel_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getAngelSymbolFailure: (state, error) => {
      state.isFetching = false;
      state.angel_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getUpstoxSymbolStart: (state) => {
      state.isFetching = false;
      state.upstox_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    getUpstoxSymbolSuccess: (state, action) => {
      state.isFetching = true;
      state.upstox_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getUpstoxSymbolFailure: (state, error) => {
      state.isFetching = false;
      state.upstox_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    fyersSymbolStart: (state) => {
      state.isFetching = false;
      state.fyers_symbol = null;
      state.error = false;
      state.errorMessage = "";
    },
    fyersSymbolSuccess: (state, action) => {
      state.isFetching = true;
      state.fyers_symbol = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    fyersSymbolFailure: (state, error) => {
      state.isFetching = false;
      state.fyers_symbol = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
    getFyersSymbolStart: (state) => {
      state.isFetching = false;
      state.fyers_symbols = null;
      state.error = false;
      state.errorMessage = "";
    },
    getFyersSymbolSuccess: (state, action) => {
      state.isFetching = true;
      state.fyers_symbols = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    getFyersSymbolFailure: (state, error) => {
      state.isFetching = false;
      state.fyers_symbols = null;
      state.error = true;
      state.errorMessage = error.payload;
    },
  },
});

export const {
  createUploadExcelFailure,
  createUploadExcelStart,
  createUploadExcelSuccess,
  getUploadExcelFailure,
  getUploadExcelStart,
  getUploadExcelSuccess,
  angelSymbolStart,
  angelSymbolSuccess,
  angelSymbolFailure,
  getAngelSymbolStart,
  getAngelSymbolFailure,
  getAngelSymbolSuccess,
  upstoxJsonFileStart,
  upstoxJsonFileSuccess,
  upstoxJsonFileFailure,
  getUpstoxSymbolStart,
  getUpstoxSymbolSuccess,
  getUpstoxSymbolFailure,
  fyersSymbolStart,
  fyersSymbolSuccess,
  fyersSymbolFailure,
  getFyersSymbolStart,
  getFyersSymbolSuccess,
  getFyersSymbolFailure,
} = uploadExcelSlice.actions;
export default uploadExcelSlice.reducer;
