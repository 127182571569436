// BASIC IMPORTS
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DateRangePicker, Stack } from "rsuite";
import { useForm } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { parseISO } from 'date-fns';
import Swal from "sweetalert2";
import PropTypes from "prop-types";

// MUI IMPORTS
import { Box, Autocomplete, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TableSortLabel, TextField, Switch } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import SceenerLogo from "../../../images/screener.png";
import { SharedStateProvider } from "../../main/SharedStateContext";

// API IMPORTS
import { autoBacktestCycleCount, fyersApiCallCounter, backtestSymbolCycleCount, countAutoBacktestApiCall } from "../../../actions/backtestAction";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";


const headCells = [
  {
    id: "chart",
    numeric: false,
    disablePadding: false,
    label: "Chart",
  },
  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "start_range",
    numeric: false,
    disablePadding: false,
    label: "Start Range",
  },
  {
    id: "gap",
    numeric: false,
    disablePadding: false,
    label: "Gap",
  },
  {
    id: "total_days",
    numeric: true,
    disablePadding: false,
    label: "Total Days",
  },
  {
    id: "cycle_count",
    numeric: true,
    disablePadding: false,
    label: "Cycle",
  },
  {
    id: "total_months",
    numeric: true,
    disablePadding: false,
    label: "Total Months",
  },
  {
    id: "monthly_cycle",
    numeric: true,
    disablePadding: false,
    label: "Monthly Cycle",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell?.id}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell?.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell?.id ? order : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell?.label}
              {orderBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function AutoBacktest() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("cycle_count");
  const [autobacktestPair, setAutobacktestPair] = useState({});
  const [autobacktestPairFilter, setAutobacktestPairFilter] = useState({});
  const [fyersApiCall, setFyersApiCall] = useState();
  const [dropdownList, setDropdownList] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [symbols, setSymbols] = useState({});
  const [selectedSymbols, setSelectedSymbols] = useState("");

  const switchLabel = { inputProps: { "aria-label": "Switch demo" } };
  const watchSwitchType = watch("switchType", "");
  const watchGapSwitch = watch("gapSwitch", "");

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { afterToday } = DateRangePicker;

  const [errorDateRange, setErrorDateRange] = useState("");
  const [dateRangePicker, setDateRangePicker] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const onSubmit = async (data) => {
    if (dateRangePicker.length === 0) {
      setErrorDateRange("Date Range is required");
      return;
    }

    const fromDateTime = new Date(dateRangePicker[0]);
    const toDateTime = new Date(dateRangePicker[1]);
    var batches = 0;

    const fromDate = `${fromDateTime.getFullYear()}-${(
      fromDateTime.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fromDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}T09:15:00.000Z`;
    const toDate = `${toDateTime.getFullYear()}-${(toDateTime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${toDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}T15:30:00.000Z`;

    const formattedData = {
      ...data,
      from_date: fromDate,
      to_date: toDate,
    };
    delete formattedData["dateRange"];

    formattedData["switchType"] = data?.switchType ? "Sell" : "Buy";
    await countAutoBacktestApiCall(dispatch, formattedData);
    if (store.getState()?.backtest?.auto_backtest_api_call_count) {
      batches = store.getState()?.backtest?.auto_backtest_api_call_count?.data;
    }
    if (batches !== 0) {
      Swal.fire({
        title: "Are you sure?",
        text: `This Process takes around ${batches} Api call`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Process it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setAutobacktestPairFilter({});
          await autoBacktestCycleCount(dispatch, formattedData);
          if (store.getState()?.backtest?.auto_backtest_cycle_count?.data) {
            toast.success(
              store.getState()?.backtest?.auto_backtest_cycle_count?.data
            );
            setDisabledBtn(true);
          } else {
            toast.error(
              store.getState()?.backtest?.auto_backtest_cycle_count?.error
            );
          }

          setPage(0);
          GetFyersApiCall();
        }
      });
    } else {
      toast.error("Symbol not found. Add first for further processing.");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const GetFyersApiCall = async () => {
    await fyersApiCallCounter(dispatch);
    if (store.getState()?.backtest?.fyers_api_call_counter) {
      setFyersApiCall(store.getState()?.backtest?.fyers_api_call_counter?.data);
    }
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState().exchange?.exchangeProductSettingList) {
      const res = store.getState().exchange?.exchangeProductSettingList;
      if (res) {
        setDropdownList(res?.data);
      }
    }
  };

  const getAutoBacktestData = async () => {
    await backtestSymbolCycleCount(dispatch).then((response) => {
      setAutobacktestPair(response?.data || {});
      setAutobacktestPairFilter(response?.data || {});
      if (response?.data) {
        const uniqueSymbolsWithIds = [
          ...new Set(response?.data?.data?.map((item) => item?.symbol)),
        ];
        setSymbols(uniqueSymbolsWithIds);
        if (response?.data["cron_complete"] === true) {
          setDisabledBtn(false);
        } else if (response?.data["cron_complete"] === false) {
          setDisabledBtn(true);
        }
      }

    })
  };

  const visibleRows = useMemo(
    () =>
      stableSort(
        autobacktestPairFilter?.data,
        getComparator(order, orderBy)
      )?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // eslint-disable-next-line
    [order, orderBy, page, rowsPerPage, autobacktestPairFilter?.data]
  );

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleResetForm = () => {
    reset();
    setDateRangePicker([]);
  }


  useEffect(() => {
    if (Object.keys(autobacktestPair).length !== 0) {
      if (autobacktestPair?.from_date !== undefined && autobacktestPair?.to_date !== undefined) {
        var from_date = new Date(autobacktestPair?.from_date);
        var to_date = new Date(autobacktestPair?.to_date);
        from_date = parseISO(from_date.toISOString());
        to_date = parseISO(to_date.toISOString());
        setDateRangePicker([from_date, to_date])
        setValue("account", autobacktestPair?.account)
        setValue("interval", autobacktestPair?.interval)
        setValue("filter_symbol", autobacktestPair?.filter_symbol)
        setValue("no_of_pair", autobacktestPair?.no_of_pair)
        setValue("gap", autobacktestPair?.gap)
        setValue("open_percentage", autobacktestPair?.open_percentage)
        setValue("switchType", autobacktestPair?.switchType === "Sell" ? true : false)
        setValue("gapSwitch", autobacktestPair?.gapSwitch)
        setDisabledBtn(!autobacktestPair?.cron_complete)
      }
    }
    // eslint-disable-next-line
  }, [autobacktestPair])

  useEffect(() => {
    const filterSymbol = autobacktestPair?.data?.filter(
      (item) => item?.symbol === selectedSymbols
    );
    if (filterSymbol !== undefined) {
      if (filterSymbol.length === 0) {
        setAutobacktestPairFilter(autobacktestPair)
      } else if (filterSymbol.length >= 0) {
        const filter_symbol = {
          data: filterSymbol,
          cron_complete: autobacktestPair?.cron_complete,
        };
        setAutobacktestPairFilter(filter_symbol);
      }
    }
    setPage(0)
    // eslint-disable-next-line
  }, [selectedSymbols]);

  useEffect(() => {
    GetFyersApiCall();
    getSettingData();
    getAutoBacktestData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/auto-backtest" style={{ fontWeight: 600 }}>
                            Auto Backtest
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3 col-md-12">
                        <div
                          style={{ marginTop: "6px", display: "flex" }}
                          className="pb-3"
                        >
                          {fyersApiCall
                            ? fyersApiCall?.length !== 0
                              ? "API CALL DETAIL :-"
                              : null
                            : null}
                          {fyersApiCall?.map((data, index) => (
                            <div key={index}>
                              <span className="ml-2">{data?.account_name}</span>{" "}
                              - <span className="mr-2">{data?.api_count}</span>
                              {index !== fyersApiCall?.length - 1 && (
                                <span>|</span>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="tradingCreateButtons">
                          <button
                            type="button"
                            className="btn btn-square btn-primary"
                            onClick={() =>
                              navigate("/add-bulk-backtest-symbols")
                            }
                          >
                            <i className="feather icon-plus"></i> Add Bulk
                            Symbols
                          </button>
                          <button
                            type="button"
                            className="btn btn-square btn-primary"
                            onClick={() =>
                              navigate("/add-view-backtest-symbols")
                            }
                          >
                            <i className="feather icon-plus"></i> Add Symbols
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-4 customDateRangeStyle pr-0">
                            <label>Date Range*</label>
                            <Stack>
                              <DateRangePicker
                                size="lg"
                                value={dateRangePicker}
                                onChange={(value) => {
                                  setDateRangePicker(value);
                                  setErrorDateRange("");
                                }}
                                disabledDate={afterToday()}
                                placeholder="Select..."
                              />
                            </Stack>
                            {errorDateRange && (
                              <div className="validationAlert">
                                Date Range is required
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-4 pr-0">
                            <label>Account*</label>
                            <select
                              className="form-control"
                              {...register("account", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              {dropdownList?.setting?.map((setting) => {
                                // Extracting the nested key from each setting object
                                const nestedKey = Object.keys(setting)[0];
                                // Extracting the nested keys from the nested object
                                const keys = Object.keys(setting[nestedKey]);
                                const keys_val = Object.values(
                                  setting[nestedKey]
                                );
                                return keys?.map((key, index) =>
                                  // keys_val[index] === "ICICI" ||
                                  keys_val[index] === "FYERS" ? (
                                    <option
                                      key={index}
                                      value={`${keys_val[index]} - ${key}`}
                                    >
                                      {key}
                                    </option>
                                  ) : null
                                );
                              })}
                            </select>
                            {errors.account && (
                              <span className="validationAlert">
                                Account is required
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-4 pr-0">
                            <label>Interval*</label>
                            <select
                              className="form-control"
                              {...register("interval", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              <option value="5second">5second</option>
                              <option value="15second">15second</option>
                              <option value="30second">30second</option>
                              <option value="1minute">1minute</option>
                              <option value="5minute">5minute</option>
                              <option value="30minute">30minute</option>
                              <option value="1day">1day</option>
                            </select>
                            {errors.interval && (
                              <span className="validationAlert">
                                Interval is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-4 pr-0">
                            <label>No Of Pair*</label>
                            <input
                              id="no_of_pair"
                              className="form-control"
                              placeholder="No Of Pair"
                              {...register("no_of_pair", {
                                required: "No Of Pair is required",
                                maxLength: {
                                  value: 100,
                                },
                              })}
                            />
                            {errors?.no_of_pair && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.no_of_pair?.message}
                              </div>
                            )}
                          </div>

                          <div className="form-group col-md-6 switchMargin pr-0">
                            <label>Gap*</label>&nbsp;&nbsp;&nbsp;
                            <label>( Numeric (Ex: 100)</label>
                            <Switch
                              {...switchLabel}
                              {...register("gapSwitch", { required: false })}
                                checked={watchGapSwitch ? true : false}
                                onChange={(event) => {
                                  setValue(
                                    "gapSwitch",
                                    event.target.checked ? true : false
                                  );
                                }}
                              className="switchGapStyle"
                            />
                            <label>Percentage (Ex: 0.25)(100*0.25) )</label>
                            <input
                              // type="number"
                              id="gap"
                              className="form-control"
                              placeholder="Gap"
                              {...register("gap", {
                                required: "Gap is required",
                                maxLength: {
                                  value: 100,
                                },
                              })}
                            />
                            {errors?.gap && (
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.gap?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2">
                            <label>Switch Type*</label>
                            <div
                              style={{
                                display: "flax",
                                color: "cadetblue",
                                fontWeight: 500,
                              }}
                            >
                              <label>Buy</label>
                              <Switch
                                {...switchLabel}
                                color="default"
                                {...register("switchType", { required: false })}
                                checked={watchSwitchType ? true : false}
                                onChange={(event) => {
                                  setValue(
                                    "switchType",
                                    event.target.checked ? true : false
                                  );
                                }}
                                className="switchStyle"
                              />
                              <label>Sell</label>
                            </div>
                          </div>
                        </div>


                        <div className="row col-md-12">
                          <div className="form-group col-md-4 pr-0">
                            <label>Start date below (%)</label>
                            <input
                              id="open_percentage"
                              className="form-control"
                              placeholder="Start date below (%)"
                              {...register("open_percentage", {
                                required: false
                              })}
                            />
                          </div>
                          <div className="form-group col-md-4 pr-0">
                            <label>Filter Symbol*</label>
                            <select
                              className="form-control"
                              {...register("filter_symbol", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              <option value="All">
                                All
                              </option>
                              <option value="Favourite">
                                Favourite
                              </option>
                              <option value="Unfavourite">
                                Unfavourite
                              </option>
                            </select>
                            {errors.filter_symbol && (
                              <span className="validationAlert">
                                Filter symbol is required
                              </span>
                            )}
                          </div>
                        </div>


                        <div className="mt-3 text-center">
                          <button
                            type="submit"
                            className="btn btn-outline-primary ml-3 px-5"
                            disabled={disabledBtn}
                          >
                            {disabledBtn ? "Processing..." : "Submit"}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger ml-3 px-4"
                            onClick={handleResetForm}
                          >
                            Clear
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  {visibleRows ? (
                    <div className="card">
                      <div className="card-body">
                        <div>
                          <div className="d-flex mb-3">
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              variant="h6"
                              id="tableTitle"
                              component="div"
                            >
                              Auto Backtest History
                            </Typography>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={symbols}
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(event, value) =>
                                setSelectedSymbols(value)
                              }
                            />
                          </div>
                          <TableContainer>
                            <Table>
                              <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={autobacktestPairFilter?.data?.length}
                              />
                              <TableBody>
                                {visibleRows?.map((row, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      key={index}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell>
                                        {row?.symbol &&
                                          row?.symbol.includes(":") &&
                                          row?.symbol.includes("-") && (
                                            <>
                                              <a
                                                className="btn m-0 mr-2"
                                                href={`https://www.screener.in/company/${row?.symbol.substring(
                                                  row?.symbol.indexOf(":") + 1,
                                                  row?.symbol.indexOf("-")
                                                )}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                  padding: "7px",
                                                  border: "1px solid limegreen",
                                                }}
                                              >
                                                <img
                                                  src={SceenerLogo}
                                                  alt="sceenerLogo"
                                                  style={{
                                                    width: "30px",
                                                    height: "25px",
                                                  }}
                                                />
                                              </a>
                                              <a
                                                className="btn m-0"
                                                href={`https://in.tradingview.com/chart/?symbol=${row?.symbol.split("-")[0]
                                                  }`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                  padding: "7px",
                                                  border: "1px solid black",
                                                }}
                                              >
                                                <svg
                                                  style={{ color: "black" }}
                                                  width="30"
                                                  height="25"
                                                  viewBox="0 0 36 28"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M14 22H7V11H0V4h14v18zM28 22h-8l7.5-18h8L28 22z"
                                                    fill="currentColor"
                                                  ></path>
                                                  <circle
                                                    cx="20"
                                                    cy="8"
                                                    r="4"
                                                    fill="currentColor"
                                                  ></circle>
                                                </svg>
                                              </a>
                                            </>
                                          )}
                                      </TableCell>
                                      <TableCell>{row?.symbol}</TableCell>
                                      <TableCell>{row?.start_range}</TableCell>
                                      <TableCell>
                                        {row?.gap}
                                        {row?.gapSwitch === true ? " (%)" : ""}
                                      </TableCell>
                                      <TableCell>{row?.total_days}</TableCell>
                                      <TableCell>{row?.cycle_count}</TableCell>
                                      <TableCell>
                                        {row?.total_months !== 0
                                          ? row?.total_months
                                          : "-"}
                                      </TableCell>
                                      <TableCell>
                                        {row?.monthly_cycle !== 0
                                          ? row?.monthly_cycle
                                          : "-"}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={autobacktestPairFilter?.data?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AutoBacktest;
