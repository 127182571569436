// BASIC IMPORTS
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

// API IMPORTS
import { liveDbToLocal, OtpVerificationForLiveDB } from "../../../actions/authAction";


const DBChangeOTPVerification = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);

  const handleOtpVerify = async (event) => {
    event.preventDefault();
    const otp = { otp: watch("verify_code") };
    await OtpVerificationForLiveDB(dispatch, otp);
    if (store.getState()?.auth?.otp_verify_data) {
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to Change Local DB as Live DB?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change Database",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await liveDbToLocal(dispatch);
          if (store.getState()?.auth?.live_to_local) {
            Swal.fire(
              "Done!",
              "Databse Transformation Successfully Complete",
              "success"
            );
            navigate("/users");
          } else {
            toast.warning("Databse Backup Not Done");
          }
        }
      });
    } else {
      toast.error("OTP Not Verified");
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r"></span>
          <span className="r s"></span>
          <span className="r s"></span>
          <span className="r"></span>
        </div>
        <form>
          <div className="card">
            <div className="card-body text-center">
              <dutton className="closeBtn" onClick={() => navigate("/users")}>
                <i className="feather icon-x"></i>
              </dutton>
              <div className="mb-4">
                <i className="feather icon-mail auth-icon"></i>
              </div>
              <h3 className="mb-4">Verify Yourself As Admin</h3>
              <div className="input-group mb-3">
                <div className="input-group">
                  <input
                    type="number"
                    id="verify_code"
                    className="form-control"
                    name="verify_code"
                    placeholder="OTP"
                    {...register("verify_code", {
                      required: "code is required",
                      minLength: {
                        value: 4,
                        message: "Invalid code!",
                      },
                      maxLength: {
                        value: 4,
                        message: "Invalid code!",
                      },
                    })}
                  />
                </div>
                {errors?.verify_code && (
                  <div
                    id="newPaswordAlert"
                    className="validationAlert"
                    style={{ margin: "10px 0px" }}
                  >
                    {errors?.verify_code.message}
                  </div>
                )}
              </div>
              <button
                className="btn btn-outline-primary mb-4"
                onClick={(e) => handleOtpVerify(e)}
              >
                Verify
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DBChangeOTPVerification;
