// BASIC IMPORTS
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// MUI IMPORTS
import { Button, Autocomplete, Typography, TextField, Box } from "@mui/material";

// FILE IMPORTS
import { getAllFavSymbolsWithAccounts } from "../../../reducers/symbolReducer";

// API IMPORTS
import { getFavSymbolsAccounts } from "../../../actions/symbolAction";


function SymbolEditForm({ initialValues, onSubmit }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({ defaultValues: initialValues });


  const [filterSymbolsData, setFilterSymbolsData] = useState([]);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favSymbolsAccounts = useSelector(getAllFavSymbolsWithAccounts);

  
  const getSymbolsAccounts = async () => {
    if (favSymbolsAccounts?.length <= 0) {
      await getFavSymbolsAccounts(dispatch).then((res) => {
        setFilterSymbolsData(res?.data);
      }).catch((error) => {
        toast.error("Favourite symbols, accounts data not found")
      })
    } else {
      setFilterSymbolsData(favSymbolsAccounts)
    }
  };


  const options = filterSymbolsData?.accounts
    ? Object.keys(filterSymbolsData.accounts).map(key => ({
      key: key,
      value: filterSymbolsData.accounts[key]
    }))
    : [];


  useEffect(() => {
    getSymbolsAccounts();
    // eslint-disable-next-line
  }, [dispatch]);


  useEffect(() => {
    if (initialValues) {
      // Set form values when the component mounts
      Object?.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
    }
    // eslint-disable-next-line
  }, [initialValues]);


  return (
    <>
      <div className="page-header-title d-flex justify-content-between pb-3">
        <div className="ListWithBadge">
          <Button className="adminbackBtn" onClick={() => navigate("/symbols")}>
            <i className="feather icon-chevron-right"></i>
          </Button>
          <h3 className="m-b-20 mr-2">Edit Symbol</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body mobileCardBody">
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Account*</label>
                    <Controller
                      name="accountName"
                      control={control}
                      rules={{ required: "Account is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          className="favAccountDropdown"
                          options={options}
                          getOptionLabel={(option) => option.key}
                          isOptionEqualToValue={(option, value) =>
                            option?.key === value?.key
                          }
                          onChange={(_, value) => {
                            field.onChange(value);
                            setValue("accountName", value);
                          }}
                          value={field.value || null}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography style={{ float: "left" }}>
                                {option.key}
                              </Typography>
                              <Typography style={{ float: "right" }}>
                                {option.value}
                              </Typography>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!errors.accountName}
                              helperText={
                                errors.accountName ? errors.accountName.message : ""
                              }
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.accountName && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.accountName?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Symbol*</label>
                    <input
                      className="form-control"
                      placeholder="Symbol"
                      {...register("symbol", {
                        required: "Symbol is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.symbol && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.symbol?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Symbol Full Name*</label>
                    <input
                      className="form-control"
                      placeholder="Symbol Full Name"
                      {...register("companyName", {
                        required: "Symbol full name is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.companyName && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.companyName?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Exchange*</label>
                    <input
                      className="form-control"
                      placeholder="Exchange"
                      {...register("exchange", {
                        required: "Exchange is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.exchange && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.exchange?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Lotsize*</label>
                    <input
                      className="form-control"
                      placeholder="Lotsize"
                      {...register("lotSize", {
                        required: "Lotsize is required",
                        maxLength: {
                          value: 100,
                        },
                      })}
                    />
                    {errors?.lotSize && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.lotSize?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Tick Size*</label>
                    <Controller
                      name="tickSize"
                      control={control}
                      rules={{
                        required: "Tick Size is required",
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <select {...field} className="form-control">
                            <option value="" disabled>
                              Select...
                            </option>
                            <option>0.01</option>
                            <option>0.05</option>
                          </select>
                        </>
                      )}
                    />
                    {errors?.tickSize && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.tickSize?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <label>Expiry Date</label>
                    <input
                      className="form-control"
                      placeholder="Expiry Date"
                      {...register("expiry_date", {})}
                    />
                    {errors?.expiry_date && (
                      <div
                        className="validationAlert"
                        style={{ margin: "10px 0px" }}
                      >
                        {errors?.expiry_date?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="col-md-12 justify-content-center text-center pt-3">
                    <button type="submit" className="btn btn-primary ml-3 px-5">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SymbolEditForm;
