// BASIC IMPORTS
import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ReactReduxContext, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

// MUI IMPORTS
import { alpha } from "@mui/material/styles";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper, Tooltip, Toolbar, Typography, Checkbox, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import NotFound from "../../../images/not_found.png";
import { SharedStateProvider } from "../../main/SharedStateContext";

// API IMPORTS
import { pairRangeMessagegsGet, deletePairRangeMsg } from "../../../actions/tradingAction";


const headCells = [
  {
    id: "accountName",
    numeric: true,
    disablePadding: false,
    label: "Account",
  },
  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "exchange",
    numeric: true,
    disablePadding: false,
    label: "Exchange",
  },
  {
    id: "message",
    numeric: true,
    disablePadding: false,
    label: "Message",
  },
];

function EnhancedTableHead(props) {
  const {
    numSelected,
    onSelectAllClick,
    rowCount,
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            padding={headCell?.disablePadding ? "none" : "normal"}
          >
            {headCell?.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function PairRangeMessage() {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rangeMessages, setRangeMessages] = useState([]);

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const isSelected = (id) => selected?.indexOf(id) !== -1;

  const getPairRangeMessage = async () => {
    await pairRangeMessagegsGet(dispatch);
    if (store.getState()?.trading?.pairRangeMessage) {
      setRangeMessages(store.getState()?.trading?.pairRangeMessage?.data);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rangeMessages?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleMultiDelete = async () => {
    if (selected.length !== 0) {
      // delte multiple records
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to delete the selected ${selected?.length} record?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { ids: selected };
          await deletePairRangeMsg(data).then((res) => {
            Swal.fire(
              "Deleted!",
              "All selected record deleted successfully.",
              "success"
            );
            setSelected([]);
            getPairRangeMessage();
          }).catch((error) => {
            toast.error("Error while deleting data. Please try again.")
          })
        }
      });
    }
  };

  useEffect(() => {
    getPairRangeMessage();
    localStorage.setItem("range_notification", "");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/pair-range" style={{ fontWeight: 600 }}>
                            Pair Range Messages
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">List</h3>
                          <h4 style={{ marginTop: "6px" }}>
                            <span className="badge badge-secondary">
                              {rangeMessages?.length}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  <Box sx={{ width: "100%" }}>
                    {rangeMessages.length !== 0 ? (
                      <Paper
                        sx={{ width: "100%", mb: 2 }}
                        className="customTable"
                      >
                        <Toolbar
                          sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            ...(selected?.length > 0 && {
                              bgcolor: (theme) =>
                                alpha(
                                  theme.palette.primary.main,
                                  theme.palette.action.activatedOpacity
                                ),
                            }),
                          }}
                          className="p-1"
                        >
                          {selected?.length > 0 ? (
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              color="inherit"
                              variant="subtitle1"
                              component="div"
                            >
                              {selected?.length} selected
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ flex: "1 1 100%" }}
                              variant="h6"
                              id="tableTitle"
                              component="div"
                            >
                              Pair Range Messages
                            </Typography>
                          )}

                          {selected?.length > 0 ? (
                            <Tooltip title="Delete">
                              <IconButton onClick={handleMultiDelete}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <i className="feather icon-trending-up pr-3"></i>
                          )}
                        </Toolbar>
                        <TableContainer>
                          <Table
                            className="addTableFontColor"
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead
                              numSelected={selected?.length}
                              onSelectAllClick={handleSelectAllClick}
                              rowCount={rangeMessages?.length}
                            />
                            <TableBody>
                              {rangeMessages?.map((row, index) => {
                                const isItemSelected = isSelected(row._id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row._id}
                                    selected={isItemSelected}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onClick={(event) =>
                                          handleClick(event, row._id)
                                        }
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>{row?.accountName}</TableCell>
                                    <TableCell>{row?.symbol}</TableCell>
                                    <TableCell>{row?.exchange}</TableCell>
                                    <TableCell>{row?.message}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={rangeMessages?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <div className="text-center">
                        <img
                          src={NotFound}
                          alt="Not Found"
                          className="notFoundImg"
                        />
                      </div>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PairRangeMessage;
