// BASIC IMPORTS
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DateRangePicker, Stack } from "rsuite";
import { useForm } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { parseISO } from 'date-fns';
import { saveAs } from 'file-saver'; 
import * as XLSX from 'xlsx'; 

// MUI IMPORTS
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, Switch, Autocomplete, Typography } from '@mui/material';

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import SceenerLogo from "../../../images/screener.png";
import ExcelLogo from "../../../images/excel.png";

// API IMPORTS
import { repeatBacktestHistoricalData, fyersApiCallCounter, countAutoBacktestApiCall, repeatBacktestCycleData, stopRepeatBacktestCron } from "../../../actions/backtestAction";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";


function RepeatBacktest() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [page, setPage] = useState(0);
  const [backtestHistory, setBacktestHistory] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [repeatBacktestCycle, setRepeatBacktestCycle] = useState([]);
  const [fyersApiCall, setFyersApiCall] = useState();
  const [dropdownList, setDropdownList] = useState();
  const [selectedSymbols, setSelectedSymbols] = useState("");
  const [symbols, setSymbols] = useState({});

  const [formData, setFormData] = useState([
    { id: 1, no_of_pair: '', gapSwitch: false, gap: '', open_percentage: '', switchType: 'Buy' }
  ]);

  const switchLabel = { inputProps: { "aria-label": "Switch demo" } };

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const { afterToday } = DateRangePicker;

  const [errorDateRange, setErrorDateRange] = useState("");
  const [dateRangePicker, setDateRangePicker] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const onSubmit = async (data) => {
    if (dateRangePicker.length === 0) {
      setErrorDateRange("Date Range is required");
      return;
    }

    const fromDateTime = new Date(dateRangePicker[0]);
    const toDateTime = new Date(dateRangePicker[1]);
    var batches = 0;

    const fromDate = `${fromDateTime.getFullYear()}-${(
      fromDateTime.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fromDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}T09:15:00.000Z`;
    const toDate = `${toDateTime.getFullYear()}-${(toDateTime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${toDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}T15:30:00.000Z`;

    const formattedData = {
      ...data,
      from_date: fromDate,
      to_date: toDate,
    };
    delete formattedData["dateRange"];
    await countAutoBacktestApiCall(dispatch, formattedData);
    if (store.getState()?.backtest?.auto_backtest_api_call_count) {
      batches = store.getState()?.backtest?.auto_backtest_api_call_count?.data;
    }
    if (batches !== 0) {
      Swal.fire({
        title: "Are you sure?",
        text: `This Process takes around ${batches} Api call`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Process it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await repeatBacktestHistoricalData(dispatch, formattedData).then((res) => {
            setDisabledBtn(true);
          }).catch((error) => {
            console.log(error);
          })

          GetFyersApiCall();
        }
      });
    } else {
      toast.error("Symbol not found. Add first for further processing.");
    }
  };


  const GetFyersApiCall = async () => {
    await fyersApiCallCounter(dispatch);
    if (store.getState()?.backtest?.fyers_api_call_counter) {
      setFyersApiCall(store.getState()?.backtest?.fyers_api_call_counter?.data);
    }
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState().exchange?.exchangeProductSettingList) {
      const res = store.getState().exchange?.exchangeProductSettingList;
      if (res) {
        setDropdownList(res?.data);
      }
    }
  };

  const getRepeatBacktestHistory = async () => {
    await repeatBacktestCycleData(dispatch).then((response) => {
      setBacktestHistory(response?.data?.search_history || {})
      setRepeatBacktestCycle(response?.data?.cycle_history)
      setFilterData(response?.data?.cycle_history)
      setDisabledBtn(!response?.data?.cron_complete)
      const uniqueSymbolsWithIds = [
        ...new Set(response?.data?.cycle_history?.map((item) => item?.symbol)),
      ];
      setSymbols(uniqueSymbolsWithIds);
    })
  };

  const handleFormField = () => {
    const newForm = {
      id: formData.length + 1,
      no_of_pair: '',
      gapSwitch: false,
      gap: '',
      open_percentage: '',
      switchType: 'Buy'
    };
    setFormData([...formData, newForm]);
  };




  const rowsPerPage = 10;

  // Function to process data and format into rows with dynamic columns
  const processAndFormatData = (data) => {
    let processedData = {};

    data?.forEach((obj, index) => {
      const { symbol, start_range, gap, monthly_cycle, gapSwitch } = obj;
      if (!processedData[symbol]) {
        processedData[symbol] = {
          symbol: symbol,
          [`Start_At_${gap}`]: start_range,
          [`Gap_${gap}`]: `${gap}${gapSwitch === true ? "%" : ""}`,
          [`Mon_Cyc_${gap}`]: monthly_cycle
        };
      } else {
        processedData[symbol][`Gap_${gap}`] = `${gap}${gapSwitch === true ? "%" : ""}`;
        processedData[symbol][`Mon_Cyc_${gap}`] = monthly_cycle;
      }
    });

    return Object.values(processedData);
  };

  // Processed data with dynamic columns
  const formattedData = processAndFormatData(filterData);

  // Function to get unique keys (columns) from processed data
  const getUniqueKeys = (formattedData) => {
    let keys = {};
    formattedData?.forEach(obj => {
      Object.keys(obj).forEach(key => {
        keys[key] = true;
      });
    });
    return Object.keys(keys);
  };

  const allColumns = getUniqueKeys(formattedData);
  const columns = allColumns.filter(column => column !== 'symbol');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to convert data to Excel format and initiate download
  const convertDataToExcel = () => {
    // Create a new array to store all rows including the Chart column
    const exportData = formattedData?.map(row => {
      const rowData = {
        Symbol: row.symbol
      };
      columns.forEach((column, index) => {
        rowData[column] = row[column]; // Assuming each column exists in row
      });
      return rowData;
    });
  
    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(exportData);
  
    // Create workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Generate XLSX file and initiate download using FileSaver
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const fileName = 'dynamic_data.xlsx';
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), fileName);
  };
  


  // Utility function to convert s to array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  };


  const handleResetForm = () => {
    reset();
    setDateRangePicker([]);
  }

  const handleStopCron = async () => {
    await stopRepeatBacktestCron(dispatch).then((res) => {
      toast.success(res?.data)
      setDisabledBtn(false)
    }).catch((error) => {
      toast.error("Cron not stop due to some error")
    })
  }

  useEffect(() => {
    if (Object.keys(backtestHistory).length !== 0) {
      if (backtestHistory?.from_date !== undefined && backtestHistory?.to_date !== undefined) {
        var from_date = new Date(backtestHistory?.from_date);
        var to_date = new Date(backtestHistory?.to_date);
        from_date = parseISO(from_date.toISOString());
        to_date = parseISO(to_date.toISOString());
        setDateRangePicker([from_date, to_date])
        setValue("account", backtestHistory?.account)
        setValue("interval", backtestHistory?.interval)
        setValue("filter_symbol", backtestHistory?.filter_symbol)
      }
    }
    // eslint-disable-next-line
  }, [backtestHistory])


  useEffect(() => {
    const filterSymbol = repeatBacktestCycle?.filter(
      (item) => item?.symbol === selectedSymbols
    );
    if (filterSymbol.length === 0) {
      setFilterData(repeatBacktestCycle);
    } else if (filterSymbol.length >= 0) {
      setFilterData(filterSymbol);
    }
    setPage(0);
    // eslint-disable-next-line
  }, [selectedSymbols]);

  useEffect(() => {
    GetFyersApiCall();
    getSettingData();
    getRepeatBacktestHistory();
    // eslint-disable-next-line
  }, []);


  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/repeat-backtest" style={{ fontWeight: 600 }}>
                            Repeat Backtest
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-0 col-md-12">
                        <div
                          style={{ marginTop: "6px", display: "flex" }}
                          className="pb-3"
                        >
                          {fyersApiCall
                            ? fyersApiCall?.length !== 0
                              ? "API CALL DETAIL :-"
                              : null
                            : null}
                          {fyersApiCall?.map((data, index) => (
                            <div key={index}>
                              <span className="ml-2">{data?.account_name}</span>{" "}
                              - <span className="mr-2">{data?.api_count}</span>
                              {index !== fyersApiCall?.length - 1 && (
                                <span>|</span>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-body pt-3 pb-1">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-3 customDateRangeStyle pr-0">
                            <label>Date Range*</label>
                            <Stack>
                              <DateRangePicker
                                size="lg"
                                value={dateRangePicker}
                                onChange={(value) => {
                                  setDateRangePicker(value);
                                  setErrorDateRange("");
                                }}
                                disabledDate={afterToday()}
                                placeholder="Select..."
                              />
                            </Stack>
                            {errorDateRange && (
                              <div className="validationAlert">
                                Date Range is required
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Account*</label>
                            <select
                              className="form-control"
                              {...register("account", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              {dropdownList?.setting?.map((setting) => {
                                // Extracting the nested key from each setting object
                                const nestedKey = Object.keys(setting)[0];
                                // Extracting the nested keys from the nested object
                                const keys = Object.keys(setting[nestedKey]);
                                const keys_val = Object.values(
                                  setting[nestedKey]
                                );
                                return keys.map((key, index) =>
                                  // keys_val[index] === "ICICI" ||
                                  keys_val[index] === "FYERS" ? (
                                    <option
                                      key={index}
                                      value={`${keys_val[index]} - ${key}`}
                                    >
                                      {key}
                                    </option>
                                  ) : null
                                );
                              })}
                            </select>
                            {errors.account && (
                              <span className="validationAlert">
                                Account is required
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Interval*</label>
                            <select
                              className="form-control"
                              {...register("interval", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              <option value="5second">5second</option>
                              <option value="15second">15second</option>
                              <option value="30second">30second</option>
                              <option value="1minute">1minute</option>
                              <option value="5minute">5minute</option>
                              <option value="30minute">30minute</option>
                              <option value="1day">1day</option>
                            </select>
                            {errors.interval && (
                              <span className="validationAlert">
                                Interval is required
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Filter Symbol*</label>
                            <select
                              className="form-control"
                              {...register("filter_symbol", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select...
                              </option>
                              <option value="All">
                                All
                              </option>
                              <option value="Favourite">
                                Favourite
                              </option>
                              <option value="Unfavourite">
                                Unfavourite
                              </option>
                            </select>
                            {errors.filter_symbol && (
                              <span className="validationAlert">
                                Filter symbol is required
                              </span>
                            )}
                          </div>
                        </div>

                        {formData?.map((form, index) => (
                          <div className="row col-md-12 pr-0" key={index}>
                            <div className="form-group col-md-2 pr-0">
                              <label>No Of Pair*</label>
                              <input
                                id={`no_of_pair_${index}`}
                                className="form-control"
                                placeholder="No Of Pair"
                                {...register(`formData[${index}].no_of_pair`, {
                                  required: "No Of Pair is required",
                                  maxLength: { value: 100 }
                                })}
                              />
                              {errors?.formData && errors.formData[index]?.no_of_pair && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors.formData[index].no_of_pair.message}
                                </div>
                              )}
                            </div>

                            <div className="form-group col-md-6 switchMargin pr-0">
                              <label>Gap*</label>&nbsp;&nbsp;&nbsp;
                              <label>( Numeric (Ex: 100)</label>
                              <Switch
                                {...switchLabel}
                                {...register(`formData[${index}].gapSwitch`, { required: false })}
                                className="switchGapStyle"
                              />
                              <label>Percentage (Ex: 0.25)(100*0.25) )</label>
                              <input
                                id={`gap_${index}`}
                                className="form-control"
                                placeholder="Gap"
                                {...register(`formData[${index}].gap`, {
                                  required: "Gap is required",
                                  maxLength: { value: 100 }
                                })}
                              />
                              {errors?.formData && errors.formData[index]?.gap && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors.formData[index].gap.message}
                                </div>
                              )}
                            </div>

                            <div className="form-group col-md-2 pr-0">
                              <label>Start date below (%)</label>
                              <input
                                id={`open_percentage_${index}`}
                                className="form-control"
                                placeholder="Start date below (%)"
                                {...register(`formData[${index}].open_percentage`, { required: false })}
                              />
                            </div>

                            <div className="form-group col-md-2">
                              <label>Switch Type*</label>
                              <div style={{ display: "flex", color: "cadetblue", fontWeight: 500 }}>
                                <label>Buy</label>
                                <Switch
                                  name={`switchType_${index}`}
                                  color="default"
                                  {...register(`formData[${index}].switchType`, { required: false })}
                                  className="switchStyle"
                                />
                                <label>Sell</label>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="mt-2 text-center">
                          <button
                            type="submit"
                            className="btn btn-outline-primary ml-3 px-4"
                            disabled={disabledBtn}
                          >
                            {disabledBtn ? "Processing..." : "Submit"}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary ml-3 px-3"
                            onClick={handleFormField}
                          >
                            Add Form
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger ml-3 px-4"
                            onClick={handleResetForm}
                          >
                            Clear
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger ml-3 px-4"
                            onClick={handleStopCron}
                          >
                            Stop Cron
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-body">
                      <div>
                        <div className="d-flex mb-3">
                          <Typography
                            sx={{ flex: "1 1 100%" }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                          >
                            Repeat Backtest History
                          </Typography>
                          <button
                            onClick={convertDataToExcel}
                            className="btn mr-4 p-1"
                            aria-label="delete"
                            style={{ width: "8%", height: "30%", border: "1px solid darkgreen", background: "transparent" }}
                          >
                            <img src={ExcelLogo} alt="excelLogo" className="w-50 h-50" />
                          </button>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={symbols}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            onChange={(event, value) =>
                              setSelectedSymbols(value)
                            }
                          />
                        </div>
                        <div>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Chart</TableCell>
                                  <TableCell>Symbol</TableCell>
                                  {columns?.map(column => (
                                    <TableCell key={column}>{column}</TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? formattedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  : formattedData
                                ).map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell key='chart' style={{ display: "flex" }}>
                                      {row?.symbol && row?.symbol.includes(":") && row?.symbol.includes("-") ? (
                                        <>
                                          <a
                                            className="btn m-0 mr-2"
                                            href={`https://www.screener.in/company/${row?.symbol.substring(
                                              row?.symbol.indexOf(":") + 1,
                                              row?.symbol.indexOf("-")
                                            )}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                              padding: "7px",
                                              border: "1px solid limegreen",
                                            }}
                                          >
                                            <img
                                              src={SceenerLogo}
                                              alt="screenerLogo"
                                              style={{
                                                width: "30px",
                                                height: "25px",
                                              }}
                                            />
                                          </a>
                                          <a
                                            className="btn m-0"
                                            href={`https://in.tradingview.com/chart/?symbol=${row?.symbol.split("-")[0]}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                              padding: "7px",
                                              border: "1px solid black",
                                            }}
                                          >
                                            <svg
                                              style={{ color: "black" }}
                                              width="30"
                                              height="25"
                                              viewBox="0 0 36 28"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M14 22H7V11H0V4h14v18zM28 22h-8l7.5-18h8L28 22z"
                                                fill="currentColor"
                                              ></path>
                                              <circle
                                                cx="20"
                                                cy="8"
                                                r="4"
                                                fill="currentColor"
                                              ></circle>
                                            </svg>
                                          </a>
                                        </>
                                      ) : (
                                        'N/A'
                                      )}
                                    </TableCell>
                                    <TableCell key='symbol'>{row.symbol}</TableCell>
                                    {columns?.map(column => (
                                      <TableCell key={column}>{row[column]}</TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[]} // disabling per page options
                            component="div"
                            count={formattedData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RepeatBacktest;
